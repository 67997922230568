import { FunctionComponent, useContext, useState } from 'react'
import {
    Deletement,
    DeploymentModel,
    FlowInstanceModel,
    ProductType,
    State,
    StateToStringMap,
    Status,
    StatusToStringMap,
} from '../../Model/AllSparkModel'
import Button, { ButtonType } from '../../../infratructure/button/Button'
import LoaderDots from '../../../infratructure/dots/LoaderDots'
import Grid from '../../../infratructure/grid/Grid'
import Heading from '../../../infratructure/heading/Heading'
import styles from './DeleteDeploymentConfirmation.module.scss'
import useInterval from '../../../infratructure/hooks/useInterval'
import useAsyncError from '../../../infratructure/hooks/useAsyncError'
import { useGlobalStateContext } from '../../../../GlobalState'
import { AllSparkContext } from '../../../..'

type DeleteFlowState = {
    pollId: string
    pollInterval: number | null
    deleteResult: Deletement | undefined
    showDeletingDetails: boolean
    productType: ProductType
    isWorking: boolean
    isFinished: boolean
    deploymentName: string
}

interface DeleteDeploymentConfirmationProps {
    selectedDeployment?: DeploymentModel | FlowInstanceModel
    productType: ProductType
    onClose: () => void
    onWorking: (isWorking: boolean) => void
    refresh: () => void
}

const DeleteDeploymentConfirmation: FunctionComponent<DeleteDeploymentConfirmationProps> = ({
    selectedDeployment,
    productType,
    onClose,
    onWorking,
    refresh,
}) => {
    const api = useContext(AllSparkContext)
    const throwError = useAsyncError()
    const { setGlobalState } = useGlobalStateContext()

    const [state, setState] = useState<DeleteFlowState>({
        pollId: '',
        pollInterval: null,
        deleteResult: undefined,
        showDeletingDetails: false,
        productType: productType,
        isWorking: false,
        isFinished: false,
        deploymentName: '',
    })

    function delay(t: number) {
        return new Promise(function (resolve) {
            setTimeout(resolve.bind(null, ''), t)
        })
    }

    useInterval(async () => {
        setState((prev) => ({ ...prev, isValidating: false }))
        const pollResult = await api.getDeletement(state.pollId)
        console.log('deleting... =>', pollResult)

        setState((prev) => ({ ...prev, deleteResult: pollResult }))

        const endDeleteProcess = () => {
            setState((prev) => ({
                ...prev,
                pollId: '',
                pollInterval: null,
                deleteResult: undefined,
                showDeletingDetails: false,
                isWorking: false,
                isFinished: true,
            }))

            onWorking(false)
            refresh()
        }

        if (pollResult?.status === null) {
            endDeleteProcess()
            setGlobalState((prev) => ({ ...prev, isDeleteDialogVisible: false, isSpinning: false, isSparking: false }))
        }
    }, state.pollInterval)

    const onDelete = async () => {
        if (productType === ProductType.Flow && selectedDeployment) {
            try {
                setState((prev) => ({
                    ...prev,
                    isWorking: true,
                    isFinished: false,
                    deploymentName: selectedDeployment.name,
                }))
                setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: true }))
                onWorking(true)

                await api.deleteFlowDeployment(selectedDeployment.deploymentId)
                await delay(5000) //If the polling starts to early, the polling thinks it is done before it has started, hence we set in an artificial delay

                if (!state.pollInterval) {
                    setState((prev) => ({
                        ...prev,
                        pollId: selectedDeployment.deploymentId,
                        pollInterval: 4000,
                        showDeletingDetails: true,
                    }))
                }
            } catch (error) {
                console.log('Delete failed !!', error)
                setState((prev) => ({ ...prev, isDeleting: false, isWorking: false }))
                onWorking(false)
                setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
                throwError(error)
            }
        } else if (productType === ProductType.Invision && selectedDeployment) {
            try {
                setState((prev) => ({
                    ...prev,
                    isWorking: true,
                    isFinished: false,
                    deploymentName: selectedDeployment.name,
                }))
                onWorking(true)
                setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: true }))

                await api.deleteDeployment(selectedDeployment.deploymentId)
                await delay(5000) //If the polling starts to early, the polling thinks it is done before it has started, hence we set in an artificial delay

                if (!state.pollInterval) {
                    setState((prev) => ({
                        ...prev,
                        pollId: selectedDeployment.deploymentId,
                        pollInterval: 4000,
                        showDeletingDetails: true,
                    }))
                }
            } catch (error) {
                console.log('Delete failed !!', error)
                setState((prev) => ({ ...prev, isDeleting: false, isWorking: false }))
                onWorking(false)
                setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
                throwError(error)
            }
        }
    }

    const renderProgressDetails = () => {
        const isRunning = (currentState: State | undefined) => {
            if (
                currentState !== undefined &&
                currentState !== State.NotStarted &&
                currentState !== State.FinishedDeleting
            ) {
                return true
            }

            return false
        }

        return (
            <Grid
                rows="auto auto auto auto auto auto auto auto auto auto"
                columns="230px 100px 155px auto"
                style={{ margin: '30px 0 30px 10px' }}
                gap={4}
            >
                <Heading type="sub">Section</Heading>
                <Heading type="sub">Status</Heading>
                <Heading type="sub">State</Heading>
                <div />
                <Heading type="normal">Deleting App Registration</Heading>
                <Heading type="normal">
                    {StatusToStringMap(state.deleteResult?.status?.applicationDeletions?.status || Status.None)}
                </Heading>
                <Heading type="normal">
                    {StateToStringMap(state.deleteResult?.status?.applicationDeletions?.state || State.NotStarted)}
                </Heading>
                {isRunning(state.deleteResult?.status?.applicationDeletions?.state || State.NotStarted) ? (
                    <LoaderDots color="black" />
                ) : (
                    <div />
                )}

                <Heading type="normal">Deleting Servicebus Queues & Topics</Heading>
                <Heading type="normal">
                    {StatusToStringMap(
                        state.deleteResult?.status?.servicebusQueueAndTopicDeletions?.status || Status.None,
                    )}
                </Heading>
                <Heading type="normal">
                    {StateToStringMap(
                        state.deleteResult?.status?.servicebusQueueAndTopicDeletions?.state || State.NotStarted,
                    )}
                </Heading>
                {isRunning(state.deleteResult?.status?.servicebusQueueAndTopicDeletions?.state || State.NotStarted) ? (
                    <LoaderDots color="black" />
                ) : (
                    <div />
                )}

                <Heading type="normal">Deleting Database</Heading>
                <Heading type="normal">
                    {StatusToStringMap(state.deleteResult?.status?.databaseDeletion?.status || Status.None)}
                </Heading>
                <Heading type="normal">
                    {StateToStringMap(state.deleteResult?.status?.databaseDeletion?.state || State.NotStarted)}
                </Heading>

                {isRunning(state.deleteResult?.status?.databaseDeletion?.state || State.NotStarted) ? (
                    <LoaderDots color="black" />
                ) : (
                    <div />
                )}

                <Heading type="normal">Uninstalling Cluster Deployment</Heading>
                <Heading type="normal">
                    {StatusToStringMap(state.deleteResult?.status?.kubernetesDeletion?.status || Status.None)}
                </Heading>
                <Heading type="normal">
                    {StateToStringMap(state.deleteResult?.status?.kubernetesDeletion?.state || State.NotStarted)}
                </Heading>

                {isRunning(state.deleteResult?.status?.kubernetesDeletion?.state || State.NotStarted) ? (
                    <LoaderDots color="black" />
                ) : (
                    <div />
                )}
            </Grid>
        )
    }

    return (
        <Grid rows="auto 1fr auto" className={styles.contentHeight}>
            <Heading type="heading2">{`${productType === ProductType.Flow ? 'Flow Instance' : 'Invision Instance'} - ${
                selectedDeployment?.name
            }`}</Heading>

            {!state.isWorking && !state.isFinished && (
                <div className={styles.centerSelf}>
                    {`Are you sure you want to delete '${selectedDeployment?.name}' ?`}
                </div>
            )}

            {state.isWorking && !state.pollInterval && <div className={styles.centerSelf}>{`Deleting...`}</div>}

            {!state.isWorking && state.isFinished && (
                <div className={styles.centerSelf}>{`Instance '${state.deploymentName}' has been deleted.`}</div>
            )}

            {state.showDeletingDetails && renderProgressDetails()}

            {!state.isWorking && !state.isFinished && (
                <Grid columns="1fr auto" className={styles.footer}>
                    <div />
                    <Button className={styles.btn} buttonType={ButtonType.Confirm} onClick={onDelete}>
                        Delete
                    </Button>
                </Grid>
            )}

            {!state.isWorking && state.isFinished && (
                <Grid columns="1fr auto" className={styles.footer}>
                    <div />
                    <Button className={styles.btn} buttonType={ButtonType.Confirm} onClick={onClose}>
                        Close
                    </Button>
                </Grid>
            )}
        </Grid>
    )
}

export default DeleteDeploymentConfirmation
