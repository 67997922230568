import { FunctionComponent } from 'react'
import { ProductModel } from '../Model/AllSparkModel'
import Grid from '../../infratructure/grid/Grid'
import List, { ListItemProps } from '../../infratructure/list/List'
import commonListStyles from './../CommonList.module.scss'

interface ProductListProps {
    items: ProductModel[]
    onSelectedChange: (product: ProductModel) => void
    selectedItems: ProductModel[] | undefined
}

const DeliveryItemLayout = ({ item }: ListItemProps<ProductModel>) => {
    return (
        <Grid className={commonListStyles.item}>
            <div className={commonListStyles.nameheader}>{`product name`}</div>
            <Grid>
                <strong className={commonListStyles.itemname} title={item.name}>
                    {`${item.name}`}
                </strong>
            </Grid>
        </Grid>
    )
}

const ProductList: FunctionComponent<ProductListProps> = ({ items, onSelectedChange, selectedItems }) => {
    return (
        <List
            className={commonListStyles.list}
            item={DeliveryItemLayout}
            spacing="compact"
            itemSource={items}
            itemSize={46}
            onItemSelect={onSelectedChange}
            selectedItems={selectedItems}
            border={true}
        />
    )
}

export default ProductList
