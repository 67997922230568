import styles from './Confirmation.module.scss'
import { FunctionComponent } from 'react'
import Grid from '../../infratructure/grid/Grid'
import Heading from '../../infratructure/heading/Heading'
import LoaderDots from '../../infratructure/dots/LoaderDots'
import Button, { ButtonType } from '../../infratructure/button/Button'

interface ConfirmDialogProps {
    height: number
    width: number
    message: string
    workingMessage: string
    completedMessage: string
    isWorking: boolean
    isFinished: boolean
    onClose: () => void
    onCancel: () => void
    onConfirm: () => void
}

const ConfirmDialog: FunctionComponent<ConfirmDialogProps> = ({
    height,
    width,
    message,
    workingMessage,
    completedMessage,
    isWorking,
    isFinished,
    onClose,
    onCancel,
    onConfirm,
}) => {
    return (
        <Grid rows="auto 1fr auto" style={{ height: height, width: width }} gap={20}>
            <Heading type="heading2" className={styles.centerSelf}>
                Confirm
            </Heading>
            {!isWorking && !isFinished && <Grid className={styles.centerSelf}>{message}</Grid>}

            {isWorking && (
                <Grid columns="auto 1fr" className={styles.centerSelf} gap={20}>
                    <Grid className={styles.centerSelf}>{workingMessage}</Grid>
                    <LoaderDots color={'black'} />
                </Grid>
            )}

            {isFinished && <Grid className={styles.centerSelf}>{completedMessage}</Grid>}

            {isWorking && !isFinished && <div style={{ height: 42 }}></div>}

            {!isWorking && !isFinished && (
                <Grid columns="1fr auto auto" className={styles.footer}>
                    <div />
                    {!isFinished ? (
                        <Button
                            className={styles.btn}
                            buttonType={ButtonType.Default}
                            onClick={onCancel}
                            disabled={isWorking}
                            style={{ marginRight: '10px', alignItems: 'center' }}
                        >
                            Cancel
                        </Button>
                    ) : (
                        <div />
                    )}
                    <Button className={styles.btn} buttonType={ButtonType.Confirm} onClick={onConfirm}>
                        Ok
                    </Button>
                </Grid>
            )}

            {!isWorking && isFinished ? (
                <Grid columns="1fr auto" className={styles.footer}>
                    <div />
                    <Button className={styles.btn} buttonType={ButtonType.Confirm} onClick={onClose}>
                        Close
                    </Button>
                </Grid>
            ) : (
                ''
            )}
        </Grid>
    )
}

export default ConfirmDialog
