import { useEffect, useRef } from 'react'

function useInterval(callback: Function, delay?: number | null) {
    const savedCallback = useRef<Function>()

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
        function tick() {
            if (savedCallback && savedCallback.current) {
                savedCallback.current()
            }
        }
        if (delay !== null) {
            let id = setInterval(tick, delay)
            return () => clearInterval(id)
        }
    }, [callback, delay])
}

export default useInterval
