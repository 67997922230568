import { FunctionComponent } from 'react'
import PlusIcon from '../../icons/PlusIcon'
import TrashIcon from '../../icons/TrashIcon'
import Button, { ButtonType } from '../../infratructure/button/Button'
import Grid from '../../infratructure/grid/Grid'
import styles from './FlowsToolbar.module.scss'
import UpgradeIcon2 from '../../icons/UpgradeIcon2'
import SettingsCogIcon from '../../icons/SettingsCogIcon'
import { Toggle } from '../Model/AllSparkModel'
import OffIcon from '../../icons/toggle/OffIcon'
import OnIcon from '../../icons/toggle/OnIcon'
import RebootIcon from '../../icons/RebootIcon'
import CopyIcon from '../../icons/CopyIcon'

interface FlowsToolbarProps {
    isEnabled: boolean
    hasItemSelected: boolean
    isWorking: boolean
    usedByDeployments: boolean
    onAdd?: () => void
    onClone: () => void
    onUpgrade?: () => void
    onDelete?: () => void
    onSettings?: () => void
    onToggle: (toggle: Toggle) => void
    onReboot: () => void
}

const FlowsToolbar: FunctionComponent<FlowsToolbarProps> = ({
    isEnabled,
    isWorking,
    hasItemSelected,
    usedByDeployments,
    onAdd,
    onClone,
    onUpgrade,
    onDelete,
    onSettings,
    onToggle,
    onReboot,
}) => {
    const toggle = () => {
        onToggle(isEnabled ? Toggle.Disable : Toggle.Enable)
    }

    return (
        <Grid className={styles.container} columns="auto auto auto auto auto auto 1fr" gap={16}>
            <Button
                onClick={onAdd}
                buttonType={ButtonType.Icon}
                className={styles.btn}
                disabled={isWorking}
                title="New Flow Instance"
            >
                <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                    <PlusIcon height={26} width={26} />
                    <div>New</div>
                </Grid>
            </Button>
            <Button
                onClick={onUpgrade}
                buttonType={ButtonType.Icon}
                className={styles.btn}
                disabled={isWorking || !hasItemSelected}
                title="Upgrade Deployment"
            >
                <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                    <UpgradeIcon2 />
                    <div>Upgrade</div>
                </Grid>
            </Button>

            <Button
                onClick={onClone}
                buttonType={ButtonType.Icon}
                className={styles.btn}
                disabled={isEnabled === false || isWorking || !hasItemSelected}
                title="Clone Deployment"
            >
                <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                    <CopyIcon height={26} width={26} />
                    <div>Clone</div>
                </Grid>
            </Button>

            <Button
                onClick={onDelete}
                buttonType={ButtonType.Icon}
                className={styles.btn}
                disabled={isWorking || !hasItemSelected || usedByDeployments}
                title="Delete Flow Instance"
            >
                <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                    <TrashIcon height={26} width={26} />
                    Delete
                </Grid>
            </Button>
            <Button
                onClick={onReboot}
                buttonType={ButtonType.Icon}
                className={styles.btn}
                disabled={isWorking || !isEnabled}
                title="Reboot instance"
            >
                <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                    <RebootIcon height={26} width={26} />
                    Reboot
                </Grid>
            </Button>
            <Button
                onClick={onSettings}
                buttonType={ButtonType.Icon}
                className={styles.btn}
                disabled={isWorking}
                title="Flow Deployment Settings"
            >
                <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                    <SettingsCogIcon height={26} width={26} />
                    Settings
                </Grid>
            </Button>

            {isEnabled && (
                <Button
                    onClick={toggle}
                    buttonType={ButtonType.Icon}
                    className={styles.btn}
                    disabled={isWorking}
                    title="Disable instance"
                >
                    <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                        <OffIcon height={26} width={26} />
                        <div>Disable</div>
                    </Grid>
                </Button>
            )}

            {!isEnabled && (
                <Button
                    onClick={toggle}
                    buttonType={ButtonType.Icon}
                    className={styles.btn}
                    disabled={isWorking}
                    title="Enable instance"
                >
                    <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                        <OnIcon height={26} width={26} />
                        <div>Enable</div>
                    </Grid>
                </Button>
            )}
        </Grid>
    )
}

export default FlowsToolbar
