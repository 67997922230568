import { CSSProperties, FunctionComponent } from 'react'
import Button from '../button/Button'
import CopyIcon from '../../icons/CopyIcon'
import styles from './ClipboardButton.module.scss'

interface ClipboardButtonProps {
    value: string
    style?: CSSProperties
}

const ClipboardButton: FunctionComponent<ClipboardButtonProps> = ({ value, style = { position: 'absolute' } }) => {
    return (
        <Button
            className={styles.btn}
            style={style}
            title="Copy value to clipboard"
            onClick={() => navigator.clipboard.writeText(value)}
        >
            <CopyIcon height={16} width={16} />
        </Button>
    )
}

export default ClipboardButton
