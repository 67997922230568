import {FunctionComponent, useContext, useState} from 'react'
import Grid from '../../infratructure/grid/Grid'
import Heading from '../../infratructure/heading/Heading'
import styles from './EditSystemLogs.module.scss'
import LoaderDots from '../../infratructure/dots/LoaderDots'
import Button, { ButtonType } from '../../infratructure/button/Button'
import { AllSparkContext } from '../../..'

interface EditSystemLogsProps{
    deploymentId: string
    onClose: (change: boolean, success: boolean) => void
}

type EditSystemLogsState = {
    isDone: boolean
    isDeleting: boolean
    hasErrors: boolean
    message: string | undefined
}

const EditSystemLogs: FunctionComponent<EditSystemLogsProps> = ({
    deploymentId,
    onClose
}) =>{

    const api = useContext(AllSparkContext)

    const [state, setState] = useState<EditSystemLogsState>({
        isDone: false,
        isDeleting: false,
        hasErrors: false,
        message: undefined
    })

    const deleteLogs = async () => {
        setState((prev) => ({ ...prev, isDeleting: true}))
        try{
            let deletemsg = await api.deleteSystemLogs(deploymentId)
            if (!deletemsg.success){
                setState((prev) => ({ ...prev, hasErrors: true, message: deletemsg.errorMessage}))
            }
            onClose(true, !state.hasErrors)
        }
        catch(error: any){
            setState((prev) => ({ ...prev, hasErrors: true, message: error.Message}))
        }
        finally{
            setState((prev) => ({ ...prev, isDeploying: false, isDone: true, isDeleting: false}))
        }
    }

    return (
        <Grid rows="auto 1fr auto" className={styles.main} gap={0}>
            <Heading type="heading1">Delete logs confirmation</Heading>
            <div className={styles.footer}>
                <Grid gap={8}>
                    <div></div>

                    {state.isDone && !state.hasErrors && (
                        <Grid gap={20} className={styles.info}>
                            Deleting system logs are finished
                        </Grid>
                    )}

                    {state.isDeleting && (
                        <Grid columns="1fr auto" columnSpan={2} style={{ marginBottom: '20px', marginTop: '20px', marginRight: '5px'}}>
                        <div/>
                        <Grid columns="1fr auto" gap={10}>
                            <Grid>Deleting</Grid>
                            <Grid style={{ alignItems: 'center', marginBottom: '3px'}}>
                                <LoaderDots color="black"/>
                            </Grid>
                        </Grid>
                    </Grid>
                    )}

                    {state.hasErrors && (
                        <Grid gap={20} className={styles.info}>
                            An error occurred, delete failed:
                            <div>{state.message}</div>
                        </Grid>
                    )}

                    {!state.isDeleting && !state.isDone && (
                        <Grid rows="auto auto" gap={20}>
                            <div>All systemlogs for this deployment '{deploymentId}' will be deleted.</div>
                            <Grid columns="1fr auto" style={{ marginBottom: '10px'}}>
                                <div/>
                                <Button
                                    className={styles.deploybtn}
                                    buttonType={ButtonType.Confirm}
                                    onClick={deleteLogs}
                                >
                                    Delete
                                </Button>
                            </Grid>
                        </Grid>
                    )}


                    {state.isDone &&(
                        <Grid columns="1fr auto" style={{ marginBottom: '10px'}}>
                            <div/>
                            <Button
                                className={styles.deploybtn}
                                buttonType={ButtonType.Confirm}
                                onClick={() => onClose(true, !state.hasErrors)}
                            >
                                Close
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </div>
        </Grid>
    )
}

export default EditSystemLogs