import { FunctionComponent } from 'react'
import { ProductModel } from '../Model/AllSparkModel'
import CopyableInput from '../../infratructure/copyableInput/CopyableInput'
import Grid from '../../infratructure/grid/Grid'
import Heading from '../../infratructure/heading/Heading'
import styles from './ProductDetails.module.scss'

interface ProductDetailProps {
    selectedProduct?: ProductModel
    onPropertyChange: (updatedProduct: ProductModel) => void
}

const ProductDetails: FunctionComponent<ProductDetailProps> = ({ selectedProduct, onPropertyChange }) => {
    return (
        <Grid columns="400px 1fr" className={styles.container}>
            <Grid rows="auto 1fr" gap={16}>
                <Heading type="heading2">{`Product - ${selectedProduct?.name}`}</Heading>
                <Grid rows="auto auto auto auto auto auto 1fr" gap={16} style={{ marginLeft: '10px' }}>
                    <CopyableInput
                        headerText={'Product Type'}
                        width={650}
                        value={selectedProduct?.productType || ''}
                        readOnly
                    />
                    <CopyableInput
                        headerText={'Name'}
                        width={650}
                        value={selectedProduct?.name || ''}
                        onChange={(e) => {
                            if (selectedProduct) {
                                let updatedProduct = { ...selectedProduct, name: e.target.value }
                                onPropertyChange(updatedProduct)
                            }
                        }}
                    />
                    <CopyableInput
                        headerText={'Version'}
                        width={650}
                        value={selectedProduct?.version || ''}
                        onChange={(e) => {
                            if (selectedProduct) {
                                let updatedProduct = { ...selectedProduct, version: e.target.value }
                                onPropertyChange(updatedProduct)
                            }
                        }}
                    />
                    <CopyableInput
                        headerText={'Platform Version'}
                        width={650}
                        value={selectedProduct?.platformVersion || ''}
                        readOnly
                    />
                    <CopyableInput
                        headerText={'Database Template Name'}
                        width={650}
                        value={selectedProduct?.databaseTemplateName || ''}
                        onChange={(e) => {
                            if (selectedProduct) {
                                let updatedProduct = { ...selectedProduct, databaseTemplateName: e.target.value }
                                onPropertyChange(updatedProduct)
                            }
                        }}
                    />
                    <Grid rows="auto 1fr" gap={4}>
                        <Heading>Description</Heading>
                        <textarea
                            className={styles.textarea}
                            style={{ height: 75 }}
                            value={selectedProduct?.description || ''}
                            onChange={(e) => {
                                if (selectedProduct) {
                                    let updatedProduct = { ...selectedProduct, description: e.target.value }
                                    onPropertyChange(updatedProduct)
                                }
                            }}
                        />
                    </Grid>
                    <Grid rows="auto 1fr" gap={4}>
                        <Heading>Sql Install Script</Heading>
                        <textarea
                            className={styles.textarea}
                            style={{ height: 300 }}
                            value={selectedProduct?.sqlInstallScript || ''}
                            onChange={(e) => {
                                if (selectedProduct) {
                                    let updatedProduct = { ...selectedProduct, sqlInstallScript: e.target.value }
                                    onPropertyChange(updatedProduct)
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ProductDetails
