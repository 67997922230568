import { FunctionComponent, useState } from 'react'
import { DeploymentModel } from '../../Model/AllSparkModel'
import Grid from '../../../infratructure/grid/Grid'
import List, { ListItemProps } from '../../../infratructure/list/List'
import { DeploymentAdvancedPanels } from '../Deployments'
import AzureApplicationDetails from './advanced/AzureApplicationDetails'
import AzureAuthenticationDetails from './advanced/AzureAuthenticationDetails'
import AzureBlobContainerDetails from './advanced/AzureBlobContainerDetails'
import AzureServiceBusDetails from './advanced/AzureServiceBusDetails'
import AzureSQLDetails from './advanced/AzureSQLDetails'
import styles from './AdvancedDeploymentSections.module.scss'

interface AdvancedDeploymentSectionsProps {
    deployment: DeploymentModel
    onAzureApplicationsEdit: () => void
    onAzureBlobContainerEdit: () => void
    onAzureSqlEdit: () => void
    onAzureServiceBusEdit: () => void
}

const AdvancedDeploymentSections: FunctionComponent<AdvancedDeploymentSectionsProps> = ({ deployment, onAzureApplicationsEdit, onAzureBlobContainerEdit, onAzureSqlEdit, onAzureServiceBusEdit }) => {
    const [selectedAdvancedSection, setSelectedAdvancedSection] = useState<string>(
        DeploymentAdvancedPanels.Applications,
    )

    const AdvSectionItemLayout = ({ item }: ListItemProps<string>) => {
        return <div className={styles.sectionItem}>{item}</div>
    }

    return (
        <Grid columns="auto 1fr" className={styles.container}>
            <List
                className={styles.list}
                item={AdvSectionItemLayout}
                spacing="compact"
                itemSource={Object.values(DeploymentAdvancedPanels).filter((v) => isNaN(Number(v)))}
                itemSize={46}
                onItemSelect={(v) => setSelectedAdvancedSection(v)}
                selectedItems={[selectedAdvancedSection]}
                border={true}
            />
            <>
                {selectedAdvancedSection === DeploymentAdvancedPanels.Applications && deployment && (
                    <AzureApplicationDetails deployment={deployment} onAzureApplicationsEdit={onAzureApplicationsEdit} />
                )}
                {selectedAdvancedSection === DeploymentAdvancedPanels.Authentication && deployment && (
                    <AzureAuthenticationDetails deployment={deployment} />
                )}
                {selectedAdvancedSection === DeploymentAdvancedPanels.CloudStorage && deployment && (
                    <AzureBlobContainerDetails deployment={deployment} onAzureBlobContainerEdit={onAzureBlobContainerEdit} />
                )}
                {selectedAdvancedSection === DeploymentAdvancedPanels.Servicebus && deployment && (
                    <AzureServiceBusDetails deployment={deployment} onAzureServiceBusEdit={onAzureServiceBusEdit} />
                )}
                {selectedAdvancedSection === DeploymentAdvancedPanels.SQL && deployment && (
                    <AzureSQLDetails deployment={deployment} onAzureSqlEdit={onAzureSqlEdit} />
                )}
            </>
        </Grid>
    )
}

export default AdvancedDeploymentSections
