import { FunctionComponent } from 'react'
import { FailedDeploymentModel } from '../Model/AllSparkModel'
import Grid from '../../infratructure/grid/Grid'
import List, { ListItemProps } from '../../infratructure/list/List'
import commonListStyles from './../CommonList.module.scss'

interface FailedDeploymentListProps {
    items: FailedDeploymentModel[]
    onSelectedChange: (deployment: FailedDeploymentModel) => void
    selectedItems: FailedDeploymentModel[] | undefined
}

const FailedDeploymentList: FunctionComponent<FailedDeploymentListProps> = ({
    items,
    onSelectedChange,
    selectedItems,
}) => {
    const DeliveryItemLayout = ({ item }: ListItemProps<FailedDeploymentModel>) => {
        return (
            <Grid className={commonListStyles.item}>
                <div className={commonListStyles.nameheader}>{`deployment name`}</div>
                <div>
                    <strong className={commonListStyles.itemname} title={item.deploymentName}>                        
                        {`💀 ${item.deploymentName}`}
                    </strong>
                </div>
            </Grid>
        )
    }

    return (
        <List
            className={commonListStyles.list}
            item={DeliveryItemLayout}
            spacing="compact"
            itemSource={items}
            itemSize={46}
            onItemSelect={onSelectedChange}
            selectedItems={selectedItems}
            border={true}
        />
    )
}

export default FailedDeploymentList
