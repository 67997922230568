import * as React from 'react'
import styles from './Input.module.scss'
import cx from 'classnames'
import Heading, { HeadingType } from '../heading/Heading'
import Grid from '../grid/Grid'
import DOMUtils from '../../utils/DOMUtils'
import useFocusHotkey from '../hooks/UseFocusHotkey'
export type HeaderPlacement = 'top' | 'left'

type Props = {
    headerText?: string
    headerPlacement?: HeaderPlacement
    headingType?: HeadingType
    maxLength?: number
    hotkey?: string
    borderless?: boolean
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    onValidation?: (event: React.ChangeEvent<HTMLInputElement>) => boolean
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

const Input = React.forwardRef<HTMLInputElement, Props>(
    (
        {
            className,
            headerText,
            headerPlacement = 'top',
            headingType = 'normal',
            maxLength,
            hotkey,
            borderless = false,
            onChange,
            onValidation,
            ...props
        }: Props,
        ref,
    ) => {
        const [headerId] = React.useState<string>(() => DOMUtils.createUniqueId())
        const { required, value } = props

        // The useFocusHotkey must have ref to the html element.
        // Need to do this in case ref / forwardRef is unitialized / not used from the caller side.
        const innerRef = React.useRef<HTMLInputElement>()
        const setRefs = (element: HTMLInputElement) => {
            innerRef.current = element
            if (typeof ref === 'function') {
                ref(element)
            } else if (ref) {
                ref.current = element
            }
        }

        useFocusHotkey<HTMLInputElement>(hotkey, innerRef)

        const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            if (onChange) {
                if (onValidation) {
                    if (onValidation(e)) {
                        onChange(e)
                    }
                } else {
                    onChange(e)
                }
            }
        }

        if (!headerText) {
            return (
                <input
                    data-testid="input-control"
                    className={cx(styles.input, { [styles.borderless]: borderless }, className)}
                    aria-required={required}
                    {...props}
                    ref={setRefs}
                    onChange={handleOnChange}
                />
            )
        }

        switch (headerPlacement) {
            case 'top':
                return (
                    <Grid rows="auto auto" className={className} gap={4}>
                        <Heading
                            className={styles.flexend}
                            id={headerId}
                            type={headingType}
                            requiredMarker={value ? undefined : required}
                        >
                            {headerText}
                        </Heading>
                        {/* About aria labelling: Because we spread props after the aria labels, both aria props can be overridden by the props from the caller */}
                        <input
                            aria-label={headerText}
                            aria-labelledby={headerId}
                            aria-required={required}
                            data-testid="input-control"
                            className={cx(styles.input, { [styles.borderless]: borderless })}
                            {...props}
                            ref={setRefs}
                            maxLength={maxLength}
                            onChange={handleOnChange}
                        />
                    </Grid>
                )

            case 'left':
                return (
                    <Grid columns="auto 1fr" className={className} gap={4}>
                        <Heading
                            className={styles.flexend}
                            id={headerId}
                            type={headingType}
                            style={{ alignSelf: 'center' }}
                            requiredMarker={value ? undefined : required}
                        >
                            {headerText}
                        </Heading>
                        {/* About aria labelling: Because we spread props after the aria labels, both aria props can be overridden by the props from the caller */}
                        <input
                            aria-label={headerText}
                            aria-labelledby={headerId}
                            aria-required={required}
                            data-testid="input-control"
                            className={cx(styles.input, { [styles.borderless]: borderless })}
                            {...props}
                            ref={setRefs}
                            maxLength={maxLength}
                            onChange={handleOnChange}
                        />
                    </Grid>
                )

            default:
                return (
                    <input
                        aria-required={required}
                        data-testid="input-control"
                        className={cx(styles.input, { [styles.borderless]: borderless }, className)}
                        {...props}
                        ref={setRefs}
                        maxLength={maxLength}
                        onChange={handleOnChange}
                    />
                )
        }
    },
)

export default Input
