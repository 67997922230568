import cx from 'classnames'
import { IconProps } from './IconProps'

export default function MoreHorizontalIcon({ width = 20, height = 20, className, style }: IconProps) {
    return (
        <svg
            fill="none"
            viewBox="0 0 24 24"
            width={width}
            height={height}
            className={cx('icon', className)}
            style={style}
            data-icon
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 14a2 2 0 110-4 2 2 0 010 4zm7 0a2 2 0 110-4 2 2 0 010 4zm5-2a2 2 0 104 0 2 2 0 00-4 0z"
                fill="currentColor"
            />
        </svg>
    )
}
