import { Guid } from '../../utils/Guid'

//ENUMS

export enum MessageType {
    Error = 0,
    Info = 1,
    Warning = 2,
}

export enum Toggle {
    Enable,
    Disable,
}

export enum Permission {
    None = 0,
    Read = 1,
    ReadWrite = 2,
}

export enum ProductType {
    All,
    Invision,
    Flow,
}

export enum EncryptionType {
    Generated = 0,
}

export enum State {
    NotStarted = 1,
    Provisioning = 2,
    RollingBack = 3,
    Verifying = 4,
    FinishedDeploying = 5,
    FinishedRollback = 6,
    Validating = 7,
    FinishedValidating = 8,
    FinishedVerifying = 9,
    Deleting = 10,
    FinishedDeleting = 11,
    Updating = 12,
    FinishedUpdating = 13,
}

export enum LogLevel {
    None = 0,
    Error = 1,
    Warning = 2,
    Info = 3,
}

export enum Source {
    General,
    ApplicationRegistrationService,
    StorageDeployer,
    ServiceBusDeployer,
    DatabaseDeployer,
    HelmChartService,
    DeleteService,
    ContainerRegistryService,
    ApplicationService,
    ServiceBusService,
    ApplicationDeployer,
    SqlService,
    StorageService,
    HelmScriptDeployer,
    DeploymentService,
    UpdateService,
    HelmScriptUpdater,
    FlowDeploymentService,
    FlowService,
}

export enum Status {
    None = 1,
    InProgress = 2,
    Succeeded = 3,
    Failed = 4,
    Ignored = 5,
}

//TYPES/INTERFACES
export interface ApplicationDeployment {
    objectId: string
    appName: string
    clientId: string
    clientSecret: string
    secretId: Guid | null
    secretExpiry: string | undefined
}

export interface AppDeployment {
    openIdConnectTenantId: string
    openIdConnectAuthority: string
    identityProviderAuthority: string
    identityProviderPermissions: Permission
    identityProviderCanInvite: boolean
    designer: ApplicationDeployment
    webApp: ApplicationDeployment
}

export interface StorageContainerDeployment {
    name: string
    sasUri: string
    sasTokenExpiry: string
    storedAccessPolicyIdentifer: string | undefined
}
export interface StorageDeployment {
    storageContainer: StorageContainerDeployment
}

export interface ServiceBusDeployment {
    name: string
    connectionString: string
    authorizationRuleName?: string
}

export interface EventBusDeployment {
    dispatcherQueue: ServiceBusDeployment
    workerQueue: ServiceBusDeployment
    systemWorkerQueue: ServiceBusDeployment
    schedulerQueue: ServiceBusDeployment
    broadcastTopic: ServiceBusDeployment
}

export interface DatabaseDeployment {
    databaseName: string
    owner: string
    connectionString: string
}

export type ClaimTypeMapping = {
    fromType: string
    toType: string
}

export interface OpenIdConnectDeployment {
    claimTypesFilter: string[]
    claimTypeMappings: ClaimTypeMapping
}

export interface JwtDeployment {
    claimTypesFilter: string[]
    claimTypeMappings: ClaimTypeMapping
}

export interface AzureADEndpoint {
    graphApi: string
}

export interface IdentityProviderDeployment {
    type: string
    canInvite: boolean
    permissions: string
    azureAd: AzureADEndpoint
}

export interface AuthorizationDeployment {
    openIdConnect: OpenIdConnectDeployment
    jwt: JwtDeployment
    identityProvider: IdentityProviderDeployment
}

export interface MemoryScaling extends Scaling {}

export interface CPUScaling extends Scaling {}

export interface Scaling {
    request: number
    limit: number
}

export interface JobScaling {
    maxTaskCount?: number
    messageCount?: number
    memory: MemoryScaling
}

export interface ScalingDeployment {
    dispatcher: JobScaling
    worker: JobScaling
    systemWorker: JobScaling
    scheduler: JobScaling
    webApp: JobScaling
}

export interface FlowScaling {
    messageCount?: number
    memory: MemoryScaling
    cpu?: CPUScaling
}

export interface FlowScalingDeployment {
    abafar: FlowScaling
    anaxes: FlowScaling
    corellia: FlowScaling
    designer: FlowScaling
    portal: FlowScaling
    rishi: FlowScaling
}

export interface FlowDefaultSettings {
    scaling: FlowScalingDeployment
}

export interface DeploymentDefaultSettings {
    scaling: ScalingDeployment
}

export interface DeploymentModel {
    enabled?: boolean
    hasCustomOverrides: boolean
    name: string
    externalId: string
    appVersion: string
    deploymentId: string
    deploymentUri?: string
    clusterController?: ClusterController
    deploymentRequestParameters: DeploymentRequest
    appDeployment: AppDeployment
    storageDeployment: StorageDeployment
    eventBusDeployment: EventBusDeployment
    database: DatabaseDeployment
    authorization: AuthorizationDeployment
    scaling: ScalingDeployment
    flowInstance?: FlowInstanceModel
    cloneSource?: CloneSource
}

export interface CloneSource {
    deploymentId: string
    deploymentName: string
    timestamp: string
}

export interface DeploymentRequest {
    environmentRowKey: string
    productRowKey: string
    databaseTemplateName?: string
    platformVersion: string
    name: string
    externalId: string
    userEmailAddress: string
    appDeployment?: AppDeployment
    rollbackOnFailure: boolean
    flowInstanceId?: string
    flowSubscriptionId?: string
    flowTenantId?: string
    defaultLanguage: string
}

export interface FlowDeploymentRequest {
    adminEmail: string
    flowSubscriptionId: string
    environmentRowKey: string
    productRowKey: string
    platformVersion: string
    name: string
    rollbackOnFailure: boolean
    externalAuthorization?: FlowExternalAuthorizationModel
}

export interface FlowExternalAuthorizationModel {
    tenantId: string | undefined
    clientId: string | undefined
    clientSecret: string | undefined
    secretExpiry: string | undefined
    canInvite: boolean | undefined
}

export interface DeploymentAuthUpdateRequest {
    deploymentId: string
    override: boolean
    identityProviderAuthority: string
    identityProviderPermissions: Permission
    identityProviderCanInvite: boolean
    openIdConnectAuthority: string
    openIdConnectTenantId: string
    webAppClientId: string
    webAppClientSecret: string
    webAppClientSecretExpiry: string
    designerClientId: string
}

export interface FlowAuthUpdateRequest {
    override: boolean
    tenantId: string
    clientId: string
    clientSecret: string
    secretExpiry: string
    canInvite: boolean
}

export interface DeploymentOverviewUpdateRequest {
    deploymentId: string
    externalId: string
}

export interface DeploymentDefaultLanguageRequest {
    deploymentId: string
    defaultLanguage: string
}

export interface ClusterControllerRequest extends ClusterController {}

export interface ClusterController {
    name: string
    invisionChart: string
    flowChart: string
}

export interface SqlConnectionUpdateRequest {
    deploymentId: string
    connectionString: string
}

export interface AuthorizationRuleUpdateRequest {
    deploymentId: string
    target: string
    authorizationRuleName: string
}

export interface ValidateReponse {
    valid: boolean
    errorMessage: string
    name: string
}

export interface DeploymentCacheStatus {
    status: Status
    state: State
}

export interface DeploymentStatus {
    applicationRegistrations?: DeploymentCacheStatus
    containerRegistration?: DeploymentCacheStatus
    databaseRegistration?: DeploymentCacheStatus
    kubernetesDeployment?: DeploymentCacheStatus
    servicebusQueueAndTopicRegistrations?: DeploymentCacheStatus
    permissionsDeployment?: DeploymentCacheStatus
    flowDeployment?: DeploymentCacheStatus
    deploymentFinished: boolean
}

export interface AppDeploymentModel extends DeploymentModel {
    customerName?: string
}

export interface FailedDeploymentModel {
    rowKey: string
    deploymentId: string
    deploymentName: string
    timestamp: string
    logs: string
}

export interface DeploymentLog {
    deploymentId: string
    name: string
}

export interface SystemLog {
    logRowKey: string
    logLevel: LogLevel
    source: Source
    deploymentId: string
    logs: string[]
    timestamp: Date
    user: string | undefined
}

export interface Deployment {
    status?: DeploymentStatus
    deploymentModel?: AppDeploymentModel
    logs: FailedDeploymentModel
}

export interface FlowInstanceModel {
    enabled?: boolean
    deploymentId: string
    rowKey?: string
    name: string
    environmentRowKey: string
    productRowKey: string
    tenantId?: string
    flowSubscriptionId: string
    adminEmailAddress: string
    adminIdentityId: string
    adminFullName: string
    adminUserCreated: boolean
    defaultTenantId: string
    platformVersion: string
    designerDeploymentUri?: string
    portalDeploymentUri?: string
    clusterController?: ClusterController
    database: FlowDatabase
    flowServiceBus: FlowServiceBusModel
    flowAuthorization: FlowAuthorization
    flowHosting: FlowHosting
    scaling: FlowScalingDeployment
    encryption: Encryption
    encryptionType: EncryptionType
    cloneSource?: CloneSource
}

export interface Encryption {
    key: string
    vector: string
    keyVaultKeyName: string
}

export interface FlowDatabase {
    connectionString: string
    databaseName: string
}

export interface FlowHosting {
    infrastructure: string
    runMode: string
    pathBase?: string
}

export interface FlowAuthorization {
    openIdConnectAuthority: string
    openIdClientId: string
    openIdClientSecret: string
    secretExpiry: string
    externalAuthorizationModel?: FlowExternalAuthorizationModel
}

export interface FlowServiceBusModel {
    executionQueue: ServiceBusDeployment
    broadCastTopic: ServiceBusDeployment
}

export interface FlowDeployment {
    status?: DeploymentStatus
    flowInstanceModel?: FlowInstanceModel
}

export interface Tenant {
    id: string
    name: string
}

export interface DeploymentResponse {
    valid: boolean
    errorMessage: string
    name: string
    deploymentId: string
}

export interface FlowDeploymentResponse {
    valid: boolean
    deploymentId: string
    name: string
    errorMessage: string
}

export interface ConnectToFlowResponse {
    success: boolean
    errorMessage: string
}

export interface DisconnectFromFlowResponse {
    success: boolean
    errorMessage: string
}

export interface ToggleResponse {
    success: boolean
    errorMessage: string
}

export interface UpgradeDeploymentResponse {
    deploymentId: string
    success: boolean
    chartVersion: string | undefined

    errorMessage: string | undefined
}

export interface UpdateDeploymentAuthResponse {
    deploymentId: string
    success: boolean
}

export interface DeleteResponse {
    success: boolean
    errorMessage: string | undefined
}

export interface HelmResponse {
    success: boolean
    errorMessage: string | undefined
    messages: CmdMessageResponse[]
}

export interface CmdMessageResponse {
    messageType: MessageType
    message: string
}

export interface AzureIdentityProviderSettings {
    authority: string
    canInvite: boolean
}

export interface OpenIdConnectSettings {
    tenantId: string
    clientId: string
    clientSecret: string
    authority: string
}

export interface AuthorizationSettings {
    identityProvider: AzureIdentityProviderSettings
    openIdConnect: OpenIdConnectSettings
}

export interface AzureSqlSettings {
    resourceGroupId: string
    serverName: string
    serverAdminUserName: string
    serverAdminPassword: string
    elasticPoolName: string
}

export interface AzureKubernetesSettings {
    hostName: string
    resoureGroupId: string
    clusterName: string
}

export interface AzureResourcesSettings {
    tenantId: string
    clientId: string
    clientSecret: string
    resourceGroupId: string
    storageAccountName: string
    serviceBusNamespace: string
    azureSql: AzureSqlSettings
    azureKubernetesServices: AzureKubernetesSettings
    azureGatewayCertificateName: string
    azureGatewayRewriteRuleName: string
    keyVaultUri?: string
}

export interface AzureDeploymentSettings {
    authorization: AuthorizationSettings
    resources: AzureResourcesSettings
}

export interface AzureDeploymentEnvironmentConfigurations {
    deployment: AzureDeploymentSettings
}

export interface EnvironmentModel {
    rowKey?: string
    name: string
    description: string
    clusterController?: string
    azureDeploymentEnvironment: AzureDeploymentEnvironmentConfigurations
}

export interface ProductModel {
    rowKey?: string
    productType: string
    name: string
    description?: string
    version: string
    platformVersion: string
    databaseTemplateName: string
    sqlInstallScript: string | null
}

export interface VersionInfo {
    version: string
    createdon: string
    lastUpdatedOn: string
}

export interface VersionResponse {
    versions: VersionInfo[]
}

export interface Deletement {
    status?: DeletementStatus
}

export interface DeletementStatus {
    applicationDeletions?: DeleteCacheStatus
    containerDeletions?: DeleteCacheStatus
    servicebusQueueAndTopicDeletions?: DeleteCacheStatus
    databaseDeletion?: DeleteCacheStatus
    kubernetesDeletion?: DeleteCacheStatus
}

export interface DeleteCacheStatus {
    status: Status
    state: State
}

export interface ProductDockerImages {
    success: boolean
    errorMessage: string | undefined
    productType: ProductType
    versionGroups: string[]
    versions: string[]
    versionsInUse: string[]
    repoVersions: ProductRepoVersion[]
}

export interface ProductRepoVersion {
    repository: string
    version: string
}

export interface CheckedDockerImage {
    version: string
    sorting: string
    selected: boolean
    disabled: boolean
}

export interface DeleteDockerImageResponse {
    success: boolean
    errorMessage: string | undefined
    deleteId: string
    deleted: number
    failed: number
    totalCount: number
    completed: boolean
}

export interface ContainerRegistryRepositoryModel {
    productType: string
    repository: string
}

export interface RegistryUsage {
    success: boolean
    errorMessage: string
    limit: number | undefined
    currentValue: number | undefined
}

export interface FlowEncryptionRequest {
    key: string
    vector: string
}

export interface UpgradeRequest {
    productType: ProductType
    deploymentId: string
    chartVersion: string
    controller: string
}

// FUNCTIONS
export function StateToStringMap(state: State) {
    switch (state) {
        case State.FinishedDeploying:
            return 'Finished deploying'
        case State.FinishedRollback:
            return 'Finished rolling back'
        case State.FinishedValidating:
            return 'Finished validating'
        case State.FinishedVerifying:
            return 'Finished verifying'
        case State.NotStarted:
            return 'Not started'
        case State.Provisioning:
            return 'Provisioning'
        case State.RollingBack:
            return 'Rolling back'
        case State.Validating:
            return 'Validating'
        case State.Verifying:
            return 'Verifying'
        case State.Deleting:
            return 'Deleting'
        case State.FinishedDeleting:
            return 'Finished deleting'
        case State.Updating:
            return 'Updating'
        case State.FinishedUpdating:
            return 'Finished updating'
        default:
            return 'Mapping failed'
    }
}

export function StatusToStringMap(status: Status) {
    switch (status) {
        case Status.None:
            return 'None'
        case Status.Succeeded:
            return 'Success'
        case Status.Failed:
            return 'Failed'
        case Status.InProgress:
            return 'In progress'
        case Status.Ignored:
            return 'Ignored'
        default:
            return 'Mapping failed'
    }
}
