import * as React from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { isKnownBrowserHotkey } from '../../utils/HotkeyUtils'

export default function useFocusHotkey<T>(
    hotkey: string | undefined | null,
    ref: React.MutableRefObject<T | undefined> | React.ForwardedRef<T>,
    onSetFocus?: (e: KeyboardEvent, ref: T | undefined) => void,
) {
    const isBrowserHotkey = isKnownBrowserHotkey(hotkey)

    const handleHotkey = (e: KeyboardEvent) => {
        if (typeof onSetFocus === 'function') {
            onSetFocus(e, (ref as any).current)
        } else if (ref) {
            ;(ref as any).current?.focus()
        }
        if (isBrowserHotkey) {
            e.preventDefault()
        }
    }

    useHotkeys(hotkey || '', handleHotkey, {
        enableOnTags: ['INPUT', 'SELECT', 'TEXTAREA'],
        enabled: hotkey != null,
        enableOnContentEditable: true,
    })
}
