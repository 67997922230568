import { FunctionComponent, useContext, useState } from 'react'
import Grid from '../../../infratructure/grid/Grid'
import Heading from '../../../infratructure/heading/Heading'
import styles from './DisconnectFromFlow.module.scss'
import LoaderDots from '../../../infratructure/dots/LoaderDots'
import Button, { ButtonType } from '../../../infratructure/button/Button'
import { useGlobalStateContext } from '../../../../GlobalState'
import { AllSparkContext } from '../../../..'

interface ConnectToFlowProps {
    deploymentName: string
    deploymentId: string
    flowId: string
    onClose: () => void
}

type ConnectToFlowState = {
    isWorking: boolean
    isDisconnectedDone: boolean
    errorMessage: string | undefined
}

const DisconnectFromFlow: FunctionComponent<ConnectToFlowProps> = ({
    deploymentName,
    deploymentId,
    flowId,
    onClose,
}) => {
    const api = useContext(AllSparkContext)
    const { setGlobalState } = useGlobalStateContext()

    const [state, setState] = useState<ConnectToFlowState>({
        isWorking: false,
        isDisconnectedDone: false,
        errorMessage: undefined,
    })

    const handleCloseClicked = () => {
        onClose()
        setState((prev) => ({ ...prev, upgradeErrorMessage: undefined }))
    }

    const handleDisconnectClicked = async () => {
        try {
            setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: true }))
            setState((prev) => ({ ...prev, isWorking: true }))

            let disconnectFromFlowResponse = await api.disconnectFlowFromInvision(deploymentId, flowId)
            console.log(disconnectFromFlowResponse)
            if (disconnectFromFlowResponse.success) {
                setState((prev) => ({
                    ...prev,
                    isWorking: false,
                    isDisconnectedDone: true,
                }))
            } else {
                setState((prev) => ({
                    ...prev,
                    isWorking: false,
                    isDisconnectedDone: false,
                    errorMessage: 'Unable to disconnect from Flow Instance.' + disconnectFromFlowResponse.errorMessage,
                }))
            }
        } catch (error) {
            setState((prev) => ({
                ...prev,
                isWorking: false,
                isDisconnectedDone: true,
                errorMessage: 'Unable to disconnect from Flow Instance.' + error,
            }))
        } finally {
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        }
    }

    return (
        <Grid rows="auto 1fr auto" gap={20} className={styles.contentHeight}>
            <Heading type="heading1" className={styles.heading}>
                Disconnect Flow from Invision deployment '{deploymentName}'
            </Heading>
            <Grid rows="1fr auto auto" className={styles.main}>
                {!state.isWorking && !state.isDisconnectedDone && (
                    <div className={styles.centerSelf}>Are your sure you want to disconnect from Flow?</div>
                )}
                {state.isWorking && (
                    <Grid columns="auto 1fr" className={styles.centerSelf} gap={16}>
                        <div className={styles.centerSelf}>Disconnecting</div>
                        <LoaderDots color={'black'} />
                    </Grid>
                )}

                {state.isDisconnectedDone && !state.errorMessage && (
                    <div className={styles.centerSelf}>
                        Disconnected from Flow Instance '{deploymentName}' succeeded!
                    </div>
                )}

                {state.isDisconnectedDone && state.errorMessage && (
                    <div className={styles.centerSelf}>
                        Disconnect from Flow failed. Check system log for error messages.
                    </div>
                )}
            </Grid>

            <Grid columns="1fr auto auto" className={styles.footer}>
                <div />
                <Button
                    className={styles.deploybtn}
                    buttonType={ButtonType.Confirm}
                    onClick={state.isDisconnectedDone ? handleCloseClicked : handleDisconnectClicked}
                    style={{ minWidth: '96px' }}
                    disabled={state.isWorking}
                >
                    {state.isDisconnectedDone ? 'Close' : 'Disconnect'}
                </Button>
            </Grid>
        </Grid>
    )
}

export default DisconnectFromFlow
