import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import styles from './List.module.scss'
import cx from 'classnames'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { Borders, setBorder } from '../../utils/BorderBuilder'
import DOMUtils from '../../utils/DOMUtils'
import MoreHorizontalIcon from '../../icons/MoreHorizontalIcon'
import ActionList, { ActionListItem } from '../actionList/ActionList'

export type ListAction = {
    title: string
    onClick: (listItem: any) => void
    isVisible?: (listItem: any) => boolean
}

type ListProps<T> = {
    item: FunctionComponent<ListItemProps<T>>
    itemSource: T[]

    selectedItems?: T[]
    disabledItems?: T[]
    onItemSelect?: (obj: T) => void
    actions?: ListAction[]

    itemSize?: number
    border?: boolean | Borders
    className?: string
    style?: React.CSSProperties
    spacing?: 'normal' | 'compact'
    ariaLabelledBy?: string
    hotkey?: string
}

export type ListItemProps<T> = {
    item: T
}

function testWorkaround() {
    if (process.env.NODE_ENV === 'test') {
        Object.defineProperty(HTMLElement.prototype, 'offsetHeight', {
            configurable: true,
            value: 200,
        })
        Object.defineProperty(HTMLElement.prototype, 'offsetWidth', {
            configurable: true,
            value: 200,
        })
    }
}

const List = <T extends {}>({
    item,
    itemSource = [],
    itemSize = 32,
    border = false,
    onItemSelect = undefined,
    actions = [],
    selectedItems = [],
    disabledItems = [],
    style,
    className,
    spacing = 'normal',
    ariaLabelledBy,
    hotkey,
}: ListProps<T>) => {
    //JSDom are unable to do size measurements
    //So in order to be able to render any content in the list we can
    //override the offsetHeight and offsetWidth on the HTMLElement.prototype.
    //BUT only in test mode
    testWorkaround()

    const filterActions = (obj: any) => {
        return actions.filter((action) => {
            if (action.isVisible && obj) {
                return action.isVisible(obj)
            }

            return true
        })
    }

    const listRef = useRef<HTMLDivElement>()
    const setKeyboardFocus = () => {
        if (!listRef?.current) {
            return
        }

        const listHtmlElement = listRef?.current as unknown as HTMLElement
        DOMUtils.makeFocusable(listHtmlElement)

        listHtmlElement.focus()
    }

    const [focusedItemIndex, setFocusedItemIndex] = useState(-1)

    const onListKeyUp = useCallback(
        (e: KeyboardEvent) => {
            if (e.code === 'ArrowUp') {
                setFocusedItemIndex((prev) => {
                    return prev > 0 ? prev - 1 : prev
                })
                e.stopPropagation()
            } else if (e.code === 'ArrowDown') {
                setFocusedItemIndex((prev) => (prev < itemSource.length - 1 ? prev + 1 : prev))
                e.stopPropagation()
            } else if ((e.code === 'Enter' || e.code === 'Space') && focusedItemIndex >= 0 && onItemSelect) {
                const item = itemSource[focusedItemIndex]
                onItemSelect(item)
            }
        },
        [itemSource, onItemSelect, focusedItemIndex],
    )

    // Subscribe and unsubscribe to keyup when the component is mounted and unmounted
    const setOuterRef = useCallback(
        (elem: HTMLDivElement) => {
            if (listRef.current) {
                listRef.current.removeEventListener('keyup', onListKeyUp)
            }
            listRef.current = elem
            if (elem) {
                DOMUtils.makeFocusable(elem)
                elem.addEventListener('keyup', onListKeyUp)
            }
        },
        [onListKeyUp],
    )

    // When the onListKeyUp callback changes, we need to resubscribe to keyup
    useEffect(() => {
        const listHtmlElement = listRef?.current as HTMLDivElement
        if (listHtmlElement) {
            DOMUtils.makeFocusable(listHtmlElement)
            listHtmlElement.addEventListener('keyup', onListKeyUp)
        }
        return () => {
            if (listHtmlElement) {
                listHtmlElement.removeEventListener('keyup', onListKeyUp)
            }
        }
    }, [onListKeyUp])

    const onSelectItem = (index: number) => {
        if (onItemSelect) {
            onItemSelect(itemSource[index])
            setFocusedItemIndex(index)
        }
        setKeyboardFocus() // SO that keyboard navigation works after an item has been selected using the mouse / pointer
    }

    return (
        <div className={cx(styles.container, className)} style={{ ...style, ...setBorder(border) }}>
            <AutoSizer>
                {({ height, width }) => (
                    <FixedSizeList
                        height={height}
                        width={width - 2} // AutoSizer calculates the width wrong - deduct 2 to avoid overlapping hover effect over the border in the right side edge.
                        itemCount={itemSource.length}
                        itemData={itemSource}
                        itemSize={itemSize}
                        aria-labelledby={ariaLabelledBy}
                        outerRef={setOuterRef}
                    >
                        {({ style, data, index }: ListChildComponentProps) => (
                            <div
                                onClick={() => onSelectItem(index)}
                                className={cx(styles.listItem, {
                                    [styles.focusedItem]: focusedItemIndex === index,
                                    [styles.selectedItem]: selectedItems.some((item) => item === data[index]),
                                    [styles.disabledItem]: disabledItems.some((item) => item === data[index]),
                                    [styles.itemCompactSpacing]: spacing === 'compact',
                                })}
                                style={style}
                                data-selected={selectedItems.some((item) => item === data[index])}
                                data-disabled={disabledItems.some((item) => item === data[index])}
                            >
                                <div className={styles.itembox}>{React.createElement(item, { item: data[index] })}</div>
                                {actions.length > 0 && (
                                    <ActionList
                                        className={cx({
                                            [styles.selectedItem]: selectedItems.some((item) => item === data[index]),
                                        })}
                                        icon={<MoreHorizontalIcon />}
                                        actions={filterActions(data[index]).map<ActionListItem>((listItem) => ({
                                            text: listItem.title,
                                            onClick: () => listItem.onClick(data[index]),
                                        }))}
                                        showOnlyOnHover={true}
                                    />
                                )}
                            </div>
                        )}
                    </FixedSizeList>
                )}
            </AutoSizer>
        </div>
    )
}

export default List
