import { FunctionComponent, useState } from "react"
import { FlowInstanceModel, ProductType } from "../../Model/AllSparkModel"
import CopyableInput from "../../../infratructure/copyableInput/CopyableInput"
import Grid from "../../../infratructure/grid/Grid"
import Heading from "../../../infratructure/heading/Heading"
import Button, { ButtonType } from "../../../infratructure/button/Button"
import styles from "./FlowInstanceDetails.module.scss"
import EditIcon from "../../../icons/EditIcon"
import RefreshIcon from "../../../icons/RefreshIcon"
import EditSqlConnectionString from "../../deployments/edit/EditSqlConnectionString"
import Modal from "react-responsive-modal"

interface FlowInstanceDetailsDatabaseProps{
    instance?: FlowInstanceModel
    onAzureSqlEdit: () => void
}

type FlowInstanceDetailsDatabaseState ={
    isEditConnectionVisible: boolean
    isWorking: boolean
}

const FlowInstanceDetailsDatabase: FunctionComponent<FlowInstanceDetailsDatabaseProps> = ({instance, onAzureSqlEdit}) =>{

    const [state, setState] = useState<FlowInstanceDetailsDatabaseState>({
        isEditConnectionVisible: false,
        isWorking: false
    })

    const onEditConnectionString = () => {
        setState((prev) => ({ ...prev, isEditConnectionVisible: true }))
    }

    const closeIcon = () => (state.isWorking ? <div /> : '')

    const onClose = () => {
        setState((prev) => ({ ...prev, isEditConnectionVisible: false }))
    }
    return (
        <Grid columns="400px 1fr">
            <Grid rows="auto 1fr" gap={16}>
                <Heading type="heading1">
                    Flow Instance Database
                </Heading>
                <Grid rows="auto auto auto auto auto 1fr" gap={16} style={{ marginLeft: '10px' }}>
                    <CopyableInput
                            headerText={'Database Name'}
                            width={650}
                            value={instance?.database?.databaseName || ''}
                            readOnly
                        />
                    <Grid columns="auto auto auto 1fr">
                        <CopyableInput
                            headerText={'ConnectionString'}
                            width={650}
                            value={instance?.database?.connectionString || ''}
                            readOnly
                        />
                        <Button style={{ marginTop: '21px', marginLeft: '5px'}}
                            onClick={onEditConnectionString}
                            buttonType={ButtonType.Icon}
                            className={styles.btn}
                            title="Edit connectionstring"
                            >
                            <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                                <EditIcon height={26} width={26} />
                                <div>Edit</div>
                            </Grid>
                        </Button>
                        <Button style={{ marginTop: '21px', marginLeft: '2px'}}
                            onClick={onAzureSqlEdit}
                            buttonType={ButtonType.Icon}
                            className={styles.btn}
                            title="Renew password"
                            >
                            <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                                <RefreshIcon height={26} width={26} />
                                <div>Renew</div>
                            </Grid>
                        </Button>  
                    </Grid>
                </Grid>
            </Grid>
            {state?.isEditConnectionVisible && (
                    <Modal
                        open={state?.isEditConnectionVisible}
                        closeOnOverlayClick={false}
                        onClose={() => {
                            if (!state?.isWorking) {
                                setState((prev) => ({
                                    ...prev,
                                    isEditConnectionVisible: false
                                }))
                            }
                        }}
                        classNames={{
                            modal: styles.customModal,
                        }}
                        closeIcon={closeIcon()}
                        >
                        <EditSqlConnectionString
                            productType={ProductType.Flow}
                            deploymentId={instance?.deploymentId}
                            connectionString={instance?.database.connectionString}
                            onClose={onClose}
                            />
                    </Modal>
                )}
        </Grid>
    )
}

export default FlowInstanceDetailsDatabase