import { Util } from './Util'
export class Str {
    public static numberToAlphanumeric(value: number): string {
        let s = '',
            t

        // we want 1 indexed positive numbers here
        if (value <= 0) {
            value = 1
        }

        while (value > 0) {
            t = (value - 1) % 26
            s = String.fromCharCode(65 + t) + s
            value = ((value - t) / 26) | 0
        }
        return s
    }

    public static coalesce(...args: string[]): string | null {
        if (args) {
            for (const p of args) {
                if (!Str.isNothingOrWhitespace(p)) {
                    return p
                }
            }
        }
        return null
    }

    public static camelCase(str: string): string {
        return str
            .replace(/([:\-_]+(.))/g, (_, separator, letter, offset) => {
                return offset ? letter.toUpperCase() : letter
            })
            .replace(/^moz([A-Z])/, 'Moz$1')
    }

    public static isNumeric(s: string): boolean {
        return !isNaN(parseFloat(s))
    }

    public static equals(s1: string | null | undefined, s2: string | null | undefined, ignoreCase?: boolean): boolean {
        if (Str.isNullOrUndefined(s1) || Str.isNullOrUndefined(s2)) {
            return false
        }
        // @ts-ignore
        return ignoreCase ? s1.toLowerCase() === s2.toLowerCase() : s1 === s2
    }

    public static startsWith(s: string | undefined, startsWith: string, ignoreCase?: boolean): boolean {
        if (Str.isNullOrUndefined(s) || Str.isNullOrUndefined(startsWith) || s!.length < startsWith.length) {
            return false
        }
        if (ignoreCase) {
            return s!.substr(0, startsWith.length).toLowerCase() === startsWith.toLowerCase()
        }
        return s!.substr(0, startsWith.length) === startsWith
    }

    public static endsWith(s: string, endsWith: string, ignoreCase?: boolean): boolean {
        if (Str.isNullOrUndefined(s) || Str.isNullOrUndefined(endsWith) || s.length < endsWith.length) return false
        if (ignoreCase) {
            return s.substr(s.length - endsWith.length, endsWith.length).toLowerCase() === endsWith.toLowerCase()
        }
        return s.substr(s.length - endsWith.length, endsWith.length) === endsWith
    }

    public static isBoolean(str: string): boolean {
        if (str === void 0 || str === null) return false
        return str === 'true' || str === 'false'
    }

    public static isDate(str: string): boolean {
        const d = new Date(str)
        return d && d.getFullYear() > 0
    }

    public static isJSONString(str: string): boolean {
        if (str === null || str === 'null') return true
        if (str === void 0) return false
        if (str[0].trim() === '{') {
            try {
                JSON.parse(str)
                return true
            } catch (e) {}
        }
        return false
    }

    public static isString(value: any): boolean {
        return typeof value === 'string' || value instanceof String
    }

    public static jsonEscape(str: string): string {
        return str.replace(/\n/g, '\\\\n').replace(/\r/g, '\\\\r').replace(/\t/g, '\\\\t')
    }

    public static convert(str?: string): any {
        if (Str.isNullOrUndefined(str)) {
            return null
        } else if (Str.isNumeric(str)) {
            return parseFloat(str)
        } else if (Str.isBoolean(str)) {
            return str === 'true'
        } else if (Str.isDate(str)) {
            return Date.parse(str)
        } else if (Str.isJSONString(str)) {
            return JSON.parse(str)
        } else {
            return Str.unquote(str)
        }
    }

    public static unquote(str: string): string {
        if (str) {
            const t = str.trim()
            if (Str.startsWith(t, '"') && Str.endsWith(t, '"')) {
                return t.replace('"', '') // s.strip(t, '"');
            } else if (Str.startsWith(t, "'") && Str.endsWith(t, "'")) {
                return t.replace("'", '') // s.strip(t, '\'');
            }
        }
        return str
    }

    public static isNullOrUndefined(s: string | null | undefined): s is null | undefined {
        return Util.isNullOrUndefined(s)
        //return (s === null || s === void 0)
    }

    public static isNothingOrWhitespace(s?: string | null): s is null | undefined {
        if (Util.isNullOrUndefined(s)) {
            return true
        }
        if (typeof s === 'string') {
            return s.trim().length === 0
        }
        return false
    }

    public static isNothingOrEmpty(s: string | null | undefined): boolean {
        if (Util.isNullOrUndefined(s)) {
            return true
        }
        if (typeof s === 'string') {
            return s.length === 0
        }
        return false
    }

    public static nullIfNothingOrWhitespace(s?: string): string | null | undefined {
        return Str.isNothingOrWhitespace(s) ? null : s
    }

    public static indexOf(s: string | undefined | null, pattern: string, startPos = 0, ignoreCase?: boolean): number {
        if (
            Util.isNullOrUndefined(s) ||
            Util.isNullOrUndefined(pattern) ||
            typeof s !== 'string' ||
            typeof pattern !== 'string'
        ) {
            return -1
        }
        if (startPos >= s.length) {
            return -1
        }
        const index = ignoreCase
            ? s.toLowerCase().indexOf(pattern.toLowerCase(), startPos)
            : s.indexOf(pattern, startPos)
        return index > -1 ? index + startPos : index
    }

    public static replace(input: string, searchValue: string, replaceValue: string, ignoreCase?: boolean): string {
        if (!input || !searchValue) {
            return input
        }
        if (!ignoreCase) {
            return input.replace(searchValue, replaceValue)
        }
        const esc = searchValue.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
        const reg = new RegExp(esc, 'ig')
        return input.replace(reg, replaceValue)
    }

    public static contains(str: string, substr: string): boolean {
        return Str.indexOf(str, substr, 0, false) > -1
    }
}
