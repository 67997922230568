import { FunctionComponent, useState } from 'react'
import Grid from '../../../infratructure/grid/Grid'
import CopyableInput from '../../../infratructure/copyableInput/CopyableInput'
import Heading from '../../../infratructure/heading/Heading'
import { DeploymentModel, EnvironmentModel, FlowInstanceModel } from '../../Model/AllSparkModel'
import styles from './FlowInstanceDetails.module.scss'
import List, { ListItemProps } from '../../../infratructure/list/List'
import commonListStyles from './../../CommonList.module.scss'
import ClipboardButton from '../../../infratructure/clipboardButton/ClipboardButton'

interface FlowInstanceDetailsOverviewProps {
    environments: EnvironmentModel[]
    instance?: FlowInstanceModel
    deploymentsInUse: DeploymentModel[]
}

const FlowInstanceDetailsOverview: FunctionComponent<FlowInstanceDetailsOverviewProps> = ({
    environments,
    instance,
    deploymentsInUse,
}) => {
    const designerUri =
        instance?.tenantId != null
            ? instance.designerDeploymentUri + '?tenant=' + instance.tenantId
            : instance?.designerDeploymentUri
    const portalUri =
        instance?.tenantId != null
            ? instance.portalDeploymentUri + '?tenant=' + instance.tenantId
            : instance?.portalDeploymentUri

    const DeliveryItemLayout = ({ item }: ListItemProps<DeploymentModel>) => {
        return (
            <Grid rows="auto 1fr" className={commonListStyles.item}>
                <div className={commonListStyles.fontStyle}>{`deployment name`}</div>
                <Grid columns="auto 1fr 105px" style={{ alignItems: 'center' }} gap={8}>
                    <div className={commonListStyles.itemname} title={item.name}>{`${item.name}`}</div>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid columns="400px 1fr">
            <Grid rows="auto auto 1fr" gap={20}>
                <Heading type="heading1">{`Flow Instance Overview`}</Heading>
                <Grid rows="auto auto  1fr" gap={16} style={{ marginLeft: '10px', marginBottom: '0px' }}>
                    {instance?.portalDeploymentUri && (
                        <Grid
                            columns="80px 1fr 20px"
                            style={
                                instance.tenantId
                                    ? { marginBottom: '0', alignItems: 'center', marginRight: '20px' }
                                    : { marginBottom: '15px', alignItems: 'center', marginRight: '20px' }
                            }
                        >
                            <div className={styles.label}>Portal</div>
                            <a
                                className={styles.uristyle}
                                href={portalUri}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    width: '530px',
                                }}
                                title={portalUri}
                            >
                                {portalUri}
                            </a>
                            <ClipboardButton value={portalUri || ''} style={{ position: 'unset' }} />
                        </Grid>
                    )}

                    {instance?.tenantId && (
                        <Grid
                            columns="80px 1fr"
                            style={instance.adminEmailAddress ? { marginBottom: '0' } : { marginBottom: '15px' }}
                        >
                            <div className={styles.label}>Tenant Id</div>
                            <div className={styles.uristyle} style={{ userSelect: 'all' }}>
                                {instance.tenantId}
                            </div>
                        </Grid>
                    )}
                    {instance?.adminEmailAddress && (
                        <Grid columns="80px 1fr" style={{ marginBottom: '15px' }}>
                            <div className={styles.label}>Admin Email</div>
                            <div className={styles.uristyle} style={{ userSelect: 'all' }}>
                                {instance.adminEmailAddress}
                            </div>
                        </Grid>
                    )}

                    <CopyableInput headerText={'Name'} width={650} value={instance?.name || ''} readOnly />
                    <CopyableInput
                        width={650}
                        headerText={'Cluster controller'}
                        value={
                            instance?.clusterController?.name !== undefined
                                ? instance?.clusterController?.name +
                                  ' (' +
                                  instance?.clusterController?.flowChart +
                                  ')'
                                : 'Unset (using default)'
                        }
                        readOnly
                    />
                    <CopyableInput
                        width={650}
                        headerText={'Environment'}
                        value={environments.find((e) => e.rowKey === instance?.environmentRowKey)?.name || ''}
                        readOnly
                    />
                    {instance?.cloneSource && (
                        <Grid gap={20}>
                            <CopyableInput
                                width={650}
                                headerText={'Cloned from'}
                                value={instance?.cloneSource?.deploymentName || ''}
                                readOnly
                            />
                            <CopyableInput
                                width={650}
                                headerText={'Cloned at'}
                                value={instance?.cloneSource.timestamp || ''}
                                readOnly
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid rows="auto 1fr" style={{ marginLeft: '10px' }}>
                    <div className={styles.label} style={{ marginBottom: '5px' }}>
                        In use by
                    </div>
                    <List
                        className={commonListStyles.list}
                        item={DeliveryItemLayout}
                        spacing="compact"
                        itemSource={deploymentsInUse}
                        itemSize={46}
                        border={true}
                        disabledItems={deploymentsInUse}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FlowInstanceDetailsOverview
