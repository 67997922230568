import { uniqueId } from 'lodash'
export default class DOMUtils {
    public static isDOMElement(obj: any): boolean {
        return typeof HTMLElement === 'object'
            ? obj instanceof HTMLElement //DOM2
            : obj && typeof obj === 'object' && obj !== null && obj.nodeType === 1 && typeof obj.nodeName === 'string'
    }

    public static makeFocusable(elem: HTMLElement) {
        if (!DOMUtils.isFocusable(elem)) {
            elem.tabIndex = 0
        }
    }

    public static isFocusable(element: any): boolean {
        if (!this.isDOMElement(element)) {
            return false
        }

        const elem = element as HTMLElement
        if (elem.tabIndex === -1) {
            return false
        }

        if (elem.tabIndex >= 0 || elem.contentEditable === 'true') {
            return true
        }

        switch (elem.tagName) {
            case 'A':
            case 'AREA':
            case 'DETAILS':
                return true
            case 'IFRAME':
                return true
            case 'INPUT':
            case 'SELECT':
            case 'TEXTAREA':
            case 'BUTTON':
                return !elem.hasAttribute('disabled')
            default:
                return false
        }
    }

    // public static getKeyboardFocusableElements(element: Element | Document = document): Element[] {
    //     if (!element) {
    //         return []
    //     }
    //     return [
    //         ...element.querySelectorAll(
    //             'a, area, iframe, button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])',
    //         ),
    //     ].filter((el) => !el.hasAttribute('disabled'))
    // }

    public static createUniqueId(): string {
        return uniqueId('pb-ctrl')
    }

    // public static selectNextElement(element: HTMLElement, maxSearchDepth: number = 4): boolean {
    //     if (document.activeElement && DOMUtils.isFocusable(document.activeElement)) {
    //         // @ts-ignore
    //         document.activeElement.blur()
    //     }

    //     for (let sibling = element.nextElementSibling; sibling != null; sibling = sibling?.nextElementSibling) {
    //         if (DOMUtils.trySelectElementOrDescendant(sibling, maxSearchDepth)) {
    //             return true
    //         }
    //     }

    //     return false
    // }

    // private static trySelectElementOrDescendant(element: Element | null, maxDepth: number): boolean {
    //     if (!element) {
    //         return false
    //     }
    //     if (DOMUtils.isFocusable(element)) {
    //         // @ts-ignore
    //         element.focus()
    //         return true
    //     }

    //     if (element.children) {
    //         for (const child of element.children) {
    //             if (DOMUtils.trySelectChildrenRecursive(child, 0, maxDepth)) {
    //                 return true
    //             }
    //         }
    //     }

    //     return false
    // }

    // private static trySelectChildrenRecursive(element: Element, currentDepth: number, maxDepth: number): boolean {
    //     if (DOMUtils.isFocusable(element)) {
    //         ;(element as HTMLElement).focus()
    //         return true
    //     }

    //     if (currentDepth >= maxDepth) {
    //         return false
    //     }

    //     currentDepth++
    //     for (const child of element.children) {
    //         if (DOMUtils.trySelectChildrenRecursive(child, currentDepth, maxDepth)) {
    //             return true
    //         }
    //     }

    //     return false
    // }
}
