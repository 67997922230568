import { FunctionComponent, useState } from 'react'
import { Page } from '../../../TheAllSpark'
import Button, { ButtonType } from '../../infratructure/button/Button'
import Grid from '../../infratructure/grid/Grid'
import DeploymentsIcon from '../../icons/DeploymentsIcon'
import styles from './AppLeftSideBar.module.scss'
import ProductsIcon from '../../icons/ProductsIcon'
import EnvironmentsIcon from '../../icons/EnvironmentsIcon'
import cx from 'classnames'
import LogIcon from '../../icons/LogIcon'
import FlowIcon from '../../icons/FlowIcon'
import DockerImageIcon from '../../icons/DockerImageIcon'
import LanguageIcon from '../../icons/LanguageIcon'
import RouterIcon from '../../icons/RouterIcon'

interface AppLeftSideBarProps {
    onNavButtonClicked: (ui: Page) => void
}

const AppLeftSideBar: FunctionComponent<AppLeftSideBarProps> = ({ onNavButtonClicked }) => {
    const [selectedUI, setSelectedUI] = useState<Page>(Page.Deployments)

    const handleDeploymentsClicked = () => {
        setSelectedUI(Page.Deployments)
        onNavButtonClicked(Page.Deployments)
    }

    const handleProductsClicked = () => {
        setSelectedUI(Page.Products)
        onNavButtonClicked(Page.Products)
    }

    const handleEnvironmentsClicked = () => {
        setSelectedUI(Page.Environments)
        onNavButtonClicked(Page.Environments)
    }

    const handleFlowsClicked = () => {
        setSelectedUI(Page.Flows)
        onNavButtonClicked(Page.Flows)
    }

    const handleFailedDeploymentsClicked = () => {
        setSelectedUI(Page.FailedDeployments)
        onNavButtonClicked(Page.FailedDeployments)
    }

    const handleSystemLogClicked = () => {
        setSelectedUI(Page.SystemLog)
        onNavButtonClicked(Page.SystemLog)
    }

    const handleVersionsClicked = () => {
        setSelectedUI(Page.DockerImages)
        onNavButtonClicked(Page.DockerImages)
    }

    const handleLanguagesClicked = () => {
        setSelectedUI(Page.Languages)
        onNavButtonClicked(Page.Languages)
    }

    const handleClusterControllersClicked = () => {
        setSelectedUI(Page.ClusterControllers)
        onNavButtonClicked(Page.ClusterControllers)
    }

    return (
        <Grid rows="auto auto auto auto auto auto auto auto auto auto 1fr">
            <Button
                className={cx(styles.activestyle, { [styles.selected]: selectedUI === Page.Deployments })}
                onClick={handleDeploymentsClicked}
                buttonType={ButtonType.Icon}
                title="Deployments"
            >
                <Grid className={styles.selector} gap={16} columns="auto 1fr">
                    <DeploymentsIcon color={'#008D17'} height={26} width={26} />
                    Invision
                </Grid>
            </Button>

            <Button
                className={cx(styles.activestyle, { [styles.selected]: selectedUI === Page.Flows })}
                onClick={handleFlowsClicked}
                buttonType={ButtonType.Icon}
                title="Flow"
            >
                <Grid className={styles.selector} gap={16} columns="auto 1fr">
                    <FlowIcon height={26} width={26} style={{ color: '#dadada' }} />
                    Flow
                </Grid>
            </Button>

            <div className={styles.splitter}></div>

            <Button
                className={cx(styles.activestyle, { [styles.selected]: selectedUI === Page.Products })}
                onClick={handleProductsClicked}
                buttonType={ButtonType.Icon}
                title="Products"
            >
                <Grid className={styles.selector} gap={16} columns="auto 1fr">
                    <ProductsIcon height={26} width={26} style={{ color: '#dadada' }} />
                    Products
                </Grid>
            </Button>

            <Button
                className={cx(styles.activestyle, { [styles.selected]: selectedUI === Page.Environments })}
                onClick={handleEnvironmentsClicked}
                buttonType={ButtonType.Icon}
                title="Environments"
            >
                <Grid className={styles.selector} gap={16} columns="auto 1fr">
                    <EnvironmentsIcon height={26} width={26} style={{ color: '#dadada' }} />
                    Environments
                </Grid>
            </Button>

            <div className={styles.splitter}></div>

            <Button
                className={cx(styles.activestyle, { [styles.selected]: selectedUI === Page.FailedDeployments })}
                onClick={handleFailedDeploymentsClicked}
                buttonType={ButtonType.Icon}
                title="Failed deployments"
            >
                <Grid className={styles.selector} gap={16} columns="auto 1fr">
                    <DeploymentsIcon color={'#CC0000'} height={26} width={26} />
                    Failed deployments
                </Grid>
            </Button>
            <Button
                className={cx(styles.activestyle, { [styles.selected]: selectedUI === Page.SystemLog })}
                onClick={handleSystemLogClicked}
                buttonType={ButtonType.Icon}
                title="System Log"
            >
                <Grid className={styles.selector} gap={16} columns="auto 1fr">
                    <LogIcon color="#dadada" height={26} width={26} />
                    System log
                </Grid>
            </Button>

            <Button
                className={cx(styles.activestyle, { [styles.selected]: selectedUI === Page.DockerImages })}
                onClick={handleVersionsClicked}
                buttonType={ButtonType.Icon}
                title="Versions"
            >
                <Grid className={styles.selector} gap={16} columns="auto 1fr">
                    <DockerImageIcon style={{ color: '#dadada' }} height={26} width={26} />
                    Docker Images
                </Grid>
            </Button>

            <Button
                className={cx(styles.activestyle, { [styles.selected]: selectedUI === Page.Languages })}
                onClick={handleLanguagesClicked}
                buttonType={ButtonType.Icon}
                title="Languages"
            >
                <Grid className={styles.selector} gap={16} columns="auto 1fr">
                    <LanguageIcon style={{ color: '#dadada' }} height={26} width={26} />
                    Languages
                </Grid>
            </Button>

            <Button
                className={cx(styles.activestyle, { [styles.selected]: selectedUI === Page.ClusterControllers })}
                onClick={handleClusterControllersClicked}
                buttonType={ButtonType.Icon}
                title="Cluster controller"
            >
                <Grid className={styles.selector} gap={16} columns="auto 1fr">
                    <RouterIcon style={{ color: '#dadada' }} height={26} width={26} />
                    Cluster controllers
                </Grid>
            </Button>
        </Grid>
    )
}

export default AppLeftSideBar
