import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import TheAllSpark from './TheAllSpark'
import { PublicClientApplication } from '@azure/msal-browser'
import { msalConfig } from './authConfig'
import { HttpService } from './HttpService'
import { AllSparkHttpClient } from './AllSparkHttpClient'
import { MsalProvider } from '@azure/msal-react'
import { Env } from './components/utils/Env'

export let ALLSPARK_BASEURL = Env.getBaseUrl()

interface AuthSettings {
    instance: string;
    domain: string;
    clientId: string;
    signedOutCallbackPath: string;
    signUpSignInPolicyId: string;
    redirectUri: string;
    apiScope: string;
    version: string;
}

if (process.env.NODE_ENV === 'development') {
    ALLSPARK_BASEURL = 'https://localhost:27080/'
}

declare global {
    interface Window {
        authSettings: AuthSettings;
    }
}

const httpService = new HttpService(ALLSPARK_BASEURL)
const backendApi = new AllSparkHttpClient(httpService)

export const AllSparkContext = React.createContext<AllSparkHttpClient>(backendApi)

export const msalInstance = new PublicClientApplication(msalConfig)
const accounts = msalInstance.getAllAccounts()
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0])
}

const root = createRoot(document.getElementById('root')!)

root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <AllSparkContext.Provider value={backendApi}>
                <TheAllSpark />
            </AllSparkContext.Provider>
        </MsalProvider>
        ,
    </React.StrictMode>,
)
