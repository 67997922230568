import { FunctionComponent, useCallback, useLayoutEffect, useState } from 'react'
import Grid from '../../infratructure/grid/Grid'
import Spark from '../../infratructure/spark/Spark'
import SpinningCube from '../../infratructure/spinningcube/SpinningCube'
import styles from './AppHeader.module.scss'
import cx from 'classnames'
import { useGlobalStateContext } from '../../../GlobalState'

//T h e\u00A0\u00A0
const headerText = `A l l S p a r k`
const empoweringText = `empowering Profitbase Azure`
const AppHeader: FunctionComponent = () => {
    const {
        globalState: { isSparking },
    } = useGlobalStateContext()
    const [startSparking, setStartSparking] = useState(false)

    useLayoutEffect(() => {
        import('../../infratructure/spark/sparkcode')
    })

    const handleSpinningCubeClicked = () => {
        setStartSparking(!startSparking)
    }

    const shouldSpark = useCallback(() => {
        if (!isSparking && !startSparking) {
            return true
        }

        return false
    }, [isSparking, startSparking])

    return (
        <Grid columns="200px 300px 1fr" className={styles.headerContainer}>
            <SpinningCube onClick={handleSpinningCubeClicked} />
            <div className={styles.header}>
                <div className={styles.maintext}>{headerText}</div>
                <div className={styles.subtext}>{empoweringText}</div>
            </div>
            <Grid id="sparkcontainer" style={{ height: '100px' }} className={cx({ [styles.invisible]: shouldSpark() })}>
                <Spark />
            </Grid>
        </Grid>
    )
}

export default AppHeader
