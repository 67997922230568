import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import styles from './FailedDeployments.module.scss'
import FailedDeploymentList from './FailedDeploymentList'
import FailedDeploymentDetails from './FailedDeploymentDetails'
import { FailedDeploymentModel } from '../Model/AllSparkModel'
import Heading from '../../infratructure/heading/Heading'
import ButtonNavigator from '../../infratructure/buttonNavigator/ButtonNavigator'
import Grid from '../../infratructure/grid/Grid'
import { AllSparkContext } from '../../..'
import FailedDeploymentsToolbar from './FailedDeploymentsToolbar'
import Spinner from '../../infratructure/spinner/Spinner'
import { ArrayUtil } from '../../utils/ArrayUtil'
import useAsyncError from '../../infratructure/hooks/useAsyncError'
import { useGlobalStateContext } from '../../../GlobalState'

type FailedDeploymentState = {
    failedDeployments: FailedDeploymentModel[]
    isWorking: boolean
}

const FailedDeployments: FunctionComponent = () => {
    const api = useContext(AllSparkContext)
    const throwError = useAsyncError()
    const {
        globalState: { selectedFailedDeploymentRowKey },
        setGlobalState,
    } = useGlobalStateContext()

    const [{ failedDeployments, isWorking }, setState] = useState<FailedDeploymentState>({
        failedDeployments: [],
        isWorking: false,
    })

    const selectedFailedDeployment = useMemo(
        () => failedDeployments.find((fd) => fd.rowKey === selectedFailedDeploymentRowKey),
        [failedDeployments, selectedFailedDeploymentRowKey],
    )

    const onGetData = useCallback(async () => {
        setState((prev) => ({ ...prev, isWorking: true }))

        try {
            const failedDeployments = await api.getFailedDeployments()

            if (failedDeployments) {
                setState((prev) => ({
                    ...prev,
                    failedDeployments: failedDeployments.sort(ArrayUtil.sortByPropCompare('deploymentId')),
                    isWorking: false,
                }))

                if (!failedDeployments.some((p) => p.rowKey === selectedFailedDeploymentRowKey)) {
                    setGlobalState((prev) => ({
                        ...prev,
                        selectedFailedDeploymentRowKey:
                            failedDeployments.length > 0 ? failedDeployments[0].deploymentId : undefined,
                    }))
                }
            } else {
                setState((prev) => ({ ...prev, isWorking: false }))
            }
        } catch (error) {
            console.log('Exception : Unable to load data. Check if the backend is running !!')
            setState((prev) => ({ ...prev, isWorking: false }))
            throwError(error)
        }
    }, [api, selectedFailedDeploymentRowKey, setGlobalState, throwError])

    // load data on mount
    useEffect(() => {
        onGetData()
    }, [api, onGetData])

    const onSelectedFailedDeploymentChange = (failedDeployment: FailedDeploymentModel) => {
        setGlobalState((prev) => ({ ...prev, selectedFailedDeploymentRowKey: failedDeployment.rowKey }))
    }

    const onDeleteFailedDeployment = async () => {
        if (selectedFailedDeployment) {
            setState((prev) => ({ ...prev, isWorking: true, isDeleting: true }))

            await api.deleteFailedDeployment(selectedFailedDeployment.rowKey)

            setState((prev) => ({
                ...prev,
                failedDeployments: failedDeployments
                    .filter((o) => o.rowKey !== selectedFailedDeployment?.rowKey)
                    .sort(ArrayUtil.sortByPropCompare('deploymentId')),
                isDeleting: false,
                isWorking: false,
            }))

            setGlobalState((prev) => ({
                ...prev,
                selectedFailedDeploymentRowKey:
                    failedDeployments.length > 0 ? failedDeployments[0].rowKey : undefined,
            }))
        }
    }
    return (
        <Grid rows="auto 1fr" style={{ overflow: 'hidden' }}>
            <FailedDeploymentsToolbar
                hasItemSelected={!!selectedFailedDeploymentRowKey}
                isWorking={false}
                onRefresh={onGetData}
                onDelete={onDeleteFailedDeployment}
            />
            <Grid rows="auto 1fr" columns="350px 1fr" gap={4} className={styles.container}>
                <Grid columns="1fr auto" style={{ alignItems: 'center' }}>
                    <Heading type={'heading1'}>Failed Deployments</Heading>
                    {isWorking && <Spinner />}
                </Grid>
                <ButtonNavigator
                    spacing="compact"
                    buttons={[
                        {
                            id: 'Details',
                            caption: 'Details',
                        },
                    ]}
                    selectedButtonId={'Details'}
                    onButtonClicked={() => {}}
                />
                <FailedDeploymentList
                    items={failedDeployments}
                    onSelectedChange={onSelectedFailedDeploymentChange}
                    selectedItems={selectedFailedDeployment ? [selectedFailedDeployment] : undefined}
                />
                <FailedDeploymentDetails selectedFailedDeployment={selectedFailedDeployment} />
            </Grid>
        </Grid>
    )
}

export default FailedDeployments
