import { FunctionComponent } from 'react'
import Grid from '../../infratructure/grid/Grid'
import Button, { ButtonType } from '../../infratructure/button/Button'
import TrashIcon from '../../icons/TrashIcon'
import styles from './DockerImagesToolbar.module.scss'
import SettingsCogIcon from '../../icons/SettingsCogIcon'

interface DockerImagesToolbarProps {
    isWorking: boolean
    hasItemSelected: boolean
    onDelete: () => void
    onSettings: () => void
}

const DockerImagesToolbar: FunctionComponent<DockerImagesToolbarProps> = ({
    isWorking,
    hasItemSelected,
    onDelete,
    onSettings,
}) => {
    return (
        <Grid className={styles.container} columns="auto auto 1fr" gap={16}>
            <Button
                onClick={onDelete}
                buttonType={ButtonType.Icon}
                className={styles.btn}
                disabled={isWorking || !hasItemSelected}
                title="Delete all selected docker images"
            >
                <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                    <TrashIcon height={26} width={26} />
                    <div>Delete</div>
                </Grid>
            </Button>
            <Button
                onClick={onSettings}
                buttonType={ButtonType.Icon}
                className={styles.btn}
                disabled={isWorking}
                title="Container Registry Settings"
            >
                <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                    <SettingsCogIcon height={26} width={26} />
                    <div>Settings</div>
                </Grid>
            </Button>
        </Grid>
    )
}

export default DockerImagesToolbar
