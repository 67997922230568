import { FunctionComponent } from "react"
import styles from "./Dirty.module.scss"

interface DirtyProps{
    dirty: boolean
}


const dirty: FunctionComponent<DirtyProps> = ({ dirty }) =>{

    return (
        <div>
        {
            dirty ? (

                <div className={styles.dirty}>*</div>
            ) : (
                <div className={styles.notDirty}></div>
            )
        }
        </div>
    )
}

export default dirty