import React from 'react'
import cx from 'classnames'
import { IconProps } from './IconProps'

export default function SaveIcon({ width = 20, height = 20, className, style }: IconProps) {
    return (
        <svg
            fill="none"
            viewBox="0 0 24 24"
            width={width}
            height={height}
            className={cx('icon', className)}
            style={style}
            data-icon
        >
            <path
                d="M2 5.056A3.056 3.056 0 015.056 2h11.071c.958 0 1.876.38 2.554 1.058l2.261 2.261A3.611 3.611 0 0122 7.873v11.071A3.056 3.056 0 0118.944 22H5.056A3.056 3.056 0 012 18.944V5.056zm3.056-1.39a1.39 1.39 0 00-1.39 1.39v13.888c0 .768.622 1.39 1.39 1.39h.277V14.5a2.5 2.5 0 012.5-2.5h8.334a2.5 2.5 0 012.5 2.5v5.833h.277c.768 0 1.39-.621 1.39-1.389V7.873c0-.516-.206-1.01-.57-1.375l-2.262-2.262a1.944 1.944 0 00-1.058-.543v3.03a2.5 2.5 0 01-2.5 2.5h-5a2.5 2.5 0 01-2.5-2.5V3.666H5.056zM17 20.334V14.5a.833.833 0 00-.833-.833H7.833A.833.833 0 007 14.5v5.833h10zM8.111 3.667v3.055c0 .46.373.834.833.834h5c.46 0 .834-.374.834-.834V3.667H8.11z"
                fill="currentColor"
            />
        </svg>
    )
}
