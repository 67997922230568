import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Grid from '../../infratructure/grid/Grid'
import Heading from '../../infratructure/heading/Heading'
import styles from './Environments.module.scss'
import EnvironmentList from './EnvironmentList'
import ButtonNavigator from '../../infratructure/buttonNavigator/ButtonNavigator'
import EnvironmentDetails from './EnvironmentDetails'
import { ClusterController, EnvironmentModel } from '../Model/AllSparkModel'
import { AllSparkContext } from '../../..'
import EnvironmentToolbar from './EnvironmentToolbar'
import Spinner from '../../infratructure/spinner/Spinner'
import { Modal } from 'react-responsive-modal'
import NewEnvironment from './new/NewEnvironment'
import cx from 'classnames'
import { ArrayUtil } from '../../utils/ArrayUtil'
import DeleteEnvironmentConfirmation from './delete/DeleteEnvironmentConfirmation'
import { useGlobalStateContext } from '../../../GlobalState'
import useAsyncError from '../../infratructure/hooks/useAsyncError'

type EnvironmentsState = {
    environments: EnvironmentModel[]
    clusterControllers: ClusterController[]
    isWorking: boolean
    isNewOpen: boolean
    isDeleteConfirmationOpen: boolean
}

const Environments: FunctionComponent = () => {
    const api = useContext(AllSparkContext)
    const throwError = useAsyncError()
    const {
        globalState: { selectedEnvironmentId },
        setGlobalState,
    } = useGlobalStateContext()

    const [{ environments, clusterControllers, isWorking, isNewOpen, isDeleteConfirmationOpen }, setState] =
        useState<EnvironmentsState>({
            environments: [],
            clusterControllers: [],
            isWorking: false,
            isNewOpen: false,
            isDeleteConfirmationOpen: false,
        })

    const selectedEnvironment = useMemo(
        () => environments.find((env) => env.rowKey === selectedEnvironmentId),
        [environments, selectedEnvironmentId],
    )

    const onGetData = useCallback(async () => {
        setState((prev) => ({ ...prev, isWorking: true }))
        try {
            const loadedEnvironments = await api.getEnvironments()
            const loadedClusterControllers = await api.getClusterControllers()
            if (loadedEnvironments && loadedClusterControllers) {
                setState((prev) => ({
                    ...prev,
                    environments: loadedEnvironments.sort(ArrayUtil.sortByPropCompare('name', true)),
                    clusterControllers: loadedClusterControllers.sort(ArrayUtil.sortByPropCompare('name', true)),
                    isWorking: false,
                }))
            } else {
                setState((prev) => ({ ...prev, isWorking: false }))
            }
        } catch (error) {
            console.log('Exception : Unable to load data. Check if the backend is running !!', error)
            setState((prev) => ({ ...prev, isWorking: false }))
            throwError(error)
        }
    }, [api, throwError])

    const onConfirmedDelete = async () => {
        if (selectedEnvironment) {
            setState((prev) => ({ ...prev, isWorking: true, isDeleting: true }))
            if (selectedEnvironment.rowKey) {
                try {
                    await api.deleteEnvironment(selectedEnvironment.rowKey)

                    setState((prev) => ({
                        ...prev,
                        environments: environments
                            .filter((o) => o.rowKey !== selectedEnvironment.rowKey)
                            .sort(ArrayUtil.sortByPropCompare('name', true)),
                        isDeleting: false,
                        isWorking: false,
                        isDeleteConfirmationOpen: false,
                    }))

                    setGlobalState((prev) => ({
                        ...prev,
                        selectedEnvironmentId: environments.length > 0 ? environments[0].rowKey : undefined,
                    }))
                } catch (error) {
                    setState((prev) => ({
                        ...prev,
                        isDeleting: false,
                        isWorking: false,
                        isDeleteConfirmationOpen: false,
                    }))
                }
            }
        }
    }

    // load on mount
    useEffect(() => {
        onGetData()
    }, [onGetData])

    useEffect(() => {
        if (environments.length > 0 && !environments.some((p) => p.rowKey === selectedEnvironmentId)) {
            setGlobalState((prev) => ({
                ...prev,
                selectedEnvironmentId: environments.length > 0 ? environments[0].rowKey : undefined,
            }))
        }
    }, [environments, selectedEnvironmentId, setGlobalState])

    const onSelectedEnvironmentChange = (environment: EnvironmentModel) => {
        setGlobalState((prev) => ({ ...prev, selectedEnvironmentId: environment.rowKey }))
    }

    const closeIcon = () => (isWorking ? <div /> : '')

    const handleNewEnvironment = (newEnvironment: EnvironmentModel) => {
        if (newEnvironment) {
            setState((prev) => ({
                ...prev,
                isNewOpen: false,
                environments: [...environments, newEnvironment].sort(ArrayUtil.sortByPropCompare('name', true)),
            }))

            setGlobalState((prev) => ({ ...prev, selectedEnvironmentId: newEnvironment.rowKey }))
        }
    }

    const handleSave = async () => {
        if (selectedEnvironment) {
            try {
                setState((prev) => ({ ...prev, isWorking: true }))

                const savedEnvironment = await api.createOrUpdateEnvironment(selectedEnvironment)

                if (savedEnvironment) {
                    const envs = [
                        ...environments.filter((e) => e.rowKey !== savedEnvironment?.rowKey),
                        savedEnvironment,
                    ].sort(ArrayUtil.sortByPropCompare('name', true))

                    setState((prev) => ({
                        ...prev,
                        isWorking: false,
                        environments: envs,
                    }))

                    setGlobalState((prev) => ({ ...prev, selectedEnvironmentId: savedEnvironment.rowKey }))
                }
            } catch (error) {
                console.log(error)
                setState((prev) => ({ ...prev, isWorking: false }))
            }
        }
    }

    const handleOnClone = async () => {
        if (selectedEnvironment?.rowKey) {
            try {
                setState((prev) => ({ ...prev, isWorking: true }))
                const clonedEnvironment = await api.cloneEnvironment(selectedEnvironment.rowKey)
                const targetIndex = environments.indexOf(selectedEnvironment) + 1
                if (clonedEnvironment) {
                    setState((prev) => ({
                        ...prev,
                        environments: [
                            ...environments.slice(0, targetIndex),
                            clonedEnvironment,
                            ...environments.slice(targetIndex),
                        ],
                        isWorking: false,
                    }))
                    setGlobalState((prev) => ({ ...prev, selectedEnvironmentId: clonedEnvironment.rowKey }))
                }
            } catch (error) {
                console.log(error)
                setState((prev) => ({ ...prev, isWorking: false }))
            }
        }
    }

    const onSelectedEnvironmentPropertyChanged = (updatedEnvironment: EnvironmentModel) => {
        const index = environments.findIndex((env) => env.rowKey === updatedEnvironment.rowKey)
        const updatedEnvironments = [
            ...environments.slice(0, index),
            updatedEnvironment,
            ...environments.slice(index + 1),
        ]

        setState((prev) => ({
            ...prev,
            environments: updatedEnvironments,
        }))

        setGlobalState((prev) => ({ ...prev, selectedEnvironmentId: updatedEnvironment.rowKey }))
    }

    return (
        <Grid rows="auto 1fr" style={{ overflow: 'hidden' }}>
            <EnvironmentToolbar
                hasItemSelected={!!selectedEnvironmentId}
                isWorking={false}
                onAdd={() => setState((prev) => ({ ...prev, isNewOpen: true }))}
                onSave={handleSave}
                onDelete={() => setState((prev) => ({ ...prev, isDeleteConfirmationOpen: true }))}
                onClone={handleOnClone}
            />
            <Grid rows="auto 1fr" columns="350px 1fr" gap={4} className={styles.container}>
                <Grid columns="1fr auto">
                    <Heading type={'heading1'}>Environments</Heading>
                    {isWorking && <Spinner />}
                </Grid>
                <ButtonNavigator
                    spacing="compact"
                    buttons={[
                        {
                            id: 'Details',
                            caption: 'Details',
                        },
                    ]}
                    selectedButtonId={'Details'}
                    onButtonClicked={() => {}}
                />
                <EnvironmentList
                    items={environments}
                    onSelectedChange={onSelectedEnvironmentChange}
                    selectedItems={selectedEnvironment ? [selectedEnvironment] : undefined}
                />

                <EnvironmentDetails
                    selectedEnvironment={selectedEnvironment}
                    clusterControllers={clusterControllers}
                    onPropertyChange={onSelectedEnvironmentPropertyChanged}
                />
            </Grid>
            {isNewOpen && (
                <Modal
                    open={isNewOpen}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!isWorking) {
                            setState((prev) => ({
                                ...prev,
                                isNewOpen: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: cx(styles.customModal),
                    }}
                    closeIcon={closeIcon()}
                >
                    <NewEnvironment onAdd={handleNewEnvironment} />
                </Modal>
            )}
            {isDeleteConfirmationOpen && (
                <Modal
                    open={isDeleteConfirmationOpen}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!isWorking) {
                            setState((prev) => ({
                                ...prev,
                                isDeleteConfirmationOpen: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: cx(styles.customModal),
                    }}
                    closeIcon={closeIcon()}
                >
                    <DeleteEnvironmentConfirmation
                        isWorking={isWorking}
                        selectedEnvironment={selectedEnvironment}
                        onDelete={onConfirmedDelete}
                    />
                </Modal>
            )}
        </Grid>
    )
}

export default Environments
