import { FunctionComponent, useState } from 'react'
import { FlowInstanceModel, VersionResponse } from '../Model/AllSparkModel'
import Grid from '../../infratructure/grid/Grid'
import commonListStyles from './../CommonList.module.scss'
import List, { ListItemProps } from '../../infratructure/list/List'
import { Util } from '../../utils/Util'
import Alert from '../../infratructure/alert/Alert'
import Ok from '../../infratructure/ok/Ok'
import UpgradeIcon2 from '../../icons/UpgradeIcon2'
import deploymentStyles from './FlowInstanceList.module.scss'
import Disabled from '../../infratructure/disabled/Disabled'
import Search from '../../infratructure/search/Search'

interface FlowInstanceListProps {
    items: FlowInstanceModel[]
    onSelectedChange: (deployment: FlowInstanceModel) => void
    onFilterTextChanged: (searchText: string) => void
    filterText: string
    selectedItems: FlowInstanceModel[] | undefined
    versions: VersionResponse | undefined
}

const FlowInstanceList: FunctionComponent<FlowInstanceListProps> = ({
    items,
    onSelectedChange,
    onFilterTextChanged,
    filterText,
    selectedItems,
    versions,
}) => {
    const DeliveryItemLayout = ({ item }: ListItemProps<FlowInstanceModel>) => {
        let latestVersion = versions?.versions ? versions.versions[0]?.version : undefined
        let today = new Date().valueOf()

        let secretExpiry = item.flowAuthorization.secretExpiry || new Date(0, 0, 1, 0, 0)
        let secretDuration = new Date(secretExpiry).valueOf() - today
        if (item.flowAuthorization.externalAuthorizationModel !== null) {
            let se = item.flowAuthorization.externalAuthorizationModel?.secretExpiry!
            secretDuration = new Date(se).valueOf() - today
        }

        const threshold = 1000 * 60 * 60 * 24 * 30 * 6 // 6 months

        let message = ''
        let messageType = 'ok'

        if (secretDuration <= threshold) {
            messageType = 'alert'
            let secretmsg = Util.millisecondsToReadableDuration(Math.abs(secretDuration))
            message =
                secretDuration <= 0
                    ? 'Client secret expired ' + secretmsg + ' ago. '
                    : secretDuration <= threshold
                    ? 'Client secret expires in ' + secretmsg + '. '
                    : ''
        } else {
            message = 'Client secret expires in ' + Util.millisecondsToReadableDuration(Math.abs(secretDuration)) + '.'
        }

        return (
            <Grid rows="auto 1fr" className={commonListStyles.item}>
                <div className={commonListStyles.fontStyle}>{`deployment name`}</div>
                <Grid columns="auto 1fr 105px" style={{ alignItems: 'center' }} gap={8}>
                    {item.enabled === false && <Disabled disabledMessage="Disabled" />}
                    {messageType === 'ok' && item.enabled !== false && <Ok okMessage={message} />}
                    {messageType === 'alert' && item.enabled !== false && <Alert alertMessage={message} />}

                    {item.enabled !== false ? (
                        <div className={commonListStyles.itemname} title={item.name}>{`${item.name}`}</div>
                    ) : (
                        <div
                            className={commonListStyles.itemname}
                            title={item.name}
                            style={{ color: 'var(--theme-disabled-color)' }}
                        >{`${item.name}`}</div>
                    )}

                    <Grid columns="auto 1fr" style={{ alignItems: 'center', justifyContent: 'flex-start' }} gap={4}>
                        {item.platformVersion !== latestVersion && latestVersion !== undefined ? (
                            <Grid title="Newer version available" style={{ alignItems: 'center' }}>
                                {item.enabled !== false ? (
                                    <UpgradeIcon2 width={22} height={22} />
                                ) : (
                                    <UpgradeIcon2 width={22} height={22} fill="var(--theme-disabled-color)" />
                                )}
                            </Grid>
                        ) : (
                            <div style={{ width: '22px' }} />
                        )}
                        {item.enabled !== false ? (
                            <div className={deploymentStyles.platformVersion}>{`${item.platformVersion}`}</div>
                        ) : (
                            <div
                                className={deploymentStyles.platformVersion}
                                style={{ color: 'var(--theme-disabled-color)' }}
                            >{`${item.platformVersion}`}</div>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid rows="auto 1fr" gap={4}>
            <Search onChange={onFilterTextChanged} className={deploymentStyles.search} style={{ borderRadius: 0 }} />
            <List
                className={commonListStyles.list}
                item={DeliveryItemLayout}
                spacing="compact"
                itemSource={items}
                itemSize={46}
                onItemSelect={onSelectedChange}
                selectedItems={selectedItems}
                border={true}
            />
        </Grid>
    )
}

export default FlowInstanceList
