const KeyboardKeyMappings = {
    win: {
        ctrl: 'ctrl',
    },
    macOS_iOS: {
        ctrl: 'command',
    },
}

const KnownBrowserHotkeys = [`${KeyboardKeyMappings.win.ctrl}+s`, `${KeyboardKeyMappings.macOS_iOS.ctrl}+s`]

/**
 * This function makes hotkeys universal across keyboards (Win vs Mac) so that we don't have to manually
 * map keyboard specific keys to their counterparts on a different keyboard / OS
 *
 * For example, the hotkey "ctrl+a" is changed to "ctrl+a, command+a" so that it works on both Windows and Mac/iOS
 * @param hotkeys
 * @returns
 */
export const makeHotkeysUniversal = (hotkeys: string): string => {
    const keyCombinations = hotkeys.split(',')
    const ar = []
    for (const keyCombo of keyCombinations) {
        ar.push(keyCombo)
        if (keyCombo.includes(KeyboardKeyMappings.win.ctrl)) {
            ar.push(keyCombo.replaceAll(KeyboardKeyMappings.win.ctrl, KeyboardKeyMappings.macOS_iOS.ctrl))
        } else if (keyCombo.includes(KeyboardKeyMappings.macOS_iOS.ctrl)) {
            ar.push(keyCombo.replaceAll(KeyboardKeyMappings.macOS_iOS.ctrl, KeyboardKeyMappings.win.ctrl))
        }
    }

    return [...new Set(ar)].join(',')
}

export const isCtrlKey = (key: string) => {
    return key === KeyboardKeyMappings.win.ctrl || KeyboardKeyMappings.macOS_iOS.ctrl
}

export const isKnownBrowserHotkey = (hotkey?: string | null): boolean => {
    if (!hotkey) {
        return false
    }
    hotkey = hotkey.toLocaleLowerCase().trim()
    return KnownBrowserHotkeys.includes(hotkey)
}

export const isControlKeyPressed = (e: KeyboardEvent): boolean => {
    if (e.ctrlKey) {
        return true
    }

    // @ts-ignore
    if (navigator.userAgentData) {
        // @ts-ignore
        const isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.userAgentData.platform)
        return e.metaKey && isMac
    }

    const isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
    return e.metaKey && isMac
}

export const isExitElementHotkeyPressed = (e: KeyboardEvent): boolean => {
    return e.code === 'F2' && e.altKey && isControlKeyPressed(e)
}
