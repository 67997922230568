import { FunctionComponent } from 'react'
import List, { ListItemProps } from '../../infratructure/list/List'
import commonListStyles from './../CommonList.module.scss'
import Grid from '../../infratructure/grid/Grid'

interface LanguagesListProps {
    languages: string[]
    onSelectedChange: (language: string) => void
    selectedItems: string[] | undefined
}

const LanguagesList: FunctionComponent<LanguagesListProps> = ({ languages, onSelectedChange, selectedItems }) => {
    const DeliveryItemLayout = ({ item }: ListItemProps<string>) => {
        return (
            <Grid className={commonListStyles.item}>
                <Grid>
                    <strong className={commonListStyles.itemname} title={item}>
                        {`${item}`}
                    </strong>
                </Grid>
            </Grid>
        )
    }

    return (
        <List
            className={commonListStyles.list}
            item={DeliveryItemLayout}
            spacing="compact"
            itemSource={languages}
            itemSize={46}
            onItemSelect={onSelectedChange}
            selectedItems={selectedItems}
            border={true}
        />
    )
}

export default LanguagesList
