import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Grid from '../../infratructure/grid/Grid'
import ClusterControllersToolbar from './ClusterControllersToolbar'
import Heading from '../../infratructure/heading/Heading'
import ClusterControllersList from './ClusterControllersList'
import { AllSparkContext } from '../../..'
import useAsyncError from '../../infratructure/hooks/useAsyncError'
import styles from './ClusterControllers.module.scss'
import cx from 'classnames'
import Modal from 'react-responsive-modal'
import NewClusterController from './NewClusterController'
import { ClusterController } from '../Model/AllSparkModel'

type ClusterControllersState = {
    clusterControllers: ClusterController[]
    isWorking: boolean
    isAddOpen: boolean
}

const ClusterControllers: FunctionComponent = () => {
    const api = useContext(AllSparkContext)
    const throwError = useAsyncError()

    const [state, setState] = useState<ClusterControllersState>({
        clusterControllers: [],
        isWorking: false,
        isAddOpen: false,
    })

    const onGetData = useCallback(async () => {
        getClusterControllers()
    }, [api, throwError])

    const getClusterControllers = async () => {
        setState((prev) => ({ ...prev, isWorking: true }))
        try {
            const clusterControllers = await api.getClusterControllers()
            if (clusterControllers && clusterControllers?.length > 0) {
                setState((prev) => ({
                    ...prev,
                    clusterControllers: clusterControllers.sort(),
                }))
            } else {
                setState((prev) => ({ ...prev, clusterControllers: [] }))
            }
        } catch (error) {
            console.log('Exception : Unable to load data. Check if the backend is running !!', error)
            setState((prev) => ({ ...prev, isWorking: false }))
        } finally {
            setState((prev) => ({ ...prev, isWorking: false }))
        }
    }

    // load data on mount
    useEffect(() => {
        onGetData()
    }, [api, onGetData])

    const onAddClusterController = () => {
        setState((prev) => ({ ...prev, isAddOpen: true }))
    }

    const onAddClusterControllerClose = async (change: boolean, success: boolean) => {
        setState((prev) => ({ ...prev, isAddOpen: false }))
        if (change) {
            await getClusterControllers()
        }
    }

    const closeIcon = () => (state.isWorking ? <div /> : '')
    const errorMessageModalStyle: React.CSSProperties = {
        height: '650px',
    }

    return (
        <Grid rows="auto 1fr" style={{ overflow: 'hidden' }}>
            <ClusterControllersToolbar isWorking={false} onAdd={onAddClusterController} />
            <Grid rows="auto 1fr" columns="350px 1fr" gap={4} className={styles.container}>
                <Grid>
                    <Heading type="heading1">Cluster Controllers</Heading>
                </Grid>
                <div key={'filler'} />
                <ClusterControllersList clusterControllers={state.clusterControllers} />
            </Grid>

            {state.isAddOpen && (
                <Modal
                    open={state.isAddOpen}
                    closeIcon={closeIcon()}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!state.isWorking) {
                            setState((prev) => ({
                                ...prev,
                                isAddOpen: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: cx(styles.customModal, errorMessageModalStyle),
                    }}
                >
                    <NewClusterController
                        onUpdate={(v) => {
                            setState((prev) => ({ ...prev, isWorking: v }))
                        }}
                        onClose={(change, success) => {
                            onAddClusterControllerClose(change, success)
                        }}
                    />
                </Modal>
            )}
        </Grid>
    )
}

export default ClusterControllers
