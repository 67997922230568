import { FunctionComponent } from 'react'
import { DeploymentModel, EnvironmentModel, FlowInstanceModel } from '../../Model/AllSparkModel'
import Grid from '../../../infratructure/grid/Grid'
import styles from './FlowInstanceDetails.module.scss'
import { FlowDetailsPanels } from '../Flows'
import FlowInstanceDetailsOverview from './FlowInstanceDetailsOverview'
import FlowInstanceDetailsServicebus from './FlowInstanceDetailsServicebus'
import FlowInstanceDetailsAuthorization from './FlowInstanceDetailsAuthorization'
import FlowInstanceDetailsDatabase from './FlowInstanceDetailsDatabase'
import FlowInstanceScaling from './FlowInstanceScaling'
import FlowInstanceDetailsEncryption from './FlowInstanceDetailsEncryption'

interface FlowInstanceDetailsProps {
    environments: EnvironmentModel[]
    instance?: FlowInstanceModel
    deploymentsInUse: DeploymentModel[]
    selectedDetailsSection: FlowDetailsPanels
    onScalingEdit: () => void
    onAzureAuthorizationEdit: () => void
    onAzureSqlEdit: () => void
    onAzureServiceBusEdit: () => void
    onEncryptionEdit: () => void
}

const FlowInstanceDetails: FunctionComponent<FlowInstanceDetailsProps> = ({
    environments,
    instance,
    deploymentsInUse,
    selectedDetailsSection,
    onScalingEdit,
    onAzureAuthorizationEdit,
    onAzureSqlEdit,
    onAzureServiceBusEdit,
    onEncryptionEdit,
}) => {
    return (
        <Grid>
            {instance?.enabled === false ? (
                <Grid columns="900px 1fr" className={styles.empty}>
                    The instance is disabled
                </Grid>
            ) : (
                <Grid className={styles.container}>
                    {selectedDetailsSection === FlowDetailsPanels.Overview && instance && (
                        <FlowInstanceDetailsOverview
                            environments={environments}
                            instance={instance}
                            deploymentsInUse={deploymentsInUse}
                        />
                    )}

                    {selectedDetailsSection === FlowDetailsPanels.Scaling && instance && (
                        <FlowInstanceScaling instance={instance} onScalingEdit={onScalingEdit} />
                    )}

                    {selectedDetailsSection === FlowDetailsPanels.Authorization && instance && (
                        <FlowInstanceDetailsAuthorization
                            instance={instance}
                            onAzureAuthorizationEdit={onAzureAuthorizationEdit}
                        />
                    )}

                    {selectedDetailsSection === FlowDetailsPanels.Servicebus && instance && (
                        <FlowInstanceDetailsServicebus
                            instance={instance}
                            onAzureServiceBusEdit={onAzureServiceBusEdit}
                        />
                    )}

                    {selectedDetailsSection === FlowDetailsPanels.Database && instance && (
                        <FlowInstanceDetailsDatabase instance={instance} onAzureSqlEdit={onAzureSqlEdit} />
                    )}

                    {selectedDetailsSection === FlowDetailsPanels.Encryption && instance && (
                        <FlowInstanceDetailsEncryption instance={instance} onEncryptionEdit={onEncryptionEdit} />
                    )}
                </Grid>
            )}
        </Grid>
    )
}

export default FlowInstanceDetails
