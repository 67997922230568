import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'
import Grid from '../../../infratructure/grid/Grid'
import styles from './NewFlow.module.scss'
import cx from 'classnames'
import Input from '../../../infratructure/input/Input'
import Heading from '../../../infratructure/heading/Heading'
import Select, { OptionType } from '../../../infratructure/select/Select'
import {
    EnvironmentModel,
    FlowDeployment,
    FlowInstanceModel,
    ProductModel,
    ProductType,
    State,
    StateToStringMap,
    Status,
    StatusToStringMap,
    ValidateReponse,
} from '../../Model/AllSparkModel'
import { ArrayUtil } from '../../../utils/ArrayUtil'
import { AllSparkContext } from '../../../..'
import { useGlobalStateContext } from '../../../../GlobalState'
import useAsyncError from '../../../infratructure/hooks/useAsyncError'
import Button, { ButtonType } from '../../../infratructure/button/Button'
import useInterval from '../../../infratructure/hooks/useInterval'
import LoaderDots from '../../../infratructure/dots/LoaderDots'
import ErrorIcon from '../../../icons/ErrorIcon'
import { Util } from '../../../utils/Util'

interface NewFlowProps {
    onValidate: (isValidating: boolean) => void
    onDeploy: (isDeploying: boolean) => void
    closeDialog: (flowInstanceModel: FlowInstanceModel) => void
}

type NewFlowState = {
    name: string
    products: ProductModel[]
    environments: EnvironmentModel[]
    selectedEnvironment: EnvironmentModel | null
    isDeploying: boolean
    selectedProduct: ProductModel | null
    showError: boolean
    pollInterval: number | null
    pollId: string
    isValidating: boolean
    deployResult: FlowDeployment | undefined
    validationResponse: ValidateReponse | null
    encryptionVector: string
    encryptionKey: string
    encryptionAzureKeyVaultUri: string
    isEncryptionVectorValid: boolean
    isEncryptionKeyValid: boolean
    startTime: Date
    currentTime: string
    durationTicker: number
    overrideEnvAuthSettings: boolean
    externalTenantId: string
    externalClientId: string
    externalClientSecret: string
    externalSecretExpiry: string
    canInvite: boolean
    createTenantId: boolean
    adminEmail: string
    flowSubscriptionId: string
    isEmailValid: boolean
    advanced: boolean
    rollbackOnFailure: boolean
}

const NewFlow: FunctionComponent<NewFlowProps> = ({ onDeploy, onValidate, closeDialog }) => {
    const api = useContext(AllSparkContext)
    const { setGlobalState } = useGlobalStateContext()
    const throwError = useAsyncError()

    const [state, setState] = useState<NewFlowState>({
        name: '',
        products: [],
        isDeploying: false,
        selectedProduct: null,
        selectedEnvironment: null,
        environments: [],
        showError: false,
        pollInterval: null,
        pollId: '',
        isValidating: false,
        deployResult: undefined,
        validationResponse: null,
        encryptionVector: '',
        encryptionKey: '',
        encryptionAzureKeyVaultUri: '',
        isEncryptionVectorValid: true,
        isEncryptionKeyValid: true,
        startTime: new Date(),
        currentTime: '',
        durationTicker: 0,
        overrideEnvAuthSettings: false,
        externalTenantId: '',
        externalClientId: '',
        externalClientSecret: '',
        externalSecretExpiry: '',
        canInvite: true,
        createTenantId: true,
        adminEmail: '',
        flowSubscriptionId: 'AllSparkDummySubscriptionId',
        isEmailValid: true,
        advanced: false,
        rollbackOnFailure: true,
    })

    const getData = useCallback(async () => {
        try {
            const products = await api.getProducts(ProductType.Flow)
            const environments = await api.getEnvironments()

            setState((prev) => ({ ...prev, products: products, environments: environments }))
        } catch (error) {
            console.log('We failed getting products!', error)
            throwError(error)
        }
    }, [api, throwError])

    useEffect(() => {
        getData()
    }, [getData])

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }))
    }

    const onSelectedProductChange = (selectedProduct: OptionType) => {
        setState((prev) => ({
            ...prev,
            selectedProduct: state.products.find((p) => p.name === selectedProduct.label) || null,
        }))
    }

    const onSelectedEnvironmentChange = (selectedEnvironment: OptionType) => {
        setState((prev) => ({
            ...prev,
            selectedEnvironment: state.environments.find((p) => p.name === selectedEnvironment.value) || null,
        }))
    }

    // polling setup
    useInterval(async () => {
        let t = state.durationTicker + 1
        setState((prev) => ({ ...prev, durationTicker: t }))
        dateBuilder()

        if (t > 4) {
            setState((prev) => ({ ...prev, isValidating: false, durationTicker: 0 }))
            const pollResult = await api.getFlowDeployment(state.pollId)
            console.log('deploying... =>', pollResult)

            setState((prev) => ({ ...prev, deployResult: pollResult }))

            const endDeployProcess = () => {
                setState((prev) => ({
                    ...prev,
                    pollId: '',
                    isDeploying: false,
                    pollInterval: null,
                    deployResult: undefined,
                }))
                onDeploy(false)
            }

            if (pollResult.flowInstanceModel) {
                // success
                endDeployProcess()
                closeDialog && closeDialog(pollResult.flowInstanceModel)
                setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
            }

            if (pollResult.flowInstanceModel || (!pollResult.status && !pollResult.flowInstanceModel)) {
                endDeployProcess()
                setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
            }
        }
    }, state.pollInterval)

    const handleDeploy = async () => {
        setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: true }))
        setState((prev) => ({
            ...prev,
            isValidating: true,
            isDeploying: true,
            validationResponse: null,
            deployResult: undefined,
        }))

        onValidate(true)
        onDeploy(true)

        try {
            const newFlow = {
                adminEmail: state.createTenantId ? state.adminEmail : '',
                flowSubscriptionId: state.flowSubscriptionId,
                deploymentId: '',
                environmentRowKey: state.selectedEnvironment?.rowKey || '',
                productRowKey: state.selectedProduct?.rowKey || '',
                platformVersion: state.selectedProduct?.platformVersion || '',
                name: state.name || '',
                rollbackOnFailure: state.rollbackOnFailure,
                externalAuthorization:
                    state.externalTenantId &&
                    state.externalClientId &&
                    state.externalClientSecret &&
                    state.externalSecretExpiry
                        ? {
                              tenantId: state.externalTenantId,
                              clientId: state.externalClientId,
                              clientSecret: state.externalClientSecret,
                              secretExpiry: state.externalSecretExpiry,
                              canInvite: state.canInvite,
                          }
                        : undefined,
            }

            const response = await api.deployFlow(newFlow)

            if (response && response.valid) {
                if (!state.pollInterval) {
                    setState((prev) => ({
                        ...prev,
                        pollId: response.deploymentId,
                        pollInterval: 1000,
                        isValidating: false,
                        isDeploying: true,
                        durationTicker: 0,
                        startTime: new Date(),
                    }))
                } else {
                    setState((prev) => ({ ...prev, isDeploying: false }))
                    onDeploy(false)
                    setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
                }
            } else {
                setState((prev) => ({ ...prev, validationResponse: response, isValidating: false }))
                setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
                onDeploy(false)
            }

            onValidate(false)
        } catch (error) {
            console.log('An error occurred:', error)
            setState((prev) => ({ ...prev, isDeploying: false, isValidating: false }))
            onDeploy(false)
            onValidate(false)
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        }
    }

    const dateBuilder = () => {
        const now = new Date()
        let durationMs = now.valueOf() - state.startTime.valueOf()
        setState((prev) => ({ ...prev, currentTime: Util.millisecondsToReadableShortDuration(Math.abs(durationMs)) }))
    }

    const onCanInviteChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({ ...prev, canInvite: !state.canInvite }))
    }

    const onOverrideEnvAuthSettingsChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({ ...prev, overrideEnvAuthSettings: !state.overrideEnvAuthSettings }))
    }

    const onAdvancedChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({ ...prev, advanced: !state.advanced }))
    }

    const onRollbackChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({ ...prev, rollbackOnFailure: !state.rollbackOnFailure }))
    }

    const onCreateTenantIdChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({ ...prev, createTenantId: !state.createTenantId }))
    }

    const onEmailBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
            isEmailValid: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value),
        }))
    }

    const renderNewFlowForm = () => {
        return (
            <Grid className={styles.main} gap={20}>
                <Input
                    name="name"
                    autoFocus={true}
                    headerText={'Flow Instance Name'}
                    placeholder="Enter a Flow Instance name"
                    style={{ width: 'inherit' }} // fixes bug? in main source
                    value={state.name}
                    onChange={onInputChange}
                    className={cx({
                        [styles.requiredInput]: !Boolean(state.name),
                    })}
                    readOnly={state.isDeploying}
                />

                <Grid gap={4}>
                    <Heading
                        type="normal"
                        className={cx({
                            [styles.requiredInput]: !Boolean(state.selectedProduct),
                        })}
                    >
                        Product
                    </Heading>
                    <Select
                        isSearchable={false}
                        isClearable={false}
                        options={state.products
                            .sort(ArrayUtil.sortByPropCompare('name', true))
                            .map((product) => ({ label: product.name, value: product.name }))}
                        value={state.selectedProduct?.name}
                        onChange={onSelectedProductChange}
                        placeholder="Select product"
                        isDisabled={state.isDeploying}
                    />
                </Grid>

                <Grid gap={4}>
                    <Heading
                        type="normal"
                        className={cx({
                            [styles.requiredInput]: !Boolean(state.selectedEnvironment),
                        })}
                    >
                        Environment
                    </Heading>
                    <Select
                        isClearable={false}
                        options={state.environments
                            .sort(ArrayUtil.sortByPropCompare('name', true))
                            .map((environment) => ({
                                label: environment.name + ' (' + environment.clusterController + ')',
                                value: environment.name,
                            }))}
                        value={state.selectedEnvironment?.name}
                        onChange={onSelectedEnvironmentChange}
                        placeholder="Select environment"
                        isDisabled={state.isDeploying}
                    />
                </Grid>

                <Grid columns="auto 1fr" gap={10} style={{ alignItems: 'center', marginBottom: '0px' }}>
                    <Input
                        name="createTenantId"
                        type="checkbox"
                        checked={state.createTenantId}
                        onChange={onCreateTenantIdChanged}
                        style={{ width: '16px', margin: 0, height: '16px' }}
                        readOnly={state.isDeploying}
                    />
                    <Heading type="normal">Create Tenant Id</Heading>
                </Grid>

                {state.createTenantId && (
                    <Grid gap={0}>
                        <Input
                            name="adminEmail"
                            headerText={'Administrator Email'}
                            placeholder="Enter the administrator email"
                            style={{ width: 'inherit', marginBottom: state.isEmailValid ? '20px' : '0px' }}
                            value={state.adminEmail}
                            onChange={onInputChange}
                            onBlur={onEmailBlur}
                            className={cx({
                                [styles.requiredInput]: !Boolean(state.adminEmail),
                            })}
                            readOnly={state.isDeploying}
                        />
                        {!state.isEmailValid && (
                            <span style={{ marginBottom: '20px' }} className={cx(styles.invalid)}>
                                E-Mail is not in a valid format. Please provide a valid E-Mail
                            </span>
                        )}
                        {!state.flowSubscriptionId && (
                            <Input
                                name="flowSubscriptionId"
                                headerText={'Flow Subscription Id'}
                                placeholder="Enter the Flow Subscription Id"
                                style={{ width: 'inherit' }}
                                value={state.flowSubscriptionId}
                                onChange={onInputChange}
                                className={cx({
                                    [styles.requiredInput]: !Boolean(state.flowSubscriptionId),
                                })}
                                readOnly={state.isDeploying}
                            />
                        )}
                    </Grid>
                )}

                <Grid>
                    <Grid columns="auto 1fr" gap={10} style={{ alignItems: 'center', marginBottom: '20px' }}>
                        <Input
                            name="advanced"
                            type="checkbox"
                            checked={state.advanced}
                            onChange={onAdvancedChanged}
                            style={{ width: '16px', margin: 0, height: '16px' }}
                            readOnly={state.isDeploying}
                        />
                        <Heading type="normal">Advanced</Heading>
                    </Grid>

                    {state.advanced && (
                        <Grid columns="auto" gap={10} style={{ alignItems: 'center' }}>
                            <Grid columns="auto 1fr" gap={10} style={{ alignItems: 'center', marginBottom: '10px' }}>
                                <Input
                                    name="rollback"
                                    type="checkbox"
                                    checked={state.rollbackOnFailure}
                                    onChange={onRollbackChange}
                                    style={{ width: '16px', margin: 0, height: '16px' }}
                                    readOnly={state.isDeploying}
                                />
                                <Heading type="normal">Rollback on deployment failure</Heading>
                            </Grid>
                            <Grid columns="auto 1fr" gap={10} style={{ alignItems: 'center', marginBottom: '0px' }}>
                                <Input
                                    name="overrideEnvAuthSettings"
                                    type="checkbox"
                                    checked={state.overrideEnvAuthSettings}
                                    onChange={onOverrideEnvAuthSettingsChanged}
                                    style={{ width: '16px', margin: 0, height: '16px' }}
                                    readOnly={state.isDeploying}
                                />
                                <Heading type="normal">Override Authorization</Heading>
                            </Grid>

                            {state.overrideEnvAuthSettings && (
                                <Grid gap={20}>
                                    <Input
                                        name="externalTenantId"
                                        headerText={'OpenId Connect Tenant Id'}
                                        placeholder="Enter the OpenId Connect Tenant Id"
                                        style={{ width: 'inherit' }}
                                        value={state.externalTenantId}
                                        onChange={onInputChange}
                                        className={cx({
                                            [styles.requiredInput]: !Boolean(state.externalTenantId),
                                        })}
                                        readOnly={state.isDeploying}
                                    />
                                    <Input
                                        name="externalClientId"
                                        headerText={'Client Id'}
                                        placeholder="Enter Client Id"
                                        style={{ width: 'inherit' }}
                                        value={state.externalClientId}
                                        onChange={onInputChange}
                                        className={cx({
                                            [styles.requiredInput]: !Boolean(state.externalClientId),
                                        })}
                                        readOnly={state.isDeploying}
                                    />
                                    <Input
                                        name="externalClientSecret"
                                        headerText={'Client Secret'}
                                        placeholder="Enter Client Secret"
                                        style={{ width: 'inherit' }}
                                        value={state.externalClientSecret}
                                        onChange={onInputChange}
                                        className={cx({
                                            [styles.requiredInput]: !Boolean(state.externalClientSecret),
                                        })}
                                        readOnly={state.isDeploying}
                                    />
                                    <Input
                                        name="externalSecretExpiry"
                                        headerText={'Client Secret Expiry'}
                                        type="datetime-local"
                                        style={{ width: 'inherit' }}
                                        value={state.externalSecretExpiry}
                                        onChange={onInputChange}
                                        className={cx({
                                            [styles.requiredInput]: !Boolean(state.externalSecretExpiry),
                                        })}
                                        readOnly={state.isDeploying}
                                    />
                                    <Grid
                                        columns="auto 1fr"
                                        gap={10}
                                        style={{ alignItems: 'center', marginBottom: '0px' }}
                                    >
                                        <Input
                                            name="canInvite"
                                            type="checkbox"
                                            checked={state.canInvite}
                                            onChange={onCanInviteChanged}
                                            style={{ width: '16px', margin: 0, height: '16px' }}
                                            readOnly={state.isDeploying}
                                        />
                                        <Heading type="normal">Can Invite</Heading>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Grid>
                {showError && (
                    <Grid columns="auto 1fr" gap={10} style={{ alignItems: 'center' }}>
                        <ErrorIcon />
                        <span style={{ fontSize: '0.7em' }}>Deployment failed! See system log for details.</span>
                    </Grid>
                )}
            </Grid>
        )
    }

    const isOverrideAuthValid = state.overrideEnvAuthSettings
        ? Boolean(state.externalTenantId) &&
          Boolean(state.externalClientId) &&
          Boolean(state.externalClientSecret) &&
          Boolean(state.externalSecretExpiry)
        : true

    const isCreateFlowTenantValid = state.createTenantId
        ? Boolean(state.adminEmail) && Boolean(state.flowSubscriptionId)
        : true

    const isFormValuesValid =
        Boolean(state.name) &&
        Boolean(state.selectedProduct) &&
        Boolean(state.selectedEnvironment) &&
        isOverrideAuthValid &&
        isCreateFlowTenantValid

    const showError =
        (state.validationResponse && !state.validationResponse.valid) || state.validationResponse?.errorMessage
    const showValidating = state.isValidating && !showError
    const showDeployingDetails = !state.isValidating && !showError && state.isDeploying

    const renderValidating = () => {
        return (
            <Grid columns="auto 1fr" gap={16} style={{ margin: '10px 0 15px 10px' }}>
                <Heading type="normal">Validating</Heading>
                <LoaderDots color="black" />
            </Grid>
        )
    }

    const renderProgressDetails = () => {
        const isRunning = (currentState: State | undefined) => {
            if (
                currentState !== undefined &&
                currentState !== State.NotStarted &&
                currentState !== State.FinishedDeploying &&
                currentState !== State.FinishedRollback
            ) {
                return true
            }

            return false
        }

        return (
            <Grid
                rows="auto auto auto auto auto auto auto auto auto auto"
                columns="230px 80px 125px auto"
                style={{ margin: '10px 0 15px 10px' }}
                gap={4}
            >
                <Heading type="sub">Section</Heading>
                <Heading type="sub">Status</Heading>
                <Heading type="sub">State</Heading>
                <div />
                <Heading type="normal">Creating authorization</Heading>
                <Heading type="normal">
                    {StatusToStringMap(state.deployResult?.status?.applicationRegistrations?.status || Status.None)}
                </Heading>
                <Heading type="normal">
                    {StateToStringMap(state.deployResult?.status?.applicationRegistrations?.state || State.NotStarted)}
                </Heading>
                {isRunning(state.deployResult?.status?.applicationRegistrations?.state || State.NotStarted) ? (
                    <LoaderDots color="black" />
                ) : (
                    <div />
                )}

                <Heading type="normal">Creating Servicebus, Queues & Topics</Heading>
                <Heading type="normal">
                    {StatusToStringMap(
                        state.deployResult?.status?.servicebusQueueAndTopicRegistrations?.status || Status.None,
                    )}
                </Heading>
                <Heading type="normal">
                    {StateToStringMap(
                        state.deployResult?.status?.servicebusQueueAndTopicRegistrations?.state || State.NotStarted,
                    )}
                </Heading>
                {isRunning(
                    state.deployResult?.status?.servicebusQueueAndTopicRegistrations?.state || State.NotStarted,
                ) ? (
                    <LoaderDots color="black" />
                ) : (
                    <div />
                )}

                <Heading type="normal">Deploying Database</Heading>
                <Heading type="normal">
                    {StatusToStringMap(state.deployResult?.status?.databaseRegistration?.status || Status.None)}
                </Heading>
                <Heading type="normal">
                    {StateToStringMap(state.deployResult?.status?.databaseRegistration?.state || State.NotStarted)}
                </Heading>

                {isRunning(state.deployResult?.status?.databaseRegistration?.state || State.NotStarted) ? (
                    <LoaderDots color="black" />
                ) : (
                    <div />
                )}

                <Heading type="normal">Deploying to Cluster</Heading>
                <Heading type="normal">
                    {StatusToStringMap(state.deployResult?.status?.kubernetesDeployment?.status || Status.None)}
                </Heading>
                <Heading type="normal">
                    {StateToStringMap(state.deployResult?.status?.kubernetesDeployment?.state || State.NotStarted)}
                </Heading>

                {isRunning(state.deployResult?.status?.kubernetesDeployment?.state || State.NotStarted) ? (
                    <LoaderDots color="black" />
                ) : (
                    <div />
                )}

                <Heading type="normal">Creating Permissions</Heading>
                <Heading type="normal">
                    {StatusToStringMap(state.deployResult?.status?.permissionsDeployment?.status || Status.None)}
                </Heading>
                <Heading type="normal">
                    {StateToStringMap(state.deployResult?.status?.permissionsDeployment?.state || State.NotStarted)}
                </Heading>

                {isRunning(state.deployResult?.status?.permissionsDeployment?.state || State.NotStarted) ? (
                    <LoaderDots color="black" />
                ) : (
                    <div />
                )}
            </Grid>
        )
    }

    return (
        <Grid rows="auto 1fr auto">
            <Heading type="heading1">New Flow Instance</Heading>
            <Grid rows="1fr auto auto">
                {renderNewFlowForm()}
                <Heading type="heading1" style={{ visibility: state.isDeploying ? 'visible' : 'hidden' }}>
                    {!showError && 'Progress'}
                    {showDeployingDetails && ' (' + state.currentTime + ')'}
                </Heading>
                {showValidating && renderValidating()}
                {showDeployingDetails && renderProgressDetails()}
            </Grid>
            <div className={styles.footer}>
                <Grid columns="1fr auto" gap={8}>
                    <div></div>
                    {!state.isDeploying && !state.showError && (
                        <Button
                            className={styles.deploybtn}
                            buttonType={ButtonType.Confirm}
                            onClick={handleDeploy}
                            disabled={!isFormValuesValid}
                        >
                            Let's Go !
                        </Button>
                    )}
                </Grid>
            </div>
        </Grid>
    )
}

export default NewFlow
