import { FunctionComponent } from 'react'
import styles from './Warning.module.scss'

interface WarningProps {
    warningMessage: string | undefined
}

const Warning: FunctionComponent<WarningProps> = ({ warningMessage }) => {

    return (
        <div className={styles.warning} title={warningMessage}>&nbsp;</div>
    )
}

export default Warning