import { AllSparkContext } from '../../../..'
import styles from "./Edit.module.scss"
import { FunctionComponent, useContext, useState } from "react"
import Grid from "../../../infratructure/grid/Grid"
import Heading from "../../../infratructure/heading/Heading"
import LoaderDots from "../../../infratructure/dots/LoaderDots"
import Button, { ButtonType } from "../../../infratructure/button/Button"
import { useGlobalStateContext } from "../../../../GlobalState"
import { ProductType } from '../../Model/AllSparkModel'

interface EditAzureSqlDetailsProps {
    deploymentId: string | undefined
    onUpdate: (isUpdating: boolean) => void
    onClose: (success: boolean) => void
    productType: ProductType
}

type EditAzureSqlDetailsState = {
    deploymentId: string
    isDeploying: boolean,
    isDone: boolean,
    hasErrors: boolean,
}

const EditAzureSqlDetails: FunctionComponent<EditAzureSqlDetailsProps> = ({
    deploymentId,
    onUpdate,
    onClose,
    productType
}) => {
    const api = useContext(AllSparkContext)
    const { setGlobalState } = useGlobalStateContext()

    const [state, setState] = useState<EditAzureSqlDetailsState>({
        deploymentId: deploymentId || '',
        isDeploying: false,
        isDone: false,
        hasErrors: false,
    })

    const update = async () => {
        setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: true}))
        setState((prev) => ({ ...prev, isDeploying: true, hasErrors: false}))
        onUpdate(true)

        try{
            await api.updateSqlUserPassword(state.deploymentId, productType)

            setState((prev) => ({ ...prev, isDeploying: false, isDone: true}))
            onUpdate(false)
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false}))
        }
        catch(error: any){
            console.log(`An error occurred, updating the sql user details failed: ${error.message}`)
            setState((prev) => ({ ...prev, isDeploying: false, hasErrors: true}))
            onUpdate(false)
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false}))
        }
    }

    return (
        <Grid rows="auto 1fr auto" className={styles.main} gap={0}>
            <Heading type="heading1">Edit Azure Sql</Heading>
            <Heading type="heading2" style={{ marginTop: '20px'}}>Renew password</Heading>
            <div className={styles.editcontainer}>
                <Grid gap={8}>

                    {state.isDone && (
                        <Grid gap={20} className={styles.info} style={{ marginBottom: '10px', marginTop: '20px'}}>
                            Password renewal is finished.
                        </Grid>
                    )}

                    {state.isDeploying && (
                        <Grid columns="1fr auto" columnSpan={2} style={{ marginBottom: '20px', marginTop: '20px', marginRight: '5px'}}>
                            <div/>
                            <Grid columns="1fr auto" gap={10}>
                                <Grid>Updating</Grid>
                                <Grid style={{ alignItems: 'center', marginBottom: '3px'}}>
                                    <LoaderDots color="black"/>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    {state.hasErrors && (
                        <Grid gap={20} className={styles.info}>
                            An error occurred, update failed.
                        </Grid>
                    )}

                    {!state.isDeploying && !state.isDone && (
                        <Grid rows="auto auto" gap={20} style={{ marginBottom: '10px', marginTop: '4px'}}>
                            <div>The password for the SQL user will be renewed, and the deployment restarted.</div>
                            <Grid columns="1fr auto">
                                <div/>
                                <Button
                                    className={styles.deploybtn}
                                    buttonType={ButtonType.Confirm}
                                    onClick={update}
                                >
                                    Renew
                                </Button>
                            </Grid>
                        </Grid>
                    )}


                    {state.isDone &&(
                        <Grid columns="1fr auto" style={{ marginBottom: '10px'}}>
                            <div/>
                            <Button
                                className={styles.deploybtn}
                                buttonType={ButtonType.Confirm}
                                onClick={() => onClose(!state.hasErrors)}
                            >
                                Close
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </div>
        </Grid>
    )
}

export default EditAzureSqlDetails