import { FunctionComponent } from 'react'
import { FlowInstanceModel } from './../../Model/AllSparkModel'
import Grid from '../../../infratructure/grid/Grid'
import Heading from '../../../infratructure/heading/Heading'
import CopyableInput from '../../../infratructure/copyableInput/CopyableInput'
import Button, { ButtonType } from '../../../infratructure/button/Button'
import styles from './FlowInstanceDetails.module.scss'
import Input from '../../../infratructure/input/Input'

interface FlowInstanceDetailsAuthorizationProps {
    instance?: FlowInstanceModel
    onAzureAuthorizationEdit: () => void
}

const FlowInstanceDetailsAuthorization: FunctionComponent<FlowInstanceDetailsAuthorizationProps> = ({
    instance,
    onAzureAuthorizationEdit,
}) => {
    return (
        <Grid columns="400px 1fr">
            <Grid rows="auto auto 1fr" gap={16}>
                <Grid columns="auto auto 1fr">
                    <Heading type="heading1">{`Flow Instance Authorization`}</Heading>
                    <Button
                        style={{ marginLeft: '10px' }}
                        onClick={onAzureAuthorizationEdit}
                        buttonType={ButtonType.Default}
                        className={styles.btn}
                        title="Edit"
                    >
                        <Grid className={styles.btnContentWrapper}>Edit</Grid>
                    </Button>
                </Grid>
                <Grid rows="auto auto auto auto auto 1fr" gap={16} style={{ marginLeft: '10px' }}>
                    <CopyableInput
                        headerText={'OpenId Connect Authority'}
                        width={650}
                        value={instance?.flowAuthorization?.openIdConnectAuthority || ''}
                        readOnly
                    />
                    <CopyableInput
                        headerText={'OpenId Connect Client Id'}
                        width={650}
                        value={instance?.flowAuthorization?.openIdClientId || ''}
                        readOnly
                    />
                    {instance?.flowAuthorization.externalAuthorizationModel === null && (
                        <Grid gap={16}>
                            <CopyableInput
                                headerText={'Client Secret'}
                                width={650}
                                value={instance?.flowAuthorization?.openIdClientSecret || ''}
                                readOnly
                            />
                            <CopyableInput
                                headerText={'Secret Expiry'}
                                width={650}
                                value={instance?.flowAuthorization?.secretExpiry || ''}
                                readOnly
                            />{' '}
                        </Grid>
                    )}
                    {instance?.flowAuthorization.externalAuthorizationModel !== null && (
                        <Grid gap={16}>
                            <CopyableInput
                                headerText={'Client Secret'}
                                width={650}
                                value={instance?.flowAuthorization?.externalAuthorizationModel?.clientSecret || ''}
                                readOnly
                            />
                            <CopyableInput
                                headerText={'Secret Expiry'}
                                width={650}
                                value={instance?.flowAuthorization?.externalAuthorizationModel?.secretExpiry || ''}
                                readOnly
                            />
                        </Grid>
                    )}
                    <Grid columns="auto 1fr" gap={10} style={{ alignItems: 'center', marginBottom: '0px' }}>
                        <Input
                            name="canInvite"
                            type="checkbox"
                            checked={
                                instance?.flowAuthorization?.externalAuthorizationModel?.canInvite === false
                                    ? false
                                    : true
                            }
                            style={{ width: '16px', margin: 0, height: '16px' }}
                            readOnly
                        />
                        <Heading type="normal">Can Invite</Heading>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FlowInstanceDetailsAuthorization
