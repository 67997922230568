import cx from 'classnames'
import { IconProps } from './IconProps'

export default function CloseIcon({ width = 20, height = 20, className, style }: IconProps) {
    return (
        <svg
            fill="none"
            viewBox="0 0 30 30"
            width={width}
            height={height}
            className={cx('icon', className)}
            style={style}
            data-icon
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.414 15l6.364 6.364-1.414 1.414L15 16.414l-6.364 6.364-1.414-1.414L13.586 15 7.222 8.636l1.414-1.414L15 13.586l6.364-6.364 1.414 1.414L16.414 15z"
                fill="currentColor"
            />
        </svg>
    )
}
