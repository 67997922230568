import cx from 'classnames'
import { IconProps } from './IconProps'

export default function WarningIcon({ width = 20, height = 20, className, style }: IconProps) {
    return (
        <svg
            fill="none"
            viewBox="0 0 24 24"
            width={width}
            height={height}
            className={cx('icon', className)}
            style={style}
            data-icon
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.4414 15.9532L15.4361 3.97762C14.7328 2.7584 13.422 2.00166 12.0043 2C10.5866 1.99834 9.27528 2.75196 8.56324 3.97767L1.5553 15.9576C0.828629 17.1795 0.81219 18.7105 1.51605 19.9592C2.2205 21.2089 3.53954 21.9866 4.97427 21.9983L19.0121 21.9983C20.4618 21.9842 21.7783 21.2089 22.4827 19.9603C23.1862 18.7132 23.1704 17.1856 22.4414 15.9532ZM3.27796 16.9737L10.2911 4.98491C10.6462 4.37354 11.2976 3.99918 12.002 4C12.7063 4.00083 13.3574 4.37673 13.7067 4.98222L20.7176 16.9672C21.0851 17.5885 21.0929 18.3533 20.7407 18.9777C20.388 19.603 19.7286 19.9913 19.0024 19.9984L4.98241 19.9983C4.2718 19.9925 3.6111 19.6029 3.25831 18.9771C2.90591 18.3519 2.91414 17.5855 3.27796 16.9737ZM12.0002 17.9983C12.5527 17.9983 13.0005 17.5506 13.0005 16.9983C13.0005 16.4461 12.5527 15.9983 12.0002 15.9983C11.4477 15.9983 10.9999 16.4461 10.9999 16.9983C10.9999 17.5506 11.4477 17.9983 12.0002 17.9983ZM13.0035 7.99835H11.0028V14.9983H13.0035V7.99835Z"
                fill="#FB973E"
            />
        </svg>
    )
}
