import { FunctionComponent } from 'react'
import { DeploymentModel } from '../../../Model/AllSparkModel'
import Grid from '../../../../infratructure/grid/Grid'
import Heading from '../../../../infratructure/heading/Heading'
import Input from '../../../../infratructure/input/Input'
import styles from './AzureAuthenticationDetails.module.scss'
import CopyableInput from '../../../../infratructure/copyableInput/CopyableInput'

interface AzureAuthenticationDetailProps {
    deployment: DeploymentModel
}

const AzureAuthenticationDetails: FunctionComponent<AzureAuthenticationDetailProps> = ({ deployment }) => {
    return (
        <Grid columns="900px 1fr" className={styles.container}>
            <Grid rows="auto auto auto auto auto auto auto  1fr" gap={16}>
                <Heading type="heading1">Authentication Details</Heading>
                <Grid gap={16} style={{ marginLeft: '10px' }}>
                    <CopyableInput
                        width={650}
                        headerText={'Identity Provider Type'}
                        value={deployment.authorization.identityProvider.type || ''}
                        readOnly
                    />

                    <CopyableInput
                        width={650}
                        headerText={'Graph Api'}
                        value={deployment.authorization.identityProvider.azureAd.graphApi || ''}
                        readOnly
                    />

                    <CopyableInput
                        width={650}
                        headerText={'Permissions'}
                        value={deployment.authorization.identityProvider.permissions || ''}
                        readOnly
                    />
                    <Input
                        type={'checkbox'}
                        headerText={'Can Invite'}
                        checked={deployment.authorization.identityProvider.canInvite || false}
                        readOnly
                        style={{ width: '16px', margin: 0, height: '16px' }}
                    />
                </Grid>

                <Heading type="heading1" style={{ marginTop: '20px' }}>
                    Open Id Connect Settings
                </Heading>
                <Grid gap={16} style={{ marginLeft: '10px' }}>
                    <CopyableInput
                        width={650}
                        headerText={'Claim types filter'}
                        value={deployment.authorization.openIdConnect.claimTypesFilter || ''}
                        readOnly
                    />

                    <CopyableInput
                        width={650}
                        headerText={'Claim types mappings - From:'}
                        value={deployment.authorization.openIdConnect.claimTypeMappings.fromType || ''}
                        readOnly
                    />
                    <CopyableInput
                        width={650}
                        headerText={'Claim types mappings - To:'}
                        value={deployment.authorization.openIdConnect.claimTypeMappings.toType || ''}
                        readOnly
                    />
                </Grid>
                <Heading type="heading1" style={{ marginTop: '20px' }}>
                    JWT Settings
                </Heading>
                <Grid gap={16} style={{ marginLeft: '10px' }}>
                    <CopyableInput
                        width={650}
                        headerText={'Claim types filter'}
                        value={deployment.authorization.jwt.claimTypesFilter || ''}
                        readOnly
                    />

                    <CopyableInput
                        width={650}
                        headerText={'Claim types mappings - From:'}
                        value={deployment.authorization.jwt.claimTypeMappings.fromType || ''}
                        readOnly
                    />

                    <CopyableInput
                        width={650}
                        headerText={'Claim types mappings - To:'}
                        value={deployment.authorization.jwt.claimTypeMappings.toType || ''}
                        readOnly
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AzureAuthenticationDetails
