import { FunctionComponent } from 'react'
import { ProductModel } from '../../Model/AllSparkModel'
import Button, { ButtonType } from '../../../infratructure/button/Button'
import LoaderDots from '../../../infratructure/dots/LoaderDots'
import Grid from '../../../infratructure/grid/Grid'
import Heading from '../../../infratructure/heading/Heading'
import styles from './DeleteProductConfirmation.module.scss'

interface DeleteProductConfirmationProps {
    onDelete: () => void
    selectedProduct?: ProductModel
    isWorking: boolean
    errorMessage: string | undefined
}

const DeleteProductConfirmation: FunctionComponent<DeleteProductConfirmationProps> = ({
    onDelete,
    selectedProduct,
    isWorking,
    errorMessage,
}) => {
    return (
        <Grid rows="auto 1fr auto" className={styles.contentHeight}>
            <Heading type="heading2">{`Product - ${selectedProduct?.name}`}</Heading>
            {!isWorking ? (
                <div className={styles.centerSelf}>{`Are you sure you want to delete ${selectedProduct?.name} ?`}</div>
            ) : (
                <Grid columns="auto 1fr" gap={16} className={styles.centerSelf}>
                    <div className={styles.centerSelf}>Deleting</div>
                    <LoaderDots color={'black'} />
                </Grid>
            )}

            <Grid columns="1fr auto" className={styles.footer}>
                <div style={{ fontStyle: 'italic', color: 'red' }}>{errorMessage}</div>
                <Button className={styles.btn} buttonType={ButtonType.Confirm} onClick={onDelete} disabled={isWorking}>
                    Delete
                </Button>
            </Grid>
        </Grid>
    )
}

export default DeleteProductConfirmation
