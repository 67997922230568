import * as React from 'react'
import cx from 'classnames'
import styles from './CopyableInput.module.scss'
import ClipboardButton from '../clipboardButton/ClipboardButton'
import Input, { HeaderPlacement } from '../input/Input'

type CopyableInputProps = {
    className?: string
    headerPlacement?: HeaderPlacement
    headerText?: string
    width: number
    props?: any
    value?: any
    autoFocus?: boolean
    readOnly?: boolean
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

const CopyableInput = React.forwardRef<HTMLInputElement, CopyableInputProps>(
    (
        { headerPlacement, headerText, value, className, width, readOnly, autoFocus, ...props }: CopyableInputProps,
        ref,
    ) => {
        const innerRef = React.useRef<HTMLInputElement>(null)

        React.useLayoutEffect(() => {
            if (ref) {
                if (typeof ref === 'function') {
                    ref(innerRef.current)
                } else {
                    ;(ref as any).current = innerRef.current
                }
            }
        }, [ref])

        React.useEffect(() => {
            if (autoFocus && innerRef.current) {
                innerRef.current!.focus()
            }
        }, [autoFocus])

        return (
            <div className={cx(styles.container)} style={{ position: 'relative', width: width }}>
                <Input
                    className={className}
                    headerPlacement={headerPlacement}
                    headerText={headerText}
                    value={value}
                    readOnly={readOnly}
                    {...props}
                    ref={innerRef}
                />
                <ClipboardButton value={value} />
            </div>
        )
    },
)

export default CopyableInput
