// import { ApplicationMessageBus, ApplicationMessageCodes } from './ApplicationMessageBus'
// import { Env } from './components/utils/Env'
// import { Str } from './components/utils/Str'
// import { HttpService } from './HttpService'

// interface AccessTokenRenewResponse {
//     accessToken: string
//     expiresAt: string
// }

export class AccessTokenStore {
    private static AccessTokenKey = 'allspark.access_token'
    private static AccessTokenExpiresAtKey = 'allspark.access_token_expires_at'

    public static getAccessToken(): string | null {
        return window.sessionStorage.getItem(AccessTokenStore.AccessTokenKey)
    }

    public static setAccessToken(accessToken: string) {
        window.sessionStorage.setItem(AccessTokenStore.AccessTokenKey, accessToken)
    }

    public static getExpiresAt(): string | null {
        return window.sessionStorage.getItem(AccessTokenStore.AccessTokenExpiresAtKey)
    }

    public static setExpiresAt(expiresAt: string) {
        window.sessionStorage.setItem(AccessTokenStore.AccessTokenExpiresAtKey, expiresAt)
    }
}

// export class AccessTokenService {
//     private _renewFailCount = 0
//     private static TokenRefreshWindowMilliSeconds = 120000 // Try two minutes before absolute expiry, token cache has a threshold of 5 minutes

//     constructor(private http: HttpService, private messageBus: ApplicationMessageBus) {}

//     public start(): void {
//         const expiresAt = AccessTokenStore.getExpiresAt()
//         if (!Str.isNothingOrWhitespace(expiresAt)) {
//             // @ts-ignore (typescript does not understand that Str.isNothingOrWhitespace checks for not null)
//             this.scheduleAccessTokenUpdate(expiresAt)
//         }
//     }

//     public getAccessToken(): string | null {
//         return AccessTokenStore.getAccessToken()
//     }

//     private updateAccessToken(): void {
//         this.renewAccessToken().then(
//             (response) => {
//                 this._renewFailCount = 0
//                 AccessTokenStore.setAccessToken(response.accessToken)
//                 AccessTokenStore.setExpiresAt(response.expiresAt)
//                 this.scheduleAccessTokenUpdate(response.expiresAt)
//             },
//             (error) => {
//                 console.error(error)
//                 this._renewFailCount++
//                 if (this._renewFailCount >= 2) {
//                     this.messageBus.sendMessage(ApplicationMessageCodes.SESSION_EXPIRED, {})
//                     this.removeAuthCookie()
//                 } else {
//                     setTimeout(this.updateAccessToken.bind(this), 500)
//                 }
//             },
//         )
//     }

//     private scheduleAccessTokenUpdate(expiresAt: string): void {
//         const utcExpiresInMilliseconds = new Date(expiresAt).valueOf()
//         const now = Date.now()
//         const renewIn = utcExpiresInMilliseconds - now - AccessTokenService.TokenRefreshWindowMilliSeconds
//         if (isNaN(renewIn)) {
//             return
//         }
//         const timeout = Math.max(30000, renewIn) // try to refresh 2 minutes, minimum 30 seconds before expiration. ADAL Token Cache has 'about to expire' threshold of 5 minutes.
//         setTimeout(this.updateAccessToken.bind(this), timeout)
//     }

//     private renewAccessToken(): Promise<AccessTokenRenewResponse> {
//         return this.http.get('api/accesstoken/renew?appid=InVision_Client')
//     }

//     private removeAuthCookie() {
//         // "invalidates" the auth cookie so that the browser removes it (sets the expiry date to the past)
//         // http(s)://domain.com/sitename/workbooks/id
//         // http(s)://host/workbooks
//         // grab what's between origin and /workbooks
//         const workbooksSlugIndex = window.location.pathname.indexOf('/workbooks/')
//         const instanceName = window.location.pathname.substr(0, workbooksSlugIndex)
//         const cookiePaths = [instanceName || '/', Env.getBaseUrl()]
//         for (let i = 0; i < cookiePaths.length; i++) {
//             if (this.tryRemoveCookie(cookiePaths[i])) {
//                 break
//             }
//         }
//     }

//     private tryRemoveCookie(path: string): boolean {
//         const authCookie = document.cookie.split('; ').find((f) => f.indexOf('Invision.AuthCookie') > -1)
//         if (authCookie) {
//             document.cookie = `Invision.AuthCookie=; expires=${new Date(1).toUTCString()};path=${path};`
//         }

//         return !document.cookie.split('; ').some((f) => f.indexOf('Invision.AuthCookie') > -1)
//     }
// }
