import { FunctionComponent } from 'react'
import { FlowInstanceModel } from '../../Model/AllSparkModel'
import CopyableInput from '../../../infratructure/copyableInput/CopyableInput'
import Grid from '../../../infratructure/grid/Grid'
import Heading from '../../../infratructure/heading/Heading'
import Button, { ButtonType } from '../../../infratructure/button/Button'
import styles from './FlowInstanceDetails.module.scss'

interface FlowInstanceDetailsEncryptionProps {
    instance?: FlowInstanceModel
    onEncryptionEdit: () => void
}

const FlowInstanceDetailsEncryption: FunctionComponent<FlowInstanceDetailsEncryptionProps> = ({
    instance,
    onEncryptionEdit,
}) => {
    return (
        <Grid columns="400px 1fr">
            <Grid rows="auto 1fr" gap={16}>
                <Grid columns="auto auto 1fr">
                    <Heading type="heading1">Flow Instance Encryption</Heading>
                    <Button
                        style={{ marginLeft: '10px' }}
                        onClick={onEncryptionEdit}
                        buttonType={ButtonType.Default}
                        className={styles.btn}
                        title="Edit"
                    >
                        <Grid className={styles.btnContentWrapper}>Edit</Grid>
                    </Button>
                </Grid>
                <Grid rows="auto auto auto auto auto 1fr" gap={16} style={{ marginLeft: '10px' }}>
                    <CopyableInput headerText={'Key'} width={650} value={instance?.encryption?.key || ''} readOnly />
                    <CopyableInput
                        headerText={'Vector'}
                        width={650}
                        value={instance?.encryption?.vector || ''}
                        readOnly
                    />
                    <CopyableInput
                        headerText={'Azure Key Vault Name'}
                        width={650}
                        value={instance?.encryption?.keyVaultKeyName || ''}
                        readOnly
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FlowInstanceDetailsEncryption
