import {
    AppDeploymentModel,
    ConnectToFlowResponse,
    Deletement,
    DeleteResponse,
    Deployment,
    DeploymentAuthUpdateRequest,
    DeploymentDefaultSettings,
    DeploymentOverviewUpdateRequest,
    DeploymentRequest,
    DeploymentResponse,
    DisconnectFromFlowResponse,
    EnvironmentModel,
    FailedDeploymentModel,
    FlowAuthUpdateRequest,
    FlowDefaultSettings,
    FlowDeployment,
    FlowDeploymentRequest,
    FlowDeploymentResponse,
    FlowInstanceModel,
    FlowScalingDeployment,
    ProductModel,
    ProductType,
    ScalingDeployment,
    ProductDockerImages,
    SqlConnectionUpdateRequest,
    SystemLog,
    Toggle,
    ToggleResponse,
    UpdateDeploymentAuthResponse,
    UpgradeDeploymentResponse,
    ValidateReponse,
    VersionResponse,
    DeleteDockerImageResponse,
    ContainerRegistryRepositoryModel,
    RegistryUsage,
    ProductRepoVersion,
    FlowEncryptionRequest,
    Tenant,
    DeploymentDefaultLanguageRequest,
    HelmResponse,
    ClusterControllerRequest,
    ClusterController,
    UpgradeRequest,
} from './components/app/Model/AllSparkModel'
import { HttpService } from './HttpService'

export class AllSparkHttpClient {
    constructor(private http: HttpService) {}

    getVersion(): Promise<string> {
        return this.http.get(`api/sys/version`)
    }

    getLanguages(): Promise<string[]> {
        return this.http.get(`api/languages`)
    }

    rebootDeployment(deploymentId: string): Promise<HelmResponse> {
        return this.http.get(`api/deployments/${encodeURIComponent(deploymentId)}/reboot`)
    }

    rebootFlowDeployment(deploymentId: string): Promise<HelmResponse> {
        return this.http.get(`api/flows/${encodeURIComponent(deploymentId)}/reboot`)
    }

    getDeployments(): Promise<AppDeploymentModel[]> {
        return this.http.get(`api/deployments`)
    }

    getFlowDeployments(): Promise<FlowInstanceModel[]> {
        return this.http.get(`api/flows`)
    }

    getDeletement(deploymentId: string): Promise<Deletement> {
        return this.http.get(`api/deletements/${encodeURIComponent(deploymentId)}`)
    }

    deleteDeployment(deploymentId: string): Promise<DeleteResponse> {
        return this.http.delete(`api/deployments/${encodeURIComponent(deploymentId)}`)
    }

    getDeployment(deploymentId: string): Promise<Deployment> {
        return this.http.get(`api/deployments/${encodeURIComponent(deploymentId)}`)
    }

    getFlowDeployment(deploymentId: string): Promise<FlowDeployment> {
        return this.http.get(`api/flows/${encodeURIComponent(deploymentId)}`)
    }

    getFlowTenants(deploymentId: string): Promise<Tenant[]> {
        return this.http.get(`api/flows/${encodeURIComponent(deploymentId)}/tenants`)
    }

    deleteFlowDeployment(deploymentId: string, deleteDatabase: boolean = true): Promise<DeleteResponse> {
        return this.http.delete(
            `api/flows/${encodeURIComponent(deploymentId)}?deleteDatabase=${encodeURIComponent(deleteDatabase)}`,
        )
    }

    validate(deploymentId: string): Promise<ValidateReponse> {
        return this.http.get(`api/deployments/${encodeURIComponent(deploymentId)}/validate`)
    }

    getFailedDeployments(): Promise<FailedDeploymentModel[]> {
        return this.http.get(`api/deployments/logs`)
    }

    deleteFailedDeployment(deploymentId: string): Promise<void> {
        return this.http.delete(`api/deployments/log/${encodeURIComponent(deploymentId)}`)
    }

    getSystemLogs(): Promise<SystemLog[]> {
        return this.http.get(`api/logs`)
    }

    getSystemLogsDeployments(): Promise<string[]> {
        return this.http.get(`api/logs/deployments`)
    }

    getDeploymentSystemLogs(deploymentId: string): Promise<SystemLog[]> {
        return this.http.get(`api/logs/deployment/${encodeURIComponent(deploymentId)}`)
    }

    setDefaultDeploymentSettings(request: DeploymentDefaultSettings) {
        return this.http.put(`api/deployments/settings/default`, request)
    }

    getDefaultDeploymentSettings(): Promise<DeploymentDefaultSettings> {
        return this.http.get(`api/deployments/settings/default`)
    }

    deleteSystemLogs(deploymentId: string): Promise<DeleteResponse> {
        return this.http.delete(`api/logs/deployment/${encodeURIComponent(deploymentId)}`)
    }

    deploy(deploymentRequest: DeploymentRequest): Promise<DeploymentResponse> {
        return this.http.post(`api/deployments`, deploymentRequest)
    }

    cloneDeployment(
        deploymentId: string,
        cloneDeploymentName: string,
        cloneFlowDeploymentName: string,
        flowId: string,
    ): Promise<DeploymentResponse> {
        return this.http.get(
            `api/deployments/${encodeURIComponent(deploymentId)}/clone/${encodeURIComponent(
                cloneDeploymentName,
            )}?flowId=${encodeURIComponent(flowId)}&cloneFlowDeploymentName=${encodeURIComponent(
                cloneFlowDeploymentName,
            )}`,
        )
    }

    cloneFlowDeployment(deploymentId: string, cloneDeploymentId: string): Promise<DeploymentResponse> {
        return this.http.get(
            `api/flows/${encodeURIComponent(deploymentId)}/clone/${encodeURIComponent(cloneDeploymentId)}`,
        )
    }

    deployFlow(flowDeploymentRequest: FlowDeploymentRequest): Promise<FlowDeploymentResponse> {
        return this.http.post(`api/flows`, flowDeploymentRequest)
    }

    connectFlowToInvision(
        deploymentId: string,
        flowInstanceId: string,
        flowSubscriptionId: string,
        tenantId: string,
    ): Promise<ConnectToFlowResponse> {
        return this.http.post(
            `api/flows/${encodeURIComponent(flowInstanceId)}/connect/${encodeURIComponent(
                deploymentId,
            )}?subscriptionId=${encodeURIComponent(flowSubscriptionId)}&tenantId=${encodeURIComponent(tenantId)}`,
            null,
        )
    }

    disconnectFlowFromInvision(deploymentId: string, flowInstanceId: string): Promise<DisconnectFromFlowResponse> {
        return this.http.post(
            `api/flows/${encodeURIComponent(flowInstanceId)}/disconnect/${encodeURIComponent(deploymentId)}`,
            null,
        )
    }

    updateScaling(deploymentId: string, request: ScalingDeployment) {
        return this.http.put(`api/deployments/${encodeURIComponent(deploymentId)}/update/scaling`, request)
    }

    updateFlowScaling(deploymentId: string, request: FlowScalingDeployment) {
        return this.http.put(`api/flows/${encodeURIComponent(deploymentId)}/update/scaling`, request)
    }

    setDefaultFlowDeploymentSettings(request: FlowDefaultSettings) {
        return this.http.put(`api/flows/settings/default`, request)
    }

    getDefaultFlowDeploymentSettings(): Promise<FlowDefaultSettings> {
        return this.http.get(`api/flows/settings/default`)
    }

    updateDeployment(upgradeRequest: UpgradeRequest): Promise<UpgradeDeploymentResponse> {
        return this.http.put(`api/deployments/update/`, upgradeRequest)
    }

    updateAuth(updateAuthRequest: DeploymentAuthUpdateRequest): Promise<UpdateDeploymentAuthResponse> {
        return this.http.put(
            `api/deployments/${encodeURIComponent(updateAuthRequest.deploymentId)}/update/auth`,
            updateAuthRequest,
        )
    }

    updateFlowEncryption(deploymentId: string, encryptionRequest: FlowEncryptionRequest): Promise<boolean> {
        return this.http.put(`api/flows/${encodeURIComponent(deploymentId)}/update/encryption`, encryptionRequest)
    }

    updateFlowAuth(
        deploymentId: string,
        updateAuthRequest: FlowAuthUpdateRequest,
    ): Promise<UpdateDeploymentAuthResponse> {
        return this.http.put(`api/flows/${encodeURIComponent(deploymentId)}/update/auth`, updateAuthRequest)
    }

    updateStorageSasToken(deploymentId: string, productType: ProductType): Promise<boolean> {
        return this.http.put(
            `api/deployments/${encodeURIComponent(
                deploymentId,
            )}/update/storage/sastoken?producttype=${encodeURIComponent(productType)}`,
            null,
        )
    }

    updateSqlConnectionString(productType: ProductType, request: SqlConnectionUpdateRequest) {
        return this.http.put(
            `api/deployments/${encodeURIComponent(
                request.deploymentId,
            )}/update/sql/connectionstring?producttype=${encodeURIComponent(productType)}`,
            request,
        )
    }

    updateSqlUserPassword(deploymentId: string, productType: ProductType): Promise<boolean> {
        return this.http.put(
            `api/deployments/${encodeURIComponent(deploymentId)}/update/sql/user?producttype=${encodeURIComponent(
                productType,
            )}`,
            null,
        )
    }

    updateServiceBusConnectionstrings(deploymentId: string, productType: ProductType): Promise<boolean> {
        return this.http.put(
            `api/deployments/${encodeURIComponent(
                deploymentId,
            )}/update/servicebus/connectionstrings?producttype=${encodeURIComponent(productType)}`,
            null,
        )
    }

    updateOverView(updateDeploymentOverviewRequest: DeploymentOverviewUpdateRequest) {
        return this.http.put(
            `api/deployments/${encodeURIComponent(updateDeploymentOverviewRequest.deploymentId)}/update/overview`,
            updateDeploymentOverviewRequest,
        )
    }

    updateDefaultLangauge(updateDefaultLanguageRequest: DeploymentDefaultLanguageRequest) {
        return this.http.put(
            `api/deployments/${encodeURIComponent(updateDefaultLanguageRequest.deploymentId)}/update/language`,
            updateDefaultLanguageRequest,
        )
    }

    addLanguage(language: string) {
        return this.http.post(`api/languages/${encodeURIComponent(language)}`)
    }

    deleteLanguage(language: string) {
        return this.http.delete(`api/languages/${encodeURIComponent(language)}`)
    }

    addClusterController(clusterControllerRequest: ClusterControllerRequest) {
        return this.http.post(`api/clustercontroller`, clusterControllerRequest)
    }

    getClusterControllers(): Promise<ClusterController[]> {
        return this.http.get(`api/clustercontroller`)
    }

    toggleDeployment(toggle: Toggle, deploymentId: string, productType: ProductType): Promise<ToggleResponse> {
        return this.http.get(
            `api/deployments/toggle/${encodeURIComponent(toggle)}/${encodeURIComponent(
                productType,
            )}/${encodeURIComponent(deploymentId)}`,
        )
    }

    // -- products
    getProducts(productType: ProductType): Promise<ProductModel[]> {
        return this.http.get(`api/products?producttype=${encodeURIComponent(productType)}`)
    }

    getProduct(productName: string): Promise<ProductModel[]> {
        return this.http.get(`api/products/${encodeURIComponent(productName)}`)
    }

    createOrUpdateProduct(product: ProductModel): Promise<ProductModel | undefined> {
        return this.http.post(`api/products`, product)
    }

    deleteProduct(rowKey: string): Promise<DeleteResponse> {
        return this.http.delete(`api/products/${encodeURIComponent(rowKey)}`)
    }

    cloneProduct(productRowKey: string): Promise<ProductModel | undefined> {
        return this.http.get(`api/products/${encodeURIComponent(productRowKey)}/clone`)
    }

    // -- environments
    getEnvironments(): Promise<EnvironmentModel[]> {
        return this.http.get(`api/environments`)
    }

    getEnvironment(environmentRowKey: string): Promise<EnvironmentModel[]> {
        return this.http.get(`api/environments/${encodeURIComponent(environmentRowKey)}`)
    }

    createOrUpdateEnvironment(environment: EnvironmentModel): Promise<EnvironmentModel | undefined> {
        return this.http.post(`api/environments`, environment)
    }

    deleteEnvironment(environmentRowKey: string): Promise<DeleteResponse> {
        return this.http.delete(`api/environments/${encodeURIComponent(environmentRowKey)}`)
    }

    cloneEnvironment(environmentRowKey: string): Promise<EnvironmentModel | undefined> {
        return this.http.get(`api/environments/${encodeURIComponent(environmentRowKey)}/clone`)
    }

    // helmchart
    getVersions(productType: ProductType): Promise<VersionResponse> {
        return this.http.get(`api/helmchart/versions?producttype=${encodeURIComponent(productType)}`)
    }

    //Versions/docker images
    getProductDockerImages(productType: ProductType): Promise<ProductDockerImages> {
        return this.http.get(`api/containerregistry/dockerimages/${encodeURIComponent(productType)}`)
    }

    deleteProductDockerImages(productType: ProductType | undefined, versions: ProductRepoVersion[]): Promise<string> {
        return this.http.put(
            `api/containerregistry/dockerimages/${encodeURIComponent(productType ?? ProductType.All)}/delete`,
            versions,
        )
    }

    checkProductDockerImagesDeleteStatus(pollId: string | null): Promise<DeleteDockerImageResponse> {
        return this.http.get(`api/containerregistry/dockerimages/delete/status?id=${encodeURIComponent(pollId ?? '')}`)
    }

    completeDeleteProductDockerImages(id: string | null) {
        return this.http.put(`api/containerregistry/dockerimages/delete/complete?id=${encodeURIComponent(id ?? '')}`)
    }

    getRegistryRepositories(): Promise<ContainerRegistryRepositoryModel[]> {
        return this.http.get(`api/containerregistry/repositories`)
    }

    addRegistryRepository(productType: ProductType, repository: string): Promise<ContainerRegistryRepositoryModel> {
        return this.http.put(
            `api/containerregistry/repositories/${encodeURIComponent(productType)}?repository=${encodeURIComponent(
                repository,
            )}`,
        )
    }

    deleteRegistryRepository(productType: string, repository: string): Promise<ContainerRegistryRepositoryModel> {
        return this.http.delete(
            `api/containerregistry/repositories/${encodeURIComponent(productType)}/${encodeURIComponent(repository)}`,
        )
    }

    getRegistryUsage(): Promise<RegistryUsage> {
        return this.http.get(`api/containerregistry/usage`)
    }
}
