import { FunctionComponent } from 'react'
import { DeploymentModel, EnvironmentModel } from '../Model/AllSparkModel'
import Grid from '../../infratructure/grid/Grid'
import { DeploymentMainPanels } from './Deployments'
import styles from './DeploymentDetails.module.scss'
import ScalingOptions from './detailComponents/ScalingOptions'
import DeploymentOverview from './detailComponents/DeploymentOverview'
import AdvancedDeploymentSections from './detailComponents/AdvancedDeploymentSections'

interface DeploymentDetailsProps {
    environments: EnvironmentModel[]
    selectedDetailsSection: DeploymentMainPanels
    selectedDeployment?: DeploymentModel
    onAzureApplicationsEdit: () => void
    onAzureBlobContainerEdit: () => void
    onAzureSqlEdit: () => void
    onAzureServiceBusEdit: () => void
    onEdit: () => void
    onEditDefaultLanguage: () => void
    onConnectToFlow: () => void
    onDisconnectFromFlow: () => void
    onScalingEdit: () => void
    disabledFlowInstanceIsInUse: boolean
}

const DeploymentDetails: FunctionComponent<DeploymentDetailsProps> = ({
    environments,
    selectedDeployment,
    selectedDetailsSection,
    onAzureApplicationsEdit,
    onAzureBlobContainerEdit,
    onAzureSqlEdit,
    onAzureServiceBusEdit,
    onEdit,
    onEditDefaultLanguage,
    onConnectToFlow,
    onDisconnectFromFlow,
    onScalingEdit,
    disabledFlowInstanceIsInUse,
}) => {
    return (
        <Grid className={styles.container}>
            {selectedDeployment?.enabled === false ? (
                <Grid columns="900px 1fr" className={styles.empty}>
                    The instance is disabled
                </Grid>
            ) : (
                <Grid className={styles.container}>
                    {selectedDetailsSection === DeploymentMainPanels.Overview && selectedDeployment && (
                        <DeploymentOverview
                            environments={environments}
                            deployment={selectedDeployment}
                            onEdit={onEdit}
                            onEditDefaultLanguage={onEditDefaultLanguage}
                            onConnectToFlow={onConnectToFlow}
                            onDisconnectFromFlow={onDisconnectFromFlow}
                            disabledFlowInstanceIsInUse={disabledFlowInstanceIsInUse}
                        />
                    )}
                    {selectedDetailsSection === DeploymentMainPanels.Scaling && selectedDeployment && (
                        <ScalingOptions deployment={selectedDeployment} onScalingEdit={onScalingEdit} />
                    )}
                    {selectedDetailsSection === DeploymentMainPanels.Advanced && selectedDeployment && (
                        <AdvancedDeploymentSections
                            deployment={selectedDeployment}
                            onAzureApplicationsEdit={onAzureApplicationsEdit}
                            onAzureBlobContainerEdit={onAzureBlobContainerEdit}
                            onAzureSqlEdit={onAzureSqlEdit}
                            onAzureServiceBusEdit={onAzureServiceBusEdit}
                        />
                    )}
                    {!selectedDeployment && <div className={styles.borderStyle}></div>}
                </Grid>
            )}
        </Grid>
    )
}

export default DeploymentDetails
