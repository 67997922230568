import { FunctionComponent } from 'react'
import CopyIcon from '../../icons/CopyIcon'
import PlusIcon from '../../icons/PlusIcon'
import SaveIcon from '../../icons/SaveIcon'
import TrashIcon from '../../icons/TrashIcon'
import Button, { ButtonType } from '../../infratructure/button/Button'
import Grid from '../../infratructure/grid/Grid'
import styles from './ProductToolbar.module.scss'
import { DeploymentModel, FlowInstanceModel } from '../Model/AllSparkModel'

interface ProductsToolbarProps {
    className?: string
    hasItemSelected: boolean
    isWorking: boolean
    onAdd: () => void
    onRefresh: () => void
    onSave: () => void
    onDelete: () => void
    onClone?: () => void
    canDelete: boolean
}

const ProductToolbar: FunctionComponent<ProductsToolbarProps> = ({
    className,
    isWorking,
    hasItemSelected,
    onAdd,
    onRefresh,
    onSave,
    onDelete,
    onClone,
    canDelete,
}) => {
    return (
        <Grid className={styles.container} columns="auto auto auto auto 1fr" gap={16}>
            <Button
                onClick={onAdd}
                buttonType={ButtonType.Icon}
                className={styles.btn}
                disabled={isWorking}
                title="New Product"
            >
                <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                    <PlusIcon height={26} width={26} />
                    <div>New</div>
                </Grid>
            </Button>

            <Button
                onClick={onSave}
                buttonType={ButtonType.Icon}
                className={styles.btn}
                disabled={isWorking}
                title="Save Product"
            >
                <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                    <SaveIcon height={26} width={26} />
                    <div>Save</div>
                </Grid>
            </Button>
            <Button
                onClick={onClone}
                buttonType={ButtonType.Icon}
                className={styles.btn}
                disabled={isWorking || !hasItemSelected}
                title="Clone Product"
            >
                <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                    <CopyIcon height={26} width={26} />
                    Clone
                </Grid>
            </Button>
            <Button
                onClick={onDelete}
                buttonType={ButtonType.Icon}
                className={styles.btn}
                disabled={isWorking || !hasItemSelected || !canDelete}
                title="Delete Product"
            >
                <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                    <TrashIcon height={26} width={26} />
                    <div>Delete</div>
                </Grid>
            </Button>
        </Grid>
    )
}

export default ProductToolbar
