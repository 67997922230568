import { Theme } from 'react-select'

export const colors = (theme: Theme) => ({
    ...theme,
    borderRadius: 0,
    colors: {
        ...theme.colors,
        primary25: 'var(--selector-item-hover-background-color)',
        primary: 'var(--selector-item-selected-background-color)',
    },
})

const getBorderColor = (isFocused: boolean) => {
    return isFocused ? 'var(--theme-border-color-focus)' : 'var(--selector-border-color)'
}

export const style = {
    container: (base: any) => ({
        ...base,
        height: 32,
    }),
    control: (base: any, state: any) => ({
        ...base,
        backgroundColor: 'var(--theme-color)',
        height: 32,
        minHeight: 32,
        fontSize: 12,
        borderRadius: 3,
        cursor: 'pointer',
        boxShadow: state.isFocused ? 0 : 0,
        border: state.selectProps.border === true ? `1px solid ${getBorderColor(state.isFocused)}` : 'none',
        '&:hover': {
            borderColor: 'var(--selector-border-color)',
        },
    }),
    option: (base: any, state: any) => ({
        ...base,
        fontSize: 12,
        cursor: 'pointer',
        color: state.isDisabled
            ? 'var(--theme-disabled-color)'
            : state.isSelected
            ? 'var(--selector-item-selected-color)'
            : state.isFocused
            ? 'var(--selector-item-hover-color)'
            : null,
        backgroundColor: state.isDisabled
            ? null
            : state.isSelected
            ? 'var(--selector-item-selected-background-color)'
            : state.isFocused
            ? 'var(--selector-item-hover-background-color)'
            : null,

        ':active': {
            ...base[':active'],
            backgroundColor:
                !state.isDisabled &&
                (state.isSelected
                    ? 'var(--selector-item-selected-background-color)'
                    : 'var(--selector-item-selected-background-color)'),
        },
    }),
    dropdownIndicator: (base: any) => ({
        ...base,
        padding: '4px',
        fontWeight: 'normal',
        color: 'var(--selector-dropdownindicator-color)',
    }),
    loadingIndicator: (base: any) => ({
        ...base,
    }),
    indicatorSeparator: (base: any) => ({
        ...base,
        padding: 0,
        backgroundColor: 'var(--selector-indicatorseparator-color)',
    }),
    clearIndicator: (base: any) => ({
        ...base,
        padding: '4px',
        color: 'var(--selector-clearindicator-color)',
    }),
    valueContainer: (base: any, state: any) => ({
        ...base,
        padding: 0,
        paddingLeft: 4,
        height: '100%',
        ...(state.selectProps.rightAlign ? { justifyContent: 'flex-end', paddingRight: 6 } : {}), //6 px because the dropdown and clear icons has a 2px "padding"
    }),
    menu: (base: any) => ({
        ...base,
        backgroundColor: 'var(--selector-background-color)',
        borderRadius: 0,
        cursor: 'pointer',
        width: 'max-content',
        minWidth: '100%',
    }),
    menuPortal: (base: any) => ({
        ...base,
        zIndex: 30003003,
    }),
}
