import { FunctionComponent } from "react"
import { DeploymentLog } from "../Model/AllSparkModel"
import List, { ListItemProps } from "../../infratructure/list/List"
import commonListStyles from './../CommonList.module.scss'
import Grid from "../../infratructure/grid/Grid"

interface SystemLogDeploymentsListProps{
    items: DeploymentLog[]
    onSelectedChange: (deployment: DeploymentLog) => void
    selectedItems: DeploymentLog[] | undefined
}

const SystemLogDeploymentsList: FunctionComponent<SystemLogDeploymentsListProps> = ({
    items,
    onSelectedChange,
    selectedItems
}) => {
    
    const DeliveryItemLayout = ({ item }: ListItemProps<DeploymentLog>) => {
        return (
            <Grid className={commonListStyles.item}>
                <div className={commonListStyles.nameheader}>{`deployment name`}</div>
                <Grid>
                    <strong className={commonListStyles.itemname} title={item.name}>
                        {`${item.name}`}
                    </strong>
                </Grid>
            </Grid>
        )
    }
    
    return (
        <List
            className={commonListStyles.list}
            item={DeliveryItemLayout}
            spacing="compact"
            itemSource={items}
            itemSize={46}
            onItemSelect={onSelectedChange}
            selectedItems={selectedItems}
            border={true}
    />
    )
}

export default SystemLogDeploymentsList