import cx from 'classnames'
import { IconProps } from './IconProps'

export default function RouterIcon({ width = 20, height = 20, className, style }: IconProps) {
    return (
        <svg
            fill="none"
            viewBox="0 0 24 24"
            width={width}
            height={height}
            className={cx('icon', className)}
            style={style}
            data-icon
        >
            <path
                d="M15.2761 18.6916C15.2761 18.8021 15.3657 18.8916 15.4761 18.8916H18.3295V17.0779H15.2761V18.6916Z"
                fill="currentColor"
            />
            <path
                d="M3 6.5667C3 6.73238 3.13431 6.8667 3.3 6.8667H5.65083C5.78317 6.8667 5.89988 6.77998 5.93807 6.65326L6.30381 5.43959C6.36183 5.24703 6.21768 5.05303 6.01657 5.05303H3.3C3.13431 5.05303 3 5.18735 3 5.35303V6.5667Z"
                fill="currentColor"
            />
            <path
                d="M4.46118 5.52832L15.3094 18.8109L16.6546 17.7165L6.20039 5.10316L4.46118 5.52832Z"
                fill="currentColor"
            />
            <path
                d="M21.5598 18.1337C21.6548 18.0538 21.6548 17.9076 21.5598 17.8277L18.5054 15.2574C18.3753 15.1479 18.1766 15.2404 18.1766 15.4104V20.551C18.1766 20.7211 18.3753 20.8135 18.5054 20.7041L21.5598 18.1337Z"
                fill="currentColor"
            />
            <rect x="15.2761" y="5.05078" width="3.14316" height="1.86711" rx="0.2" fill="currentColor" />
            <path
                d="M3 17.3742C3 17.2085 3.13431 17.0742 3.3 17.0742H6.03236C6.19426 17.0742 6.32695 17.2027 6.3322 17.3645L6.37164 18.5782C6.37715 18.7477 6.24131 18.888 6.0718 18.888H3.3C3.13431 18.888 3 18.7537 3 18.588V17.3742Z"
                fill="currentColor"
            />
            <path
                d="M11.6729 9.60649L15.3095 5.11913L16.1013 6.88736L12.8383 11.0205L11.6729 9.60649Z"
                fill="currentColor"
            />
            <path
                d="M5.03779 17.6789L8.92256 13.1185L10.0258 14.4626L6.30266 18.7845L5.03779 17.6789Z"
                fill="currentColor"
            />
            <path
                d="M21.5599 5.83135C21.6548 5.91127 21.6548 6.05748 21.5599 6.1374L18.5056 8.7077C18.3755 8.81717 18.1768 8.72469 18.1768 8.55467V3.41408C18.1768 3.24406 18.3755 3.15158 18.5056 3.26105L21.5599 5.83135Z"
                fill="currentColor"
            />{' '}
        </svg>
    )
}
