import { FunctionComponent } from 'react'
import styles from './ClusterControllersToolbar.module.scss'
import Grid from '../../infratructure/grid/Grid'
import Button, { ButtonType } from '../../infratructure/button/Button'
import PlusIcon from '../../icons/PlusIcon'

interface ClusterControllersToolbarProps {
    isWorking: boolean
    onAdd: () => void
}

const ClusterControllersToolbar: FunctionComponent<ClusterControllersToolbarProps> = ({ isWorking, onAdd }) => {
    return (
        <Grid className={styles.container} columns="auto auto 1fr" gap={16}>
            <Button
                onClick={onAdd}
                buttonType={ButtonType.Icon}
                className={styles.btn}
                disabled={isWorking}
                title="Add Cluster Controller (HELM-chart Ingress)"
            >
                <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                    <PlusIcon height={26} width={26} />
                    <div>Add</div>
                </Grid>
            </Button>
        </Grid>
    )
}

export default ClusterControllersToolbar
