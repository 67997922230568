import React from 'react'
import styles from './Search.module.scss'
import { debounce } from 'lodash'
import cx from 'classnames'
import Input from '../input/Input'
import SearchIcon from '../../icons/SearchIcon'
import CloseIcon from '../../icons/CloseIcon'

type SearchProps = {
    onSearch?: (searchText: string) => void
    onChange?: (searchText: string) => void
    border?: boolean
    autoFocus?: boolean
    placeholder?: string
    className?: string
    style?: React.CSSProperties
    debounced?: boolean
    hotkey?: string
}

const Search = React.forwardRef<HTMLInputElement, SearchProps>(
    (
        {
            onSearch = () => {},
            onChange = () => {},
            className,
            placeholder,
            autoFocus,
            border = true,
            debounced = false,
            hotkey,
            ...props
        }: SearchProps,
        ref,
    ) => {
        const innerRef = React.useRef<HTMLInputElement>(null)
        const [searchText, setSearchText] = React.useState<string>('')
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const debounced_onChange = React.useCallback(debounce(onChange, 500), [onChange])

        React.useLayoutEffect(() => {
            if (ref) {
                if (typeof ref === 'function') {
                    ref(innerRef.current)
                } else {
                    ;(ref as any).current = innerRef.current
                }
            }
        }, [ref])

        React.useEffect(() => {
            if (autoFocus && innerRef.current) {
                innerRef.current!.focus()
            }
        }, [autoFocus])

        if (placeholder === undefined) {
            placeholder = 'Search'
        }

        const handleClear = () => {
            setSearchText('')
            onSearch('')
            onChange('')
        }

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchText(e.target.value)
            if (debounced) {
                debounced_onChange(e.target.value)
            } else {
                onChange(e.target.value)
            }
        }

        const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.code === 'Enter') {
                onSearch(searchText)
            }
        }

        return (
            <div className={cx(styles.search, className, { [styles.border]: border })} {...props}>
                <SearchIcon />
                <Input
                    role="searchbox"
                    ref={innerRef}
                    placeholder={placeholder}
                    value={searchText}
                    onChange={handleChange}
                    onKeyDown={handleSearch}
                    hotkey={hotkey}
                />
                <button
                    aria-label={'Clear'}
                    onClick={handleClear}
                    style={{ visibility: searchText ? 'visible' : 'hidden' }}
                >
                    <CloseIcon />
                </button>
            </div>
        )
    },
)

export default Search
