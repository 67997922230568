import { FunctionComponent } from 'react'
import styles from './AzureBlobContainerDetails.module.scss'
import 'react-datepicker/dist/react-datepicker.css'
import CopyableInput from '../../../../infratructure/copyableInput/CopyableInput'
import { DeploymentModel } from '../../../Model/AllSparkModel'
import Grid from '../../../../infratructure/grid/Grid'
import Heading from '../../../../infratructure/heading/Heading'
import Button, { ButtonType } from '../../../../infratructure/button/Button'

interface AzureBlobContainerDetailProps {
    deployment: DeploymentModel
    onAzureBlobContainerEdit: () => void
}

const AzureBlobContainerDetails: FunctionComponent<AzureBlobContainerDetailProps> = ({ deployment, onAzureBlobContainerEdit }) => {
    return (
        <Grid columns="900px 1fr" className={styles.container}>
            <Grid rows="auto auto 1fr" gap={16}>
                <Grid columns="auto auto 1fr">
                    <Heading type="heading1">
                        Blob Container Details
                    </Heading>
                    <Button
                        style={{ marginLeft: '10px'}}
                        onClick={onAzureBlobContainerEdit}
                        buttonType={ButtonType.Default}
                        className={styles.btn}
                        title="Edit"
                        >
                        <Grid className={styles.btnContentWrapper}>
                            Edit
                        </Grid>
                    </Button>
                </Grid>
                <Grid rows="auto auto auto auto" gap={16} style={{ marginLeft: '10px' }}>
                    <CopyableInput
                        width={650}
                        headerText={'Name'}
                        value={deployment.storageDeployment.storageContainer.name}
                        readOnly
                    />

                    <CopyableInput
                        width={650}
                        headerText={'SAS Token'}
                        value={deployment.storageDeployment.storageContainer.sasUri}
                        readOnly
                    />

                    <CopyableInput
                        width={650}
                        headerText={'Token Expiry'}
                        value={deployment.storageDeployment.storageContainer.sasTokenExpiry?.toString()}
                        readOnly
                    />

                    <CopyableInput
                        width={650}
                        headerText={'Stored Access Policy Identifier'}
                        value={deployment.storageDeployment.storageContainer.storedAccessPolicyIdentifer?.toString() || ''}
                        readOnly
                    />
                </Grid>
                <div></div>
            </Grid>
        </Grid>
    )
}

export default AzureBlobContainerDetails
