import { FunctionComponent } from 'react'
import { EnvironmentModel } from '../../Model/AllSparkModel'
import { useGlobalStateContext } from '../../../../GlobalState'
import Button, { ButtonType } from '../../../infratructure/button/Button'
import Grid from '../../../infratructure/grid/Grid'
import Heading from '../../../infratructure/heading/Heading'
import Spinner from '../../../infratructure/spinner/Spinner'
import styles from './DeleteEnvironmentConfirmation.module.scss'

interface DeleteEnvironmentConfirmationProps {
    onDelete: () => void
    selectedEnvironment?: EnvironmentModel
    isWorking: boolean
}

const DeleteEnvironmentConfirmation: FunctionComponent<DeleteEnvironmentConfirmationProps> = ({
    onDelete,
    selectedEnvironment,
    isWorking,
}) => {
    const {
        globalState: { deployments },
    } = useGlobalStateContext()
    const canDelete = !deployments.some(
        (dep) => dep.deploymentRequestParameters.environmentRowKey === selectedEnvironment?.rowKey,
    )

    return (
        <Grid rows="auto 1ft auto">
            <Heading type="heading2">{`Environment - ${selectedEnvironment?.name}`}</Heading>
            <Grid style={{ margin: '20px' }}>
                {canDelete ? (
                    <div>{`Are you sure you want to delete - ${selectedEnvironment?.name} ?`}</div>
                ) : (
                    <>
                        <div>{`Unable to delete - ${selectedEnvironment?.name}.`}</div>
                        <div>{`It has been used in one or several deployments.`}</div>
                    </>
                )}
            </Grid>
            <Grid columns="1fr auto" gap={8} className={styles.footer}>
                <div />
                <Button
                    className={styles.deletebtn}
                    buttonType={ButtonType.Confirm}
                    onClick={onDelete}
                    disabled={!canDelete}
                >
                    {isWorking ? <Spinner size={18} color={'#FFFF'} /> : 'Delete'}
                </Button>
            </Grid>
        </Grid>
    )
}

export default DeleteEnvironmentConfirmation
