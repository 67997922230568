import cx from 'classnames'
import { IconProps } from './IconProps'

export default function PlusIcon({ width = 20, height = 20, className, style }: IconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            className={cx('icon', className)}
            style={style}
            data-icon
        >
            <path fillRule="evenodd" clipRule="evenodd" d="M13 11h9v2h-9v9h-2v-9H2v-2h9V2h2v9z" fill="currentColor" />
        </svg>
    )
}
