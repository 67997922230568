import cx from 'classnames'
import { IconProps } from './IconProps'

export default function TrashIcon({ width = 20, height = 20, className, style }: IconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            className={cx('icon', className)}
            style={style}
            data-icon
        >
            {/* <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 1h6a2 2 0 012 2v1h3a2 2 0 012 2v2a2 2 0 01-2 2h-.08L19 21a2 2 0 01-2 2H7c-1.105 0-2-.895-1.997-1.917L4.08 10H4a2 2 0 01-2-2V6a2 2 0 012-2h3V3a2 2 0 012-2zM4 6h16v2H4V6zm2.086 4h11.827l-.91 10.917L17 21H7l-.914-11zM15 3v1H9V3h6z"
                fill="currentColor"
            /> */}
            <path
                d="M10 .75a3.25 3.25 0 0 1 3.245 3.066L13.25 4h5.25a.75.75 0 0 1 .102 1.493L18.5 5.5h-.796l-1.28 13.02a2.75 2.75 0 0 1-2.561 2.474l-.176.006H6.313a2.75 2.75 0 0 1-2.714-2.307l-.023-.174L2.295 5.5H1.5a.75.75 0 0 1-.743-.648L.75 4.75a.75.75 0 0 1 .648-.743L1.5 4h5.25A3.25 3.25 0 0 1 10 .75Zm6.197 4.75H3.802l1.267 12.872a1.25 1.25 0 0 0 1.117 1.122l.127.006h7.374c.6 0 1.109-.425 1.225-1.002l.02-.126L16.196 5.5ZM11.75 8.25a.75.75 0 0 1 .743.648L12.5 9v7a.75.75 0 0 1-1.493.102L11 16V9a.75.75 0 0 1 .75-.75Zm-3.5 0a.75.75 0 0 1 .743.648L9 9v7a.75.75 0 0 1-1.493.102L7.5 16V9a.75.75 0 0 1 .75-.75Zm1.75-6a1.75 1.75 0 0 0-1.744 1.606L8.25 4h3.5A1.75 1.75 0 0 0 10 2.25Z"
                fill="currentColor"
            />
        </svg>
    )
}
