import { FunctionComponent } from 'react'
import styles from './Alert.module.scss'

interface AlertProps {
    alertMessage: string | undefined
}

const Alert: FunctionComponent<AlertProps> = ({ alertMessage }) => {

    return (
        <div className={styles.alert} title={alertMessage}>&nbsp;</div>
    )
}

export default Alert