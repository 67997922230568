import { FunctionComponent } from 'react'
import styles from './Disabled.module.scss'

interface DisabledProps {
    disabledMessage: string | undefined
}

const Disabled: FunctionComponent<DisabledProps> = ({ disabledMessage }) => {

    return (
        <div className={styles.disabled} title={disabledMessage}>&nbsp;</div>
    )
}

export default Disabled