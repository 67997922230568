import cx from 'classnames'
import { IconProps } from './IconProps'

export default function EnvironmentsIcon({ width = 20, height = 20, className, style }: IconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            className={cx('icon', className)}
            style={style}
            data-icon
        >
            <path
                d="M17.5 12a5.5 5.5 0 1 1 0 11 5.5 5.5 0 0 1 0-11ZM12.002 2c5.523 0 10.001 4.478 10.001 10.002 0 .263-.01.524-.03.782a6.518 6.518 0 0 0-1.476-1.053l.004.177-.008-.318a8.493 8.493 0 0 0-.227-1.59l-.014.001h-3.344c.032.335.057.675.073 1.02a6.429 6.429 0 0 0-1.491.296c-.015-.451-.046-.89-.091-1.316H8.605a18.966 18.966 0 0 0 .135 5h2.758a6.466 6.466 0 0 0-.422 1.5H9.063c.598 2.216 1.602 3.735 2.655 3.97a6.497 6.497 0 0 0 1.066 1.502c-.258.02-.52.03-.782.03C6.478 22.003 2 17.525 2 12.002 2 6.478 6.478 2 12.002 2ZM17.5 14l-.09.007a.5.5 0 0 0-.402.402L17 14.5V17h-2.502l-.09.008a.5.5 0 0 0-.402.402l-.008.09.008.09a.5.5 0 0 0 .402.402l.09.008H17v2.503l.008.09a.5.5 0 0 0 .402.402l.09.008.09-.008a.5.5 0 0 0 .402-.402l.008-.09V18l2.504.001.09-.008a.5.5 0 0 0 .402-.402l.008-.09-.008-.09a.5.5 0 0 0-.403-.402l-.09-.008H18v-2.5l-.008-.09a.5.5 0 0 0-.402-.403L17.5 14Zm-9.99 2.502H4.787a8.531 8.531 0 0 0 4.095 3.41c-.58-.91-1.048-2.076-1.372-3.41ZM3.737 10l-.004.017a8.525 8.525 0 0 0-.233 1.984c0 1.056.193 2.067.545 3h3.173a20.3 20.3 0 0 1-.218-3c0-.684.032-1.354.095-2.001H3.737Zm5.146-5.91-.023.008A8.531 8.531 0 0 0 4.252 8.5H7.3c.313-1.752.86-3.278 1.583-4.41Zm3.119-.591-.116.005C10.621 3.62 9.398 5.622 8.83 8.5h6.343c-.585-2.965-1.865-5-3.171-5Zm3.12.59.106.175c.67 1.112 1.178 2.572 1.475 4.237h3.048a8.531 8.531 0 0 0-4.63-4.411Z"
                fill="currentColor"
            />
        </svg>
    )
}
