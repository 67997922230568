import { FunctionComponent } from 'react'
import Button, { ButtonType } from '../../infratructure/button/Button'
import Grid from '../../infratructure/grid/Grid'
import TrashIcon from '../../icons/TrashIcon'
import styles from './LanguagesToolbar.module.scss'
import PlusIcon from '../../icons/PlusIcon'

interface SystemLogToolbarProps {
    isWorking: boolean
    hasItemSelected: boolean
    canBeDeleted: boolean
    onRefresh: () => void
    onDelete: () => void
    onAdd: () => void
}

const LanguagesToolbar: FunctionComponent<SystemLogToolbarProps> = ({
    isWorking,
    hasItemSelected,
    canBeDeleted,
    onDelete,
    onAdd,
}) => {
    return (
        <Grid className={styles.container} columns="auto auto 1fr" gap={16}>
            <Button
                onClick={onAdd}
                buttonType={ButtonType.Icon}
                className={styles.btn}
                disabled={isWorking}
                title="Add language"
            >
                <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                    <PlusIcon height={26} width={26} />
                    <div>Add</div>
                </Grid>
            </Button>
            <Button
                onClick={onDelete}
                buttonType={ButtonType.Icon}
                className={styles.btn}
                disabled={isWorking || !hasItemSelected || !canBeDeleted}
                title="Delete language"
            >
                <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                    <TrashIcon height={26} width={26} />
                    <div>Delete</div>
                </Grid>
            </Button>
        </Grid>
    )
}

export default LanguagesToolbar
