import cx from 'classnames'

type UpgradeIcon2IconProps = { width?: number; height?: number; className?: string; style?: React.CSSProperties, fill?: string }

export default function UpgradeIcon2({ width = 40, height = 40, className, style, fill }: UpgradeIcon2IconProps) {
    return (
        <svg
            width={width} 
            height={height} 
            viewBox="0 0 24 24" 
            fill="none"
            className={cx('icon', className)}
            style={style}
            data-icon
            >
            <path d="M9.14645 11.1464L11.6464 8.64645C11.8417 8.45118 12.1583 8.45118 12.3536 8.64645L14.8536 11.1464C15.0488 11.3417 15.0488 11.6583 14.8536 11.8536C14.6583 12.0488 14.3417 12.0488 14.1464 11.8536L12.5 10.2071V15C12.5 15.2761 12.2761 15.5 12 15.5C11.7239 15.5 11.5 15.2761 11.5 15V10.2071L9.85355 11.8536C9.65829 12.0488 9.34171 12.0488 9.14645 11.8536C8.95118 11.6583 8.95118 11.3417 9.14645 11.1464ZM12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12Z"
            fill={fill || 'currentColor'}/>
        </svg>                                
    )
}
