import cx from 'classnames'
import { IconProps } from './IconProps'

export default function EditIcon({ width = 40, height = 40, className, style }: IconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            className={cx('icon', className)}
            style={style}
            data-icon
        >
            <path d="M21.0303 2.96948C22.4278 4.36695 22.4278 6.63268 21.0303 8.03014L9.06198 19.9985C8.7851 20.2753 8.44076 20.4752 8.063 20.5782L2.94731 21.9734C2.38744 22.1261 1.87371 21.6123 2.0264 21.0525L3.42159 15.9368C3.52462 15.559 3.72444 15.2147 4.00132 14.9378L15.9696 2.96948C17.3671 1.57202 19.6328 1.57202 21.0303 2.96948ZM15.0001 6.06039L5.06198 15.9985C4.96969 16.0908 4.90308 16.2055 4.86874 16.3315L3.81889 20.1809L7.66833 19.1311C7.79425 19.0967 7.90903 19.0301 8.00132 18.9378L17.9391 8.99939L15.0001 6.06039ZM17.0303 4.03014L16.0601 4.99939L18.9991 7.93939L19.9696 6.96948C20.7813 6.15781 20.7813 4.84182 19.9696 4.03014C19.158 3.21847 17.842 3.21847 17.0303 4.03014Z" fill="#212121"/>
        </svg>
    )
}
