import { FunctionComponent } from 'react'
import { DeploymentModel } from '../../Model/AllSparkModel'
import Grid from '../../../infratructure/grid/Grid'
import styles from './ScalingOptions.module.scss'
import Heading from '../../../infratructure/heading/Heading'
import Button, { ButtonType } from '../../../infratructure/button/Button'
import cx from 'classnames'

interface ScalingProps {
    deployment: DeploymentModel
    onScalingEdit: () => void
}

const Scaling: FunctionComponent<ScalingProps> = ({ deployment, onScalingEdit }) => {
    return (
        <Grid columns="900px 1fr" className={styles.container}>
            <Grid columns="auto auto auto auto auto 1fr" rows="auto auto 1fr" gap={16}>
                <Heading type="heading1" style={{ textAlign: 'center'}}>System Worker</Heading>
                <Heading type="heading1" style={{ textAlign: 'center'}}>Worker</Heading>
                <Heading type="heading1" style={{ textAlign: 'center'}}>Dispatcher</Heading>
                <Heading type="heading1" style={{ textAlign: 'center'}}>Scheduler</Heading>
                <Heading type="heading1" style={{ textAlign: 'center'}}>WebApp</Heading>
                <div/>

                <Grid rows="auto auto auto auto 1fr" className={styles.service}>
                    <div className={cx(styles.servicedetail, styles.servicedetailheader, styles.servicedetailtopmargin)}>Memory</div>
                    <div className={cx(styles.servicedetail)}>request / limit (Mi)</div>
                    <div className={cx(styles.servicedetail)}>{deployment.scaling.systemWorker.memory.request || 2000} / {deployment.scaling.systemWorker.memory.limit || 8000}</div>
                    <div className={cx(styles.servicedetail, styles.servicedetailtopmargin)}>Message Count {deployment.scaling.systemWorker.messageCount}</div>
                    <div className={cx(styles.servicedetail, styles.servicedetailtopmargin)}>Max Tasks {deployment.scaling.systemWorker.maxTaskCount}</div>
                </Grid>
                
                <Grid rows="auto auto auto auto 1fr" className={styles.service}>
                    <div className={cx(styles.servicedetail, styles.servicedetailheader, styles.servicedetailtopmargin)}>Memory</div>
                    <div className={cx(styles.servicedetail)}>request / limit (Mi)</div>
                    <div className={cx(styles.servicedetail)}>{deployment.scaling.worker.memory.request || 3000} / {deployment.scaling.worker.memory.limit || 8000}</div>
                    <div className={cx(styles.servicedetail, styles.servicedetailtopmargin)}>Message Count {deployment.scaling.worker.messageCount}</div>
                    <div className={cx(styles.servicedetail, styles.servicedetailtopmargin)}>Max Tasks {deployment.scaling.worker.maxTaskCount}</div>
                </Grid>

                <Grid rows="auto auto auto auto 1fr" className={styles.service}>
                    <div className={cx(styles.servicedetail, styles.servicedetailheader, styles.servicedetailtopmargin)}>Memory</div>
                    <div className={cx(styles.servicedetail)}>request / limit (Mi)</div>
                    <div className={cx(styles.servicedetail)}>{deployment.scaling.dispatcher.memory.request || 300} / {deployment.scaling.dispatcher.memory.limit || 3000}</div>
                    <div className={cx(styles.servicedetail, styles.servicedetailtopmargin)}>Message Count {deployment.scaling.dispatcher.messageCount}</div>
                    <div className={cx(styles.servicedetail, styles.servicedetailtopmargin)}>Max Tasks {deployment.scaling.dispatcher.maxTaskCount}</div>
                </Grid>

                <Grid rows="auto auto auto auto 1fr" className={styles.service}>
                <div className={cx(styles.servicedetail, styles.servicedetailheader, styles.servicedetailtopmargin)}>Memory</div>
                    <div className={cx(styles.servicedetail)}>request / limit (Mi)</div>
                    <div className={cx(styles.servicedetail)}>{deployment.scaling.scheduler.memory.request || 200} / {deployment.scaling.scheduler.memory.limit || 2000}</div>
                </Grid>

                <Grid rows="auto auto auto auto 1fr" className={styles.service}>
                    <div className={cx(styles.servicedetail, styles.servicedetailheader, styles.servicedetailtopmargin)}>Memory</div>
                    <div className={cx(styles.servicedetail)}>request / limit (Mi)</div>
                    <div className={cx(styles.servicedetail)}>{deployment.scaling.webApp.memory.request || 600} / {deployment.scaling.webApp.memory.limit || 6000}</div>
                </Grid>

                <Grid columnSpan={6} className={cx(styles.servicedetailtopmargin)}>
                    <Button
                            style={{ marginLeft: '0px', width: '96px'}}
                            onClick={onScalingEdit}
                            buttonType={ButtonType.Default}
                            className={styles.btn}
                            title="Edit"
                            >
                            <Grid className={styles.btnContentWrapper}>
                                Edit
                            </Grid>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Scaling
