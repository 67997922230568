import { FunctionComponent } from 'react'
import styles from './LoaderDots.module.scss'
import Grid from '../grid/Grid'

interface LoaderDotsProps {
    size?: number
    color: string
}

const LoaderDots: FunctionComponent<LoaderDotsProps> = ({ size = 5, color = '#FFFF' }) => {
    return (
        <Grid columns="auto auto auto" className={styles.container} gap={4}>
            <div
                style={{ height: size, width: size, borderRadius: size, backgroundColor: color }}
                className={styles.loader__dot}
            />
            <div
                style={{ height: size, width: size, borderRadius: size, backgroundColor: color }}
                className={styles.loader__dot}
            />
            <div
                style={{ height: size, width: size, borderRadius: size, backgroundColor: color }}
                className={styles.loader__dot}
            />
        </Grid>
        // </div>
    )
}

export default LoaderDots
