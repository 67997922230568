import { FunctionComponent } from 'react'
import { FlowInstanceModel } from '../../Model/AllSparkModel'
import Grid from '../../../infratructure/grid/Grid'
import Heading from '../../../infratructure/heading/Heading'
import cx from 'classnames'
import styles from './FlowInstanceScaling.module.scss'
import Button, { ButtonType } from '../../../infratructure/button/Button'

interface ScalingProps {
    instance: FlowInstanceModel
    onScalingEdit: () => void
}

const FlowInstanceScaling: FunctionComponent<ScalingProps> = ({ instance, onScalingEdit }) => {
    return (
        <Grid columns="auto auto auto auto auto auto 1fr" rows="auto auto 1fr" gap={16}>
            <Heading type="heading1" style={{ textAlign: 'center' }}>
                Abafar
            </Heading>
            <Heading type="heading1" style={{ textAlign: 'center' }}>
                Anaxes
            </Heading>
            <Heading type="heading1" style={{ textAlign: 'center' }}>
                Corellia
            </Heading>
            <Heading type="heading1" style={{ textAlign: 'center' }}>
                Designer
            </Heading>
            <Heading type="heading1" style={{ textAlign: 'center' }}>
                Portal
            </Heading>
            <Heading type="heading1" style={{ textAlign: 'center' }}>
                Rishi
            </Heading>
            <div />

            {/* Abafar */}
            <Grid rows="auto auto auto auto 1fr" className={styles.service}>
                <div className={cx(styles.servicedetail, styles.servicedetailheader, styles.servicedetailtopmargin)}>
                    Memory
                </div>
                <div className={cx(styles.servicedetail)}>request / limit (Mi)</div>
                <div className={cx(styles.servicedetail)}>
                    {instance.scaling.abafar.memory.request || 2000} / {instance.scaling.abafar.memory.limit || 5000}
                </div>
                <div className={cx(styles.servicedetail, styles.servicedetailtopmargin)}>
                    Message Count {instance.scaling.abafar.messageCount || 50}
                </div>
            </Grid>

            {/* Anaxes */}
            <Grid rows="auto auto auto auto 1fr" className={styles.service}>
                <div className={cx(styles.servicedetail, styles.servicedetailheader, styles.servicedetailtopmargin)}>
                    Memory
                </div>
                <div className={cx(styles.servicedetail)}>request / limit (Mi)</div>
                <div className={cx(styles.servicedetail)}>
                    {instance.scaling.anaxes.memory.request || 500} / {instance.scaling.anaxes.memory.limit || 5000}
                </div>
            </Grid>

            {/* Corellia */}
            <Grid rows="auto auto auto auto auto auto auto 1fr" className={styles.service}>
                <div className={cx(styles.servicedetail, styles.servicedetailheader, styles.servicedetailtopmargin)}>
                    Memory
                </div>
                <div className={cx(styles.servicedetail)}>request / limit (Mi)</div>
                <div className={cx(styles.servicedetail)}>
                    {instance.scaling.corellia.memory.request || 2000} /{' '}
                    {instance.scaling.corellia.memory.limit || 5000}
                </div>
                {instance.scaling.corellia.cpu?.request !== 0 && (
                    <Grid>
                        <div
                            className={cx(
                                styles.servicedetail,
                                styles.servicedetailheader,
                                styles.servicedetailtopmargin,
                            )}
                        >
                            CPU
                        </div>
                        <div className={cx(styles.servicedetail)}>request / limit (Mi)</div>
                        <div className={cx(styles.servicedetail)}>
                            {instance.scaling.corellia.cpu?.request || 0} / {instance.scaling.corellia.cpu?.limit || 0}
                        </div>
                    </Grid>
                )}
            </Grid>

            {/* Designer */}
            <Grid rows="auto auto auto auto 1fr" className={styles.service}>
                <div className={cx(styles.servicedetail, styles.servicedetailheader, styles.servicedetailtopmargin)}>
                    Memory
                </div>
                <div className={cx(styles.servicedetail)}>request / limit (Mi)</div>
                <div className={cx(styles.servicedetail)}>
                    {instance.scaling.designer.memory.request || 200} / {instance.scaling.designer.memory.limit || 2000}
                </div>
            </Grid>

            {/* Portal */}
            <Grid rows="auto auto auto auto 1fr" className={styles.service}>
                <div className={cx(styles.servicedetail, styles.servicedetailheader, styles.servicedetailtopmargin)}>
                    Memory
                </div>
                <div className={cx(styles.servicedetail)}>request / limit (Mi)</div>
                <div className={cx(styles.servicedetail)}>
                    {instance.scaling.portal.memory.request || 200} / {instance.scaling.portal.memory.limit || 2000}
                </div>
            </Grid>

            {/* Rishi */}
            <Grid rows="auto auto auto auto 1fr" className={styles.service}>
                <div className={cx(styles.servicedetail, styles.servicedetailheader, styles.servicedetailtopmargin)}>
                    Memory
                </div>
                <div className={cx(styles.servicedetail)}>request / limit (Mi)</div>
                <div className={cx(styles.servicedetail)}>
                    {instance.scaling.rishi.memory.request || 500} / {instance.scaling.rishi.memory.limit || 5000}
                </div>
            </Grid>

            <Grid columnSpan={6} className={cx(styles.servicedetailtopmargin)}>
                <Button
                    style={{ marginLeft: '0px', width: '96px' }}
                    onClick={onScalingEdit}
                    buttonType={ButtonType.Default}
                    className={styles.btn}
                    title="Edit"
                >
                    <Grid className={styles.btnContentWrapper}>Edit</Grid>
                </Button>
            </Grid>
        </Grid>
    )
}

export default FlowInstanceScaling
