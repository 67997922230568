import { FunctionComponent, useContext, useState } from "react"
import { AllSparkContext } from "../../../.."
import { useGlobalStateContext } from "../../../../GlobalState"
import Grid from "../../../infratructure/grid/Grid"
import Heading from "../../../infratructure/heading/Heading"
import LoaderDots from "../../../infratructure/dots/LoaderDots"
import Button, { ButtonType } from "../../../infratructure/button/Button"
import styles from "./Edit.module.scss"
import Input from "../../../infratructure/input/Input"
import { ProductType } from "../../Model/AllSparkModel"

interface EditOverViewDetailsProps {
    productType: ProductType
    deploymentId: string | undefined
    connectionString: string | undefined
    onClose: (change: boolean, success: boolean) => void
}

type EditOverViewDetailsState = {
    deploymentId: string
    isDeploying: boolean
    isDone: boolean
    hasErrors: boolean
    connectionString: string
}

const EditSqlConnectionString: FunctionComponent<EditOverViewDetailsProps> = ({
    productType,
    deploymentId,
    connectionString,
    onClose
}) => { 

    const api = useContext(AllSparkContext)
    const { setGlobalState } = useGlobalStateContext()

    const [state, setState] = useState<EditOverViewDetailsState>({
        deploymentId: deploymentId || '',
        isDeploying: false,
        isDone: false,
        hasErrors: false,
        connectionString: connectionString || ''
    })

    const update = async () => {
        setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: true}))
        setState((prev) => ({ ...prev, isDeploying: true, hasErrors: false}))

        try{
            const req = {
                deploymentId: state.deploymentId,
                connectionString: state.connectionString
            }
            await api.updateSqlConnectionString(productType, req)

            setState((prev) => ({ ...prev, isDeploying: false, isDone: true}))
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false}))
        }
        catch(error: any){
            console.log(`An error occurred, updating the external ID failed: ${error.message}`)
            setState((prev) => ({ ...prev, isDeploying: false, hasErrors: true}))
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false}))
        }
    }
    return (
        <Grid rows="auto 1fr auto" className={styles.main} gap={0}>
            <Heading type="heading1">Edit ConnectionString</Heading>
            <div className={styles.footer}>
                <Grid columns="1fr auto auto" gap={0}>

                    {!state.isDeploying && !state.isDone && (
                        <Grid rows="auto auto" gap={20} style={{ marginBottom: '10px', marginTop: '20px'}}>
                            <div>Deployment will be restarted with the new connectionstring, when saved.</div>
                        </Grid>
                    )}

                    {state.isDone && (
                        <Grid gap={0} className={styles.info} columnSpan={3}>
                            ConnectionString Id updated
                        </Grid>
                    )}

                    {state.isDeploying && (
                        <Grid columns="1fr auto" columnSpan={2} style={{ marginBottom: '20px', marginTop: '20px'}}>
                        <div/>
                        <Grid columns="1fr auto" gap={10}>
                            <Grid>Updating</Grid>
                            <Grid style={{ alignItems: 'center', marginBottom: '3px'}}>
                                <LoaderDots color="black"/>
                            </Grid>
                        </Grid>
                    </Grid>
                    )}

                    {state.hasErrors && (
                        <Grid gap={20} className={styles.info}>
                            An error occurred, update failed
                        </Grid>
                    )}

                    {!state.isDeploying && !state.isDone && (
                        <Grid rows="auto auto" columns="1fr auto" columnSpan={3} gap={20}>
                            <Grid columnSpan={2}>
                                <Input
                                    name="connectionString"
                                    headerText={'ConnectionString'}
                                    placeholder="Enter ConnectionString"
                                    style={{ width: 'inherit' }}
                                    value={state.connectionString}
                                    onChange={(e) =>
                                        setState((prev) => (
                                        { 
                                            ...prev, 
                                            connectionString: e.target.value, 
                                        }))
                                    }
                                    readOnly={state.isDeploying}
                                />
                            </Grid>
                            <div/>
                            <Button
                                style={{ marginBottom: '10px'}}
                                className={styles.deploybtn}
                                buttonType={ButtonType.Confirm}
                                onClick={update}
                            >
                                Save
                            </Button>
                        </Grid>
                    )}


                    {state.isDone &&(
                        <Grid columns="1fr auto" style={{ marginBottom: '10px', marginTop: '20px'}}>
                            <div/>
                            <Button
                                className={styles.deploybtn}
                                buttonType={ButtonType.Confirm}
                                onClick={() => onClose(true, !state.hasErrors)}
                            >
                                Close
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </div>
        </Grid>
    )
}

export default EditSqlConnectionString