import * as React from 'react'
import styles from './SpinningCube.module.css'
import cx from 'classnames'
import { useGlobalStateContext } from '../../../GlobalState'

type SpinningCubeProps = {
    style?: React.CSSProperties
    onClick?: () => void
}

const SpinningCube: React.FunctionComponent<SpinningCubeProps> = ({ style, onClick }) => {
    const {
        globalState: { isSpinning },
    } = useGlobalStateContext()
    return (
        <div className={styles.cubewrapper} onClick={onClick} style={style}>
            <div className={cx(styles.cube, { [styles.workspin]: isSpinning })}>
                <div className={styles.top} />
                <div className={styles.right} />
                <div className={styles.bottom} />
                <div className={styles.left} />
                <div className={styles.front} />
                <div className={styles.back} />
            </div>
        </div>
    )
}

export default SpinningCube
