import { FunctionComponent } from 'react'
import { DeploymentModel, VersionResponse } from '../Model/AllSparkModel'
import UpgradeIcon2 from '../../icons/UpgradeIcon2'
import Alert from '../../infratructure/alert/Alert'
import Grid from '../../infratructure/grid/Grid'
import List, { ListItemProps } from '../../infratructure/list/List'
import Ok from '../../infratructure/ok/Ok'
import deploymentStyles from './DeploymentList.module.scss'
import commonListStyles from './../CommonList.module.scss'
import { Util } from '../../utils/Util'
import FlowIcon from '../../icons/FlowIcon'
import Disabled from '../../infratructure/disabled/Disabled'
import Search from '../../infratructure/search/Search'

interface DeploymentListProps {
    items: DeploymentModel[]
    onSelectedChange: (deployment: DeploymentModel) => void
    onFilterTextChanged: (searchText: string) => void
    selectedItems: DeploymentModel[] | undefined
    versions: VersionResponse | undefined
}

const DeploymentList: FunctionComponent<DeploymentListProps> = ({
    items,
    onSelectedChange,
    onFilterTextChanged,
    selectedItems,
    versions,
}) => {
    const DeliveryItemLayout = ({ item }: ListItemProps<DeploymentModel>) => {
        let latestVersion = versions?.versions ? versions.versions[0]?.version : undefined
        let today = new Date().valueOf()
        let secretExpiry = item.appDeployment.webApp.secretExpiry || new Date(0, 0, 1, 0, 0)
        let secretDuration = new Date(secretExpiry).valueOf() - today
        let sasUriExpiry = item.storageDeployment.storageContainer.sasTokenExpiry
        let sasUriDuration = new Date(sasUriExpiry).valueOf() - today

        const threshold = 1000 * 60 * 60 * 24 * 30 * 6 // 6 months

        let message = ''
        let messageType = 'ok'

        if (
            secretDuration <= threshold ||
            sasUriDuration <= threshold ||
            !item.storageDeployment.storageContainer.storedAccessPolicyIdentifer
        ) {
            messageType = 'alert'
            let secretmsg = Util.millisecondsToReadableDuration(Math.abs(secretDuration))
            let sasUrimsg = Util.millisecondsToReadableDuration(Math.abs(sasUriDuration))
            message =
                secretDuration <= 0
                    ? 'Webapp client secret expired ' + secretmsg + ' ago. '
                    : secretDuration <= threshold
                    ? 'Webapp client secret expires in ' + secretmsg + '. '
                    : ''
            message =
                message +
                (sasUriDuration <= 0
                    ? 'SASUri expired ' + sasUrimsg + ' ago. '
                    : sasUriDuration <= threshold
                    ? 'SASUri expires in ' + sasUrimsg + '. '
                    : '')
            message =
                message +
                (!item.storageDeployment.storageContainer.storedAccessPolicyIdentifer
                    ? 'There is no Stored Access Policy for the SASUri, renew to get it. '
                    : '')
        } else {
            message =
                'Webapp client secret expires in ' +
                Util.millisecondsToReadableDuration(Math.abs(secretDuration)) +
                '. SASUri expires in ' +
                Util.millisecondsToReadableDuration(Math.abs(sasUriDuration)) +
                '.'
        }

        return (
            <Grid rows="auto 1fr" className={commonListStyles.item}>
                <div className={commonListStyles.fontStyle}>{`deployment name`}</div>
                <Grid columns="auto 1fr 120px" style={{ alignItems: 'center' }} gap={8}>
                    {item.enabled === false && <Disabled disabledMessage="Disabled" />}
                    {messageType === 'ok' && item.enabled !== false && <Ok okMessage={message} />}
                    {messageType === 'alert' && item.enabled !== false && <Alert alertMessage={message} />}

                    {item.enabled !== false ? (
                        <div className={commonListStyles.itemname} title={item.name}>{`${item.name}`}</div>
                    ) : (
                        <div
                            className={commonListStyles.itemname}
                            title={item.name}
                            style={{ color: 'var(--theme-disabled-color)' }}
                        >{`${item.name}`}</div>
                    )}

                    <Grid columns="auto 1fr" style={{ alignItems: 'center', justifyContent: 'flex-start' }} gap={4}>
                        {item.deploymentRequestParameters.platformVersion !== latestVersion &&
                            latestVersion !== undefined &&
                            item.flowInstance !== undefined &&
                            item.flowInstance !== null && (
                                <Grid columns="auto 1fr" style={{ alignItems: 'center', width: '40px' }} gap={0}>
                                    <Grid title="Newer version available" style={{ alignItems: 'center' }}>
                                        {item.enabled !== false ? (
                                            <UpgradeIcon2 width={22} height={22} />
                                        ) : (
                                            <UpgradeIcon2 width={22} height={22} fill="var(--theme-disabled-color)" />
                                        )}
                                    </Grid>
                                    <Grid
                                        title={"Flow Instance '" + item.flowInstance?.name + "'"}
                                        style={{ alignItems: 'center' }}
                                    >
                                        {item.enabled !== false ? (
                                            <FlowIcon width={15} height={15} style={{ marginRight: '5px' }} />
                                        ) : (
                                            <FlowIcon
                                                width={15}
                                                height={15}
                                                style={{ marginRight: '5px' }}
                                                fill="var(--theme-disabled-color)"
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                        {item.deploymentRequestParameters.platformVersion !== latestVersion &&
                            latestVersion !== undefined &&
                            (item.flowInstance === undefined || item.flowInstance === null) && (
                                <Grid
                                    title="Newer version available"
                                    style={{ alignItems: 'center', width: '40px', justifyContent: 'flex-end' }}
                                    gap={0}
                                >
                                    {item.enabled !== false ? (
                                        <UpgradeIcon2 width={22} height={22} />
                                    ) : (
                                        <UpgradeIcon2 width={22} height={22} fill="var(--theme-disabled-color)" />
                                    )}
                                </Grid>
                            )}
                        {(item.deploymentRequestParameters.platformVersion === latestVersion ||
                            latestVersion === undefined) &&
                            item.flowInstance !== undefined &&
                            item.flowInstance !== null && (
                                <Grid
                                    title={"Flow Instance '" + item.flowInstance?.name + "'"}
                                    style={{ alignItems: 'center', width: '40px', justifyContent: 'flex-end' }}
                                    gap={0}
                                >
                                    <FlowIcon width={15} height={15} style={{ marginRight: '5px' }} />
                                </Grid>
                            )}
                        {(item.deploymentRequestParameters.platformVersion === latestVersion ||
                            latestVersion === undefined) &&
                            (item.flowInstance === undefined || item.flowInstance === null) && (
                                <Grid style={{ width: '40px' }} gap={0} />
                            )}
                        {item.enabled !== false ? (
                            <div
                                className={deploymentStyles.platformVersion}
                            >{`${item.deploymentRequestParameters.platformVersion}`}</div>
                        ) : (
                            <div
                                className={deploymentStyles.platformVersion}
                                style={{ color: 'var(--theme-disabled-color)' }}
                            >{`${item.deploymentRequestParameters.platformVersion}`}</div>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid rows="auto 1fr" gap={4}>
            <Search onChange={onFilterTextChanged} className={deploymentStyles.search} style={{ borderRadius: 0 }} />
            <List
                className={commonListStyles.list}
                item={DeliveryItemLayout}
                spacing="compact"
                itemSource={items}
                itemSize={46}
                onItemSelect={onSelectedChange}
                selectedItems={selectedItems}
                border={true}
            />
        </Grid>
    )
}

export default DeploymentList
