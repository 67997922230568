import React, { Component, ErrorInfo, ReactNode } from 'react'
import Grid from '../grid/Grid'
import Heading from '../heading/Heading'
import styles from '../../infratructure/errorBoundry/ErrorBoundry.module.scss'

interface Props {
    children: ReactNode
}

interface State {
    hasError: boolean
}

class ErrorBoundry extends Component<Props, State> {
    public state: State = {
        hasError: false,
    }

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Uncaught error:', error, errorInfo)
    }

    public render() {
        if (this.state.hasError) {
            return (
                <Grid rows="auto auto  1fr" gap={24} className={styles.container}>
                    <Heading type="heading1" style={{ fontWeight: 'bold' }}>
                        This is not the page you are looking for...
                    </Heading>
                    <div>
                        Fear is the path to the dark side. Fear leads to anger. Anger leads to hate. Hate leads to
                        suffering. - Master Yoda
                        <br />
                        <br />
                        Press the 🔄 to return to the light side...
                    </div>
                </Grid>
            )
        }

        return this.props.children
    }
}

export default ErrorBoundry
