import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'
import styles from './../../deployments/edit/Edit.module.scss'
import css from './EditFlowScaling.module.scss'
import { AllSparkContext } from '../../../..'
import { useGlobalStateContext } from '../../../../GlobalState'
import Grid from '../../../infratructure/grid/Grid'
import Button, { ButtonType } from '../../../infratructure/button/Button'
import LoaderDots from '../../../infratructure/dots/LoaderDots'
import Input from '../../../infratructure/input/Input'
import Heading from '../../../infratructure/heading/Heading'
import useAsyncError from '../../../infratructure/hooks/useAsyncError'

interface EditSettingsProps {
    onCancel: () => void
    onSave: () => void
    onClose: () => void
    isWorking: boolean
}

type EditSettingsState = {
    abafarMessageCount?: number
    abafarMemoryRequest: number
    abafarMemoryLimit: number

    anaxesMemoryRequest: number
    anaxesMemoryLimit: number

    corelliaMemoryRequest: number
    corelliaMemoryLimit: number
    corelliaCPURequest: number
    corelliaCPULimit: number

    designerMemoryRequest: number
    designerMemoryLimit: number

    portalMemoryRequest: number
    portalMemoryLimit: number

    rishiMemoryRequest: number
    rishiMemoryLimit: number

    isDone: boolean
    isWorking: boolean
    hasErrors: boolean
}

const EditSettings: FunctionComponent<EditSettingsProps> = ({ onCancel, onSave, onClose, isWorking }) => {
    const api = useContext(AllSparkContext)
    const throwError = useAsyncError()
    const { setGlobalState } = useGlobalStateContext()

    const [state, setState] = useState<EditSettingsState>({
        abafarMessageCount: 0,
        abafarMemoryRequest: 0,
        abafarMemoryLimit: 0,

        anaxesMemoryRequest: 0,
        anaxesMemoryLimit: 0,

        corelliaMemoryRequest: 0,
        corelliaMemoryLimit: 0,
        corelliaCPURequest: 0,
        corelliaCPULimit: 0,

        designerMemoryRequest: 0,
        designerMemoryLimit: 0,

        portalMemoryRequest: 0,
        portalMemoryLimit: 0,

        rishiMemoryRequest: 0,
        rishiMemoryLimit: 0,

        isDone: false,
        isWorking: isWorking,
        hasErrors: false,
    })

    const onGetData = useCallback(async () => {
        setState((prev) => ({ ...prev, isWorking: true }))
        setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: false }))

        try {
            const settings = await api.getDefaultFlowDeploymentSettings()
            if (settings) {
                setState((prev) => ({
                    ...prev,
                    isWorking: false,
                    abafarMessageCount: settings.scaling.abafar.messageCount,
                    abafarMemoryRequest: settings.scaling.abafar.memory.request,
                    abafarMemoryLimit: settings.scaling.abafar.memory.limit,
                    anaxesMemoryRequest: settings.scaling.anaxes.memory.request,
                    anaxesMemoryLimit: settings.scaling.anaxes.memory.limit,
                    corelliaMemoryRequest: settings.scaling.corellia.memory.request,
                    corelliaMemoryLimit: settings.scaling.corellia.memory.limit,
                    corelliaCPURequest:
                        settings.scaling.corellia.cpu != null ? settings.scaling.corellia.cpu.request : 100,
                    corelliaCPULimit:
                        settings.scaling.corellia.cpu != null ? settings.scaling.corellia.cpu.limit : 2000,
                    designerMemoryRequest: settings.scaling.designer.memory.request,
                    designerMemoryLimit: settings.scaling.designer.memory.limit,
                    portalMemoryRequest: settings.scaling.portal.memory.request,
                    portalMemoryLimit: settings.scaling.portal.memory.limit,
                    rishiMemoryRequest: settings.scaling.rishi.memory.request,
                    rishiMemoryLimit: settings.scaling.rishi.memory.limit,
                }))
            } else {
                setState((prev) => ({ ...prev, isWorking: false }))
            }
        } catch (error) {
            console.log(
                'Exception : Unable to load data. Press refresh in the browser or check if the backend is running !!',
                error,
            )
            setState((prev) => ({ ...prev, isWorking: false }))
            throwError(error)
        } finally {
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        }
    }, [api, setGlobalState, throwError])

    // load data on mount
    useEffect(() => {
        onGetData()
    }, [onGetData])

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.name, e.target.value)
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }))
    }

    const handleSave = async () => {
        onSave()
        setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: true }))
        try {
            setState((prev) => ({ ...prev, isWorking: true }))
            let request = {
                scaling: {
                    abafar: {
                        messageCount: state.abafarMessageCount,
                        memory: {
                            request: state.abafarMemoryRequest,
                            limit: state.abafarMemoryLimit,
                        },
                    },
                    anaxes: {
                        memory: {
                            request: state.anaxesMemoryRequest,
                            limit: state.anaxesMemoryLimit,
                        },
                    },
                    corellia: {
                        memory: {
                            request: state.corelliaMemoryRequest,
                            limit: state.corelliaMemoryLimit,
                        },
                        cpu: {
                            request: state.corelliaCPURequest,
                            limit: state.corelliaCPULimit,
                        },
                    },
                    designer: {
                        memory: {
                            request: state.designerMemoryRequest,
                            limit: state.designerMemoryLimit,
                        },
                    },
                    portal: {
                        memory: {
                            request: state.portalMemoryRequest,
                            limit: state.portalMemoryLimit,
                        },
                    },
                    rishi: {
                        memory: {
                            request: state.rishiMemoryRequest,
                            limit: state.rishiMemoryLimit,
                        },
                    },
                },
            }

            await api.setDefaultFlowDeploymentSettings(request)

            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
            setState((prev) => ({ ...prev, isDone: true, isWorking: false }))
        } catch (error) {
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
            setState((prev) => ({ ...prev, isDone: false, isWorking: false, hasErrors: true }))
        }
    }

    return (
        <Grid
            rows="auto auto auto auto auto auto auto auto auto auto auto auto auto auto 1fr"
            gap={0}
            style={{ height: '650px', marginLeft: '10px' }}
        >
            <Heading type="heading1" style={{ marginTop: '20px' }}>
                Edit Flow Default Deployment Settings
            </Heading>
            <Heading type="heading2" style={{ marginTop: '20px' }}>
                Abafar
            </Heading>
            <Grid
                rows="auto auto auto 1fr"
                columns="400px 100px"
                gap={5}
                style={{ marginTop: '15px', marginLeft: '10px' }}
            >
                <span className={css.label}>Message Count limit before scaling up</span>
                <Input
                    name="abafarMessageCount"
                    value={state.abafarMessageCount}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service Memory Request (Mi)</span>
                <Input
                    name="abafarMemoryRequest"
                    value={state.abafarMemoryRequest}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service Memory Limit (Mi)</span>
                <Input
                    name="abafarMemoryLimit"
                    value={state.abafarMemoryLimit}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
            </Grid>

            <Heading type="heading2" style={{ marginTop: '20px' }}>
                Anaxes
            </Heading>
            <Grid
                rows="auto auto auto 1fr"
                columns="400px 100px"
                gap={5}
                style={{ marginTop: '15px', marginLeft: '10px' }}
            >
                <span className={css.label}>Service Memory Request (Mi)</span>
                <Input
                    name="anaxesMemoryRequest"
                    value={state.anaxesMemoryRequest}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service Memory Limit (Mi)</span>
                <Input
                    name="anaxesMemoryLimit"
                    value={state.anaxesMemoryLimit}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
            </Grid>

            <Heading type="heading2" style={{ marginTop: '20px' }}>
                Corellia
            </Heading>
            <Grid
                rows="auto auto auto 1fr"
                columns="400px 100px"
                gap={5}
                style={{ marginTop: '15px', marginLeft: '10px' }}
            >
                <span className={css.label}>Service Memory Request (Mi)</span>
                <Input
                    name="corelliaMemoryRequest"
                    value={state.corelliaMemoryRequest}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service Memory Limit (Mi)</span>
                <Input
                    name="corelliaMemoryLimit"
                    value={state.corelliaMemoryLimit}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service CPU Request (m)</span>
                <Input
                    name="corelliaCPURequest"
                    value={state.corelliaCPURequest}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service CPU Limit (m)</span>
                <Input
                    name="corelliaCPULimit"
                    value={state.corelliaCPULimit}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />{' '}
            </Grid>

            <Heading type="heading2" style={{ marginTop: '20px' }}>
                Designer
            </Heading>
            <Grid
                rows="auto auto auto 1fr"
                columns="400px 100px"
                gap={5}
                style={{ marginTop: '15px', marginLeft: '10px' }}
            >
                <span className={css.label}>Service Memory Request (Mi)</span>
                <Input
                    name="designerMemoryRequest"
                    value={state.designerMemoryRequest}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service Memory Limit (Mi)</span>
                <Input
                    name="designerMemoryLimit"
                    value={state.designerMemoryLimit}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
            </Grid>

            <Heading type="heading2" style={{ marginTop: '20px' }}>
                Portal
            </Heading>
            <Grid
                rows="auto auto auto 1fr"
                columns="400px 100px"
                gap={5}
                style={{ marginTop: '15px', marginLeft: '10px' }}
            >
                <span className={css.label}>Service Memory Request (Mi)</span>
                <Input
                    name="portalMemoryRequest"
                    value={state.portalMemoryRequest}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service Memory Limit (Mi)</span>
                <Input
                    name="portalMemoryLimit"
                    value={state.portalMemoryLimit}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
            </Grid>

            <Heading type="heading2" style={{ marginTop: '20px' }}>
                Rishi
            </Heading>
            <Grid
                rows="auto auto auto 1fr"
                columns="400px 100px"
                gap={5}
                style={{ marginTop: '15px', marginLeft: '10px' }}
            >
                <span className={css.label}>Service Memory Request (Mi)</span>
                <Input
                    name="rishiMemoryRequest"
                    value={state.rishiMemoryRequest}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service Memory Limit (Mi)</span>
                <Input
                    name="rishiMemoryLimit"
                    value={state.rishiMemoryLimit}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
            </Grid>

            <Grid
                columns="1fr auto auto auto auto"
                style={{ height: '60px', marginTop: '20px', marginRight: '10px' }}
                gap={10}
            >
                {state.isWorking && !state.isDone ? (
                    <Grid columns="auto auto 1fr" style={{ height: '30px' }} gap={10}>
                        <Grid style={{ alignItems: 'center' }}>Updating</Grid>
                        <Grid style={{ alignItems: 'center' }}>
                            <LoaderDots color="black" />
                        </Grid>
                    </Grid>
                ) : (
                    <div />
                )}
                {!state.isWorking && state.isDone ? (
                    <Grid columns="auto 1fr" style={{ height: '30px' }} gap={10}>
                        <Grid style={{ alignItems: 'center' }}>Done!</Grid>
                        <div />
                    </Grid>
                ) : (
                    <div />
                )}
                {state.hasErrors ? (
                    <Grid columns="auto 1fr" style={{ height: '30px' }} gap={10}>
                        <Grid style={{ alignItems: 'center' }}>Failed! Check System Log for details.</Grid>
                        <div />
                    </Grid>
                ) : (
                    <div />
                )}
                {!state.isDone ? (
                    <Button
                        className={styles.deploybtn}
                        buttonType={ButtonType.Default}
                        onClick={onCancel}
                        disabled={state.isWorking}
                    >
                        Cancel
                    </Button>
                ) : (
                    <div />
                )}
                <Button
                    className={styles.deploybtn}
                    buttonType={ButtonType.Confirm}
                    onClick={state.isDone ? onClose : handleSave}
                    disabled={state.isWorking}
                >
                    {state.isDone ? 'Close' : 'Save'}
                </Button>
            </Grid>
        </Grid>
    )
}

export default EditSettings
