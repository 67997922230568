import { FunctionComponent } from 'react'
import styles from './AzureApplicationDetails.module.scss'
import CopyableInput from '../../../../infratructure/copyableInput/CopyableInput'
import { DeploymentModel } from '../../../Model/AllSparkModel'
import Grid from '../../../../infratructure/grid/Grid'
import Heading from '../../../../infratructure/heading/Heading'
import Button, { ButtonType } from '../../../../infratructure/button/Button'

interface AzureApplicationDetailProps {
    deployment: DeploymentModel
    onAzureApplicationsEdit: () => void
}

const AzureApplicationDetails: FunctionComponent<AzureApplicationDetailProps> = ({ deployment , onAzureApplicationsEdit}) => {
    return (
        <Grid columns="500px 1fr" className={styles.container}>
            <Grid rows="auto auto auto auto auto 1fr" gap={16}>
                <Grid columns="auto auto 1fr">
                    <Heading type="heading1">
                        Web Application
                    </Heading>
                    <Button
                            style={{ marginLeft: '10px'}}
                            onClick={onAzureApplicationsEdit}
                            buttonType={ButtonType.Default}
                            className={styles.btn}
                            title="Edit"
                            >
                            <Grid className={styles.btnContentWrapper}>
                                Edit
                            </Grid>
                    </Button>
                </Grid>

                <Grid rows="auto auto auto auto" gap={16} style={{ marginLeft: '10px' }}>
                    <CopyableInput
                        width={650}
                        headerText={'Name'}
                        value={deployment.appDeployment.webApp.appName || ''}
                        readOnly
                    />

                    <CopyableInput
                        width={650}
                        headerText={'Client Id'}
                        value={deployment.appDeployment.webApp.clientId || ''}
                        readOnly
                    />

                    <CopyableInput
                        width={650}
                        headerText={'Client Secret'}
                        value={deployment.appDeployment.webApp.clientSecret || ''}
                        readOnly
                    />

                    <CopyableInput
                        width={650}
                        headerText={'Secret Expiry'}
                        value={deployment.appDeployment.webApp.secretExpiry || ''}
                        readOnly
                    />
                </Grid>
                <Heading type="heading1" style={{ marginTop: '20px' }}>
                    Designer
                </Heading>
                <Grid rows="auto auto auto 1fr" gap={16} style={{ marginLeft: '10px' }}>
                    <CopyableInput
                        width={650}
                        headerText={'Name'}
                        value={deployment.appDeployment?.designer?.appName || ''}
                        readOnly
                    />

                    <CopyableInput
                        width={650}
                        headerText={'Client Id'}
                        value={deployment.appDeployment?.designer?.clientId || ''}
                        readOnly
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AzureApplicationDetails
