import cx from 'classnames'
import { IconProps } from './IconProps'

interface RebootIconProps extends IconProps {
    color?: string
}

export default function RebootIcon({ width = 24, height = 24, className, style }: RebootIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            className={cx('icon', className)}
            style={style}
            fill="none"
        >
            <path d="M12 8.5V15.5" stroke="currentcolor" strokeWidth="1.6" strokeLinecap="round" />
            <path
                d="M17.8318 4.5C16.2217 3.24608 14.1983 2.5 12 2.5C6.7533 2.5 2.5 6.75329 2.5 12C2.5 17.2467 6.7533 21.5 12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 11.5479 21.4697 11.3411 21.3993 10.861L21.399 10.8588C21.3389 10.449 21.6224 10.068 22.0322 10.0079C22.442 9.94784 22.823 10.2314 22.8831 10.6412L22.8849 10.6534C22.9568 11.1436 23 11.4384 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C14.6592 1 17.0985 1.94423 19 3.5144V1.75C19 1.33579 19.3358 1 19.75 1C20.1642 1 20.5 1.33579 20.5 1.75V5.25C20.5 5.66421 20.1642 6 19.75 6H16.25C15.8358 6 15.5 5.66421 15.5 5.25C15.5 4.83579 15.8358 4.5 16.25 4.5H17.8318Z"
                fill="currentcolor"
            />
        </svg>
    )
}
