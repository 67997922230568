export class ArrayUtil {
    public static isArrayWithItems(ar: any): ar is any[] {
        if (Array.isArray(ar)) {
            return ar && ar.length > 0
        }
        return false
    }
    public static removeItem(array: any[], item: any) {
        const index = array.indexOf(item)
        if (index > -1) {
            array.splice(index, 1)
        }
    }

    public static sortByPropCompare(prop: string, caseInsensitive = false) {
        if (caseInsensitive) {
            return (a: any, b: any) => {
                if (a[prop].toLowerCase() > b[prop].toLowerCase()) return 1
                if (a[prop].toLowerCase() < b[prop].toLowerCase()) return -1
                return 0
            }
        }

        return (a: any, b: any) => {
            if (a[prop] > b[prop]) return 1
            if (a[prop] < b[prop]) return -1
            return 0
        }
    }

    public static distinct<T>(array: T[], equalityComparer: (a: T, b: T) => boolean): T[] {
        return array.filter((value, index, ar) => ar.findIndex((f) => equalityComparer(f, value)) === index)
    }

    public static difference<T>(array: T[], notIn: T[], equalityComparer: (a: T, b: T) => boolean): T[] {
        let ar: T[] = []
        for (let item of array) {
            if (notIn.findIndex((n) => equalityComparer(n, item)) === -1) {
                ar.push(item)
            }
        }
        return ar
    }
}
