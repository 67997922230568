import { FunctionComponent, useContext, useState } from 'react'
import Input from '../../../infratructure/input/Input'
import Grid from '../../../infratructure/grid/Grid'
import Heading from '../../../infratructure/heading/Heading'
import Button, { ButtonType } from '../../../infratructure/button/Button'
import { AllSparkContext } from '../../../..'
import Spinner from '../../../infratructure/spinner/Spinner'
import { EnvironmentModel } from '../../Model/AllSparkModel'
import styles from './NewEnvironment.module.scss'
import cx from 'classnames'

interface NewEnvironmentProps {
    onAdd: (environment: EnvironmentModel) => void
}

type NewEnvironmentState = {
    isWorking: boolean
    name: string
    description: string
}

const NewEnvironment: FunctionComponent<NewEnvironmentProps> = ({ onAdd }) => {
    const api = useContext(AllSparkContext)

    const [{ isWorking, name, description }, setState] = useState<NewEnvironmentState>({
        isWorking: false,
        name: '',
        description: '',
    })

    const handleAdd = async () => {
        try {
            setState((prev) => ({ ...prev, isWorking: true }))

            const addedEnvironment = await api.createOrUpdateEnvironment({
                name: name,
                description: description,
                clusterController: '',
                azureDeploymentEnvironment: {
                    deployment: {
                        authorization: {
                            identityProvider: { authority: '', canInvite: true },
                            openIdConnect: { authority: '', clientId: '', clientSecret: '', tenantId: '' },
                        },
                        resources: {
                            clientId: '',
                            clientSecret: '',
                            resourceGroupId: '',
                            serviceBusNamespace: '',
                            storageAccountName: '',
                            tenantId: '',
                            azureKubernetesServices: {
                                clusterName: '',
                                resoureGroupId: '',
                                hostName: '',
                            },
                            azureGatewayCertificateName: '',
                            azureGatewayRewriteRuleName: '',
                            keyVaultUri: '',
                            azureSql: {
                                resourceGroupId: '',
                                elasticPoolName: '',
                                serverAdminPassword: '',
                                serverAdminUserName: '',
                                serverName: '',
                            },
                        },
                    },
                },
            })

            if (addedEnvironment) {
                onAdd(addedEnvironment)
                setState((prev) => ({ ...prev, isWorking: false }))
            }
        } catch (error) {
            console.log(error)
        }
    }

    const isNameValid = () => {
        if (!name || name.length === 0) {
            return false
        }

        return true
    }

    return (
        <Grid rows="auto 1fr auto">
            <Heading type="heading1">Add Environment</Heading>
            <Grid rows="1fr auto auto">
                <Grid className={styles.main} gap={20}>
                    <Input
                        autoFocus={true}
                        headerText={'Name'}
                        style={{ width: 'inherit' }} // fixes bug? in main source
                        value={name || ''}
                        onChange={(e) => setState((prev) => ({ ...prev, name: e.target.value }))}
                        className={cx({
                            [styles.requiredInput]: !isNameValid,
                        })}
                    />
                    <Input
                        headerText={'Description'}
                        style={{ width: 'inherit' }} // fixes bug? in main source
                        value={description || ''}
                        onChange={(e) => setState((prev) => ({ ...prev, description: e.target.value }))}
                    />
                </Grid>
            </Grid>

            <Grid columns="1fr auto" gap={8} className={styles.footer}>
                <div></div>
                <Button
                    className={styles.deploybtn}
                    buttonType={ButtonType.Confirm}
                    onClick={handleAdd}
                    disabled={!isNameValid}
                >
                    {isWorking ? <Spinner size={18} color={'#FFFF'} /> : 'Create'}
                </Button>
            </Grid>
        </Grid>
    )
}

export default NewEnvironment
