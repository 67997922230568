import _ from 'lodash'
import { FunctionComponent } from 'react'
import { ClusterController, EnvironmentModel } from '../Model/AllSparkModel'
import Select from '../../infratructure/select/Select'
import CopyableInput from '../../infratructure/copyableInput/CopyableInput'
import Grid from '../../infratructure/grid/Grid'
import Heading from '../../infratructure/heading/Heading'
import styles from './EnvironmentDetails.module.scss'

interface EnvironmentDetailProps {
    selectedEnvironment?: EnvironmentModel
    clusterControllers: ClusterController[]
    onPropertyChange: (environment: EnvironmentModel) => void
}

const EnvironmentDetails: FunctionComponent<EnvironmentDetailProps> = ({
    selectedEnvironment,
    clusterControllers,
    onPropertyChange,
}) => {
    return (
        <Grid className={styles.container}>
            <Grid gap={16} style={{ marginBottom: '80px' }}>
                <Heading type="heading2">{`Environment - ${selectedEnvironment?.name}`}</Heading>
                <Grid gap={16} className={styles.leftGap}>
                    <CopyableInput
                        headerText={'Name'}
                        width={650}
                        value={selectedEnvironment?.name || ''}
                        onChange={(e) => {
                            if (selectedEnvironment) {
                                const newEnvironment: EnvironmentModel = _.cloneDeep(selectedEnvironment)
                                newEnvironment.name = e.target.value
                                onPropertyChange(newEnvironment)
                            }
                        }}
                    />
                    <CopyableInput
                        headerText={'Description'}
                        width={650}
                        value={selectedEnvironment?.description || ''}
                        onChange={(e) => {
                            if (selectedEnvironment) {
                                const newEnvironment: EnvironmentModel = _.cloneDeep(selectedEnvironment)
                                newEnvironment.description = e.target.value
                                onPropertyChange(newEnvironment)
                            }
                        }}
                    />
                    <Grid gap={4} style={{ width: '650px' }}>
                        <Select
                            headerText="Cluster controller"
                            isClearable={false}
                            options={clusterControllers.map((c) => ({ value: c.name, label: c.name }))}
                            value={selectedEnvironment?.clusterController}
                            onChange={(e) => {
                                if (selectedEnvironment) {
                                    const newEnvironment: EnvironmentModel = _.cloneDeep(selectedEnvironment)
                                    newEnvironment.clusterController = e.value
                                    onPropertyChange(newEnvironment)
                                }
                            }}
                            placeholder="Cluster controller"
                            isDisabled={false}
                        />
                    </Grid>
                </Grid>

                <Heading type="heading2" className={styles.topGap}>
                    Authorization Settings
                </Heading>
                <Grid gap={16} className={styles.leftGap}>
                    <CopyableInput
                        headerText={'Identity Provider Authority'}
                        width={650}
                        value={
                            selectedEnvironment?.azureDeploymentEnvironment?.deployment.authorization.identityProvider
                                .authority || ''
                        }
                        onChange={(e) => {
                            if (selectedEnvironment && selectedEnvironment.azureDeploymentEnvironment) {
                                const newEnvironment: EnvironmentModel = _.cloneDeep(selectedEnvironment)
                                if (newEnvironment.azureDeploymentEnvironment) {
                                    newEnvironment.azureDeploymentEnvironment.deployment.authorization.identityProvider.authority =
                                        e.target.value
                                }
                                onPropertyChange(newEnvironment)
                            }
                        }}
                    />
                    <Grid gap={4} style={{ width: '650px' }}>
                        <Select
                            headerText="Identity Provider Can Invite"
                            isClearable={false}
                            options={[
                                { value: 1, label: 'Yes' },
                                { value: 0, label: 'No' },
                            ]}
                            value={
                                selectedEnvironment?.azureDeploymentEnvironment.deployment.authorization
                                    .identityProvider.canInvite
                                    ? 1
                                    : 0
                            }
                            onChange={(e) => {
                                if (selectedEnvironment && selectedEnvironment.azureDeploymentEnvironment) {
                                    const newEnvironment: EnvironmentModel = _.cloneDeep(selectedEnvironment)
                                    if (newEnvironment.azureDeploymentEnvironment) {
                                        newEnvironment.azureDeploymentEnvironment.deployment.authorization.identityProvider.canInvite =
                                            Boolean(e.value)
                                    }
                                    onPropertyChange(newEnvironment)
                                }
                            }}
                            placeholder="Can invite"
                            isDisabled={false}
                        />
                    </Grid>

                    <Heading type="heading2" className={styles.topGap}>
                        Open Id Connect Settings
                    </Heading>
                    <Grid gap={16} className={styles.leftGap}>
                        <CopyableInput
                            headerText={'Tenant Id'}
                            width={650}
                            value={
                                selectedEnvironment?.azureDeploymentEnvironment?.deployment.authorization.openIdConnect
                                    .tenantId || ''
                            }
                            onChange={(e) => {
                                if (selectedEnvironment && selectedEnvironment.azureDeploymentEnvironment) {
                                    const newEnvironment: EnvironmentModel = _.cloneDeep(selectedEnvironment)
                                    if (newEnvironment.azureDeploymentEnvironment) {
                                        newEnvironment.azureDeploymentEnvironment.deployment.authorization.openIdConnect.tenantId =
                                            e.target.value
                                    }
                                    onPropertyChange(newEnvironment)
                                }
                            }}
                        />
                        <CopyableInput
                            headerText={'Client Id'}
                            width={650}
                            value={
                                selectedEnvironment?.azureDeploymentEnvironment?.deployment.authorization.openIdConnect
                                    .clientId || ''
                            }
                            onChange={(e) => {
                                if (selectedEnvironment && selectedEnvironment.azureDeploymentEnvironment) {
                                    const newEnvironment: EnvironmentModel = _.cloneDeep(selectedEnvironment)
                                    if (newEnvironment.azureDeploymentEnvironment) {
                                        newEnvironment.azureDeploymentEnvironment.deployment.authorization.openIdConnect.clientId =
                                            e.target.value
                                    }
                                    onPropertyChange(newEnvironment)
                                }
                            }}
                        />
                        <CopyableInput
                            headerText={'Client Secret'}
                            width={650}
                            value={
                                selectedEnvironment?.azureDeploymentEnvironment?.deployment.authorization.openIdConnect
                                    .clientSecret || ''
                            }
                            type="password"
                            onChange={(e) => {
                                if (selectedEnvironment && selectedEnvironment.azureDeploymentEnvironment) {
                                    const newEnvironment: EnvironmentModel = _.cloneDeep(selectedEnvironment)
                                    if (newEnvironment.azureDeploymentEnvironment) {
                                        newEnvironment.azureDeploymentEnvironment.deployment.authorization.openIdConnect.clientSecret =
                                            e.target.value
                                    }
                                    onPropertyChange(newEnvironment)
                                }
                            }}
                        />
                        <CopyableInput
                            headerText={'Authority'}
                            width={650}
                            value={
                                selectedEnvironment?.azureDeploymentEnvironment?.deployment.authorization.openIdConnect
                                    .authority || ''
                            }
                            onChange={(e) => {
                                if (selectedEnvironment && selectedEnvironment.azureDeploymentEnvironment) {
                                    const newEnvironment: EnvironmentModel = _.cloneDeep(selectedEnvironment)
                                    if (newEnvironment.azureDeploymentEnvironment) {
                                        newEnvironment.azureDeploymentEnvironment.deployment.authorization.openIdConnect.authority =
                                            e.target.value
                                    }
                                    onPropertyChange(newEnvironment)
                                }
                            }}
                        />
                    </Grid>
                </Grid>

                <Heading type="heading2" className={styles.topGap}>
                    Azure Resources
                </Heading>
                <Grid gap={16} className={styles.leftGap}>
                    <CopyableInput
                        headerText={'Tenant Id'}
                        width={650}
                        value={selectedEnvironment?.azureDeploymentEnvironment?.deployment.resources.tenantId || ''}
                        onChange={(e) => {
                            if (selectedEnvironment && selectedEnvironment.azureDeploymentEnvironment) {
                                const newEnvironment: EnvironmentModel = _.cloneDeep(selectedEnvironment)
                                if (newEnvironment.azureDeploymentEnvironment) {
                                    newEnvironment.azureDeploymentEnvironment.deployment.resources.tenantId =
                                        e.target.value
                                }
                                onPropertyChange(newEnvironment)
                            }
                        }}
                    />

                    <CopyableInput
                        headerText={'Client Id'}
                        width={650}
                        value={selectedEnvironment?.azureDeploymentEnvironment?.deployment.resources.clientId || ''}
                        onChange={(e) => {
                            if (selectedEnvironment && selectedEnvironment.azureDeploymentEnvironment) {
                                const newEnvironment: EnvironmentModel = _.cloneDeep(selectedEnvironment)
                                if (newEnvironment.azureDeploymentEnvironment) {
                                    newEnvironment.azureDeploymentEnvironment.deployment.resources.clientId =
                                        e.target.value
                                }
                                onPropertyChange(newEnvironment)
                            }
                        }}
                    />

                    <CopyableInput
                        headerText={'Client Secret'}
                        width={650}
                        value={selectedEnvironment?.azureDeploymentEnvironment?.deployment.resources.clientSecret || ''}
                        type="password"
                        onChange={(e) => {
                            if (selectedEnvironment && selectedEnvironment.azureDeploymentEnvironment) {
                                const newEnvironment: EnvironmentModel = _.cloneDeep(selectedEnvironment)
                                if (newEnvironment.azureDeploymentEnvironment) {
                                    newEnvironment.azureDeploymentEnvironment.deployment.resources.clientSecret =
                                        e.target.value
                                }
                                onPropertyChange(newEnvironment)
                            }
                        }}
                    />

                    <CopyableInput
                        headerText={'Resource group Id (default)'}
                        width={650}
                        value={
                            selectedEnvironment?.azureDeploymentEnvironment?.deployment.resources.resourceGroupId || ''
                        }
                        onChange={(e) => {
                            if (selectedEnvironment && selectedEnvironment.azureDeploymentEnvironment) {
                                const newEnvironment: EnvironmentModel = _.cloneDeep(selectedEnvironment)
                                if (newEnvironment.azureDeploymentEnvironment) {
                                    newEnvironment.azureDeploymentEnvironment.deployment.resources.resourceGroupId =
                                        e.target.value
                                }
                                onPropertyChange(newEnvironment)
                            }
                        }}
                    />

                    <CopyableInput
                        headerText={'Storage Account Name'}
                        width={650}
                        value={
                            selectedEnvironment?.azureDeploymentEnvironment?.deployment.resources.storageAccountName ||
                            ''
                        }
                        onChange={(e) => {
                            if (selectedEnvironment && selectedEnvironment.azureDeploymentEnvironment) {
                                const newEnvironment: EnvironmentModel = _.cloneDeep(selectedEnvironment)
                                if (newEnvironment.azureDeploymentEnvironment) {
                                    newEnvironment.azureDeploymentEnvironment.deployment.resources.storageAccountName =
                                        e.target.value
                                }
                                onPropertyChange(newEnvironment)
                            }
                        }}
                    />

                    <CopyableInput
                        headerText={'Servicebus Namespace'}
                        width={650}
                        value={
                            selectedEnvironment?.azureDeploymentEnvironment?.deployment.resources.serviceBusNamespace ||
                            ''
                        }
                        onChange={(e) => {
                            if (selectedEnvironment && selectedEnvironment.azureDeploymentEnvironment) {
                                const newEnvironment: EnvironmentModel = _.cloneDeep(selectedEnvironment)
                                if (newEnvironment.azureDeploymentEnvironment) {
                                    newEnvironment.azureDeploymentEnvironment.deployment.resources.serviceBusNamespace =
                                        e.target.value
                                }
                                onPropertyChange(newEnvironment)
                            }
                        }}
                    />

                    <CopyableInput
                        headerText={'Key Vault Uri'}
                        width={650}
                        value={selectedEnvironment?.azureDeploymentEnvironment?.deployment.resources.keyVaultUri || ''}
                        onChange={(e) => {
                            if (selectedEnvironment && selectedEnvironment.azureDeploymentEnvironment) {
                                const newEnvironment: EnvironmentModel = _.cloneDeep(selectedEnvironment)
                                if (newEnvironment.azureDeploymentEnvironment) {
                                    newEnvironment.azureDeploymentEnvironment.deployment.resources.keyVaultUri =
                                        e.target.value
                                }
                                onPropertyChange(newEnvironment)
                            }
                        }}
                    />
                </Grid>

                <Heading type="heading2" className={styles.topGap}>
                    Azure SQL Settings
                </Heading>
                <Grid gap={16} className={styles.leftGap}>
                    <CopyableInput
                        headerText={'Resource group Id override (optional)'}
                        width={650}
                        value={
                            selectedEnvironment?.azureDeploymentEnvironment?.deployment.resources.azureSql
                                .resourceGroupId || ''
                        }
                        onChange={(e) => {
                            if (selectedEnvironment && selectedEnvironment.azureDeploymentEnvironment) {
                                const newEnvironment: EnvironmentModel = _.cloneDeep(selectedEnvironment)
                                if (newEnvironment.azureDeploymentEnvironment) {
                                    newEnvironment.azureDeploymentEnvironment.deployment.resources.azureSql.resourceGroupId =
                                        e.target.value
                                }
                                onPropertyChange(newEnvironment)
                            }
                        }}
                    />
                    <CopyableInput
                        headerText={'Elastic Pool Name'}
                        width={650}
                        value={
                            selectedEnvironment?.azureDeploymentEnvironment?.deployment.resources.azureSql
                                .elasticPoolName || ''
                        }
                        onChange={(e) => {
                            if (selectedEnvironment && selectedEnvironment.azureDeploymentEnvironment) {
                                const newEnvironment: EnvironmentModel = _.cloneDeep(selectedEnvironment)
                                if (newEnvironment.azureDeploymentEnvironment) {
                                    newEnvironment.azureDeploymentEnvironment.deployment.resources.azureSql.elasticPoolName =
                                        e.target.value
                                }
                                onPropertyChange(newEnvironment)
                            }
                        }}
                    />
                    <CopyableInput
                        headerText={'Server name'}
                        width={650}
                        value={
                            selectedEnvironment?.azureDeploymentEnvironment?.deployment.resources.azureSql.serverName ||
                            ''
                        }
                        onChange={(e) => {
                            if (selectedEnvironment && selectedEnvironment.azureDeploymentEnvironment) {
                                const newEnvironment: EnvironmentModel = _.cloneDeep(selectedEnvironment)
                                if (newEnvironment.azureDeploymentEnvironment) {
                                    newEnvironment.azureDeploymentEnvironment.deployment.resources.azureSql.serverName =
                                        e.target.value
                                }
                                onPropertyChange(newEnvironment)
                            }
                        }}
                    />

                    <CopyableInput
                        headerText={'Server Admin Username'}
                        width={650}
                        value={
                            selectedEnvironment?.azureDeploymentEnvironment?.deployment.resources.azureSql
                                .serverAdminUserName || ''
                        }
                        onChange={(e) => {
                            if (selectedEnvironment && selectedEnvironment.azureDeploymentEnvironment) {
                                const newEnvironment: EnvironmentModel = _.cloneDeep(selectedEnvironment)
                                if (newEnvironment.azureDeploymentEnvironment) {
                                    newEnvironment.azureDeploymentEnvironment.deployment.resources.azureSql.serverAdminUserName =
                                        e.target.value
                                }
                                onPropertyChange(newEnvironment)
                            }
                        }}
                    />
                    <CopyableInput
                        headerText={'Server Admin Password'}
                        width={650}
                        value={
                            selectedEnvironment?.azureDeploymentEnvironment?.deployment.resources.azureSql
                                .serverAdminPassword || ''
                        }
                        type="password"
                        onChange={(e) => {
                            if (selectedEnvironment && selectedEnvironment.azureDeploymentEnvironment) {
                                const newEnvironment: EnvironmentModel = _.cloneDeep(selectedEnvironment)
                                if (newEnvironment.azureDeploymentEnvironment) {
                                    newEnvironment.azureDeploymentEnvironment.deployment.resources.azureSql.serverAdminPassword =
                                        e.target.value
                                }
                                onPropertyChange(newEnvironment)
                            }
                        }}
                    />
                </Grid>

                <Heading type="heading2" className={styles.topGap}>
                    Kubernetes Settings
                </Heading>
                <Grid gap={16} className={styles.leftGap}>
                    <CopyableInput
                        headerText={'Resource Group Id'}
                        width={650}
                        value={
                            selectedEnvironment?.azureDeploymentEnvironment?.deployment.resources
                                .azureKubernetesServices.resoureGroupId || ''
                        }
                        onChange={(e) => {
                            if (selectedEnvironment && selectedEnvironment.azureDeploymentEnvironment) {
                                const newEnvironment: EnvironmentModel = _.cloneDeep(selectedEnvironment)
                                if (newEnvironment.azureDeploymentEnvironment) {
                                    newEnvironment.azureDeploymentEnvironment.deployment.resources.azureKubernetesServices.resoureGroupId =
                                        e.target.value
                                }
                                onPropertyChange(newEnvironment)
                            }
                        }}
                    />
                    <CopyableInput
                        headerText={'Host name'}
                        width={650}
                        value={
                            selectedEnvironment?.azureDeploymentEnvironment?.deployment.resources
                                .azureKubernetesServices.hostName || ''
                        }
                        onChange={(e) => {
                            if (selectedEnvironment && selectedEnvironment.azureDeploymentEnvironment) {
                                const newEnvironment: EnvironmentModel = _.cloneDeep(selectedEnvironment)
                                if (newEnvironment.azureDeploymentEnvironment) {
                                    newEnvironment.azureDeploymentEnvironment.deployment.resources.azureKubernetesServices.hostName =
                                        e.target.value
                                }
                                onPropertyChange(newEnvironment)
                            }
                        }}
                    />
                    <CopyableInput
                        headerText={'Cluster Name'}
                        width={650}
                        value={
                            selectedEnvironment?.azureDeploymentEnvironment?.deployment.resources
                                .azureKubernetesServices.clusterName || ''
                        }
                        onChange={(e) => {
                            if (selectedEnvironment && selectedEnvironment.azureDeploymentEnvironment) {
                                const newEnvironment: EnvironmentModel = _.cloneDeep(selectedEnvironment)
                                if (newEnvironment.azureDeploymentEnvironment) {
                                    newEnvironment.azureDeploymentEnvironment.deployment.resources.azureKubernetesServices.clusterName =
                                        e.target.value
                                }
                                onPropertyChange(newEnvironment)
                            }
                        }}
                    />
                </Grid>

                <Heading type="heading2" className={styles.topGap}>
                    Azure Gateway Settings
                </Heading>

                <CopyableInput
                    headerText={'SSL Certificate Name'}
                    width={650}
                    value={
                        selectedEnvironment?.azureDeploymentEnvironment?.deployment.resources
                            .azureGatewayCertificateName || ''
                    }
                    onChange={(e) => {
                        if (selectedEnvironment && selectedEnvironment.azureDeploymentEnvironment) {
                            const newEnvironment: EnvironmentModel = _.cloneDeep(selectedEnvironment)
                            if (newEnvironment.azureDeploymentEnvironment) {
                                newEnvironment.azureDeploymentEnvironment.deployment.resources.azureGatewayCertificateName =
                                    e.target.value
                            }
                            onPropertyChange(newEnvironment)
                        }
                    }}
                />
                <CopyableInput
                    headerText={'Rewrite Rules'}
                    width={650}
                    value={
                        selectedEnvironment?.azureDeploymentEnvironment?.deployment.resources
                            .azureGatewayRewriteRuleName || ''
                    }
                    onChange={(e) => {
                        if (selectedEnvironment && selectedEnvironment.azureDeploymentEnvironment) {
                            const newEnvironment: EnvironmentModel = _.cloneDeep(selectedEnvironment)
                            if (newEnvironment.azureDeploymentEnvironment) {
                                newEnvironment.azureDeploymentEnvironment.deployment.resources.azureGatewayRewriteRuleName =
                                    e.target.value
                            }
                            onPropertyChange(newEnvironment)
                        }
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default EnvironmentDetails
