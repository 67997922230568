import { FunctionComponent } from 'react'
import { FlowInstanceModel } from '../../Model/AllSparkModel'
import Grid from '../../../infratructure/grid/Grid'
import Heading from '../../../infratructure/heading/Heading'
import CopyableInput from '../../../infratructure/copyableInput/CopyableInput'
import Button, { ButtonType } from '../../../infratructure/button/Button'
import styles from './FlowInstanceDetails.module.scss'

interface FlowInstanceDetailsServicebusProps {
    instance?: FlowInstanceModel
    onAzureServiceBusEdit: () => void
}

const FlowInstanceDetailsServicebus: FunctionComponent<FlowInstanceDetailsServicebusProps> = ({
    instance,
    onAzureServiceBusEdit,
}) => {
    return (
        <Grid columns="400px 1fr">
            <Grid rows="auto 1fr" gap={16}>
                <Grid columns="auto auto 1fr">
                    <Heading type="heading1">Service Bus Content</Heading>
                    <Button
                        style={{ marginLeft: '10px' }}
                        onClick={onAzureServiceBusEdit}
                        buttonType={ButtonType.Default}
                        className={styles.btn}
                        title="Edit"
                    >
                        <Grid className={styles.btnContentWrapper}>Edit</Grid>
                    </Button>
                </Grid>
                <Grid rows="auto auto auto auto auto 1fr" gap={16} style={{ marginLeft: '10px' }}>
                    <CopyableInput
                        headerText={'Broadcast Topic Name'}
                        width={650}
                        value={instance?.flowServiceBus?.broadCastTopic.name || ''}
                        readOnly
                    />
                    <CopyableInput
                        headerText={'Broadcast Topic ConnectionString'}
                        width={650}
                        value={instance?.flowServiceBus?.broadCastTopic.connectionString || ''}
                        readOnly
                    />
                    <CopyableInput
                        headerText={'Broadcast Topic Authorization Rule Name'}
                        width={650}
                        value={instance?.flowServiceBus?.broadCastTopic.authorizationRuleName || ''}
                        readOnly
                    />
                    <CopyableInput
                        headerText={'Execution Queue Name'}
                        width={650}
                        value={instance?.flowServiceBus?.executionQueue.name || ''}
                        readOnly
                    />
                    <CopyableInput
                        headerText={'Execution Queue ConnectionString'}
                        width={650}
                        value={instance?.flowServiceBus?.executionQueue.connectionString || ''}
                        readOnly
                    />
                    <CopyableInput
                        headerText={'Execution Queue Authorization Rule Name'}
                        width={650}
                        value={instance?.flowServiceBus?.executionQueue.authorizationRuleName || ''}
                        readOnly
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FlowInstanceDetailsServicebus
