import React from 'react'
import Button, { ButtonType } from '../button/Button'
import styles from './ButtonNavigator.module.scss'
import cx from 'classnames'
import Heading from '../heading/Heading'
import Grid from '../grid/Grid'

export type NavButton = {
    id: string
    caption?: string
    icon?: JSX.Element
}

type fn = (id: any) => boolean

type Props = {
    className?: string
    buttons: NavButton[]
    onButtonClicked: (buttonId: string) => void
    selectedButtonId: string
    spacing?: 'normal' | 'compact'
    isDisabled?: fn | boolean
    fontSize?: number
}

export default function ButtonNavigator({
    className,
    buttons = [],
    onButtonClicked,
    selectedButtonId,
    spacing = 'normal',
    isDisabled,
    fontSize = 0.9,
}: Props) {
    if (buttons.length === 0) {
        return <div>No buttons defined.</div>
    }

    const handleButtonClick = (id: string) => {
        onButtonClicked(id)
    }

    return (
        <div className={cx(className, styles.componentContainer, { [styles.normalSpacing]: spacing === 'normal' })}>
            {buttons.map((btn, i) => (
                <Button
                    key={btn.id}
                    className={cx(styles.btn, { [styles.selected]: btn.id === selectedButtonId })}
                    buttonType={ButtonType.Icon}
                    onClick={() => handleButtonClick(btn.id)}
                    disabled={
                        isDisabled ? (typeof isDisabled === 'boolean' ? isDisabled : isDisabled(btn.id)) : undefined
                    }
                >
                    <Grid columns="auto 1fr" className={styles.buttonContentContainer}>
                        {btn.icon}
                        <Heading
                            type="heading1"
                            className={cx({ [styles.iconTextSpace]: Boolean(btn.icon) })}
                            style={{ fontSize: fontSize + 'em', fontWeight: 'bold' }}
                        >
                            {btn.caption}
                        </Heading>
                    </Grid>
                </Button>
            ))}
        </div>
    )
}
