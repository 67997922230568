import { FunctionComponent, useContext, useState } from "react"
import { ProductType, Toggle } from "../Model/AllSparkModel"
import Grid from "../../infratructure/grid/Grid"
import Heading from "../../infratructure/heading/Heading"
import styles from './Confirmation.module.scss'
import { AllSparkContext } from "../../.."
import { useGlobalStateContext } from "../../../GlobalState"
import Button, { ButtonType } from "../../infratructure/button/Button"
import LoaderDots from "../../infratructure/dots/LoaderDots"
import WarningIcon from "../../icons/WarningIcon"

interface ConfirmationProps {  
    toggle: Toggle  
    deploymentId: string
    deploymentName: string
    productType: ProductType
    onClose: () => void
    onCancel: () => void
    isInUse: boolean
}

type ConfirmationState = {
    isWorking: boolean
    isFinished: boolean
    hasErrors: boolean
    deploymentName: string
}

const Confirmation: FunctionComponent<ConfirmationProps> = ({
    toggle,
    deploymentId,
    deploymentName,
    productType,
    onClose,
    onCancel,
    isInUse
}) => {

    const api = useContext(AllSparkContext)
    const {
        setGlobalState,
    } = useGlobalStateContext()
    
    const [state, setState] = useState<ConfirmationState>({
        isWorking: false,
        isFinished: false,
        hasErrors: false,
        deploymentName: deploymentName
    })

    const onToggle = async () => {
        try {
            setState((prev) => ({ ...prev, isWorking: true, isFinished: false, deploymentName: deploymentName, hasErrors: false }))
            setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: true }))
            
            let result = await api.toggleDeployment(toggle, deploymentId, productType)
            if (!result.success){
                setState((prev) => ({ ...prev, isWorking: false, hasErrors: true }))
            }
            else{
                setState((prev) => ({ ...prev, isWorking: false, isFinished: true }))
            }

            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))

        } catch (error) {
            console.log('Disabling failed !!', error)
            setState((prev) => ({ ...prev, isWorking: false, hasErrors: true }))
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        }
    }

    return (
        <Grid rows="auto 1fr auto" className={styles.contentHeight}>
            <Heading type="heading2">
                {`${productType === ProductType.Flow ? 'Flow Instance' : 'Deployment'} - ${deploymentName}`}
            </Heading>

            {!state.isWorking && !state.isFinished && (
                <Grid rows="auto auto auto" className={styles.centerSelf}>
                    <div className={styles.centerSelf}>
                        {`Are you sure you want to ${Toggle[toggle].toString().toLocaleLowerCase()} '${deploymentName}' ?`}                    
                    </div>
                    {toggle === Toggle.Disable ?
                        <div className={styles.centerSelf} style={{ marginTop: '20px'}}>
                            This will remove the services, but retain all data.
                        </div>
                        :
                        <div className={styles.centerSelf} style={{ marginTop: '20px'}}>
                            This will recreate the services, and connect the existing data.
                        </div>
                    }

                    {isInUse && productType === ProductType.Invision && toggle === Toggle.Enable &&  (
                        <Grid columns="auto auto" className={styles.inuse} gap={10}>
                            <WarningIcon/>
                            <div>This instance is using a Flow instance which is disabled!</div>
                        </Grid>
                    )}
                    
                    {isInUse && productType === ProductType.Flow && toggle === Toggle.Disable &&  (
                        <Grid columns="auto auto" className={styles.inuse} gap={10}>
                            <WarningIcon/>
                            <div>This instance is in use by other instances, hence they will stop working!</div>
                        </Grid>
                    )}
                </Grid>
            )}

            {!state.isWorking && state.isFinished &&(
                <div className={styles.centerSelf}>
                    {`Instance '${state.deploymentName}' has been '${ toggle == Toggle.Disable ? "disabled" : "enabled"}'.`}
                </div>
            )}

            {state.isWorking && (
                <Grid columns="auto 1fr" className={styles.centerSelf} gap={16}>
                    <div className={styles.centerSelf}>{ toggle == Toggle.Disable ? "Disabling" : "Enabling"}</div>
                    <LoaderDots color={'black'} />
                </Grid>
            )}

            {!state.isWorking && !state.isFinished &&(
                <Grid columns="1fr auto auto" className={styles.footer}>
                    {state.hasErrors ?
                        <div className={styles.failed}>{ toggle == Toggle.Disable ? "Disabling" : "Enabling"} failed. Check system log.</div>
                        :
                        <div/>
                    }
                    {!state.isFinished ?
                        <Button
                            className={styles.btn}
                            buttonType={ButtonType.Default}
                            onClick={onCancel}
                            disabled={state.isWorking}
                            style={{ marginRight: '10px', alignItems: 'center'}}
                            >
                                Cancel
                        </Button>
                        :
                        <div/>
                    }
                    <Button className={styles.btn} buttonType={ButtonType.Confirm} onClick={onToggle}>
                        {Toggle[toggle]}
                    </Button>
                </Grid>
            )}

            {!state.isWorking && state.isFinished &&(
                <Grid columns="1fr auto" className={styles.footer}>
                    <div />
                    <Button className={styles.btn} buttonType={ButtonType.Confirm} onClick={onClose}>
                        Close
                    </Button>
                </Grid>
            )}

        </Grid>

    )
}

export default Confirmation