import { FunctionComponent, useMemo, useState } from "react"
import { LogLevel, Source, SystemLog } from "../Model/AllSparkModel"
import Grid from "../../infratructure/grid/Grid"
import List, { ListItemProps } from "../../infratructure/list/List"
import systemLogStyles from "./SystemLogDetails.module.scss"
import commonListStyles from './../CommonList.module.scss'
import Alert from '../../infratructure/alert/Alert'
import Warning from '../deployments/Warning'
import Ok from '../../infratructure/ok/Ok'

interface SystemLogDetailsProps {
    items: SystemLog[]
    selected: SystemLog | undefined
}

type SystemLogDetailsState = {
    selectedSystemLog: SystemLog | undefined
    logdata: string | undefined
}

const SystemLogDetails: FunctionComponent<SystemLogDetailsProps> = ({
    items,
    selected
}) =>{
    
    const [{selectedSystemLog, logdata}, setState] = useState<SystemLogDetailsState>({
        selectedSystemLog: selected,
        logdata: undefined
    })

    const SystemLogItemLayout = ({ item }: ListItemProps<SystemLog>) => {
        let formattedDate = formatDate(item.timestamp, false)

        return (
            <Grid columns="auto 1fr" className={commonListStyles.item} gap={5}>
                {item?.logLevel === LogLevel.Error && (
                    <Alert alertMessage="Error" />
                )}
                {item?.logLevel === LogLevel.Warning && (
                    <Warning warningMessage=" Warning" />
                )}
                {item?.logLevel !== LogLevel.Error && item?.logLevel !== LogLevel.Warning && (
                    <Ok okMessage={translateLoglevel(item?.logLevel)} />
                )}
                <Grid style={{ alignItems: 'center'}}>{`${formattedDate}`}: {`${translateLoglevel(item.logLevel)}`}</Grid>
            </Grid>
        )
    }

    const onSelectedChange = (log: SystemLog) => {
        let logdata = ""
        log.logs.map(lg => (
            logdata = logdata + lg + "\n"
        ))

        setState((prev) => ({ ...prev, selectedSystemLog: log, logdata: logdata}))
    }

    function formatDate(d: Date, utc: boolean){
        let date = new Date(d)

        if (utc){
            return date.getUTCFullYear() + "-" + 
            (date.getUTCMonth()+1).toString().padStart(2, '0') + "-" + 
            date.getUTCDate().toString().padStart(2, '0') + " " + 
            date.getUTCHours().toString().padStart(2, '0') + ":" + 
            date.getUTCMinutes().toString().padStart(2, '0') + ":" + 
            date.getUTCSeconds().toString().padStart(2, '0')
        }

        return date.getFullYear() + "-" + 
               (date.getMonth()+1).toString().padStart(2, '0') + "-" + 
               date.getDate().toString().padStart(2, '0') + " " + 
               (utc ? date.getUTCHours() :  date.getHours()).toString().padStart(2, '0') + ":" + 
               date.getMinutes().toString().padStart(2, '0') + ":" + 
               date.getSeconds().toString().padStart(2, '0')
    }

    function translateLoglevel(loglevel: LogLevel | undefined){
        if (loglevel === undefined){
            return ""
        }

        switch (loglevel) {
            case LogLevel.Error: return "Error";
            case LogLevel.Info: return "Information"
            case LogLevel.Warning: return "Warning"
            default: return ""
        }
    }

    function translateSource(source: Source | undefined){
        if (source === undefined){
            return "Undetermined source"
        }

        switch (source) {
            case Source.ApplicationDeployer: return "Application Deployer"
            case Source.ApplicationRegistrationService: return "Application Registration Service"
            case Source.ApplicationService: return "Application Service"
            case Source.ContainerRegistryService: return "Container Registration Service"
            case Source.DatabaseDeployer: return "Database Deployer"
            case Source.DeleteService: return "Delete Service"
            case Source.DeploymentService: return "Deployment Service"
            case Source.General: return "General"
            case Source.HelmChartService: return "Helm Chart Service"
            case Source.HelmScriptDeployer: return "Helm Script Deployer"
            case Source.HelmScriptUpdater: return "Helm Script Updater"
            case Source.ServiceBusDeployer: return "Servicebus Deployer"
            case Source.ServiceBusService: return "Servicebus Service"
            case Source.SqlService: return "Sql Service"
            case Source.StorageDeployer: return "Storage Deployer"
            case Source.StorageService: return "Storage Service"
            case Source.UpdateService: return "Update Service"
            case Source.FlowDeploymentService: return "Flow Deployment Service"
            case Source.FlowService: return "Flow Service"
            default: return ""
        }
    }

    const selectedItems = useMemo(
        () => items.find((l) => l.timestamp === selectedSystemLog?.timestamp),
        [items, selectedSystemLog?.timestamp]
    )

    return (
        <Grid>
            {items.some(x => x) && (
                <Grid columns="280px 1fr">
                    <List
                        className={commonListStyles.list}
                        item={SystemLogItemLayout}
                        spacing="compact"
                        itemSource={items}
                        itemSize={46}
                        onItemSelect={onSelectedChange}
                        selectedItems={selectedItems ? [selectedItems] : undefined}
                        border={true}
                    />
                    {selectedSystemLog !== undefined && selectedItems !== undefined && (
                        <Grid rows="auto auto auto auto auto 1fr" className={systemLogStyles.logitemdetails} gap={10}>

                            <Grid columns="100px 1fr">
                                <div className={systemLogStyles.logitemdetailsheader}>Deployment</div>
                                <div>{selectedSystemLog?.deploymentId}</div>                            
                            </Grid>

                            <Grid columns="100px 1fr">
                                <div className={systemLogStyles.logitemdetailsheader}>Log type: </div>
                                <div className={(selectedSystemLog?.logLevel === LogLevel.Error ? systemLogStyles.errorlogitemdetailsinfo : "")}>{translateLoglevel(selectedSystemLog?.logLevel)}</div>                            
                            </Grid>

                            <Grid columns="100px 1fr">
                                <div className={systemLogStyles.logitemdetailsheader}>Timestamp:</div>
                                <div>{formatDate(selectedSystemLog?.timestamp, false)} (UTC: {formatDate(selectedSystemLog?.timestamp, true)})</div>
                            </Grid>

                            <Grid columns="100px 1fr">
                                <div className={systemLogStyles.logitemdetailsheader}>Source:</div>
                                <div> { translateSource(selectedSystemLog?.source) }</div>
                            </Grid>

                            <Grid columns="100px 1fr">
                                <div className={systemLogStyles.logitemdetailsheader}>User:</div>
                                <div> {selectedSystemLog.user}</div>
                            </Grid>

                            <Grid className={systemLogStyles.logscontainer}>
                                <textarea readOnly className={systemLogStyles.logdata} value={logdata}/>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            )}
            {!items.some(x => x) && (
                <Grid className={systemLogStyles.empty} columnSpan={2}>
                    No logs for this deployment
                </Grid>   
            )}
        </Grid>
    )
}

export default SystemLogDetails