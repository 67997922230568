import { Util } from './Util'
export class Env {
    public static isIE(): boolean {
        return Util.isArrayWithItems(navigator.userAgent.match(/Trident/))
    }
    public static isCssGridLayoutSupported(): boolean {
        return 'gridRow' in document.body.style
    }

    public static isMSCssGridLayoutSupported(): boolean {
        return 'msGridRow' in document.body.style
    }

    public static getQueryStringParameters(): {
        [index: string]: string
    } {
        let str = window.location.search
        if (!str) {
            str = window.location.hash.substring(window.location.hash.indexOf('?'))
        }
        const params: {
            [index: string]: string
        } = {}
        let e
        const a = /\+/g // Regex for replacing addition symbol with a space
        const r = /([^&=]+)=?([^&]*)/g
        const d = function (s: string) {
            return decodeURIComponent(s.replace(a, ' '))
        }
        const q = str.substring(1) // window.location.search.substring(1);
        while ((e = r.exec(q))) params[d(e[1])] = d(e[2])
        return params
    }

    public static getBaseUrl(): string {
        let baseUrl = (document.querySelector('base') || {}).href
        // @ts-ignore
        if (!baseUrl) {
            baseUrl = window.location.protocol + '//' + window.location.host + window.location.pathname
        }
        return baseUrl || ''
    }
}
