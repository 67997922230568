import React from 'react'
import cx from 'classnames'
import styles from './Heading.module.scss'

export type HeadingType = 'sub' | 'normal' | 'heading1' | 'heading2' | 'heading3'

type Props = {
    type?: HeadingType
    requiredMarker?: boolean
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>

const getHeadingLevel = (type: HeadingType): number => {
    switch (type) {
        case 'heading1':
            return 1
        case 'heading2':
            return 2
        case 'heading3':
            return 3
        case 'normal':
            return 4
        case 'sub':
            return 5
    }
}

const Heading = React.forwardRef<HTMLButtonElement, Props>(
    ({ type = 'normal', requiredMarker = false, className, children, ...props }: Props, ref) => {
        return (
            <span
                className={cx(styles.base, styles[type], className, { [styles.requiredMarker]: requiredMarker })}
                role="heading"
                aria-level={getHeadingLevel(type)}
                {...props}
                ref={ref}
            >
                {children}
            </span>
        )
    },
)

export default Heading
