import { FunctionComponent } from 'react'
import Grid from '../../infratructure/grid/Grid'
import { ClusterController } from '../Model/AllSparkModel'

interface ClusterControllersListProps {
    clusterControllers: ClusterController[]
}

const ClusterControllersList: FunctionComponent<ClusterControllersListProps> = ({ clusterControllers }) => {
    return (
        <Grid
            rows={new Array(clusterControllers.length).fill('auto ').join('') + 'auto 1fr'}
            style={{ marginTop: '10px' }}
            gap={10}
        >
            <Grid columns="150px 200px 200px" style={{ borderBottom: '1px solid', marginBottom: '0px' }}>
                <div key="controller-name">Name</div>
                <div key="invision-chart">Invision HELM Chart</div>
                <div key="flow-chart">Flow HELM Chart</div>
            </Grid>

            {clusterControllers.map((c, i) => (
                <Grid key={'controller-' + i} columns="150px 200px 200px">
                    <div key={'controller-name-' + i}>{c.name}</div>
                    <div key={'invision-chart-' + i}>{c.invisionChart}</div>
                    <div key={'flow-chart-' + i}>{c.flowChart}</div>
                </Grid>
            ))}
        </Grid>
    )
}

export default ClusterControllersList
