import commonListStyles from './../CommonList.module.scss'
import { FunctionComponent, useState } from 'react'
import List, { ListItemProps } from '../../infratructure/list/List'
import Grid from '../../infratructure/grid/Grid'
import Input from '../../infratructure/input/Input'
import { CheckedDockerImage, ProductRepoVersion, ProductType } from '../Model/AllSparkModel'

type GroupVersionListProps = {
    repoVersions: ProductRepoVersion[] | undefined
    dockerImages: CheckedDockerImage[] | undefined
    onVersionChecked: (item: string) => void
}

const GroupVersionList: FunctionComponent<GroupVersionListProps> = ({
    repoVersions,
    dockerImages,
    onVersionChecked,
}) => {
    const DeliveryItemLayout = ({ item }: ListItemProps<string>) => {
        let rv = repoVersions?.filter((rv) => rv.version === item)?.length
        let title = rv ? (rv === 1 ? '1 docker image' : rv + ' docker images') : 'docker image'
        return (
            <Grid className={commonListStyles.item} gap={4}>
                <div className={commonListStyles.nameheader}>{title}</div>
                <Grid columns="20px 1fr">
                    <Input
                        name="advanced"
                        type="checkbox"
                        checked={dockerImages?.find((v) => v.version === item)?.selected}
                        onChange={() => onVersionChecked(item)}
                        style={{ width: '16px', margin: 0, height: '16px' }}
                        readOnly={false}
                        disabled={dockerImages?.find((v) => v.version === item)?.disabled}
                    />
                    <strong className={commonListStyles.itemname} title={item}>
                        {`${item}`}
                    </strong>
                </Grid>
            </Grid>
        )
    }

    return (
        <List
            className={commonListStyles.list}
            item={DeliveryItemLayout}
            spacing="compact"
            itemSource={
                dockerImages?.map((v) => {
                    return v.version
                }) ?? []
            }
            itemSize={46}
            onItemSelect={undefined}
            selectedItems={undefined}
            border={true}
        />
    )
}

export default GroupVersionList
