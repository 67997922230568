import { FunctionComponent, useContext, useState } from "react"
import Grid from "../../../infratructure/grid/Grid"
import { DeploymentModel } from "../../Model/AllSparkModel"
import Heading from "../../../infratructure/heading/Heading"
import styles from "./Edit.module.scss"
import css from "./EditScaling.module.scss"
import Input from "../../../infratructure/input/Input"
import Button, { ButtonType } from "../../../infratructure/button/Button"
import LoaderDots from "../../../infratructure/dots/LoaderDots"
import { AllSparkContext } from "../../../.."
import { useGlobalStateContext } from "../../../../GlobalState"

interface EditScalingDetailsProps{
    deployment: DeploymentModel | undefined
    onCancel: () => void
    onSave: () => void
    onClose: () => void
    isWorking: boolean
}

type EditScalingDetailsState = {
    systemWorkerMaxTaskCount: number
    systemWorkerMessageCount: number
    systemWorkerServiceMemoryRequest: number
    systemWorkerServiceMemoryLimit: number

    workerMaxTaskCount: number
    workerMessageCount: number
    workerServiceMemoryRequest: number
    workerServiceMemoryLimit: number

    dispatcherMaxTaskCount: number
    dispatcherMessageCount: number
    dispatcherServiceMemoryRequest: number
    dispatcherServiceMemoryLimit: number

    schedulerServiceMemoryRequest: number
    schedulerServiceMemoryLimit: number

    webAppServiceMemoryRequest: number
    webAppServiceMemoryLimit: number

    isDone: boolean
    isWorking: boolean
    hasErrors: boolean
}

const EditScalingDetails: FunctionComponent<EditScalingDetailsProps> = ({
    deployment,
    onCancel,
    onSave,
    onClose,
    isWorking
}) => {

    const api = useContext(AllSparkContext)
    const { setGlobalState } = useGlobalStateContext()

    const [state, setState] = useState<EditScalingDetailsState>({
        systemWorkerMaxTaskCount: deployment?.scaling.systemWorker.maxTaskCount || 1000,
        systemWorkerMessageCount: deployment?.scaling.systemWorker.messageCount || 1000,
        systemWorkerServiceMemoryRequest: deployment?.scaling.systemWorker.memory.request || 2000,
        systemWorkerServiceMemoryLimit: deployment?.scaling.systemWorker.memory.limit || 8000,

        workerMaxTaskCount: deployment?.scaling.worker.maxTaskCount || 1000,
        workerMessageCount: deployment?.scaling.worker.messageCount || 1000,
        workerServiceMemoryRequest: deployment?.scaling.worker.memory.request || 3000,
        workerServiceMemoryLimit: deployment?.scaling.worker.memory.limit || 8000,

        dispatcherMaxTaskCount: deployment?.scaling.dispatcher.maxTaskCount || 1000,
        dispatcherMessageCount: deployment?.scaling.dispatcher.messageCount || 1000,
        dispatcherServiceMemoryRequest: deployment?.scaling.dispatcher.memory.request || 300,
        dispatcherServiceMemoryLimit: deployment?.scaling.dispatcher.memory.limit || 3000,

        schedulerServiceMemoryRequest: deployment?.scaling.scheduler.memory.request || 200,
        schedulerServiceMemoryLimit: deployment?.scaling.scheduler.memory.limit || 2000,

        webAppServiceMemoryRequest: deployment?.scaling.webApp.memory.request || 600,
        webAppServiceMemoryLimit: deployment?.scaling.webApp.memory.limit || 6000,

        isDone: false,
        isWorking: isWorking,
        hasErrors: false
    });

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.name, e.target.value)
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }))
    }

    const handleSave = async () =>  {
        onSave()
        setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: true}))
        try{
            setState((prev) => ({ ...prev, isWorking: true}))
            let request = {
                dispatcher:{
                    maxTaskCount: state.dispatcherMaxTaskCount,
                    messageCount: state.dispatcherMessageCount,
                    memory:{
                        request: state.dispatcherServiceMemoryRequest,
                        limit: state.dispatcherServiceMemoryLimit
                    }
                },
                worker:{
                    maxTaskCount: state.workerMaxTaskCount,
                    messageCount: state.workerMessageCount,
                    memory:{
                        request: state.workerServiceMemoryRequest,
                        limit: state.workerServiceMemoryLimit
                    }
                },
                systemWorker:{
                    maxTaskCount: state.systemWorkerMaxTaskCount,
                    messageCount: state.systemWorkerMessageCount,
                    memory:{
                        request: state.systemWorkerServiceMemoryRequest,
                        limit: state.systemWorkerServiceMemoryLimit
                    }
                },
                scheduler:{
                    maxTaskCount: -1,
                    messageCount: -1,
                    memory:{
                        request: state.schedulerServiceMemoryRequest,
                        limit: state.schedulerServiceMemoryLimit
                    }
                },
                webApp:{
                    maxTaskCount: -1,
                    messageCount: -1,
                    memory:{
                        request: state.webAppServiceMemoryRequest,
                        limit: state.webAppServiceMemoryLimit
                    }
                }
            }

            await api.updateScaling(deployment?.deploymentId!, request)

            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false}))
            setState((prev) => ({ ...prev, isDone: true, isWorking: false}))
        }catch(error){
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
            setState((prev) => ({ ...prev, isDone: false, isWorking: false, hasErrors: true }))
        }
    }

    return (
        <Grid rows="auto auto auto auto auto auto auto auto auto auto auto auto 1fr" 
              gap={0} 
              style={{ height: '650px', marginLeft: '10px'}}
              >
            <Heading type="heading1" style={{ marginTop: '20px'}}>
                Edit cluster service scaling values
            </Heading>
            <Heading type="heading2" style={{ marginTop: '20px'}}>
                The application will be restarted when this config is saved.
            </Heading>
            <Heading type="heading2" style={{ marginTop: '20px'}}>System Worker</Heading>
            <Grid rows="auto auto auto 1fr" columns="400px 100px" gap={5} style={{ marginTop: '15px', marginLeft: '10px'}}>
                <span className={css.label}>Message Count limit before scaling up</span>
                <Input
                    name="systemWorkerMessageCount"
                    value={state.systemWorkerMessageCount}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Max concurrent jobs</span>
                <Input
                    name="systemWorkerMaxTaskCount"
                    value={state.systemWorkerMaxTaskCount}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service Memory Request (Mi)</span>        
                <Input
                    name="systemWorkerServiceMemoryRequest"
                    value={state.systemWorkerServiceMemoryRequest}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service Memory Limit (Mi)</span>
                <Input
                    name="systemWorkerServiceMemoryLimit"
                    value={state.systemWorkerServiceMemoryLimit}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
            </Grid>

            <Heading type="heading2" style={{ marginTop: '20px'}}>Worker</Heading>
            <Grid rows="auto auto auto 1fr" columns="400px 100px" gap={5} style={{ marginTop: '15px', marginLeft: '10px'}}>
                <span className={css.label}>Message Count limit before scaling up</span>
                <Input
                    name="workerMessageCount"
                    value={state.workerMessageCount}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Max concurrent jobs</span>
                <Input
                    name="workerMaxTaskCount"
                    value={state.workerMaxTaskCount}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service Memory Request (Mi)</span>        
                <Input
                    name="workerServiceMemoryRequest"
                    value={state.workerServiceMemoryRequest}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service Memory Limit (Mi)</span>
                <Input
                    name="workerServiceMemoryLimit"
                    value={state.workerServiceMemoryLimit}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
            </Grid>

            <Heading type="heading2" style={{ marginTop: '20px'}}>Dispatcher</Heading>
            <Grid rows="auto auto auto 1fr" columns="400px 100px" gap={5} style={{ marginTop: '15px', marginLeft: '10px'}}>
                <span className={css.label}>Message Count limit before scaling up</span>
                <Input
                    name="dispatcherMessageCount"
                    value={state.dispatcherMessageCount}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Max concurrent jobs</span>
                <Input
                    name="dispatcherMaxTaskCount"
                    value={state.dispatcherMaxTaskCount}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service Memory Request (Mi)</span>        
                <Input
                    name="dispatcherServiceMemoryRequest"
                    value={state.dispatcherServiceMemoryRequest}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service Memory Limit (Mi)</span>
                <Input
                    name="dispatcherServiceMemoryLimit"
                    value={state.dispatcherServiceMemoryLimit}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
            </Grid>

            <Heading type="heading2" style={{ marginTop: '20px'}}>Scheduler</Heading>
            <Grid rows="auto auto auto 1fr" columns="400px 100px" gap={5} style={{ marginTop: '15px', marginLeft: '10px'}}>
                <span className={css.label}>Service Memory Request (Mi)</span>        
                <Input
                    name="schedulerServiceMemoryRequest"
                    value={state.schedulerServiceMemoryRequest}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service Memory Limit (Mi)</span>
                <Input
                    name="schedulerServiceMemoryLimit"
                    value={state.schedulerServiceMemoryLimit}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
            </Grid>

            <Heading type="heading2" style={{ marginTop: '20px'}}>Web App</Heading>
            <Grid rows="auto auto auto 1fr" columns="400px 100px" gap={5} style={{ marginTop: '15px', marginLeft: '10px'}}>
                <span className={css.label}>Service Memory Request (Mi)</span>        
                <Input
                    name="webAppServiceMemoryRequest"
                    value={state.webAppServiceMemoryRequest}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service Memory Limit (Mi)</span>
                <Input
                    name="webAppServiceMemoryLimit"
                    value={state.webAppServiceMemoryLimit}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
            </Grid>

            <Grid columns="1fr auto auto auto auto" style={{ height: '60px', marginTop: '20px', marginRight: '10px'}} gap={10}>
                {state.isWorking && !state.isDone ? 
                    <Grid columns="auto auto 1fr" style={{ height: '30px'}} gap={10}>
                        <Grid style={{ alignItems: 'center'}}>Updating</Grid>
                        <Grid style={{ alignItems: 'center'}}>
                            <LoaderDots color="black"/>
                        </Grid>
                    </Grid>
                    :
                    <div/>
                }
                {!state.isWorking && state.isDone ?
                    <Grid columns="auto 1fr" style={{ height: '30px'}} gap={10}>
                        <Grid style={{ alignItems: 'center'}}>Done!</Grid>
                        <div/>
                    </Grid>
                    :
                    <div/>
                }
                {state.hasErrors ?
                    <Grid columns="auto 1fr" style={{ height: '30px'}} gap={10}>
                        <Grid style={{ alignItems: 'center'}}>Failed! Check System Log for details.</Grid>
                        <div/>
                    </Grid>
                    :
                    <div/>
                }
                {!state.isDone ?
                    <Button
                        className={styles.deploybtn}
                        buttonType={ButtonType.Default}
                        onClick={onCancel}
                        disabled={state.isWorking}
                        >
                            Cancel
                    </Button>
                    :
                    <div/>
                }
                <Button
                     className={styles.deploybtn}
                     buttonType={ButtonType.Confirm}
                     onClick={state.isDone ? onClose : handleSave}
                     disabled={state.isWorking}
                     >
                        { state.isDone ? "Close" : "Save" }
                </Button>
            </Grid>
        </Grid>
    )
}

export default EditScalingDetails