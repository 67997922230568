import cx from 'classnames'

type FlowIconProps = { width?: number; height?: number; className?: string; style?: React.CSSProperties, fill?: string }

export default function FlowIcon({ width = 20, height = 20, className, style, fill }: FlowIconProps) {
    return (
        <svg
            fill={fill || 'currentColor'}
            viewBox="0 0 24 24"
            width={width}
            height={height}
            className={cx('icon', className)}
            style={style}
            data-icon
        >
            <rect
                x={11.125}
                y={13.433}
                width={8.594}
                height={1.75}
                rx={0.875}
                transform="rotate(-90 11.125 13.433)"
                fill={fill || 'currentColor'}
            />
            <rect
                x={11.263}
                y={6.589}
                width={1.75}
                height={5.309}
                rx={0.875}
                transform="rotate(-90 11.263 6.59)"
                fill={fill || 'currentColor'}
            />
            <rect x={17.75} y={7.903} width={1.75} height={9.35} rx={0.875} fill={fill || 'currentColor'} />
            <rect
                x={7.648}
                y={13.453}
                width={1.751}
                height={5.142}
                rx={0.875}
                transform="rotate(-90 7.648 13.453)"
                fill={fill || 'currentColor'}
            />
            <path
                d="M7.25 10.703v3.75H3.5v-3.75h3.75Zm0-1.5H3.5a1.5 1.5 0 0 0-1.5 1.5v3.75a1.5 1.5 0 0 0 1.5 1.5h3.75a1.5 1.5 0 0 0 1.5-1.5v-3.75a1.5 1.5 0 0 0-1.5-1.5ZM20.5 4.078v3.75h-3.75v-3.75h3.75Zm0-1.5h-3.75a1.5 1.5 0 0 0-1.5 1.5v3.75a1.5 1.5 0 0 0 1.5 1.5h3.75a1.5 1.5 0 0 0 1.5-1.5v-3.75a1.5 1.5 0 0 0-1.5-1.5Zm0 14.75v3.75h-3.75v-3.75h3.75Zm0-1.5h-3.75a1.5 1.5 0 0 0-1.5 1.5v3.75a1.5 1.5 0 0 0 1.5 1.5h3.75a1.5 1.5 0 0 0 1.5-1.5v-3.75a1.5 1.5 0 0 0-1.5-1.5Z"
                fill={fill || 'currentColor'}
            />
        </svg>
    )
}
