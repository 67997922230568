import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Method, ResponseType } from 'axios'
import { AccessTokenStore } from './AccessTokenStore'

export type HttpRequestConfig = {
    method?: Method
    data?: any
    url?: string
    responseType?: ResponseType
    headers?: { [index: string]: string }
}

export type HttpResponse<T> = {
    data: T
    headers: { [index: string]: any }
    status: number
}

export interface IHttpService {
    invoke<T>(requestConfig: HttpRequestConfig): Promise<T>
    get<T>(url: string, requestConfig?: HttpRequestConfig): Promise<T>
    post<T>(url: string, data?: any, requestConfig?: HttpRequestConfig): Promise<T>
    put<T>(url: string, data?: any, requestConfig?: HttpRequestConfig): Promise<T>
}

export class HttpService {
    constructor(private baseUrl: string) {}

    async invoke<T>(requestConfig: HttpRequestConfig): Promise<T> {
        try {
            const response = await axios(this.createRequestConfig(void 0, void 0, null, requestConfig))
            return response.data
        } catch (error: any) {
            throw this.handleErrors(error)
        }
    }

    async get<T>(url: string, requestConfig?: HttpRequestConfig): Promise<T> {
        try {
            const response = await axios(this.createRequestConfig('GET', url, null, requestConfig))
            return response.data
        } catch (error: any) {
            throw this.handleErrors(error)
        }
    }

    async getRaw<T>(url: string, requestConfig?: HttpRequestConfig): Promise<HttpResponse<T>> {
        try {
            const response = await axios(this.createRequestConfig('GET', url, null, requestConfig))
            return {
                data: response.data,
                status: response.status,
                headers: response.headers,
            }
        } catch (error: any) {
            throw this.handleErrors(error)
        }
    }

    async post<T>(url: string, data?: any, requestConfig?: HttpRequestConfig): Promise<T> {
        try {
            const response = await axios(this.createRequestConfig('POST', url, data, requestConfig))
            return response.data
        } catch (error: any) {
            throw this.handleErrors(error)
        }
    }

    async put<T>(url: string, data?: any, requestConfig?: HttpRequestConfig): Promise<T> {
        try {
            const response = await axios(this.createRequestConfig('PUT', url, data, requestConfig))
            return response.data
        } catch (error: any) {
            throw this.handleErrors(error)
        }
    }

    async delete<T>(url: string, requestConfig?: HttpRequestConfig): Promise<T> {
        try {
            const response = await axios(this.createRequestConfig('DELETE', url, null, requestConfig))
            return response.data
        } catch (error: any) {
            throw this.handleErrors(error)
        }
    }

    private handleErrors(err: AxiosError) {
        if (err.response?.status === 409) {
            throw new ConflictError(err.response)
        }
        if (typeof err.response?.data === 'string') {
            const error = new Error(err.response.data)
            const config = err.response.config
            if (config.data) {
                config.data = JSON.parse(config.data)
            }
            error.stack = JSON.stringify(config, null, 4)
            throw error
        }

        if (err.response) {
            const { title, ...data } = err.response?.data
            const error = new Error(title || err.message)
            error.stack = JSON.stringify(data, null, 4)
            throw error
        }

        throw err
    }

    private createRequestConfig(
        method?: Method,
        url?: string,
        data?: any,
        requestConfig?: HttpRequestConfig,
    ): AxiosRequestConfig {
        const defaultHeaders = {}
        const config: AxiosRequestConfig = {
            headers: { ...defaultHeaders, ...(requestConfig && requestConfig.headers) },
            method: method || (requestConfig && requestConfig.method) || 'GET',
            url: url || (requestConfig && requestConfig.url),
            data: data || (requestConfig && requestConfig.data),
        }

        if (this.baseUrl) {
            config.baseURL = this.baseUrl
        }

        if (requestConfig && requestConfig.responseType) {
            config.responseType = requestConfig.responseType
        }

        const accessToken = AccessTokenStore.getAccessToken()
        if (accessToken && config.headers) {
            config.headers['Authorization'] = 'Bearer ' + accessToken
        } else {
            config.withCredentials = false
        }

        return config
    }
}

export class ConflictError extends Error {
    public status: number
    constructor(res: AxiosResponse) {
        super(res.data)
        this.name = res.statusText
        this.status = res.status
    }
}
