import { FunctionComponent } from 'react'
import styles from './Ok.module.scss'

interface OkProps{
    okMessage: string | undefined
}

const Ok: FunctionComponent<OkProps> = ({okMessage})=>{

    return (
        <div className={styles.ok} title={okMessage}>&nbsp;</div>
    )
}

export default Ok