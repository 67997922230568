import { FunctionComponent } from 'react'
import { EnvironmentModel } from '../Model/AllSparkModel'
import Grid from '../../infratructure/grid/Grid'
import List, { ListItemProps } from '../../infratructure/list/List'
import commonListStyles from './../CommonList.module.scss'

interface EnvironmentListProps {
    items: EnvironmentModel[]
    onSelectedChange: (environment: EnvironmentModel) => void
    selectedItems: EnvironmentModel[] | undefined
}

const DeliveryItemLayout = ({ item }: ListItemProps<EnvironmentModel>) => {
    return (
        <Grid className={commonListStyles.item}>
            <div style={{ fontSize: '0.7em' }}>{`environment name`}</div>
            <Grid columns="1fr auto">
                <strong className={commonListStyles.itemname} title={item.name}>
                    {`${item.name}`}
                </strong>
            </Grid>
        </Grid>
    )
}

const EnvironmentList: FunctionComponent<EnvironmentListProps> = ({ items, onSelectedChange, selectedItems }) => {
    return (
        <List
            className={commonListStyles.list}
            item={DeliveryItemLayout}
            spacing="compact"
            itemSource={items}
            itemSize={46}
            onItemSelect={onSelectedChange}
            selectedItems={selectedItems}
            border={true}
        />
    )
}

export default EnvironmentList
