import commonListStyles from './../CommonList.module.scss'
import { FunctionComponent } from 'react'
import List, { ListItemProps } from '../../infratructure/list/List'
import Grid from '../../infratructure/grid/Grid'
import Input from '../../infratructure/input/Input'
import { CheckedDockerImage } from '../Model/AllSparkModel'

interface DockerImagesGroupsListProps {
    items: string[] | undefined
    selectedItems: string[] | undefined
    versionGroupsChecked: CheckedDockerImage[]
    onSelectedChange: (g: string) => void
    onVersionGroupChecked: (item: string) => void
}

const DockerImagesGroupsList: FunctionComponent<DockerImagesGroupsListProps> = ({
    items,
    selectedItems,
    versionGroupsChecked,
    onSelectedChange,
    onVersionGroupChecked,
}) => {
    const DeliveryItemLayout = ({ item }: ListItemProps<string>) => {
        return (
            <Grid className={commonListStyles.item} gap={4}>
                <div className={commonListStyles.nameheader}>{`main version`}</div>
                <Grid columns="20px 1fr" gap={4}>
                    <Input
                        name="advanced"
                        type="checkbox"
                        checked={versionGroupsChecked.find((i) => i.version == item)?.selected}
                        onChange={() => onVersionGroupChecked(item)}
                        style={{ width: '16px', margin: 0, height: '16px', zIndex: 9999 }}
                        readOnly={false}
                    />
                    <strong className={commonListStyles.itemname} title={item}>
                        {`${item}`}
                    </strong>
                </Grid>
            </Grid>
        )
    }

    return (
        <List
            className={commonListStyles.list}
            item={DeliveryItemLayout}
            spacing="compact"
            itemSource={items ?? []}
            itemSize={46}
            onItemSelect={onSelectedChange}
            selectedItems={selectedItems}
            border={true}
        />
    )
}

export default DockerImagesGroupsList
