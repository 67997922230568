import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'
import styles from './UpgradeDeployment.module.scss'
import Grid from '../../../infratructure/grid/Grid'
import Heading from '../../../infratructure/heading/Heading'
import Button, { ButtonType } from '../../../infratructure/button/Button'
import { AllSparkContext } from '../../../..'
import Select, { OptionType } from '../../../infratructure/select/Select'
import { Options } from 'react-select'
import { ClusterController, ProductType } from '../../Model/AllSparkModel'
import LoaderDots from '../../../infratructure/dots/LoaderDots'
import useAsyncError from '../../../infratructure/hooks/useAsyncError'
import { useGlobalStateContext } from '../../../../GlobalState'
import Input from '../../../infratructure/input/Input'

interface UpgradeDeploymentProps {
    deploymentId?: string
    deploymentName?: string
    currentPlatformVersion?: string
    clusterControllers: ClusterController[]
    currentClusterController: string
    onDeploymentUpgraded: (newVersion: string) => void
    onUpgrading: () => void
    onUpgradeFinished: () => void
    onClose: () => void
    productType: ProductType
}

type UpgradeDeploymentState = {
    isWorking: boolean
    versions:
        | Options<{
              label: string
              value: string
          }>
        | undefined
    selectedVersion: OptionType | undefined
    isLoadingVersions: boolean
    isUpgradeDone: boolean
    upgradeErrorMessage?: string
    versionPlaceHolderText: string
    chosenClusterController: ClusterController
    clusterControllers: ClusterController[]
    enableClusterControllerChange: boolean
}

const versionSelectorPlaceholderText = 'Select target platform version'

const UpgradeDeployment: FunctionComponent<UpgradeDeploymentProps> = ({
    deploymentId,
    currentPlatformVersion,
    deploymentName,
    clusterControllers,
    currentClusterController,
    onDeploymentUpgraded,
    onUpgrading,
    onUpgradeFinished,
    onClose,
    productType,
}) => {
    const api = useContext(AllSparkContext)
    const { setGlobalState } = useGlobalStateContext()
    const throwError = useAsyncError()

    const [state, setState] = useState<UpgradeDeploymentState>({
        isWorking: false,
        versions: [{ label: 'Latest', value: 'latest' }],
        isLoadingVersions: false,
        selectedVersion: undefined,
        isUpgradeDone: false,
        versionPlaceHolderText: versionSelectorPlaceholderText,
        clusterControllers: clusterControllers,
        chosenClusterController: clusterControllers.find((e) => e.name === currentClusterController)!,
        enableClusterControllerChange: false,
    })

    const handleUpgradeClicked = async () => {
        try {
            if (state.selectedVersion?.value && deploymentId) {
                setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: true }))
                setState((prev) => ({ ...prev, isWorking: true }))

                onUpgrading()

                const upgradeResult = await api.updateDeployment({
                    productType: productType,
                    deploymentId: deploymentId,
                    chartVersion: state.selectedVersion?.value.toString(),
                    controller: state.chosenClusterController?.name || 'AzureGateway',
                })

                if (upgradeResult.success) {
                    if (onDeploymentUpgraded) {
                        onDeploymentUpgraded(upgradeResult.chartVersion || '')
                    }
                    setState((prev) => ({
                        ...prev,
                        isWorking: false,
                        isUpgradeDone: true,
                    }))
                } else {
                    setState((prev) => ({
                        ...prev,
                        isWorking: false,
                        isUpgradeDone: true,
                        upgradeErrorMessage: upgradeResult.errorMessage,
                    }))

                    console.log(
                        `Upgrade of deployment with id: ${upgradeResult.deploymentId} failed with the following error message -> `,
                        upgradeResult.errorMessage,
                    )
                }

                onUpgradeFinished()
            }
        } catch (error) {
            setState((prev) => ({
                ...prev,
                isWorking: false,
                isUpgradeDone: true,
                upgradeErrorMessage: 'Unable to upgrade.' + error,
            }))
            onUpgradeFinished()
            throwError(error)
        } finally {
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        }
    }

    const handleCloseClicked = () => {
        onClose()
        setState((prev) => ({ ...prev, upgradeErrorMessage: undefined }))
    }

    const onVersionChange = (version: OptionType) => {
        setState((prev) => ({ ...prev, selectedVersion: version }))
    }

    const onEnvironmentChanged = (data: { label: string; value: string }) => {
        setState((prev) => ({
            ...prev,
            chosenClusterController: state.clusterControllers.find((c) => c.name == data.value)!,
        }))
    }

    const getVersions = useCallback(async () => {
        setState((prev) => ({
            ...prev,
            isLoadingVersions: true,
            versionPlaceHolderText: 'Loading Platform Versions, please wait ...',
        }))

        try {
            const chartVersions = await api.getVersions(productType)
            if (chartVersions) {
                let versions = [
                    { label: 'latest', value: 'latest' },
                    ...chartVersions.versions.map((v) => ({ label: v.version, value: v.version })),
                ]

                setState((prev) => ({
                    ...prev,
                    isLoadingVersions: false,
                    versions: versions,
                    versionPlaceHolderText: versionSelectorPlaceholderText,
                }))
            }
        } catch (error) {
            console.log('Failed getting the platform versions !', error)
            setState((prev) => ({
                ...prev,
                isLoadingVersions: false,
                versionPlaceHolderText: versionSelectorPlaceholderText,
            }))
            throwError(error)
        }
    }, [api, throwError])

    useEffect(() => {
        getVersions()
    }, [api, getVersions])

    const onEnableClusterControllerChanged = () => {
        setState((prev) => ({ ...prev, enableClusterControllerChange: !state.enableClusterControllerChange }))
    }

    return (
        <Grid rows="auto 1fr auto" gap={20} className={styles.contentHeight}>
            <Heading type="heading1">Upgrade '{deploymentName}'</Heading>
            <Grid rows="1fr auto auto" className={styles.main}>
                {!state.isWorking && !state.isUpgradeDone && (
                    <Grid rows="auto 1fr" columns="200px 1fr" gap={20}>
                        <Grid columnSpan={3} className={styles.labelformat}>
                            {`Current platform version is ${currentPlatformVersion}`}
                        </Grid>

                        <Select
                            headerText="Target version"
                            isSearchable={false}
                            isClearable={false}
                            options={state?.versions}
                            value={state?.selectedVersion?.value}
                            onChange={onVersionChange}
                            placeholder={state.versionPlaceHolderText}
                            isDisabled={state.isWorking}
                        />

                        <Select
                            headerText="Gateway/controller"
                            isSearchable={false}
                            isClearable={false}
                            options={state?.clusterControllers.map((cc) => ({
                                label: cc.name + ' (' + cc.flowChart + ')',
                                value: cc.name || 'AzureGateway',
                            }))}
                            value={state.chosenClusterController?.name}
                            onChange={onEnvironmentChanged}
                            placeholder={'Select gateway/controller'}
                            isDisabled={state.isWorking || !state.enableClusterControllerChange}
                        />

                        <Input
                            name="enableClusterController"
                            type="checkbox"
                            checked={state.enableClusterControllerChange}
                            onChange={onEnableClusterControllerChanged}
                            style={{
                                width: '16px',
                                margin: 0,
                                height: '16px',
                                alignItems: 'center',
                                verticalAlign: 'center',
                                marginTop: '27px',
                            }}
                            title="Enable/disable Controller (Gateway)"
                            readOnly={state.isWorking}
                        />
                    </Grid>
                )}
                {state.isWorking && (
                    <Grid columns="auto 1fr" className={styles.centerSelf} gap={16}>
                        <div className={styles.centerSelf}>Upgrading</div>
                        <LoaderDots color={'black'} />
                    </Grid>
                )}

                {state.isUpgradeDone && !state.upgradeErrorMessage && (
                    <div className={styles.centerSelf}>
                        Upgrade to {state.selectedVersion?.value.toString()} succeeded!
                    </div>
                )}

                {state.isUpgradeDone && state.upgradeErrorMessage && (
                    <div className={styles.centerSelf}>Upgrade failed. Check system log for error messages.</div>
                )}
            </Grid>

            <Grid columns="1fr auto auto" className={styles.footer}>
                <div />
                <Button
                    className={styles.deploybtn}
                    buttonType={ButtonType.Confirm}
                    onClick={state.isUpgradeDone ? handleCloseClicked : handleUpgradeClicked}
                    disabled={state.isWorking || !state?.selectedVersion?.value || state.isLoadingVersions}
                >
                    {state.isUpgradeDone ? 'Close' : 'Upgrade'}
                </Button>
            </Grid>
        </Grid>
    )
}

export default UpgradeDeployment
