import cx from 'classnames'
import { IconProps } from './IconProps'

interface ErrorIconProps extends IconProps {
    color?: string
}

export default function DeploymentsIcon({
    width = 20,
    height = 20,
    className,
    style,
    color = '#008D17',
}: ErrorIconProps) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            className={cx('icon', className)}
            style={style}
            data-icon
            fill="none"
        >
            <path
                d="M10.9 1.559a2.2 2.2 0 0 1 2.2 0l7.392 4.268a2.2 2.2 0 0 1 1.1 1.905v8.536a2.2 2.2 0 0 1-1.1 1.905L13.1 22.441a2.2 2.2 0 0 1-2.2 0l-7.392-4.268a2.2 2.2 0 0 1-1.1-1.905V7.732a2.2 2.2 0 0 1 1.1-1.905L10.9 1.559ZM12.2 22V12m.406.304 8.829-5m-8.847 5.382-10-6"
                stroke={color}
                strokeWidth={1.6}
            />
        </svg>
    )
}
