import cx from 'classnames'
import { IconProps } from './IconProps'

export default function SearchIcon({ width = 20, height = 20, className, style }: IconProps) {
    return (
        <svg
            fill="none"
            viewBox="0 0 21 20"
            width={width}
            height={height}
            className={cx('icon', className)}
            style={style}
            data-icon
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 15a6.667 6.667 0 115.267-2.579l4.489 4.49-1.179 1.178-4.489-4.49A6.638 6.638 0 019 15zm5-6.667a5 5 0 11-10 0 5 5 0 0110 0z"
                fill="currentColor"
            />
        </svg>
    )
}
