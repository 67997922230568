export type Borders = {
    top?: boolean
    left?: boolean
    bottom?: boolean
    right?: boolean
}

export function setBorder(border: boolean | Borders | undefined) {
    if (border === undefined) return

    //If border property is a boolean set all border if true or none is false
    if (typeof border === 'boolean') {
        const borderWidth = border ? 1 : 0
        return {
            borderTopWidth: borderWidth,
            borderLeftWidth: borderWidth,
            borderBottomWidth: borderWidth,
            borderRightWidth: borderWidth,
        }
    }
    //If border property is an object set the spesific border if true else it will be false
    return {
        borderTopWidth: border.top ? 1 : 0,
        borderLeftWidth: border.left ? 1 : 0,
        borderBottomWidth: border.bottom ? 1 : 0,
        borderRightWidth: border.right ? 1 : 0,
    }
}
