import { AllSparkContext } from '../../../..'
import cx from 'classnames'
import { FunctionComponent, useContext, useState } from "react"
import Grid from "../../../infratructure/grid/Grid"
import Heading from "../../../infratructure/heading/Heading"
import Input from "../../../infratructure/input/Input"
import styles from "./Edit.module.scss"
import Select, { OptionType } from "../../../infratructure/select/Select"
import { permissionValues } from "../new/NewDeployment"
import { Permission } from "../../Model/AllSparkModel"
import Button, { ButtonType } from "../../../infratructure/button/Button"
import Dirty from '../../../infratructure/dirty/Dirty'
import { useGlobalStateContext } from '../../../../GlobalState'
import LoaderDots from '../../../infratructure/dots/LoaderDots'
import { Util } from '../../../utils/Util'

interface EditAzureApplicationsDetailsProps {
    deploymentId: string | undefined
    hasCustomOverrides: boolean | undefined
    identityProviderAuthority: string | undefined
    identityProviderPermissions: Permission | undefined
    identityProviderCanInvite: boolean | undefined
    openIdConnectAuthority: string | undefined
    openIdConnectTenantId: string | undefined
    webAppclientId: string | undefined
    webAppclientSecret: string | undefined
    designerClientId: string | undefined
    onUpdate: (isUpdating: boolean) => void
    onClose: (success: boolean) => void
}

class EditAzureApplicationsDirtyState {
    IdentityProviderAuthority: boolean = false
    IdentityProviderPermissions: boolean = false
    IdentityProviderCanInvite: boolean = false
    openIdConnectAuthority: boolean = false
    openIdConnectTenantId: boolean = false
    webAppclientId: boolean = false
    webAppclientSecret: boolean = false
    webAppclientSecretExpiry: boolean = false
    designerClientId: boolean = false
    isDirty: boolean = false
}

type EditAzureApplicationsDetailsState = {
    deploymentId: string
    hasCustomOverrides: boolean | undefined
    identityProviderAuthority: string | undefined
    identityProviderPermissions: Permission | undefined
    identityProviderCanInvite: boolean
    openIdConnectAuthority: string | undefined
    openIdConnectTenantId: string | undefined
    webAppclientId: string | undefined
    webAppclientSecret: string | undefined
    webAppClientSecretExpiry: string
    designerClientId: string | undefined
    override: boolean
    isDeploying: boolean
    isDone: boolean
    hasErrors: boolean
    dirty: EditAzureApplicationsDirtyState
}

const EditAzureApplicationsDetails: FunctionComponent<EditAzureApplicationsDetailsProps> = ({ 
    deploymentId,
    hasCustomOverrides,
    identityProviderAuthority, 
    identityProviderPermissions,
    identityProviderCanInvite,
    openIdConnectAuthority,
    openIdConnectTenantId,
    webAppclientId,
    webAppclientSecret,
    designerClientId,
    onUpdate,
    onClose
 }) => {
    const api = useContext(AllSparkContext)
    const { setGlobalState } = useGlobalStateContext()
    const dirtyState = new EditAzureApplicationsDirtyState()

    const [state, setState] = useState<EditAzureApplicationsDetailsState>({
        deploymentId: deploymentId || '',
        hasCustomOverrides: hasCustomOverrides,
        identityProviderAuthority: identityProviderAuthority,
        identityProviderPermissions: identityProviderPermissions,
        identityProviderCanInvite: identityProviderCanInvite || false,
        openIdConnectAuthority: openIdConnectAuthority,
        openIdConnectTenantId: openIdConnectTenantId,
        webAppclientId: webAppclientId,
        webAppclientSecret: webAppclientSecret,
        webAppClientSecretExpiry: '',
        designerClientId: designerClientId,
        override: hasCustomOverrides ? true : false,
        isDeploying: false,
        isDone: false,
        hasErrors: false,
        dirty: dirtyState
    })

    const onSelectedPermissionChange = (selectedPermission: OptionType) => {
        setState((prev) => ({
            ...prev,
            identityProviderPermissions: selectedPermission.value as Permission,
            dirty:{ ...prev.dirty, IdentityProviderPermissions: true, isDirty: true }
        }))
    }

    const onCanInviteChange = (canInvite: OptionType) =>{
        setState((prev) => ({
            ...prev,
            identityProviderCanInvite: !Boolean(state.identityProviderCanInvite),
            dirty:{ ...prev.dirty, IdentityProviderCanInvite: true, isDirty: true }
        }))
    }

    const update = async () => {        
        setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: true}))
        setState((prev) => ({ ...prev, isDeploying: true, hasErrors: false}))
        onUpdate(true)

        try{
            const updateAuthRequest = {
                deploymentId: state.deploymentId,
                override: state.override,
                identityProviderAuthority: state.identityProviderAuthority || '',
                identityProviderPermissions: state.identityProviderPermissions || Permission.None,
                identityProviderCanInvite: state.identityProviderCanInvite,
                openIdConnectAuthority: state.openIdConnectAuthority || '',
                openIdConnectTenantId: state.openIdConnectTenantId || '',
                webAppClientId: state.webAppclientId || '',
                webAppClientSecret: state.webAppclientSecret || '',
                webAppClientSecretExpiry: state.webAppClientSecretExpiry || Util.getDefaultExpiryDateString(),
                designerClientId: state.designerClientId || ''
            }

            await api.updateAuth(updateAuthRequest)

            setState((prev) => ({ ...prev, isDeploying: false, isDone: true}))
            onUpdate(false)
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false}))
        }
        catch(error: any){
            console.log(`An error occurred, updating the authentication details failed: ${error.message}`)
            setState((prev) => ({ ...prev, isDeploying: false, hasErrors: true}))
            onUpdate(false)
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false}))
        }
    }

    return (
        <Grid rows="auto 1fr auto" className={styles.main} gap={30}>

            <Heading type="heading1">Edit Azure applications</Heading>
            
            {!state.isDone && (

                <Grid gap={20}>
                    <Grid columns="auto auto auto 1fr"  gap={10} style={{ alignItems: 'center' }} >
                        <Input
                            name="automatic"
                            type="radio"
                            checked={!state.override}
                            onChange={() => 
                                setState((prev) => ({ ...prev, override: !state.override}))                            
                            }
                            style={{ width: '16px', margin: 0, height: '16px' }}
                            disabled={state.isDeploying || state.hasCustomOverrides}
                        />
                        <Heading
                            type="normal">
                                Automatic renew (secrets)
                        </Heading>
                        <Input
                            name="manual"
                            type="radio"
                            checked={state.override}
                            onChange={() => 
                                setState((prev) => ({ ...prev, override: !state.override}))                            
                            }
                            style={{ width: '16px', margin: 0, height: '16px' }}
                            disabled={state.isDeploying || state.hasCustomOverrides}
                        />
                        <Heading
                            type="normal">
                                Configure manually
                        </Heading>                    
                    </Grid>

                    {state.hasCustomOverrides &&(
                        <Grid gap={20} className={styles.info}>
                            This deployment has manual overrides, and can not be automatically renewed.
                        </Grid>
                    )}

                    {!state.override &&(
                        <Grid gap={20} className={styles.info}>
                            Client secret for the registered web application will be renewed, and the application restarted.
                        </Grid>
                    )}

                    {state.override && (
                        <Grid gap={20}>

                            <Grid gap={20} className={styles.info}>
                                The identity provider will be updated with this information, and the application restarted.
                            </Grid>

                            <Grid columns="1fr auto" gap={4} style={{ marginRight: '-10px'}}>
                                <Input
                                    name="openIdConnectTenantId"
                                    headerText={'OpenId Connect Tenant Id'}
                                    placeholder="Enter the OpenId Connect Tenant Id"
                                    style={{ width: 'inherit' }}
                                    value={state.openIdConnectTenantId}
                                    onChange={(e) =>
                                        setState((prev) => (
                                            { 
                                                ...prev, 
                                                openIdConnectTenantId: e.target.value, 
                                                identityProviderAuthority: 'https://login.microsoftonline.com/' + e.target.value,
                                                openIdConnectAuthority: 'https://login.microsoftonline.com/' + e.target.value + '/v2.0',
                                                dirty:{ ...prev.dirty, openIdConnectTenantId: true, isDirty: true}
                                            }))
                                    }
                                    className={cx({
                                        [styles.requiredInput]: !Boolean(state.openIdConnectTenantId),
                                    })}
                                    readOnly={state.isDeploying}
                                />
                                <Dirty dirty={state.dirty.openIdConnectTenantId}/>
                            </Grid>

                            <Grid columns="1fr auto" gap={4} style={{ marginRight: '-10px'}}>
                                <Select
                                        headerText='Identity Provider Permissions'
                                        isClearable={false}
                                        options={permissionValues}
                                        value={state.identityProviderPermissions}
                                        onChange={onSelectedPermissionChange}
                                        placeholder="Select permission"
                                        isDisabled={state.isDeploying}                            
                                    />
                                <Dirty dirty={state.dirty.IdentityProviderPermissions}/>
                            </Grid>

                            <Grid columns="1fr auto" gap={4} style={{ marginRight: '-10px'}}>
                                <Select
                                        headerText='Identity Provider Can Invite'
                                        isClearable={false}
                                        options={[{ value: 1, label: "Yes" }, { value: 0, label: "No"}]}
                                        value={state.identityProviderCanInvite ? 1 : 0}
                                        onChange={onCanInviteChange}
                                        placeholder="Can invite"
                                        isDisabled={state.isDeploying}
                                    />
                                <Dirty dirty={state.dirty.IdentityProviderCanInvite}/>
                            </Grid>

                            <Grid columns="1fr auto" gap={4} style={{ marginRight: '-10px'}}>                        
                                <Input
                                    name="clientId"
                                    headerText={'Client Id Webapp'}
                                    placeholder="Enter Client Id Webapp"
                                    style={{ width: 'inherit' }}
                                    value={state.webAppclientId}
                                    onChange={(e) =>
                                        setState((prev) => ({ ...prev, webAppclientId: e.target.value, dirty: { ...prev.dirty, webAppclientId: true, isDirty: true}}))
                                    }
                                    className={cx({
                                        [styles.requiredInput]: !Boolean(state.webAppclientId),
                                    })}
                                    disabled={state.isDeploying}
                                />
                                <Dirty dirty={state.dirty.webAppclientId}/>
                            </Grid>

                            <Grid columns="1fr auto" gap={4} style={{ marginRight: '-10px'}}>
                                <Input
                                    name="clientSecret"
                                    headerText={'Client Secret Webapp'}
                                    placeholder="Enter Client Secret Webapp"
                                    style={{ width: 'inherit' }}
                                    value={state.webAppclientSecret}
                                    onChange={(e) =>
                                        setState((prev) => ({ ...prev, webAppclientSecret: e.target.value, dirty: { ...prev.dirty, webAppclientSecret: true, isDirty: true}}))
                                    }
                                    className={cx({
                                        [styles.requiredInput]: !Boolean(state.webAppclientSecret),
                                    })}
                                    readOnly={state.isDeploying}
                                />
                                <Dirty dirty={state.dirty.webAppclientSecret}/>
                            </Grid>

                            <Grid columns="1fr auto" gap={4} style={{ marginRight: '-10px'}}>
                                <Input
                                    name="clientSecretExpiry"
                                    headerText='Client Secret Webapp Expiry (utc)'
                                    type="datetime-local"
                                    style={{ width: 'inherit' }}
                                    value={state.webAppClientSecretExpiry}
                                    onChange={(e) =>
                                        setState((prev) => ({ ...prev, webAppClientSecretExpiry: e.target.value, dirty: { ...prev.dirty, webAppclientSecretExpiry: true, isDirty: true}}))
                                    }
                                    className={cx({
                                        [styles.requiredInput]: !Boolean(state.webAppClientSecretExpiry),
                                    })}
                                    />
                                <Dirty dirty={state.dirty.webAppclientSecretExpiry}/>
                            </Grid>

                            <Grid columns="1fr auto" gap={4} style={{ marginRight: '-10px'}}>
                                <Input
                                    name="clientIdDesigner"
                                    headerText={'Client Id Designer'}
                                    placeholder="Enter Client Id Designer"
                                    style={{ width: 'inherit' }}
                                    value={state.designerClientId}
                                    onChange={(e) =>
                                        setState((prev) => ({ ...prev, designerClientId: e.target.value, dirty: { ...prev.dirty, designerClientId: true, isDirty: true}}))
                                    }
                                    className={cx({
                                        [styles.requiredInput]: !Boolean(state.designerClientId),
                                    })}
                                    readOnly={state.isDeploying}
                                />
                                <Dirty dirty={state.dirty.designerClientId} />
                            </Grid>
                        </Grid>
                    )}

                </Grid>
            )}

            {state.isDone && (
                <Grid gap={20} className={styles.info}>
                    Update is finished
                </Grid>
            )}

            {state.hasErrors && (
                <Grid gap={20} className={styles.info}>
                    An error occurred, update failed
                </Grid>
            )}

            <div className={styles.footer}>
                <Grid columns="1fr auto auto" gap={0} style={{ marginBottom: '10px'}}>
                    <div></div>
                    {!state.hasCustomOverrides && state.override && !state.isDeploying && !state.isDone && (
                        <Grid gap={0} className={styles.disableinfo} style={{ marginRight: '10px'}}>
                            Setting values manually disables the automatic renew-function
                        </Grid>
                    )}

                    {state.isDeploying && (
                        <Grid columns="1fr auto" gap={10} style={{ alignItems: 'center', marginBottom: '10px'}}>
                            <Grid>Updating</Grid>
                            <LoaderDots color="black" />
                        </Grid>
                    )}

                    {!state.isDeploying && !state.isDone && (
                        <Button 
                            className={styles.deploybtn}
                            buttonType={ButtonType.Confirm}
                            onClick={update}
                            disabled={state.override && !state.dirty.isDirty}
                            style={{ marginRight: '0px'}}
                        >
                            Renew
                        </Button>
                    )}

                    {state.isDone &&(
                        <Button
                            className={styles.deploybtn}
                            buttonType={ButtonType.Confirm}
                            onClick={() => onClose(!state.hasErrors)}
                        >
                            Close
                        </Button>
                    )}
                </Grid>
            </div>
        </Grid>
    )
}

export default EditAzureApplicationsDetails