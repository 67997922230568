import { FunctionComponent, useState } from 'react'
import { DeploymentModel, ProductType } from '../../../Model/AllSparkModel'
import Grid from '../../../../infratructure/grid/Grid'
import Heading from '../../../../infratructure/heading/Heading'
import styles from './AzureSQLDetails.module.scss'
import CopyableInput from '../../../../infratructure/copyableInput/CopyableInput'
import Button, { ButtonType } from '../../../../infratructure/button/Button'
import EditIcon from '../../../../icons/EditIcon'
import Modal from 'react-responsive-modal'
import EditSqlConnectionString from '../../edit/EditSqlConnectionString'
import { unwatchFile } from 'fs'
import RefreshIcon from '../../../../icons/RefreshIcon'

interface AzureSQLDetailProps {
    deployment: DeploymentModel
    onAzureSqlEdit: () => void
}

type AzureSqlDetailsState = {
    isEditConnectionVisible: boolean
    isWorking: boolean
}

const AzureSQLDetails: FunctionComponent<AzureSQLDetailProps> = ({ deployment, onAzureSqlEdit }) => {
    const [state, setState] = useState<AzureSqlDetailsState>({
        isEditConnectionVisible: false,
        isWorking: false,
    })

    const onEditConnectionString = () => {
        setState((prev) => ({ ...prev, isEditConnectionVisible: true }))
    }

    const closeIcon = () => (state.isWorking ? <div /> : '')

    const onClose = () => {
        setState((prev) => ({ ...prev, isEditConnectionVisible: false }))
    }

    return (
        <Grid columns="900px 1fr" className={styles.container}>
            <Grid rows="auto auto auto  1fr" gap={16}>
                <Heading type="heading1">SQL Settings</Heading>
                <Grid gap={16} style={{ marginLeft: '10px' }}>
                    <Grid columns="auto auto auto 1fr">
                        <CopyableInput
                            width={650}
                            headerText={'InVision Database ConnectionString'}
                            value={deployment.database.connectionString}
                            readOnly
                        />
                        <Button
                            style={{ marginTop: '21px', marginLeft: '5px' }}
                            onClick={onEditConnectionString}
                            buttonType={ButtonType.Icon}
                            className={styles.btn}
                            title="Edit connectionstring"
                        >
                            <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                                <EditIcon height={26} width={26} />
                                <div>Edit</div>
                            </Grid>
                        </Button>
                        <Button
                            style={{ marginTop: '21px', marginLeft: '2px' }}
                            onClick={onAzureSqlEdit}
                            buttonType={ButtonType.Icon}
                            className={styles.btn}
                            title="Renew password"
                        >
                            <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                                <RefreshIcon height={26} width={26} />
                                <div>Renew</div>
                            </Grid>
                        </Button>
                    </Grid>
                    <CopyableInput
                        width={650}
                        headerText={'InVision Database Name'}
                        value={deployment.database.databaseName}
                        readOnly
                    />
                    <CopyableInput
                        width={650}
                        headerText={'InVision Database Owner'}
                        value={deployment.database.owner}
                        readOnly
                    />
                </Grid>
                <div></div>

                {state?.isEditConnectionVisible && (
                    <Modal
                        open={state?.isEditConnectionVisible}
                        closeOnOverlayClick={false}
                        onClose={() => {
                            if (!state?.isWorking) {
                                setState((prev) => ({
                                    ...prev,
                                    isEditConnectionVisible: false,
                                }))
                            }
                        }}
                        classNames={{
                            modal: styles.customModal,
                        }}
                        closeIcon={closeIcon()}
                    >
                        <EditSqlConnectionString
                            productType={ProductType.Invision}
                            deploymentId={deployment.deploymentId}
                            connectionString={deployment.database.connectionString}
                            onClose={onClose}
                        />
                    </Modal>
                )}
            </Grid>
        </Grid>
    )
}

export default AzureSQLDetails
