import { FunctionComponent, useContext, useState } from 'react'
import styles from './../../deployments/edit/Edit.module.scss'
import css from './EditFlowScaling.module.scss'
import { FlowInstanceModel } from '../../Model/AllSparkModel'
import { AllSparkContext } from '../../../..'
import { useGlobalStateContext } from '../../../../GlobalState'
import Grid from '../../../infratructure/grid/Grid'
import Button, { ButtonType } from '../../../infratructure/button/Button'
import LoaderDots from '../../../infratructure/dots/LoaderDots'
import Input from '../../../infratructure/input/Input'
import Heading from '../../../infratructure/heading/Heading'

interface EditFlowScalingDetailsProps {
    deployment: FlowInstanceModel | undefined
    onCancel: () => void
    onSave: () => void
    onClose: () => void
    isWorking: boolean
}

type EditScalingDetailsState = {
    abafarMessageCount?: number
    abafarMemoryRequest: number
    abafarMemoryLimit: number

    anaxesMemoryRequest: number
    anaxesMemoryLimit: number

    corelliaMemoryRequest: number
    corelliaMemoryLimit: number
    corelliaCPURequest: number
    corelliaCPULimit: number

    designerMemoryRequest: number
    designerMemoryLimit: number

    portalMemoryRequest: number
    portalMemoryLimit: number

    rishiMemoryRequest: number
    rishiMemoryLimit: number

    isDone: boolean
    isWorking: boolean
    hasErrors: boolean
}

const EditFlowScalingDetails: FunctionComponent<EditFlowScalingDetailsProps> = ({
    deployment,
    onCancel,
    onSave,
    onClose,
    isWorking,
}) => {
    const api = useContext(AllSparkContext)
    const { setGlobalState } = useGlobalStateContext()

    const [state, setState] = useState<EditScalingDetailsState>({
        abafarMessageCount: deployment?.scaling.abafar.messageCount,
        abafarMemoryRequest: deployment?.scaling.abafar.memory.request || 2000,
        abafarMemoryLimit: deployment?.scaling.abafar.memory.limit || 5000,

        anaxesMemoryRequest: deployment?.scaling.anaxes.memory.request || 500,
        anaxesMemoryLimit: deployment?.scaling.anaxes.memory.limit || 5000,

        corelliaMemoryRequest: deployment?.scaling.corellia.memory.request || 2000,
        corelliaMemoryLimit: deployment?.scaling.corellia.memory.limit || 5000,
        corelliaCPURequest: deployment?.scaling.corellia.cpu?.request || 0,
        corelliaCPULimit: deployment?.scaling.corellia.cpu?.limit || 0,

        designerMemoryRequest: deployment?.scaling.designer.memory.request || 200,
        designerMemoryLimit: deployment?.scaling.designer.memory.limit || 2000,

        portalMemoryRequest: deployment?.scaling.portal.memory.request || 200,
        portalMemoryLimit: deployment?.scaling.portal.memory.limit || 2000,

        rishiMemoryRequest: deployment?.scaling.rishi.memory.request || 500,
        rishiMemoryLimit: deployment?.scaling.rishi.memory.limit || 5000,

        isDone: false,
        isWorking: isWorking,
        hasErrors: false,
    })

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }))
    }

    const handleSave = async () => {
        onSave()
        setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: true }))
        try {
            setState((prev) => ({ ...prev, isWorking: true }))
            let request = {
                abafar: {
                    messageCount: state.abafarMessageCount,
                    memory: {
                        request: state.abafarMemoryRequest,
                        limit: state.abafarMemoryLimit,
                    },
                },
                anaxes: {
                    memory: {
                        request: state.anaxesMemoryRequest,
                        limit: state.anaxesMemoryLimit,
                    },
                },
                corellia: {
                    memory: {
                        request: state.corelliaMemoryRequest,
                        limit: state.corelliaMemoryLimit,
                    },
                    cpu: {
                        request: state.corelliaCPURequest,
                        limit: state.corelliaCPULimit,
                    },
                },
                designer: {
                    memory: {
                        request: state.designerMemoryRequest,
                        limit: state.designerMemoryLimit,
                    },
                },
                portal: {
                    memory: {
                        request: state.portalMemoryRequest,
                        limit: state.portalMemoryLimit,
                    },
                },
                rishi: {
                    memory: {
                        request: state.rishiMemoryRequest,
                        limit: state.rishiMemoryLimit,
                    },
                },
            }

            await api.updateFlowScaling(deployment?.deploymentId!, request)

            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
            setState((prev) => ({ ...prev, isDone: true, isWorking: false }))
        } catch (error) {
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
            setState((prev) => ({ ...prev, isDone: false, isWorking: false, hasErrors: true }))
        }
    }

    return (
        <Grid
            rows="auto auto auto auto auto auto auto auto auto auto auto auto auto auto 1fr"
            gap={0}
            style={{ height: '650px', marginLeft: '10px' }}
        >
            <Heading type="heading1" style={{ marginTop: '20px' }}>
                Edit cluster service scaling values
            </Heading>
            <Heading type="heading2" style={{ marginTop: '20px' }}>
                The application will be restarted when this config is saved.
            </Heading>
            <Heading type="heading2" style={{ marginTop: '20px' }}>
                Abafar
            </Heading>
            <Grid
                rows="auto auto auto 1fr"
                columns="400px 100px"
                gap={5}
                style={{ marginTop: '15px', marginLeft: '10px' }}
            >
                <span className={css.label}>Message Count limit before scaling up</span>
                <Input
                    name="abafarMessageCount"
                    value={state.abafarMessageCount}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service Memory Request (Mi)</span>
                <Input
                    name="abafarMemoryRequest"
                    value={state.abafarMemoryRequest}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service Memory Limit (Mi)</span>
                <Input
                    name="abafarMemoryLimit"
                    value={state.abafarMemoryLimit}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
            </Grid>

            <Heading type="heading2" style={{ marginTop: '20px' }}>
                Anaxes
            </Heading>
            <Grid
                rows="auto auto auto 1fr"
                columns="400px 100px"
                gap={5}
                style={{ marginTop: '15px', marginLeft: '10px' }}
            >
                <span className={css.label}>Service Memory Request (Mi)</span>
                <Input
                    name="anaxesMemoryRequest"
                    value={state.anaxesMemoryRequest}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service Memory Limit (Mi)</span>
                <Input
                    name="anaxesMemoryLimit"
                    value={state.anaxesMemoryLimit}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
            </Grid>

            <Heading type="heading2" style={{ marginTop: '20px' }}>
                Corellia
            </Heading>
            <Grid
                rows="auto auto auto 1fr"
                columns="400px 100px"
                gap={5}
                style={{ marginTop: '15px', marginLeft: '10px' }}
            >
                <span className={css.label}>Service Memory Request (Mi)</span>
                <Input
                    name="corelliaMemoryRequest"
                    value={state.corelliaMemoryRequest}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service Memory Limit (Mi)</span>
                <Input
                    name="corelliaMemoryLimit"
                    value={state.corelliaMemoryLimit}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service CPU Request (m)</span>
                <Input
                    name="corelliaCPURequest"
                    value={state.corelliaCPURequest}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service CPU Limit (m)</span>
                <Input
                    name="corelliaCPULimit"
                    value={state.corelliaCPULimit}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
            </Grid>

            <Heading type="heading2" style={{ marginTop: '20px' }}>
                Designer
            </Heading>
            <Grid
                rows="auto auto auto 1fr"
                columns="400px 100px"
                gap={5}
                style={{ marginTop: '15px', marginLeft: '10px' }}
            >
                <span className={css.label}>Service Memory Request (Mi)</span>
                <Input
                    name="designerMemoryRequest"
                    value={state.designerMemoryRequest}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service Memory Limit (Mi)</span>
                <Input
                    name="designerMemoryLimit"
                    value={state.designerMemoryLimit}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
            </Grid>

            <Heading type="heading2" style={{ marginTop: '20px' }}>
                Portal
            </Heading>
            <Grid
                rows="auto auto auto 1fr"
                columns="400px 100px"
                gap={5}
                style={{ marginTop: '15px', marginLeft: '10px' }}
            >
                <span className={css.label}>Service Memory Request (Mi)</span>
                <Input
                    name="portalMemoryRequest"
                    value={state.portalMemoryRequest}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service Memory Limit (Mi)</span>
                <Input
                    name="portalMemoryLimit"
                    value={state.portalMemoryLimit}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
            </Grid>

            <Heading type="heading2" style={{ marginTop: '20px' }}>
                Rishi
            </Heading>
            <Grid
                rows="auto auto auto 1fr"
                columns="400px 100px"
                gap={5}
                style={{ marginTop: '15px', marginLeft: '10px' }}
            >
                <span className={css.label}>Service Memory Request (Mi)</span>
                <Input
                    name="rishiMemoryRequest"
                    value={state.rishiMemoryRequest}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service Memory Limit (Mi)</span>
                <Input
                    name="rishiMemoryLimit"
                    value={state.rishiMemoryLimit}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
            </Grid>

            <Grid
                columns="1fr auto auto auto auto"
                style={{ height: '60px', marginTop: '20px', marginRight: '10px' }}
                gap={10}
            >
                {state.isWorking && !state.isDone ? (
                    <Grid columns="auto auto 1fr" style={{ height: '30px' }} gap={10}>
                        <Grid style={{ alignItems: 'center' }}>Updating</Grid>
                        <Grid style={{ alignItems: 'center' }}>
                            <LoaderDots color="black" />
                        </Grid>
                    </Grid>
                ) : (
                    <div />
                )}
                {!state.isWorking && state.isDone ? (
                    <Grid columns="auto 1fr" style={{ height: '30px' }} gap={10}>
                        <Grid style={{ alignItems: 'center' }}>Done!</Grid>
                        <div />
                    </Grid>
                ) : (
                    <div />
                )}
                {state.hasErrors ? (
                    <Grid columns="auto 1fr" style={{ height: '30px' }} gap={10}>
                        <Grid style={{ alignItems: 'center' }}>Failed! Check System Log for details.</Grid>
                        <div />
                    </Grid>
                ) : (
                    <div />
                )}
                {!state.isDone ? (
                    <Button
                        className={styles.deploybtn}
                        buttonType={ButtonType.Default}
                        onClick={onCancel}
                        disabled={state.isWorking}
                    >
                        Cancel
                    </Button>
                ) : (
                    <div />
                )}
                <Button
                    className={styles.deploybtn}
                    buttonType={ButtonType.Confirm}
                    onClick={state.isDone ? onClose : handleSave}
                    disabled={state.isWorking}
                >
                    {state.isDone ? 'Close' : 'Save'}
                </Button>
            </Grid>
        </Grid>
    )
}

export default EditFlowScalingDetails
