import { FunctionComponent } from 'react'
import { FailedDeploymentModel } from '../Model/AllSparkModel'
import Grid from '../../infratructure/grid/Grid'
import Heading from '../../infratructure/heading/Heading'
import Input from '../../infratructure/input/Input'
import styles from './FailedDeploymentDetails.module.scss'
import ReactJson from 'react-json-view'

interface FailedDeploymentDetailProps {
    selectedFailedDeployment?: FailedDeploymentModel
}

const FailedDeploymentDetails: FunctionComponent<FailedDeploymentDetailProps> = ({ selectedFailedDeployment }) => {
    const getParsedlog = (log: string) => {
        if (!selectedFailedDeployment) return

        try {
            return JSON.parse(log)
        } catch (ex) {
            return { root: 'unable to parse log', log: log }
        }
    }

    const getReadableDatetime = (datetime: string) => {
        if (datetime && datetime.length > 0) {
            const localDateTime = new Date(datetime)
            return `${localDateTime.toDateString()} ${localDateTime.toTimeString()}`
        }

        return ''
    }
    return (
        <Grid columns="900px 1fr" className={styles.container}>
            <Grid rows="auto 1fr" gap={16} style={{ overflow: 'hidden' }}>
                <Grid columns="360px 1fr " gap={16} style={{ marginLeft: '10px' }}>
                    <Input
                        headerText={'Deployment Name'}
                        value={selectedFailedDeployment?.deploymentName || ''}
                        readOnly
                    />
                    <Input
                        headerText={'Timestamp'}
                        value={getReadableDatetime(selectedFailedDeployment?.timestamp || '')}
                        readOnly
                    />
                </Grid>

                <Grid rows="auto 1fr" style={{ marginLeft: '10px', overflow: 'hidden' }}>
                    <Heading type="normal">Log</Heading>
                    <div
                        style={{
                            margin: '4px 0 0px 0',
                            borderTop: '1px solid var(--theme-border-color)',
                            borderLeft: '1px solid var(--theme-border-color)',
                            borderRight: '1px solid var(--theme-border-color)',
                            padding: '10px',
                            overflow: 'auto',
                        }}
                    >
                        <ReactJson
                            name={null}
                            collapsed={2}
                            displayDataTypes={false}
                            displayObjectSize={false}
                            enableClipboard={false}
                            src={getParsedlog(selectedFailedDeployment?.logs || '')}
                        />
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FailedDeploymentDetails
