import { FunctionComponent } from 'react'
import Button, { ButtonType } from '../../infratructure/button/Button'
import Grid from '../../infratructure/grid/Grid'
import PlusIcon from '../../icons/PlusIcon'
import TrashIcon from '../../icons/TrashIcon'
import styles from './DeploymentsToolbar.module.scss'
import SaveIcon from '../../icons/SaveIcon'
import UpgradeIcon2 from '../../icons/UpgradeIcon2'
import SettingsCogIcon from '../../icons/SettingsCogIcon'
import OnIcon from '../../icons/toggle/OnIcon'
import OffIcon from '../../icons/toggle/OffIcon'
import { Toggle } from '../Model/AllSparkModel'
import RebootIcon from '../../icons/RebootIcon'
import CopyIcon from '../../icons/CopyIcon'

interface DeploymentsToolbarProps {
    isEnabled: boolean
    isWorking: boolean
    hasItemSelected: boolean
    onCreate: () => void
    onClone: () => void
    onDelete: () => void
    onUpgrade: () => void
    onSave?: () => void
    onSettings?: () => void
    onToggle: (toggle: Toggle) => void
    onReboot: () => void
}

const DeploymentsToolbar: FunctionComponent<DeploymentsToolbarProps> = ({
    isEnabled,
    isWorking,
    hasItemSelected,
    onCreate,
    onClone,
    onDelete,
    onUpgrade,
    onSave,
    onSettings,
    onToggle,
    onReboot,
}) => {
    const toggle = () => {
        onToggle(isEnabled ? Toggle.Disable : Toggle.Enable)
    }

    return (
        <Grid className={styles.container} columns="auto auto auto auto auto auto auto auto 1fr" gap={16}>
            <Button
                onClick={onCreate}
                buttonType={ButtonType.Icon}
                className={styles.btn}
                disabled={isWorking}
                title="New Deployment"
            >
                <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                    <PlusIcon height={26} width={26} />
                    <div>New</div>
                </Grid>
            </Button>

            <Button
                onClick={onSave}
                buttonType={ButtonType.Icon}
                className={styles.btn}
                disabled={true}
                title="Save Deployment"
            >
                <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                    <SaveIcon height={26} width={26} />
                    <div>Save</div>
                </Grid>
            </Button>

            <Button
                onClick={onUpgrade}
                buttonType={ButtonType.Icon}
                className={styles.btn}
                disabled={isWorking || !hasItemSelected || isEnabled === false}
                title="Upgrade Deployment"
            >
                <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                    <UpgradeIcon2 />
                    <div>Upgrade</div>
                </Grid>
            </Button>

            <Button
                onClick={onClone}
                buttonType={ButtonType.Icon}
                className={styles.btn}
                disabled={isEnabled === false || isWorking || !hasItemSelected}
                title="Clone Deployment and it's connected Flow Deployment"
            >
                <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                    <CopyIcon height={26} width={26} />
                    <div>Clone</div>
                </Grid>
            </Button>

            <Button
                onClick={onDelete}
                buttonType={ButtonType.Icon}
                className={styles.btn}
                disabled={isWorking || !hasItemSelected}
                title="Delete Deployment"
            >
                <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                    <TrashIcon height={26} width={26} />
                    <div>Delete</div>
                </Grid>
            </Button>

            <Button
                onClick={onReboot}
                buttonType={ButtonType.Icon}
                className={styles.btn}
                disabled={isWorking || !isEnabled}
                title="Reboot instance"
            >
                <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                    <RebootIcon height={26} width={26} />
                    <div>Reboot</div>
                </Grid>
            </Button>

            <Button
                onClick={onSettings}
                buttonType={ButtonType.Icon}
                className={styles.btn}
                disabled={isWorking}
                title="Deployment Settings"
            >
                <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                    <SettingsCogIcon height={26} width={26} />
                    <div>Settings</div>
                </Grid>
            </Button>

            {isEnabled && (
                <Button
                    onClick={toggle}
                    buttonType={ButtonType.Icon}
                    className={styles.btn}
                    disabled={isWorking}
                    title="Disable instance"
                >
                    <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                        <OffIcon height={26} width={26} />
                        <div>Disable</div>
                    </Grid>
                </Button>
            )}

            {!isEnabled && (
                <Button
                    onClick={toggle}
                    buttonType={ButtonType.Icon}
                    className={styles.btn}
                    disabled={isWorking}
                    title="Enable instance"
                >
                    <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                        <OnIcon height={26} width={26} />
                        <div>Enable</div>
                    </Grid>
                </Button>
            )}
        </Grid>
    )
}

export default DeploymentsToolbar
