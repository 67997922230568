import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styles from './ActionList.module.scss'
import cx from 'classnames'
import Button, { ButtonType } from '../button/Button'
import Grid from '../grid/Grid'

export type ActionListItem = {
    text?: string
    onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    icon?: JSX.Element
}

type Props = {
    actions: ActionListItem[]
    title?: string
    tooltip?: string
    icon?: JSX.Element
    iconWidth?: number
    buttonType?: ButtonType
    disabled?: boolean
    className?: string
    showOnlyOnHover?: boolean
    horizontalAlign?: 'left' | 'center' | 'right'
    stopPropagation?: boolean
}

const MARGIN = 5

export default function ActionList({
    actions = [],
    className,
    title,
    tooltip,
    icon,
    iconWidth,
    buttonType = ButtonType.Icon,
    disabled,
    horizontalAlign = 'right',
    showOnlyOnHover = false,
    stopPropagation = true,
}: Props) {
    const [style, setStyle] = React.useState<React.CSSProperties>({ top: 0, left: 0 })
    const [isOpen, setIsOpen] = React.useState(false)
    const [listDimensions, setListDimensions] = useState({ width: 0, height: 0 })

    const actionsText = 'Actions'
    const menuButtonRef = React.useRef<HTMLButtonElement>(null)
    const listRef = React.useRef<HTMLDivElement>(null)
    const itemIndexRef = React.useRef(-1)

    const open = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        stopPropagation && e.stopPropagation()
        // Note! e.preventDefault() was added 14.june 21.
        // Reason is the AddVersionDialog (WorkProcess): 1.Click on button will close dialog. 2.Focus moves to next field that requires input.
        e.preventDefault()
        setIsOpen(true)
    }

    React.useLayoutEffect(() => {
        if (listRef.current) {
            setListDimensions({
                width: listRef.current.offsetWidth,
                height: listRef.current.offsetHeight,
            })

            listRef.current.focus()
        }
    }, [isOpen])

    React.useLayoutEffect(() => {
        const alignVertical = (rect: DOMRect) => {
            //if there is no space under icon then try position abow
            if (rect.bottom + listDimensions.height > window.innerHeight) {
                //if there is not enough space over icon, leave it below
                if (rect.top - listDimensions.height < MARGIN) return rect.bottom
                //position over
                return rect.top - listDimensions.height
            }
            //position under
            return rect.bottom
        }

        const alignHorizontal = (rect: DOMRect) => {
            switch (horizontalAlign) {
                case 'left':
                    return rect.left
                case 'center':
                    return rect.right - rect.width / 2 - listDimensions.width / 2
                case 'right':
                    return rect.right - listDimensions.width
            }
        }

        const adjustHorizontallyToScreen = (left: number) => {
            //if there is no space left and right just return original position
            if (left + listDimensions.width > window.innerWidth && left < 0) {
                return left
            }
            //nudge it left if not enough space on the right side
            if (left + listDimensions.width > window.innerWidth) {
                return left - (left + listDimensions.width - window.innerWidth) - MARGIN
            }

            //nudge it right if not enough space on the left side
            if (left < 0) {
                return MARGIN
            }

            //there is enough space
            return left
        }

        const rect = menuButtonRef.current!.getBoundingClientRect()
        const top = alignVertical(rect)
        const left = adjustHorizontallyToScreen(alignHorizontal(rect))

        setStyle((style) => ({
            ...style,
            top,
            left,
        }))
    }, [listDimensions, horizontalAlign])

    const focusActionListItem = (index: number) => {
        if (!listRef.current) {
            return
        }

        const child = listRef.current.querySelector(`div[data-list-index="${index}"]`)
        if (child) {
            ;(child as HTMLDivElement).focus()
        }
    }

    React.useEffect(() => {
        function handleKeyboard(e: KeyboardEvent) {
            if (e.code === 'Escape') {
                setIsOpen(false)
            } else if (e.code === 'ArrowDown') {
                if (itemIndexRef.current < actions.length - 1) {
                    itemIndexRef.current++
                }
                focusActionListItem(itemIndexRef.current)
                e.stopPropagation()
            } else if (e.code === 'ArrowUp') {
                if (itemIndexRef.current > 0) {
                    itemIndexRef.current--
                }
                focusActionListItem(itemIndexRef.current)
                e.stopPropagation()
            }
        }

        window.addEventListener('keydown', handleKeyboard)
        return () => {
            window.removeEventListener('keydown', handleKeyboard)
        }
    }, [isOpen, actions])

    const handleOnActionListItemClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, item: ActionListItem) => {
        menuButtonRef?.current?.focus() // Set focus back to the button so the list can be reopened using the keyboard
        item.onClick(e)
        setIsOpen(false)
    }

    const handleActionListItemKeydown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.code === 'Enter' || e.code === 'Space') {
            ;(e.target as HTMLDivElement).click()
        }
    }

    const handleBackDropClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation()
        setIsOpen(false)
    }

    return (
        <>
            <Button
                data-testid="actions"
                className={cx(
                    styles.button,
                    {
                        [styles.showOnlyOnHover]: showOnlyOnHover,
                        [styles.isOpen]: isOpen,
                    },
                    className,
                )}
                ref={menuButtonRef}
                // defaultPadding={false}
                onClick={open}
                disabled={disabled || actions.length === 0}
                style={{ width: iconWidth }}
                buttonType={buttonType}
                aria-haspopup={true}
                aria-expanded={isOpen}
                title={tooltip || actionsText}
                aria-label={tooltip || actionsText}
            >
                <Grid columns="auto auto" gap={title && icon ? 4 : 0}>
                    {icon}
                    {title}
                </Grid>
            </Button>
            {isOpen
                ? ReactDOM.createPortal(
                      <div className={styles.backdrop} onClick={handleBackDropClick}>
                          <div
                              className={cx('pb-actionlist', styles.actionList)}
                              style={style}
                              ref={listRef}
                              tabIndex={0}
                          >
                              {actions.map((action, index) => (
                                  <div
                                      tabIndex={0}
                                      key={index}
                                      className={cx(styles.item)}
                                      onClick={(e) => handleOnActionListItemClick(e, action)}
                                      onKeyUp={handleActionListItemKeydown}
                                      data-list-index={index}
                                  >
                                      {action.icon ? (
                                          <Grid columns="auto 1fr" className={styles.actionContainer} gap={8}>
                                              {action.icon}
                                              {action.text}
                                          </Grid>
                                      ) : (
                                          <div className={styles.actionContainer}>{action.text}</div>
                                      )}
                                  </div>
                              ))}
                          </div>
                      </div>,
                      document.body,
                  )
                : null}
        </>
    )
}
