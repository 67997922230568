import React, { CSSProperties, FunctionComponent } from 'react';
import cx from 'classnames';
import styles from './Grid.module.css';

type Props = {
  rows?: string | string[];
  columns?: string | string[];
  row?: string | number;
  rowSpan?: string | number;
  column?: string | number;
  columnSpan?: string | number;
  areas?: string | string[];
  gap?: string | number;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const getGridPlacement = (
  row?: number | string,
  column?: number | string,
  rowSpan?: number | string,
  columnSpan?: number | string
) => {
  let gridStyle: CSSProperties = {};

  if (row != null && rowSpan) {
    gridStyle.gridRow = `${row} / span ${rowSpan}`;
  } else if (rowSpan) {
    gridStyle.gridRow = `span ${rowSpan}`;
  } else if (row != null) {
    gridStyle.gridRow = row;
  }

  if (column != null && columnSpan) {
    gridStyle.gridColumn = `${column} / span ${columnSpan}`;
  } else if (columnSpan) {
    gridStyle.gridColumn = `span ${columnSpan}`;
  } else if (column != null) {
    gridStyle.gridColumn = column;
  }

  return gridStyle;
};

export const Grid = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      rows,
      columns,
      row,
      rowSpan,
      column,
      columnSpan,
      areas,
      gap,
      style = {},
      children,
      ...props
    }: Props,
    ref
  ) => {
    const gridStyle = {
      ...style,
      gridTemplateColumns: Array.isArray(columns) ? columns.join(' ') : columns,
      gridTemplateRows: Array.isArray(rows) ? rows.join(' ') : rows,
      gridTemplateAreas: Array.isArray(areas)
        ? `'${areas.join("' '")}'`
        : areas,
      ...getGridPlacement(row, column, rowSpan, columnSpan),
      gap: gap,
    };
    return (
      <div
        className={cx(styles.grid, className)}
        style={gridStyle}
        {...props}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

type GridCellProps = {
  row?: number | string;
  column?: number | string;
  rowSpan?: number | string;
  columnSpan?: number | string;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export const GridCell: FunctionComponent<GridCellProps> = ({
  row,
  column,
  rowSpan,
  columnSpan,
  style = {},
  className,
  children,
  ...props
}) => {
  const gridCellStyle: React.CSSProperties = {
    ...style,
    ...getGridPlacement(row, column, rowSpan, columnSpan),
  };

  return (
    <div
      className={cx(styles.gridCell, className)}
      style={gridCellStyle}
      {...props}
    >
      {children}
    </div>
  );
};

export default Grid;
