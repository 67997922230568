import * as React from 'react'
import ReactSelect, { Options, OptionProps, SingleValueProps, components } from 'react-select'
import { colors, style } from './SelectResources'
import { FunctionComponent } from 'react'
import styles from './Select.module.scss'
import Heading, { HeadingType } from '../heading/Heading'
import Grid from '../grid/Grid'

type HeaderPlacement = 'top' | 'left'

export interface OptionType {
    label: string
    value: string | number
}

export type SelectType<T> = {
    headerText?: string
    headerPlacement?: HeaderPlacement
    required?: boolean
    headingType?: HeadingType
    border?: boolean
    options?: Options<T>
    value?: string | number
    onChange?: (value: T) => void
    optionsRenderer?: FunctionComponent<T>
    selectedItemRenderer?: FunctionComponent<T>
    isDisabled?: boolean
    isClearable?: boolean
    isSearchable?: boolean
    tabIndex?: number | null
    rightAlign?: boolean
    ariaLabelledBy?: string
    placeholder?: string
}

const Select = <T extends OptionType>({
    headerText,
    headerPlacement = 'top',
    required = false,
    headingType = 'normal',
    options,
    border = true,
    value,
    onChange,
    optionsRenderer,
    selectedItemRenderer,
    isDisabled,
    tabIndex,
    isClearable = true,
    isSearchable,
    rightAlign,
    ariaLabelledBy,
    placeholder = '',
}: SelectType<T>) => {
    const ref = React.useRef<any>(null)

    React.useEffect(() => {
        // Because there's a "bug" in react-select, the required attribute is not (internally) passed down to the input element
        // so we need to use this "hack" to set it (WCAG)
        if (required && ref?.current?.inputRef) {
            const inputRef = ref.current.inputRef as unknown as HTMLInputElement
            if (!inputRef.hasAttribute('required')) {
                inputRef.setAttribute('required', '')
            }
        }
    }, [required])

    const SingleValue: FunctionComponent<SingleValueProps<T>> = (props: SingleValueProps<T>) => {
        const { getStyles, innerProps, data } = props
        return (
            // @ts-ignore
            // Complains because div does not have a "label" property...
            <div {...innerProps} style={getStyles('singleValue', props)}>
                {selectedItemRenderer ? (
                    React.createElement(selectedItemRenderer, data)
                ) : (
                    <DefaultDataRenderer {...data} />
                )}
            </div>
        )
    }

    const Option: FunctionComponent<OptionProps<T>> = (props: OptionProps<T>) => {
        const { getStyles, innerRef, innerProps, data } = props
        return (
            // @ts-ignore
            // Complains because div does not have a "label" property...
            <div ref={innerRef} {...innerProps} style={getStyles('option', props)}>
                {optionsRenderer ? React.createElement(optionsRenderer, data) : <DefaultDataRenderer {...data} />}
            </div>
        )
    }

    const NoOptionsMessage: React.FunctionComponent = (props: any) => {
        // @ts-ignore
        return <components.NoOptionsMessage {...props}>{noOptionsText}</components.NoOptionsMessage>
    }

    const handleOnChange = (value: any) => {
        if (onChange) {
            onChange(value as T)
        }
    }

    const selectedOption = options?.find((item: any) => item.value === value) ?? null

    const DefaultDataRenderer = ({ label }: T) => {
        return <span>{label}</span>
    }

    function RSelect() {
        return (
            <ReactSelect
                aria-labelledby={ariaLabelledBy}
                aria-label={headerText}
                className="pb-select"
                classNamePrefix="pb-select"
                isClearable={isClearable}
                isSearchable={isSearchable}
                isMulti={false}
                isDisabled={isDisabled}
                value={selectedOption}
                options={options}
                onChange={handleOnChange}
                styles={style}
                placeholder={placeholder}
                tabIndex={tabIndex == null ? undefined : tabIndex}
                theme={colors}
                menuPortalTarget={document.body}
                menuPlacement="auto"
                closeMenuOnScroll={true}
                components={{ SingleValue, Option, NoOptionsMessage }}
                ref={ref}
                // @ts-ignore
                border={border}
                rightAlign={rightAlign}
            />
        )
    }

    if (!headerText) {
        return <RSelect />
    }

    return (
        <Grid className={headerPlacement === 'top' ? styles.rows : styles.columns} gap={4}>
            <Heading
                type={headingType}
                requiredMarker={required}
                style={headerPlacement === 'left' ? { alignSelf: 'center' } : undefined}
            >
                {headerText}
            </Heading>
            <RSelect />
        </Grid>
    )
}

export default Select
