import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import ButtonNavigator from '../../infratructure/buttonNavigator/ButtonNavigator'
import Grid from '../../infratructure/grid/Grid'
import styles from './Deployments.module.scss'
import { DeploymentModel, EnvironmentModel, ProductType, Toggle, VersionResponse } from '../Model/AllSparkModel'
import DeploymentList from './DeploymentList'
import DeploymentDetails from './DeploymentDetails'
import Heading from '../../infratructure/heading/Heading'
import { AllSparkContext } from '../../..'
import DeploymentsToolbar from './DeploymentsToolbar'
import Modal from 'react-responsive-modal'
import NewDeployment from './new/NewDeployment'
import cx from 'classnames'
import { ArrayUtil } from '../../utils/ArrayUtil'
import UpgradeDeployment from './upgrade/UpgradeDeployment'
import DeleteDeploymentConfirmation from './delete/DeleteDeploymentConfigrmation'
import useAsyncError from '../../infratructure/hooks/useAsyncError'
import { useGlobalStateContext } from '../../../GlobalState'
import EditAzureApplicationsDetails from './edit/EditAzureApplicationsDetails'
import EditAzureBlobContainerDetails from './edit/EditAzureBlobContainerDetails'
import EditAzureSqlDetails from './edit/EditAzureSqlDetails'
import EditOverViewDetails from './edit/EditOverViewDetails'
import EditAzureServiceBus from './edit/EditAzureServiceBus'
import ConnectToFlow from '../flow/connectToFlow/ConnectToFlow'
import EditScalingDetails from './edit/EditScalingDetails'
import EditDeploymentSettings from './EditDeploymentSettings'
import Confirmation from '../shared/Confirmation'
import DisconnectFromFlow from '../flow/disconnectFromFlow/DisconnectFromFlow'
import EditDefaultLanguage from './edit/EditDefaultLanguage'
import ConfirmDialog from '../shared/ConfirmDialog'
import Select from '../../infratructure/select/Select'
import CloneDeployment from './clone/CloneDeployment'

export enum DeploymentMainPanels {
    Overview,
    Scaling,
    Advanced,
}

export enum DeploymentAdvancedPanels {
    Applications = 'Applications',
    Authentication = 'Authentication',
    CloudStorage = 'Cloud storage',
    Servicebus = 'Service Bus',
    SQL = 'SQL',
}

type DeploymentState = {
    isNewOpen: boolean
    isUpgradeDialogVisible: boolean
    isWorking: boolean
    isCloneDialogVisible: boolean
    isDeleteDialogVisible: boolean
    isValidating: boolean
    hasError: boolean
    isAzureApplicationsEditOpen: boolean
    isAzureBlobContainerEditOpen: boolean
    isAzureSqlEditOpen: boolean
    isAzureServiceBusEditOpen: boolean
    isEditOpen: boolean
    versions: VersionResponse | undefined
    target: string
    isConnectToFlowVisible: boolean
    isDisconnectFromFlowVisible: boolean
    isEditScalingOpen: boolean
    isDeploymentSettingsDialogVisible: boolean
    isToggleInstanceDialogVisible: boolean
    currentToggle: Toggle
    disabledFlowInstanceIsInUse: boolean
    isEditDefaultLanguageOpen: boolean
    isRebootConfirmDialogOpen: boolean
    completedMessage: string
    isFinished: boolean
    environments: EnvironmentModel[]
    selectedEnvironmentFilter: string
    filterText: string
    allDeployments: DeploymentModel[]
}

const Deployments: FunctionComponent = () => {
    const api = useContext(AllSparkContext)

    const {
        globalState: { deployments, flows, selectedDeploymentId, clusterControllers: clusterControllers },
        setGlobalState,
    } = useGlobalStateContext()
    const throwError = useAsyncError()

    const [selectedDetailsSection, setSelectedDetailsSection] = useState<DeploymentMainPanels>(
        DeploymentMainPanels.Overview,
    )

    const selectedDeployment = useMemo(
        () => deployments.find((dep) => dep.deploymentId === selectedDeploymentId),
        [deployments, selectedDeploymentId],
    )

    const [
        {
            isNewOpen,
            isUpgradeDialogVisible,
            isWorking,
            isCloneDialogVisible,
            isDeleteDialogVisible,
            isValidating,
            hasError,
            isAzureApplicationsEditOpen,
            isAzureBlobContainerEditOpen,
            isAzureSqlEditOpen,
            isAzureServiceBusEditOpen,
            isEditOpen,
            versions,
            target,
            isConnectToFlowVisible,
            isDisconnectFromFlowVisible,
            isEditScalingOpen,
            isDeploymentSettingsDialogVisible,
            isToggleInstanceDialogVisible,
            currentToggle,
            disabledFlowInstanceIsInUse,
            isEditDefaultLanguageOpen,
            isRebootConfirmDialogOpen,
            completedMessage,
            isFinished,
            environments,
            selectedEnvironmentFilter,
            filterText,
            allDeployments,
        },
        setState,
    ] = useState<DeploymentState>({
        isWorking: false,
        isCloneDialogVisible: false,
        isDeleteDialogVisible: false,
        isNewOpen: false,
        isUpgradeDialogVisible: false,
        isValidating: false,
        hasError: false,
        isAzureApplicationsEditOpen: false,
        isAzureBlobContainerEditOpen: false,
        isAzureSqlEditOpen: false,
        isAzureServiceBusEditOpen: false,
        isEditOpen: false,
        versions: undefined,
        target: '',
        isConnectToFlowVisible: false,
        isDisconnectFromFlowVisible: false,
        isEditScalingOpen: false,
        isDeploymentSettingsDialogVisible: false,
        isToggleInstanceDialogVisible: false,
        currentToggle: selectedDeployment?.enabled === false ? Toggle.Enable : Toggle.Disable,
        disabledFlowInstanceIsInUse: false,
        isEditDefaultLanguageOpen: false,
        isRebootConfirmDialogOpen: false,
        completedMessage: '',
        isFinished: false,
        environments: [],
        selectedEnvironmentFilter: 'All',
        filterText: '',
        allDeployments: [],
    })

    const prevDepsRef = useRef({ filterText })
    
    const onGetData = useCallback(async () => {
        setState((prev) => ({ ...prev, isWorking: true }))
        setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: false }))

        try {
            const allversions = await api.getVersions(ProductType.Invision)
            if (allversions) {
                setState((prev) => ({ ...prev, versions: allversions }))
            }
        } catch (error: any) {
            console.log(error.Message)
        }

        try {
            let clusterControllers = await api.getClusterControllers()
            if (!clusterControllers) {
                clusterControllers = []
            }

            const environments = await api.getEnvironments()
            const deployments = await api.getDeployments()
            const flows = await api.getFlowDeployments()

            if (deployments) {
                setState((prev) => ({
                    ...prev,
                    isWorking: false,
                    environments: environments?.sort(ArrayUtil.sortByPropCompare('name', true)) || [],
                    allDeployments: deployments,
                }))

                let dpl: DeploymentModel[]
                if (selectedEnvironmentFilter !== 'All') {
                    dpl = deployments
                        .filter((d) => d.deploymentRequestParameters.environmentRowKey === selectedEnvironmentFilter)
                        .sort(ArrayUtil.sortByPropCompare('name', true))
                } else {
                    dpl = deployments.sort(ArrayUtil.sortByPropCompare('name', true))
                }

                setGlobalState((prev) => ({
                    ...prev,
                    deployments: dpl,
                    flows: flows.sort(ArrayUtil.sortByPropCompare('name', true)),
                    clusterControllers: clusterControllers,
                }))

                if (!deployments.some((p) => p.deploymentId === selectedDeploymentId)) {
                    setGlobalState((prev) => ({
                        ...prev,
                        selectedDeploymentId: deployments.length > 0 ? deployments[0].deploymentId : undefined,
                    }))
                }
            } else {
                setState((prev) => ({ ...prev, isWorking: false }))
            }
        } catch (error) {
            console.log(
                'Exception : Unable to load data. Press refresh in the browser or check if the backend is running !!',
                error,
            )
            setState((prev) => ({ ...prev, isWorking: false }))
            throwError(error)
        } finally {
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        }
    }, [api, selectedDeploymentId, setGlobalState, throwError])

    // load data on mount
    useEffect(() => {
        if (prevDepsRef.current.filterText === filterText) {
            onGetData()
        }
    }, [onGetData])

    const onConnectToFlow = async () => {
        try {
            const flows = await api.getFlowDeployments()
            if (flows) {
                setState((prev) => ({ ...prev, flows: flows, isConnectToFlowVisible: true }))
            }
        } catch (error: any) {
            console.log(error.Message)
        }
    }

    const onEdit = () => {
        setState((prev) => ({ ...prev, isEditOpen: true }))
    }

    const onEditDefaultLanguage = () => {
        setState((prev) => ({ ...prev, isEditDefaultLanguageOpen: true }))
    }

    const onAzureServiceBusEdit = () => {
        setState((prev) => ({ ...prev, isAzureServiceBusEditOpen: true }))
    }

    const onAzureSqlEdit = () => {
        setState((prev) => ({ ...prev, isAzureSqlEditOpen: true }))
    }

    const onAzureBlobContainerEdit = () => {
        setState((prev) => ({ ...prev, isAzureBlobContainerEditOpen: true }))
    }

    const onAzureApplicationsEdit = () => {
        setState((prev) => ({ ...prev, isAzureApplicationsEditOpen: true }))
    }

    const onDetailsNavButtonClicked = (id: string) => {
        setSelectedDetailsSection(DeploymentMainPanels[id as keyof typeof DeploymentMainPanels])
    }

    const onSelectedDeploymentChange = (deployment: DeploymentModel) => {
        setGlobalState((prev) => ({ ...prev, selectedDeploymentId: deployment.deploymentId }))

        let inUse = false
        if (deployment?.flowInstance !== null && deployment?.flowInstance !== undefined) {
            inUse = flows.some((f) => f.deploymentId === deployment.flowInstance?.deploymentId && f.enabled === false)
        }

        setState((prev) => ({ ...prev, disabledFlowInstanceIsInUse: inUse }))
    }

    const onDeploymentUpgraded = async (newVersion: string) => {
        let dep: DeploymentModel | undefined = deployments.find((d) => d.deploymentId === selectedDeploymentId)

        if (dep && newVersion) {
            dep = {
                ...dep,
                deploymentRequestParameters: { ...dep.deploymentRequestParameters, platformVersion: newVersion },
            }

            setGlobalState((prev) => ({
                ...prev,
                deployments: deployments.map((o) => (o.deploymentId === dep?.deploymentId ? dep : o)),
                selectedDeploymentId: dep?.deploymentId,
            }))
        }

        await refreshDeployments()
    }

    const onDeleteDialogClose = () => {
        setState((prev) => ({ ...prev, isDeleteDialogVisible: false }))
        refreshDeployments()
    }

    const closeIcon = () => (isWorking || isValidating ? <div /> : '')

    const errorMessageModalStyle: React.CSSProperties = {
        height: hasError ? '650px' : 'inherit',
    }

    async function refreshDeployments() {
        let selected = selectedDeployment?.deploymentId
        let newSelected: string | undefined

        const deployments = await api.getDeployments()

        if (deployments.some((d) => d.deploymentId === selected)) {
            newSelected = selected
        } else {
            newSelected = deployments.length > 0 ? deployments[0].deploymentId : undefined
        }

        setState((prev) => ({
            ...prev,
            allDeployments: deployments,
        }))

        setGlobalState((prev) => ({
            ...prev,
            deployments: deployments.sort(ArrayUtil.sortByPropCompare('name', true)),
            selectedDeploymentId: newSelected,
        }))
    }

    const onUpdateBlobContainerClose = async (sucess: boolean) => {
        setState((prev) => ({ ...prev, isAzureBlobContainerEditOpen: false }))
        await refreshDeployments()
    }

    const onEditClose = async (change: boolean, success: boolean) => {
        setState((prev) => ({ ...prev, isEditOpen: false }))
        if (change) {
            await refreshDeployments()
        }
    }

    const onEditDefaultLanguageClose = async (change: boolean, success: boolean) => {
        setState((prev) => ({ ...prev, isEditDefaultLanguageOpen: false }))
        if (change) {
            await refreshDeployments()
        }
    }

    const onSetAuthoriaztionRuleClose = async (change: boolean, success: boolean) => {
        setState((prev) => ({ ...prev, isAzureServiceBusEditOpen: false }))
        if (change) {
            await refreshDeployments()
        }
    }

    const onUpdateSqlClose = async (success: boolean) => {
        setState((prev) => ({ ...prev, isAzureSqlEditOpen: false }))
        await refreshDeployments()
    }

    const onUpdateAuthClose = async (success: boolean) => {
        setState((prev) => ({ ...prev, isAzureApplicationsEditOpen: false }))
        await refreshDeployments()
    }

    const onConnectFlowClose = async () => {
        setState((prev) => ({ ...prev, isConnectToFlowVisible: false }))
        await refreshDeployments()
    }

    const onDisconnectFlowClose = async () => {
        setState((prev) => ({ ...prev, isDisconnectFromFlowVisible: false }))
        await refreshDeployments()
    }

    const handleDeploymentSuccess = (deployment: DeploymentModel) => {
        setState((prev) => ({
            ...prev,
            isNewOpen: false,
        }))

        setGlobalState((prev) => ({
            ...prev,
            deployments: [deployment, ...deployments].sort(ArrayUtil.sortByPropCompare('name', true)),
            selectedDeploymentId: deployment.deploymentId,
        }))
    }

    const handleCloneSuccess = (deployment: DeploymentModel) => {
        setState((prev) => ({
            ...prev,
            isCloneDialogVisible: false,
        }))

        setGlobalState((prev) => ({
            ...prev,
            deployments: [deployment, ...deployments].sort(ArrayUtil.sortByPropCompare('name', true)),
            selectedDeploymentId: deployment.deploymentId,
        }))
    }

    const onCreate = async () => {
        try {
            const flows = await api.getFlowDeployments()
            if (flows) {
                setState((prev) => ({ ...prev, flows: flows, isNewOpen: true }))
            }
        } catch (error: any) {
            console.log(error.Message)
        }
    }

    const onScalingEdit = () => {
        setState((prev) => ({ ...prev, isEditScalingOpen: true }))
    }

    const onEditScalingCancel = () => {
        setState((prev) => ({ ...prev, isEditScalingOpen: false }))
    }

    const onEditScalingClose = async () => {
        setState((prev) => ({ ...prev, isWorking: false, isEditScalingOpen: false }))
        await refreshDeployments()
    }

    const onEditSettingsClose = () => {
        setState((prev) => ({ ...prev, isWorking: false, isDeploymentSettingsDialogVisible: false }))
    }

    const onInstanceToggle = (toogle: Toggle) => {
        setState((prev) => ({ ...prev, isWorking: false, isToggleInstanceDialogVisible: true, currentToggle: toogle }))
    }

    const onInstanceToggleClose = async () => {
        setState((prev) => ({ ...prev, isWorking: false, isToggleInstanceDialogVisible: false }))
        await refreshDeployments()
    }

    const onInstanceToggleCancel = () => {
        setState((prev) => ({ ...prev, isWorking: false, isToggleInstanceDialogVisible: false }))
    }

    const onDisconnectFromFlow = () => {
        setState((prev) => ({ ...prev, isWorking: false, isDisconnectFromFlowVisible: true }))
    }

    const onReboot = () => {
        setState((prev) => ({ ...prev, isRebootConfirmDialogOpen: true }))
    }

    const onConfirmRebootClose = () => {
        setState((prev) => ({ ...prev, isRebootConfirmDialogOpen: false, isFinished: false }))
    }

    const onConfirmRebootCancel = () => {
        setState((prev) => ({ ...prev, isRebootConfirmDialogOpen: false }))
    }

    const onConfirmReboot = async () => {
        setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: true }))
        setState((prev) => ({ ...prev, isWorking: true }))
        try {
            if (selectedDeploymentId) {
                var response = await api.rebootDeployment(selectedDeploymentId)
                if (!response.success) {
                    console.log(response.errorMessage, response.messages)
                }

                let completedMessage = response.success
                    ? `Deployment '` + selectedDeployment?.name + `' rebooted`
                    : `Rebooting deployment '` + selectedDeployment?.name + `' failed. Check console for errors.`

                setState((prev) => ({
                    ...prev,
                    isWorking: false,
                    isFinished: true,
                    completedMessage: completedMessage,
                    selectedLanguageCode: undefined,
                }))
            }

            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false, selectedLanguage: undefined }))
        } catch (error: any) {
            console.log('An error occurred:', error.message)
            setState((prev) => ({
                ...prev,
                isWorking: false,
                isFinished: true,
                completedMessage:
                    `Rebooting deployment '` + selectedDeployment?.name + `' failed. Check console for errors.`,
            }))
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        }
    }

    const onFilterChanged = (filter: { label: string; value: string }) => {
        let filteredDeployments: DeploymentModel[]
        if (filter.value === 'All') {
            filteredDeployments = allDeployments.sort(ArrayUtil.sortByPropCompare('name', true))
        } else {
            filteredDeployments = allDeployments
                .filter((d) => d.deploymentRequestParameters.environmentRowKey === filter.value)
                .sort(ArrayUtil.sortByPropCompare('name', true))
        }

        setGlobalState((prev) => ({ ...prev, deployments: filteredDeployments }))
        setState((prev) => ({ ...prev, selectedEnvironmentFilter: filter.value, filterText: '' }))
    }

    const onFilterTextChanged = (searchText: string) => {
        const filterText: string = searchText

        let filteredDeployments: DeploymentModel[]

        if (selectedEnvironmentFilter === 'All') {
            filteredDeployments = allDeployments
                .filter((d) => d.name.toLowerCase().includes(filterText))
                .sort(ArrayUtil.sortByPropCompare('name', true))
        } else {
            filteredDeployments = allDeployments
                .filter(
                    (d) =>
                        d.name.toLowerCase().includes(filterText) &&
                        d.deploymentRequestParameters.environmentRowKey === selectedEnvironmentFilter,
                )
                .sort(ArrayUtil.sortByPropCompare('name', true))
        }

        setGlobalState((prev) => ({ ...prev, deployments: filteredDeployments }))
        setState((prev) => ({ ...prev, filterText: filterText }))
    }

    function getFilterOptions() {
        return [{ label: 'All', value: 'All' }, ...environments.map((e) => ({ label: e.name, value: e.rowKey || '' }))]
    }

    return (
        <Grid rows="auto 1fr" style={{ overflow: 'hidden' }}>
            <DeploymentsToolbar
                isEnabled={selectedDeployment?.enabled === false ? false : true}
                hasItemSelected={!!selectedDeploymentId}
                isWorking={false}
                onCreate={onCreate}
                onClone={() => setState((prev) => ({ ...prev, isCloneDialogVisible: true }))}
                onDelete={() => setState((prev) => ({ ...prev, isDeleteDialogVisible: true }))}
                onUpgrade={() => setState((prev) => ({ ...prev, isUpgradeDialogVisible: true }))}
                onSettings={() => setState((prev) => ({ ...prev, isDeploymentSettingsDialogVisible: true }))}
                onToggle={onInstanceToggle}
                onReboot={onReboot}
            />
            <Grid rows="auto 1fr" columns="350px 1fr" gap={4} className={styles.container}>
                <Grid columns="1fr 180px">
                    <Heading type={'heading1'}>Invision Instances</Heading>
                    <Select
                        isSearchable={false}
                        isClearable={false}
                        options={getFilterOptions()}
                        value={selectedEnvironmentFilter}
                        onChange={onFilterChanged}
                        placeholder={'Filter'}
                        isDisabled={false}
                    />
                </Grid>
                {selectedDeployment?.enabled === false ? (
                    <div style={{ height: '32px' }} />
                ) : (
                    <ButtonNavigator
                        spacing="compact"
                        buttons={[
                            {
                                id: DeploymentMainPanels[DeploymentMainPanels.Overview],
                                caption: DeploymentMainPanels[DeploymentMainPanels.Overview],
                            },
                            {
                                id: DeploymentMainPanels[DeploymentMainPanels.Scaling],
                                caption: DeploymentMainPanels[DeploymentMainPanels.Scaling],
                            },
                            {
                                id: DeploymentMainPanels[DeploymentMainPanels.Advanced],
                                caption: DeploymentMainPanels[DeploymentMainPanels.Advanced],
                            },
                        ]}
                        selectedButtonId={DeploymentMainPanels[selectedDetailsSection]}
                        onButtonClicked={onDetailsNavButtonClicked}
                    />
                )}

                <DeploymentList
                    items={deployments}
                    onSelectedChange={onSelectedDeploymentChange}
                    onFilterTextChanged={onFilterTextChanged}
                    selectedItems={selectedDeployment ? [selectedDeployment] : undefined}
                    versions={versions}
                />

                <DeploymentDetails
                    environments={environments}
                    selectedDeployment={selectedDeployment}
                    selectedDetailsSection={selectedDetailsSection}
                    onAzureApplicationsEdit={onAzureApplicationsEdit}
                    onAzureBlobContainerEdit={onAzureBlobContainerEdit}
                    onAzureSqlEdit={onAzureSqlEdit}
                    onAzureServiceBusEdit={onAzureServiceBusEdit}
                    onEdit={onEdit}
                    onEditDefaultLanguage={onEditDefaultLanguage}
                    onConnectToFlow={onConnectToFlow}
                    onDisconnectFromFlow={onDisconnectFromFlow}
                    onScalingEdit={onScalingEdit}
                    disabledFlowInstanceIsInUse={disabledFlowInstanceIsInUse}
                />
            </Grid>

            {isRebootConfirmDialogOpen && (
                <Modal
                    open={isRebootConfirmDialogOpen}
                    closeIcon={closeIcon()}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!isWorking && !isValidating) {
                            setState((prev) => ({
                                ...prev,
                                isRebootConfirmDialogOpen: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: cx(styles.customModal, errorMessageModalStyle),
                    }}
                >
                    <ConfirmDialog
                        height={160}
                        width={540}
                        message={"Are you sure you want to reboot instance '" + selectedDeployment?.name + "'?"}
                        workingMessage="Rebooting instance"
                        completedMessage={completedMessage}
                        isWorking={isWorking}
                        isFinished={isFinished}
                        onClose={onConfirmRebootClose}
                        onCancel={onConfirmRebootCancel}
                        onConfirm={onConfirmReboot}
                    />
                </Modal>
            )}

            {isToggleInstanceDialogVisible && (
                <Modal
                    open={isToggleInstanceDialogVisible}
                    closeIcon={closeIcon()}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!isWorking && !isValidating) {
                            setState((prev) => ({
                                ...prev,
                                isToggleInstanceDialogVisible: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: cx(styles.customModal, errorMessageModalStyle),
                    }}
                >
                    <Confirmation
                        toggle={currentToggle}
                        deploymentId={selectedDeploymentId || ''}
                        deploymentName={selectedDeployment?.name || ''}
                        productType={ProductType.Invision}
                        onClose={onInstanceToggleClose}
                        onCancel={onInstanceToggleCancel}
                        isInUse={disabledFlowInstanceIsInUse}
                    />
                </Modal>
            )}

            {isDeploymentSettingsDialogVisible && (
                <Modal
                    open={isDeploymentSettingsDialogVisible}
                    closeIcon={closeIcon()}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!isWorking && !isValidating) {
                            setState((prev) => ({
                                ...prev,
                                isDeploymentSettingsDialogVisible: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: cx(styles.customModal, errorMessageModalStyle),
                    }}
                >
                    <EditDeploymentSettings
                        onCancel={onEditSettingsClose}
                        isWorking={isWorking}
                        onSave={() => setState((prev) => ({ ...prev, isWorking: true }))}
                        onClose={onEditSettingsClose}
                    />
                </Modal>
            )}

            {isEditScalingOpen && (
                <Modal
                    open={isEditScalingOpen}
                    closeIcon={closeIcon()}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!isWorking && !isValidating) {
                            setState((prev) => ({
                                ...prev,
                                isEditScalingOpen: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: cx(styles.customModal, errorMessageModalStyle),
                    }}
                >
                    <EditScalingDetails
                        deployment={selectedDeployment}
                        onCancel={onEditScalingCancel}
                        isWorking={isWorking}
                        onSave={() => setState((prev) => ({ ...prev, isWorking: true }))}
                        onClose={onEditScalingClose}
                    />
                </Modal>
            )}

            {isEditOpen && (
                <Modal
                    open={isEditOpen}
                    closeIcon={closeIcon()}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!isWorking && !isValidating) {
                            setState((prev) => ({
                                ...prev,
                                isEditOpen: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: cx(styles.customModal, errorMessageModalStyle),
                    }}
                >
                    <EditOverViewDetails
                        deploymentId={selectedDeployment?.deploymentId}
                        externalId={selectedDeployment?.externalId}
                        onUpdate={(v) => {
                            setState((prev) => ({ ...prev, isWorking: v }))
                        }}
                        onClose={(change, success) => {
                            onEditClose(change, success)
                        }}
                    />
                </Modal>
            )}

            {isEditDefaultLanguageOpen && (
                <Modal
                    open={isEditDefaultLanguageOpen}
                    closeIcon={closeIcon()}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!isWorking && !isValidating) {
                            setState((prev) => ({
                                ...prev,
                                isEditDefaultLanguageOpen: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: cx(styles.customModal, errorMessageModalStyle),
                    }}
                >
                    <EditDefaultLanguage
                        deploymentId={selectedDeployment?.deploymentId}
                        defaultLanguage={selectedDeployment?.deploymentRequestParameters.defaultLanguage || ''}
                        onUpdate={(v) => {
                            setState((prev) => ({ ...prev, isWorking: v }))
                        }}
                        onClose={(change, success) => {
                            onEditDefaultLanguageClose(change, success)
                        }}
                    />
                </Modal>
            )}

            {isAzureServiceBusEditOpen && (
                <Modal
                    open={isAzureServiceBusEditOpen}
                    closeIcon={closeIcon()}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!isWorking && !isValidating) {
                            setState((prev) => ({
                                ...prev,
                                isAzureServiceBusEditOpen: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: cx(styles.customModal, errorMessageModalStyle),
                    }}
                >
                    <EditAzureServiceBus
                        deploymentId={selectedDeployment?.deploymentId}
                        onUpdate={(v) => {
                            setState((prev) => ({ ...prev, isWorking: v }))
                        }}
                        onClose={(change, success) => {
                            onSetAuthoriaztionRuleClose(change, success)
                        }}
                        productType={ProductType.Invision}
                    />
                </Modal>
            )}

            {isAzureSqlEditOpen && (
                <Modal
                    open={isAzureSqlEditOpen}
                    closeIcon={closeIcon()}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!isWorking && !isValidating) {
                            setState((prev) => ({
                                ...prev,
                                isAzureSqlEditOpen: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: cx(styles.customModal, errorMessageModalStyle),
                    }}
                >
                    <EditAzureSqlDetails
                        deploymentId={selectedDeployment?.deploymentId}
                        onUpdate={(v) => {
                            setState((prev) => ({ ...prev, isWorking: v }))
                        }}
                        onClose={(success) => {
                            onUpdateSqlClose(success)
                        }}
                        productType={ProductType.Invision}
                    />
                </Modal>
            )}

            {isAzureBlobContainerEditOpen && (
                <Modal
                    open={isAzureBlobContainerEditOpen}
                    closeIcon={closeIcon()}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!isWorking && !isValidating) {
                            setState((prev) => ({
                                ...prev,
                                isAzureBlobContainerEditOpen: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: cx(styles.customModal, errorMessageModalStyle),
                    }}
                >
                    <EditAzureBlobContainerDetails
                        deploymentId={selectedDeployment?.deploymentId}
                        onUpdate={(v) => {
                            setState((prev) => ({ ...prev, isWorking: v }))
                        }}
                        onClose={(success) => {
                            onUpdateBlobContainerClose(success)
                        }}
                        productType={ProductType.Invision}
                    />
                </Modal>
            )}

            {isAzureApplicationsEditOpen && (
                <Modal
                    open={isAzureApplicationsEditOpen}
                    closeIcon={closeIcon()}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!isWorking && !isValidating) {
                            setState((prev) => ({
                                ...prev,
                                isAzureApplicationsEditOpen: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: cx(styles.customModal, errorMessageModalStyle),
                    }}
                >
                    <EditAzureApplicationsDetails
                        deploymentId={selectedDeployment?.deploymentId}
                        hasCustomOverrides={selectedDeployment?.hasCustomOverrides}
                        identityProviderAuthority={selectedDeployment?.appDeployment.identityProviderAuthority}
                        identityProviderPermissions={selectedDeployment?.appDeployment.identityProviderPermissions}
                        identityProviderCanInvite={selectedDeployment?.appDeployment.identityProviderCanInvite}
                        openIdConnectAuthority={selectedDeployment?.appDeployment.openIdConnectAuthority}
                        openIdConnectTenantId={selectedDeployment?.appDeployment.openIdConnectTenantId}
                        webAppclientId={selectedDeployment?.appDeployment.webApp.clientId}
                        webAppclientSecret={selectedDeployment?.appDeployment.webApp.clientSecret}
                        designerClientId={selectedDeployment?.appDeployment.designer.clientId}
                        onUpdate={(v) => {
                            setState((prev) => ({ ...prev, isWorking: v }))
                        }}
                        onClose={(success) => {
                            onUpdateAuthClose(success)
                        }}
                    />
                </Modal>
            )}

            {isNewOpen && (
                <Modal
                    open={isNewOpen}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!isWorking && !isValidating) {
                            setState((prev) => ({
                                ...prev,
                                isNewOpen: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: cx(styles.customModal, errorMessageModalStyle),
                    }}
                    closeIcon={closeIcon()}
                >
                    <NewDeployment
                        onValidate={(v) => {
                            setState((prev) => ({ ...prev, isValidating: v }))
                        }}
                        onDeploy={(v) => {
                            setState((prev) => ({ ...prev, isWorking: v }))
                        }}
                        closeDialog={handleDeploymentSuccess}
                        onError={(errorLog) =>
                            setState((prev) => ({ ...prev, errorLog: errorLog, showErrorModal: true }))
                        }
                        flows={flows.filter((f) => f.enabled || f.enabled === null)}
                    />
                </Modal>
            )}

            {isUpgradeDialogVisible && (
                <Modal
                    open={isUpgradeDialogVisible}
                    closeOnOverlayClick={false}
                    onClose={() => setState((prev) => ({ ...prev, isUpgradeDialogVisible: false }))}
                    classNames={{
                        modal: styles.customUpgradeModal,
                    }}
                    closeIcon={closeIcon()}
                >
                    <UpgradeDeployment
                        deploymentId={selectedDeployment?.deploymentId}
                        deploymentName={selectedDeployment?.name}
                        currentPlatformVersion={selectedDeployment?.deploymentRequestParameters?.platformVersion}
                        clusterControllers={clusterControllers || []}
                        currentClusterController={selectedDeployment?.clusterController?.name || 'AzureGateway'}
                        onDeploymentUpgraded={onDeploymentUpgraded}
                        onUpgrading={() => setState((prev) => ({ ...prev, isWorking: true }))}
                        onUpgradeFinished={() => setState((prev) => ({ ...prev, isWorking: false }))}
                        onClose={() =>
                            setState((prev) => ({
                                ...prev,
                                isUpgradeDialogVisible: false,
                            }))
                        }
                        productType={ProductType.Invision}
                    />
                </Modal>
            )}

            {isDeleteDialogVisible && (
                <Modal
                    open={isDeleteDialogVisible}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!isWorking) {
                            setState((prev) => ({
                                ...prev,
                                isDeleteDialogVisible: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: cx(styles.customModal),
                    }}
                    closeIcon={closeIcon()}
                >
                    <DeleteDeploymentConfirmation
                        selectedDeployment={selectedDeployment}
                        productType={ProductType.Invision}
                        onClose={onDeleteDialogClose}
                        onWorking={(w) => setState((prev) => ({ ...prev, isWorking: w }))}
                        refresh={refreshDeployments}
                    />
                </Modal>
            )}

            {isConnectToFlowVisible && (
                <Modal
                    open={isConnectToFlowVisible}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!isWorking) {
                            setState((prev) => ({
                                ...prev,
                                isConnectToFlowVisible: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: cx(styles.customModal),
                    }}
                    closeIcon={closeIcon()}
                >
                    <ConnectToFlow
                        deploymentName={selectedDeployment?.name || ''}
                        deploymentId={selectedDeployment?.deploymentId || ''}
                        flows={flows.filter((f) => f.enabled || f.enabled === null)}
                        onClose={onConnectFlowClose}
                    />
                </Modal>
            )}

            {isDisconnectFromFlowVisible && (
                <Modal
                    open={isDisconnectFromFlowVisible}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!isWorking) {
                            setState((prev) => ({
                                ...prev,
                                isDisconnectFromFlowVisible: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: cx(styles.customModal),
                    }}
                    closeIcon={closeIcon()}
                >
                    <DisconnectFromFlow
                        deploymentName={selectedDeployment?.name || ''}
                        deploymentId={selectedDeployment?.deploymentId || ''}
                        flowId={selectedDeployment?.flowInstance?.deploymentId || ''}
                        onClose={onDisconnectFlowClose}
                    />
                </Modal>
            )}

            {isCloneDialogVisible && (
                <Modal
                    open={isCloneDialogVisible}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!isWorking) {
                            setState((prev) => ({
                                ...prev,
                                isCloneDialogVisible: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: cx(styles.customModal),
                    }}
                    closeIcon={closeIcon()}
                >
                    <CloneDeployment
                        deploymentName={selectedDeployment?.name || ''}
                        deploymentId={selectedDeployment?.deploymentId || ''}
                        flowId={selectedDeployment?.flowInstance?.deploymentId || ''}
                        closeDialog={handleCloneSuccess}
                        onError={(errorLog) =>
                            setState((prev) => ({ ...prev, errorLog: errorLog, showErrorModal: true }))
                        }
                        onDeploy={(v) => {
                            setState((prev) => ({ ...prev, isWorking: v }))
                        }}
                    />
                </Modal>
            )}
        </Grid>
    )
}

export default Deployments
