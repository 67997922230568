import React, { useState } from 'react'
import { ClusterController, DeploymentModel, FlowInstanceModel } from './components/app/Model/AllSparkModel'

type Props = {
    children: React.ReactNode
}

type GlobalStateProps = {
    accessToken: string
    isSparking: boolean
    isSpinning: boolean
    deployments: DeploymentModel[]
    flows: FlowInstanceModel[]
    clusterControllers?: ClusterController[]
    selectedDeploymentId?: string
    selectedProductId?: string
    selectedEnvironmentId?: string
    selectedFailedDeploymentRowKey?: string
    selectedSystemLogDeploymentId?: string
    selectedFlowDeploymentId?: string
    selectedGroupId?: string
    selectedLanguage?: string
}

type GlobalStateContextType = {
    globalState: GlobalStateProps
    setGlobalState: React.Dispatch<React.SetStateAction<GlobalStateProps>>
}

export const GlobalStateContext = React.createContext<GlobalStateContextType>({} as GlobalStateContextType)

const GlobalStateProvider = ({ children }: Props) => {
    const [globalState, setGlobalState] = useState({
        isSpinning: false,
        isSparking: false,
        deployments: [] as DeploymentModel[],
        flows: [] as FlowInstanceModel[],
        accessToken: '',
    })

    return <GlobalStateContext.Provider value={{ globalState, setGlobalState }}>{children}</GlobalStateContext.Provider>
}

export default GlobalStateProvider

export const useGlobalStateContext = () => React.useContext(GlobalStateContext)
