import { ArrayUtil } from './ArrayUtil'
import { MD5 } from './MD5'
import { Str } from './Str'
export class Util {
    public static isNaN(value: any) {
        return Number.isNaN(value)
    }

    public static isNumber(value: any): value is number {
        return typeof value === 'number' && !isNaN(value)
    }

    public static isArrayWithItems(ar: any): ar is any[] {
        return ArrayUtil.isArrayWithItems(ar)
    }

    public static isIterable(obj: any): boolean {
        if (obj === null || obj === undefined) {
            return false
        }

        return typeof obj[Symbol.iterator] === 'function'
    }

    public static isNullOrUndefined(s: any): boolean {
        return s === null || s === void 0
    }

    public static coerceUndefinedNull(val: any): any {
        return val === void 0 ? null : val
    }

    public static coerceUndefinedOrNANNull(val: any): any {
        return val === void 0 || isNaN(val) ? null : val
    }

    public static getValueOrDefault<T>(value: T | undefined | null, defaultValue: T): T {
        return value === null || value === undefined ? defaultValue : value
    }

    public static computeHash(obj: any) {
        return MD5.compute(Util._computeHash(obj))
    }

    private static _computeHash(obj: any): string {
        let serializedCode = ''
        const type = typeof obj
        if (type === 'object') {
            let element
            for (element in obj) {
                serializedCode += '[' + type + ':' + element + Util._computeHash(obj[element]) + ']'
            }
        } else if (type === 'function') {
            serializedCode += '[' + type + ':' + obj.toString() + ']'
        } else {
            serializedCode += '[' + type + ':' + obj + ']'
        }
        return serializedCode.replace(/\s/g, '')
    }

    public static dotNetToJavascriptType(dotNetDataType: string | undefined): 'boolean' | 'date' | 'number' | 'string' {
        if (Str.indexOf(dotNetDataType, 'Boolean', 0, true) > -1) {
            return 'boolean'
        } else if (
            Str.indexOf(dotNetDataType, 'DateTime', 0, true) > -1 ||
            Str.indexOf(dotNetDataType, 'DateTimeOffset', 0, true) > -1
        ) {
            return 'date'
        } else if (Str.indexOf(dotNetDataType, 'String', 0, true) > -1) {
            return 'string'
        } else {
            return 'number'
        }
    }

    public static isDataTypeDateTimeOffset(dotNetDataType: string | undefined): boolean {
        return Str.indexOf(dotNetDataType, 'DateTimeOffset', 0, true) > -1
    }

    public static delay(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms))
    }

    public static getDateString(date: Date) {
        const pad = (number: number, length: number) => {
            return number.toString().padStart(length, '0')
        }

        const year = date.getFullYear()
        const month = pad(date.getMonth() + 1, 2)
        const day = pad(date.getDate(), 2)
        const hours = pad(date.getHours(), 2)
        const minutes = pad(date.getMinutes(), 2)
        const seconds = pad(date.getSeconds(), 2)
        const milliseconds = pad(date.getMilliseconds(), 3)
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`
    }

    public static millisecondsToReadableDuration(ms: number) {
        const days = ms / (1000 * 60 * 60 * 24)
        const years = Math.floor(ms / (1000 * 60 * 60 * 24 * 365))

        if (years > 0) {
            let d = Math.floor(days - years * 365)
            return (
                years +
                ' year' +
                (years > 1 ? 's' : '') +
                (d > 0 ? (d > 1 ? ' and ' + d + ' days' : ' and ' + d + ' day') : '')
            )
        } else if (days > 1) {
            return Math.floor(days) + ' days'
        } else if (days === 1) {
            return days + ' day'
        }
    }

    /*
        Will return minutes and seconds 00:00
    */
    public static millisecondsToReadableShortDuration(s: number) {
        // Pad to 2 or 3 digits, default is 2
        function pad(n: number, z: number) {
            z = z || 2
            return ('00' + n).slice(-z)
        }

        var ms = s % 1000
        s = (s - ms) / 1000
        var secs = s % 60
        s = (s - secs) / 60
        var mins = s % 60
        var hrs = (s - mins) / 60

        return pad(mins, 2) + ':' + pad(secs, 2)
    }

    public static getDefaultExpiryDateString() {
        var d = new Date()
        d.setDate(d.getDate() + 730) //2 years = 730 days
        var datestring =
            d.getFullYear() +
            '-' +
            ('0' + (d.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + d.getDate()).slice(-2) +
            'T' +
            ('0' + d.getHours()).slice(-2) +
            ':' +
            ('0' + d.getMinutes()).slice(-2)
        return datestring
    }
}
