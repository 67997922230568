import { InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useMsalAuthentication } from '@azure/msal-react'
import { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react'
import 'react-responsive-modal/styles.css'
import { AllSparkContext, msalInstance } from '.'
import { AccessTokenStore } from './AccessTokenStore'
import AppHeader from './components/app/appHeader/AppHeader'
import AppLeftSideBar from './components/app/appLeftSidebar/AppLeftSideBar'
import Deployments from './components/app/deployments/Deployments'
import Environments from './components/app/environments/Environments'
import FailedDeployments from './components/app/failedDeployments/FailedDeployments'
import Products from './components/app/products/Products'
import SystemLogs from './components/app/systemLog/SystemLogs'
import LogoutIcon from './components/icons/LogoutIcon'
import Button, { ButtonType } from './components/infratructure/button/Button'
import ErrorBoundry from './components/infratructure/errorBoundry/ErrorBoundry'
import Grid from './components/infratructure/grid/Grid'
import SpinningCube from './components/infratructure/spinningcube/SpinningCube'
import GlobalStateProvider from './GlobalState'
import styles from './TheAllSpark.module.scss'
import Flows from './components/app/flow/Flows'
import DockerImages from './components/app/dockerImages/DockerImages'
import Languages from './components/app/languages/Languages'
import ClusterControllers from './components/app/clusterControllers/ClusterControllers'

const apiScope = window.authSettings.apiScope

export enum Page {
    Deployments,
    FailedDeployments,
    Products,
    Environments,
    Flows,
    SystemLog,
    DockerImages,
    Languages,
    ClusterControllers,
}

type TheAllSparkState = {
    section: Page
    footerText: string
}

const TheAllSpark: FunctionComponent = () => {
    const [{ section, footerText }, setState] = useState<TheAllSparkState>({
        section: Page.Deployments,
        footerText: `Powered by Profitbase R&D, version `,
    })

    const api = useContext(AllSparkContext)

    useEffect(() => {
        getVersion()
    }, [])

    const getVersion = async () => {
        try {
            let version = await api.getVersion()
            setState((prev) => ({ ...prev, footerText: footerText + version }))
        } catch (error: any) {
            console.log(error.Message)
        }
    }

    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md#usemsalauthentication-hook
    // ----
    const { accounts } = useMsal()
    const request = useMemo(
        () => ({
            loginHint: accounts[0]?.username,
            scopes: [apiScope],
        }),
        [accounts],
    )
    const { login, result, error } = useMsalAuthentication(InteractionType.Silent, request)

    if (result) {
        AccessTokenStore.setAccessToken(result.accessToken)
        AccessTokenStore.setExpiresAt(result.expiresOn?.toString() || '')

        if (result.expiresOn) {
            const expiresIn = result.expiresOn.valueOf() - Date.now()

            if (expiresIn < 900000) {
                // 15 minutes
                login(InteractionType.Silent, request).then((result) => {
                    if (result) {
                        AccessTokenStore.setAccessToken(result.accessToken)
                        AccessTokenStore.setExpiresAt(result.expiresOn?.toString() || '')
                    }
                })
            }
        }
    }

    useEffect(() => {
        if (error instanceof InteractionRequiredAuthError) {
            login(InteractionType.Redirect, request).then((res) => {
                if (res) {
                    AccessTokenStore.setAccessToken(res.accessToken)
                    AccessTokenStore.setExpiresAt(res.expiresOn?.toString() || '')
                }
            })
        }
    }, [error, login, request])

    return (
        <GlobalStateProvider>
            {/* Render a spinning cube if we are not authenticated */}
            <UnauthenticatedTemplate>
                <Grid className={styles.unauthenticatedContainer}>
                    <SpinningCube />
                </Grid>
            </UnauthenticatedTemplate>

            {/* Render the following UI if we are authenticated */}
            <AuthenticatedTemplate>
                <ErrorBoundry>
                    <Grid rows="auto 1fr auto" className={styles.theallsparkwrapper}>
                        <Grid columns="1fr auto auto" className={styles.centeritems}>
                            <AppHeader />
                            <AuthenticatedTemplate>
                                <Grid columns="auto auto" style={{ alignItems: 'center', marginRight: '20px' }}>
                                    <Grid rows="auto auto" className={styles.authenticatedContainer}>
                                        <div className={styles.fontSmallBold}>Signed in as</div>
                                        <div className={styles.userNameStyle}>{accounts[0]?.username}</div>
                                    </Grid>
                                    <Button
                                        onClick={() =>
                                            msalInstance.logoutRedirect({
                                                account: accounts[0],
                                            })
                                        }
                                        buttonType={ButtonType.Icon}
                                        title="Logout"
                                        className={styles.logoutBtn}
                                    >
                                        <LogoutIcon height={28} width={28} />
                                    </Button>
                                </Grid>
                            </AuthenticatedTemplate>
                            <UnauthenticatedTemplate>
                                <div style={{ color: 'white' }}>No users are signed in!</div>
                            </UnauthenticatedTemplate>
                        </Grid>

                        <Grid columns="200px 1fr" style={{ overflow: 'hidden' }}>
                            <AppLeftSideBar
                                onNavButtonClicked={(id) => {
                                    setState((prev) => ({ ...prev, section: id }))
                                }}
                            />
                            {section === Page.Deployments && <Deployments />}

                            {section === Page.FailedDeployments && <FailedDeployments />}

                            {section === Page.Products && <Products />}

                            {section === Page.Environments && <Environments />}

                            {section === Page.Flows && <Flows />}

                            {section === Page.SystemLog && <SystemLogs />}

                            {section === Page.DockerImages && <DockerImages />}

                            {section === Page.Languages && <Languages />}

                            {section === Page.ClusterControllers && <ClusterControllers />}
                        </Grid>

                        <Grid className={styles.footer}>{footerText}</Grid>
                    </Grid>
                </ErrorBoundry>
            </AuthenticatedTemplate>
        </GlobalStateProvider>
    )
}

export default TheAllSpark
