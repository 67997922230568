import { FunctionComponent } from 'react'
import styles from './Spinner.module.scss'

export type SpinnerProps = {
    size?: number
    color?: string
}

const Spinner: FunctionComponent<SpinnerProps> = ({ size = 20, color = '#FF7700' }) => {
    return (
        <svg
            className={styles.svg}
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            style={{ height: size + 'px', width: size + 'px' }}
        >
            <circle className={styles.circle} cx="50" cy="50" r="45" style={{ stroke: color }} />
        </svg>
    )
}

export default Spinner
