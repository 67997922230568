import { FunctionComponent } from 'react'
import { DeploymentModel, EnvironmentModel } from '../../Model/AllSparkModel'
import CopyableInput from '../../../infratructure/copyableInput/CopyableInput'
import Grid from '../../../infratructure/grid/Grid'
import Heading from '../../../infratructure/heading/Heading'
import styles from './DeploymentOverview.module.scss'
import Button, { ButtonType } from '../../../infratructure/button/Button'
import EditIcon from '../../../icons/EditIcon'
import FlowIcon from '../../../icons/FlowIcon'
import WarningIcon from '../../../icons/WarningIcon'
import ClipboardButton from '../../../infratructure/clipboardButton/ClipboardButton'

interface DeploymentOverviewProps {
    deployment: DeploymentModel
    environments: EnvironmentModel[]
    onEdit: () => void
    onEditDefaultLanguage: () => void
    onConnectToFlow: () => void
    onDisconnectFromFlow: () => void
    disabledFlowInstanceIsInUse: boolean
}

const DeploymentOverview: FunctionComponent<DeploymentOverviewProps> = ({
    deployment,
    environments: environments,
    onEdit,
    onEditDefaultLanguage,
    onConnectToFlow,
    onDisconnectFromFlow,
    disabledFlowInstanceIsInUse,
}) => {
    return (
        <Grid columns="900px 1fr" className={styles.container}>
            <Grid rows="auto auto auto auto auto 1fr" gap={16}>
                <Grid columns="auto auto 1fr" gap={20} style={{ height: '32px' }}>
                    <Heading type="heading1">Deployment Overview</Heading>
                    {deployment?.flowInstance === undefined || deployment?.flowInstance === null ? (
                        <Grid columns="auto 1fr" gap={0}>
                            <Button
                                onClick={onConnectToFlow}
                                buttonType={ButtonType.Default}
                                className={styles.btn}
                                title="Connect Flow"
                                style={{ width: '170px' }}
                            >
                                <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                                    <FlowIcon height={20} width={20} />
                                    <div>Connect Flow</div>
                                </Grid>
                            </Button>
                            <div />
                        </Grid>
                    ) : (
                        <div />
                        // <Grid columns="auto 1fr" gap={0}>
                        //     <Button
                        //         onClick={onDisconnectFromFlow}
                        //         buttonType={ButtonType.Default}
                        //         className={styles.btn}
                        //         title="Disconnect Flow"
                        //         style={{ width: '170px' }}
                        //     >
                        //         <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                        //             <FlowIcon height={20} width={20} />
                        //             <div>Disconnect Flow</div>
                        //         </Grid>
                        //     </Button>
                        //     <div />
                        // </Grid>
                    )}
                </Grid>
                <Grid gap={16} style={{ marginLeft: '10px' }}>
                    <Grid columns={'610px 20px'} style={{ alignItems: 'center' }}>
                        <a
                            className={styles.uristyle}
                            href={deployment?.deploymentUri}
                            style={{
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                width: '600px',
                            }}
                            target="_blank"
                            rel="noreferrer"
                            title={deployment?.deploymentUri}
                        >
                            {deployment?.deploymentUri}
                        </a>
                        <ClipboardButton value={deployment?.deploymentUri || ''} style={{ position: 'unset' }} />
                    </Grid>
                    <CopyableInput
                        width={650}
                        headerText={'Name'}
                        value={deployment?.deploymentRequestParameters?.name || ''}
                        readOnly
                    />
                    <CopyableInput
                        width={650}
                        headerText={'Cluster controller'}
                        value={
                            deployment?.clusterController?.name !== undefined
                                ? deployment?.clusterController?.name +
                                  ' (' +
                                  deployment?.clusterController?.invisionChart +
                                  ')'
                                : 'Unset (using default)'
                        }
                        readOnly
                    />
                    <Grid columns="auto auto 1fr">
                        <CopyableInput
                            width={650}
                            headerText={'Default language'}
                            value={deployment?.deploymentRequestParameters.defaultLanguage || 'no'}
                            readOnly
                        />
                        <Button
                            style={{ marginTop: '21px', marginLeft: '10px' }}
                            onClick={onEditDefaultLanguage}
                            buttonType={ButtonType.Icon}
                            className={styles.btn}
                            title="Edit default language"
                        >
                            <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                                <EditIcon height={26} width={26} />
                                <div>Edit</div>
                            </Grid>
                        </Button>
                    </Grid>
                    <CopyableInput
                        width={650}
                        headerText={'Platform Version'}
                        value={deployment?.deploymentRequestParameters?.platformVersion || ''}
                        readOnly
                    />
                    <CopyableInput
                        width={650}
                        headerText={'Environment'}
                        value={
                            environments.find(
                                (e) => e.rowKey === deployment?.deploymentRequestParameters?.environmentRowKey,
                            )?.name || ''
                        }
                        readOnly
                    />
                    <CopyableInput
                        width={650}
                        headerText={'Administrator email'}
                        value={deployment?.deploymentRequestParameters?.userEmailAddress || ''}
                        readOnly
                    />
                    {deployment?.flowInstance !== undefined && deployment?.flowInstance !== null && (
                        <Grid rows="auto auto" columns="auto auto 1fr" gap={20}>
                            <CopyableInput
                                width={650}
                                headerText={'Flow Instance'}
                                value={deployment?.flowInstance?.name || ''}
                                readOnly
                            />

                            {disabledFlowInstanceIsInUse === true ? (
                                <Grid
                                    columns="auto auto"
                                    style={{ alignContent: 'center', marginLeft: '10px', marginTop: '20px' }}
                                    gap={10}
                                >
                                    <WarningIcon />
                                    <div style={{ color: '#FB973E' }}>Disabled!</div>
                                </Grid>
                            ) : (
                                <div />
                            )}
                            <div />
                            <CopyableInput
                                width={650}
                                headerText={'Flow TenantId'}
                                value={deployment?.flowInstance?.tenantId || ''}
                                readOnly
                            />
                            <div />
                            <div />
                        </Grid>
                    )}
                    <Grid columns="auto auto 1fr">
                        <CopyableInput
                            width={650}
                            headerText={'External Id'}
                            value={deployment?.externalId || ''}
                            readOnly
                        />
                        <Button
                            style={{ marginTop: '21px', marginLeft: '10px' }}
                            onClick={onEdit}
                            buttonType={ButtonType.Icon}
                            className={styles.btn}
                            title="Edit external Id"
                        >
                            <Grid columns="auto auto" gap={8} className={styles.btnContentWrapper}>
                                <EditIcon height={26} width={26} />
                                <div>Edit</div>
                            </Grid>
                        </Button>
                    </Grid>
                    {deployment?.cloneSource && (
                        <Grid gap={20}>
                            <CopyableInput
                                width={650}
                                headerText={'Cloned from'}
                                value={deployment?.cloneSource.deploymentName || ''}
                                readOnly
                            />
                            <CopyableInput
                                width={650}
                                headerText={'Cloned at'}
                                value={deployment?.cloneSource.timestamp || ''}
                                readOnly
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default DeploymentOverview
