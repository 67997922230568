import { FunctionComponent, useContext, useState } from 'react'
import { DeploymentModel } from '../../../Model/AllSparkModel'
import Grid from '../../../../infratructure/grid/Grid'
import Heading from '../../../../infratructure/heading/Heading'
import styles from './AzureServiceBusDetails.module.scss'
import CopyableInput from '../../../../infratructure/copyableInput/CopyableInput'
import Button, { ButtonType } from '../../../../infratructure/button/Button'

interface AzureServiceBusDetailProps {
    deployment: DeploymentModel
    onAzureServiceBusEdit: () => void
}

const AzureServiceBusDetails: FunctionComponent<AzureServiceBusDetailProps> = ({
    deployment,
    onAzureServiceBusEdit,
}) => {
    return (
        <Grid rows="auto 1fr" className={styles.container}>
            <Grid columns="auto auto 1fr">
                <Heading type="heading1">Service Bus Content</Heading>
                <Button
                    style={{ marginLeft: '10px' }}
                    onClick={onAzureServiceBusEdit}
                    buttonType={ButtonType.Default}
                    className={styles.btn}
                    title="Edit"
                >
                    <Grid className={styles.btnContentWrapper}>Edit</Grid>
                </Button>
            </Grid>

            <Grid rows="auto auto auto auto auto auto auto auto auto auto 1fr" gap={16} style={{ marginLeft: '10px' }}>
                <Heading type="heading1" style={{ marginTop: '20px' }}>
                    DataFlow Dispatcher Queue
                </Heading>
                <Grid rows="auto auto auto" gap={16} style={{ marginLeft: '10px' }}>
                    <CopyableInput
                        width={650}
                        headerText={'Name'}
                        value={deployment.eventBusDeployment.dispatcherQueue.name}
                        readOnly
                    />

                    <CopyableInput
                        width={650}
                        headerText={'ConnectionString'}
                        value={deployment.eventBusDeployment.dispatcherQueue.connectionString}
                        readOnly
                    />

                    <Grid columns="auto auto 1fr">
                        <CopyableInput
                            width={650}
                            headerText={'Authorization Rule Name'}
                            value={deployment.eventBusDeployment.dispatcherQueue.authorizationRuleName || ''}
                            readOnly
                        />
                        <div />
                    </Grid>
                </Grid>

                <Heading type="heading1" style={{ marginTop: '20px' }}>
                    DataFlow Worker Queue
                </Heading>
                <Grid rows="auto auto auto" gap={16} style={{ marginLeft: '10px' }}>
                    <CopyableInput
                        width={650}
                        headerText={'Name'}
                        value={deployment.eventBusDeployment.workerQueue.name}
                        readOnly
                    />

                    <CopyableInput
                        width={650}
                        headerText={'ConnectionString'}
                        value={deployment.eventBusDeployment.workerQueue.connectionString}
                        readOnly
                    />
                    <Grid columns="auto auto 1fr">
                        <CopyableInput
                            width={650}
                            headerText={'Authorization Rule Name'}
                            value={deployment.eventBusDeployment.workerQueue.authorizationRuleName || ''}
                            readOnly
                        />
                        <div />
                    </Grid>
                </Grid>

                <Heading type="heading1" style={{ marginTop: '20px' }}>
                    SystemWorker Queue
                </Heading>
                <Grid rows="auto auto" gap={16} style={{ marginLeft: '10px' }}>
                    <CopyableInput
                        width={650}
                        headerText={'Name'}
                        value={deployment.eventBusDeployment.systemWorkerQueue.name}
                        readOnly
                    />

                    <CopyableInput
                        width={650}
                        headerText={'ConnectionString'}
                        value={deployment.eventBusDeployment.systemWorkerQueue.connectionString}
                        readOnly
                    />
                    <CopyableInput
                        width={650}
                        headerText={'Authorization Rule Name'}
                        value={deployment.eventBusDeployment.systemWorkerQueue.authorizationRuleName || ''}
                        readOnly
                    />
                </Grid>

                <Heading type="heading1" style={{ marginTop: '20px' }}>
                    Scheduler Queue
                </Heading>
                <Grid rows="auto auto" gap={16} style={{ marginLeft: '10px' }}>
                    <CopyableInput
                        width={650}
                        headerText={'Name'}
                        value={deployment.eventBusDeployment.schedulerQueue.name}
                        readOnly
                    />
                    <CopyableInput
                        width={650}
                        headerText={'ConnectionString'}
                        value={deployment.eventBusDeployment.schedulerQueue.connectionString}
                        readOnly
                    />
                    <CopyableInput
                        width={650}
                        headerText={'Authorization Rule Name'}
                        value={deployment.eventBusDeployment.schedulerQueue.authorizationRuleName || ''}
                        readOnly
                    />
                </Grid>

                <Heading type="heading1" style={{ marginTop: '20px' }}>
                    Broadcast Topic
                </Heading>
                <Grid rows="auto auto" gap={16} style={{ marginLeft: '10px' }}>
                    <CopyableInput
                        width={650}
                        headerText={'Name'}
                        value={deployment.eventBusDeployment.broadcastTopic.name}
                        readOnly
                    />

                    <CopyableInput
                        width={650}
                        headerText={'ConnectionString'}
                        value={deployment.eventBusDeployment.broadcastTopic.connectionString}
                        readOnly
                    />
                    <CopyableInput
                        width={650}
                        headerText={'Authorization Rule Name'}
                        value={deployment.eventBusDeployment.broadcastTopic.authorizationRuleName || ''}
                        readOnly
                    />
                </Grid>
                <div></div>
            </Grid>
        </Grid>
    )
}

export default AzureServiceBusDetails
