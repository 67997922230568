import cx from 'classnames'
import { IconProps } from '../IconProps'

export default function OffIcon({ width = 20, height = 20, className, style }: IconProps) {
    return (
        <svg
            fill="none"
            viewBox="0 0 30 30"
            width={width}
            height={height}
            className={cx('icon', className)}
            style={style}
            data-icon
        >
            <path d="M2 14C2 10.6863 4.68629 8 8 8H20C23.3137 8 26 10.6863 26 14C26 17.3137 23.3137 20 20 20H8C4.68629 20 2 17.3137 2 14ZM9 17C10.6569 17 12 15.6569 12 14C12 12.3431 10.6569 11 9 11C7.34315 11 6 12.3431 6 14C6 15.6569 7.34315 17 9 17Z" 
            fill="currentColor"/>
        </svg>
    )
}
