import { FunctionComponent, useContext, useState } from 'react'
import { FlowInstanceModel } from '../../Model/AllSparkModel'
import styles from './../../deployments/edit/Edit.module.scss'
import Grid from '../../../infratructure/grid/Grid'
import Heading from '../../../infratructure/heading/Heading'
import { useGlobalStateContext } from '../../../../GlobalState'
import { AllSparkContext } from '../../../..'
import Input from '../../../infratructure/input/Input'
import cx from 'classnames'
import LoaderDots from '../../../infratructure/dots/LoaderDots'
import Button, { ButtonType } from '../../../infratructure/button/Button'

interface EditFlowEncryptionDetailsProps {
    instance: FlowInstanceModel | undefined
    onCancel: () => void
    onUpdate: (isUpdating: boolean) => void
    onClose: (change: boolean, success: boolean) => void
}

type EditFlowEncryptionDetailsState = {
    key: string
    vector: string
    isDone: boolean
    isWorking: boolean
    hasErrors: boolean
    confirm: boolean
}

const EditFlowEncryptionDetails: FunctionComponent<EditFlowEncryptionDetailsProps> = ({
    instance,
    onCancel,
    onUpdate,
    onClose,
}) => {
    const api = useContext(AllSparkContext)
    const { setGlobalState } = useGlobalStateContext()

    const [state, setState] = useState<EditFlowEncryptionDetailsState>({
        key: '',
        vector: '',
        isDone: false,
        isWorking: false,
        hasErrors: false,
        confirm: false,
    })

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }))
    }

    const handleSave = async () => {
        onUpdate(true)
        setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: true }))
        setState((prev) => ({ ...prev, isWorking: true, hasErrors: false }))

        try {
            const encryptionRequest = {
                key: state.key,
                vector: state.vector,
            }

            await api.updateFlowEncryption(instance?.deploymentId!, encryptionRequest)

            setState((prev) => ({ ...prev, isWorking: false, isDone: true }))
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        } catch (error) {
            console.log('An error occurred:', error)
            setState((prev) => ({ ...prev, isWorking: false, hasErrors: true }))
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        } finally {
            onUpdate(false)
        }
    }

    const onCloseEditDialog = () => {
        onClose(state.isDone, !state.hasErrors)
    }

    const isFormValuesValid = Boolean(state.key) && Boolean(state.vector)
    const isButtonDisabled = state.isWorking ? true : !isFormValuesValid || !state.confirm

    const onConfirmChange = () => {
        setState((prev) => ({ ...prev, confirm: !state.confirm }))
    }

    return (
        <Grid rows="1fr auto auto" className={styles.main} gap={0}>
            <Heading type="heading1">Edit Encryption</Heading>
            <Heading type="heading2" style={{ marginTop: '20px' }}>
                The application will be restarted when this config is saved.
            </Heading>
            <Grid
                style={{ height: '30px', marginTop: '20px', marginBottom: '20px', color: 'red', fontSize: 'smaller' }}
                gap={0}
            >
                <div key={'m1'}>This should only be used when the database is moved from another instance. </div>
                <div key={'m2'}>You must copy the key and vector from the source instance.</div>
            </Grid>

            <Grid gap={20} style={{ marginTop: '20px', marginBottom: '20px' }}>
                <Input
                    name="key"
                    headerText={'Key'}
                    placeholder="Enter the encryption key"
                    style={{ width: 'inherit' }}
                    value={state.key}
                    onChange={onInputChange}
                    className={cx({
                        [styles.requiredInput]: !Boolean(state.key),
                    })}
                    readOnly={state.isWorking || state.isDone}
                />
                <Input
                    name="vector"
                    headerText={'Vector'}
                    placeholder="Enter the encryption vector"
                    style={{ width: 'inherit' }}
                    value={state.vector}
                    onChange={onInputChange}
                    className={cx({
                        [styles.requiredInput]: !Boolean(state.vector),
                    })}
                    readOnly={state.isWorking || state.isDone}
                />
                <Grid
                    columns="1fr auto auto auto auto"
                    style={{ height: '20px', marginTop: '20px', marginRight: '0px' }}
                    gap={10}
                >
                    {state.isWorking && !state.isDone ? (
                        <Grid columns="auto auto 1fr" style={{ height: '30px' }} gap={10}>
                            <Grid style={{ alignItems: 'center' }}>Updating</Grid>
                            <Grid style={{ alignItems: 'center' }}>
                                <LoaderDots color="black" />
                            </Grid>
                        </Grid>
                    ) : (
                        <div />
                    )}
                    {!state.isWorking && state.isDone ? (
                        <Grid columns="auto 1fr" style={{ height: '30px' }} gap={10}>
                            <Grid style={{ alignItems: 'center' }}>Done!</Grid>
                            <div />
                        </Grid>
                    ) : (
                        <div />
                    )}
                    {state.hasErrors ? (
                        <Grid columns="auto 1fr" style={{ height: '30px' }} gap={10}>
                            <Grid style={{ alignItems: 'center' }}>Failed! Check System Log for details.</Grid>
                            <div />
                        </Grid>
                    ) : isFormValuesValid && !state.hasErrors && !state.isDone ? (
                        <Grid columns="auto 1fr" gap={10} style={{ alignItems: 'center', marginBottom: '0px' }}>
                            <Heading type="normal">Confirm</Heading>
                            <Input
                                name="confirm"
                                type="checkbox"
                                checked={state.confirm}
                                onChange={onConfirmChange}
                                style={{ width: '16px', margin: 0, height: '16px' }}
                                readOnly={state.isWorking}
                            />
                        </Grid>
                    ) : (
                        <div />
                    )}
                    {!state.isDone ? (
                        <Button
                            className={styles.deploybtn}
                            buttonType={ButtonType.Default}
                            onClick={onCancel}
                            disabled={state.isWorking}
                        >
                            Cancel
                        </Button>
                    ) : (
                        <div />
                    )}
                    <Button
                        className={styles.deploybtn}
                        buttonType={ButtonType.Confirm}
                        onClick={state.isDone ? onCloseEditDialog : handleSave}
                        disabled={isButtonDisabled || state.isWorking}
                    >
                        {state.isDone ? 'Close' : 'Save'}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default EditFlowEncryptionDetails
