import cx from 'classnames'
import { IconProps } from './IconProps'

interface LogIconProps extends IconProps {
    color?: string
}

export default function LogIcon({ width = 20, height = 20, className, style, color = '#F75151' }: LogIconProps) {
    return (
        <svg 
            width={width}
            height={height}
            viewBox="0 0 24 24" 
            className={cx('icon', className)}
            style={style}
            fill="none" 
        >
            <path fillRule="evenodd" clipRule="evenodd" d="M5 20.5C4.724 20.5 4.5 20.275 4.5 20V10H9C10.104 10 11 9.104 11 8V3.5H17C17.276 3.5 17.5 3.725 17.5 4V11.0764C18.0232 11.1572 18.5258 11.3004 19 11.4982V4C19 2.896 18.104 2 17 2H10.828C10.8001 2 10.7729 2.00371 10.7458 2.00741C10.7262 2.01008 10.7067 2.01274 10.687 2.014C10.472 2.029 10.26 2.07 10.063 2.152C10.0056 2.17648 9.9512 2.20797 9.897 2.23933C9.88034 2.24897 9.8637 2.25859 9.847 2.268C9.83154 2.27647 9.81553 2.28426 9.79948 2.29207C9.77194 2.30548 9.74427 2.31894 9.719 2.336C9.641 2.389 9.571 2.452 9.5 2.516C9.48853 2.5262 9.47615 2.53567 9.46372 2.5452C9.44689 2.55808 9.42995 2.57105 9.415 2.586L3.586 8.414C3.211 8.789 3 9.298 3 9.828V20C3 21.104 3.896 22 5 22H11.8096C11.3832 21.5557 11.0194 21.051 10.7322 20.5H5ZM5.622 8.5L9.5 4.621V8C9.5 8.275 9.276 8.5 9 8.5H5.622Z" fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M11 17.5C11 20.5376 13.4624 23 16.5 23C19.5376 23 22 20.5376 22 17.5C22 14.4624 19.5376 12 16.5 12C13.4624 12 11 14.4624 11 17.5ZM16.5 13.4C16.8314 13.4 17.1 13.6686 17.1 14V17.5H19.4C19.7314 17.5 20 17.7686 20 18.1C20 18.4313 19.7314 18.7 19.4 18.7H16.6C16.4089 18.7 16.2387 18.6106 16.1288 18.4714C15.9894 18.3615 15.9 18.1912 15.9 18V14C15.9 13.6686 16.1686 13.4 16.5 13.4Z" fill={color}/>
        </svg>

    )
}
