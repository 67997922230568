import { FunctionComponent, useContext, useState } from 'react'
import styles from './CloneDeployment.module.scss'
import Grid from '../../../infratructure/grid/Grid'
import Heading from '../../../infratructure/heading/Heading'
import Input from '../../../infratructure/input/Input'
import LoaderDots from '../../../infratructure/dots/LoaderDots'
import Button, { ButtonType } from '../../../infratructure/button/Button'
import { AllSparkContext } from '../../../..'
import { useGlobalStateContext } from '../../../../GlobalState'
import useInterval from '../../../infratructure/hooks/useInterval'
import { Util } from '../../../utils/Util'
import {
    Deployment,
    DeploymentModel,
    FailedDeploymentModel,
    State,
    StateToStringMap,
    Status,
    StatusToStringMap,
} from '../../Model/AllSparkModel'

interface CloneDeploymentProps {
    deploymentName: string
    deploymentId: string
    flowId: string
    closeDialog: (deploymentModel: DeploymentModel) => void
    onDeploy: (deploy: boolean) => void
    onError?: (log: FailedDeploymentModel) => void
}

type CloneDeploymentState = {
    isWorking: boolean
    isCloneFinished: boolean
    cloneDeploymentName: string
    cloneFlowDeploymentName: string
    durationTicker: number
    pollId: string
    pollInterval: number | null
    startTime: Date
    currentTime: string
    deployResult: Deployment | undefined
    isValidating: boolean
    showError: boolean
    errorMessage: string | undefined
}

const CloneDeployment: FunctionComponent<CloneDeploymentProps> = ({
    deploymentName,
    deploymentId,
    flowId,
    onDeploy,
    closeDialog,
}) => {
    const api = useContext(AllSparkContext)
    const { setGlobalState } = useGlobalStateContext()

    const now = new Date()
    const [state, setState] = useState<CloneDeploymentState>({
        isWorking: false,
        isCloneFinished: false,
        cloneDeploymentName: deploymentName + ' ' + Util.getDateString(now),
        cloneFlowDeploymentName: deploymentName + ' ' + Util.getDateString(now) + ' Flow',
        durationTicker: 0,
        pollId: '',
        pollInterval: null,
        startTime: new Date(),
        currentTime: '',
        deployResult: undefined,
        isValidating: false,
        showError: false,
        errorMessage: undefined,
    })

    // polling setup
    useInterval(async () => {
        let t = state.durationTicker + 1
        setState((prev) => ({ ...prev, durationTicker: t }))
        dateBuilder()

        if (t > 4) {
            setState((prev) => ({ ...prev, isValidating: false, durationTicker: 0 }))
            const pollResult = await api.getDeployment(state.pollId)
            console.log('cloning... =>', pollResult)

            setState((prev) => ({ ...prev, deployResult: pollResult }))

            const endDeployProcess = () => {
                setState((prev) => ({
                    ...prev,
                    pollId: '',
                    isWorking: false,
                    pollInterval: null,
                    deployResult: undefined,
                    durationTicker: 0,
                }))
                onDeploy(false)
            }

            if (pollResult.deploymentModel) {
                // success
                endDeployProcess()
                closeDialog && closeDialog(pollResult.deploymentModel)
                setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
            }

            if (pollResult.deploymentModel || (!pollResult.status && !pollResult.deploymentModel)) {
                // failure
                endDeployProcess()
                setState((prev) => ({ ...prev, deployResult: pollResult, showError: true, errorMessage: "Deployment failed, check system log for messages.", isWorking: false }))
                setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
            }
        }
    }, state.pollInterval)

    const dateBuilder = () => {
        const now = new Date()
        let durationMs = now.valueOf() - state.startTime.valueOf()
        setState((prev) => ({ ...prev, currentTime: Util.millisecondsToReadableShortDuration(Math.abs(durationMs)) }))
    }
    const handleCloneClicked = async () => {
        setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: true }))
        setState((prev) => ({
            ...prev,
            isWorking: true,
            isValidating: true,
            errorMessage: undefined,
            showError: false,
        }))

        onDeploy(true)

        try {
            const response = await api.cloneDeployment(
                deploymentId,
                state.cloneDeploymentName,
                state.cloneFlowDeploymentName,
                flowId,
            )

            if (response && response.valid) {
                console.log('Validating clone succeeded.')

                if (!state.pollInterval) {
                    setState((prev) => ({
                        ...prev,
                        pollId: response.deploymentId,
                        pollInterval: 1000, // 4000,
                        isValidating: false,
                        isWorking: true,
                        durationTicker: 0,
                        startTime: new Date(),
                    }))
                } else {
                    setState((prev) => ({ ...prev, isWorking: false, isValidating: false }))
                    onDeploy(false)
                }
            } else {
                console.log('Failed validating clone.')

                setState((prev) => ({
                    ...prev,
                    isWorking: false,
                    isValidating: false,
                    errorMessage: response.errorMessage,
                    showError: true,
                }))
                setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
                onDeploy(false)
            }
        } catch (error) {
            console.log('An error occurred: ', error)
            onDeploy(false)
            setState((prev) => ({ ...prev, isWorking: false, isValidating: false }))
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        }
    }

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }))
    }

    const showDeployingDetails = state.isWorking && !state.isValidating

    const renderValidating = () => {
        return (
            <Grid columns="auto 1fr" gap={16} style={{ margin: '10px 0 15px 10px' }}>
                <Heading type="normal">Validating</Heading>
                <LoaderDots color="black" />
            </Grid>
        )
    }

    const renderProgressDetails = () => {
        const isRunning = (currentState: State | undefined) => {
            if (
                currentState !== undefined &&
                currentState !== State.NotStarted &&
                currentState !== State.FinishedDeploying &&
                currentState !== State.FinishedRollback
            ) {
                return true
            }

            return false
        }

        return (
            <Grid
                rows="auto auto auto auto auto auto auto auto auto auto"
                columns="230px 80px 125px auto"
                style={{ margin: '10px 0 15px 10px' }}
                gap={4}
            >
                <Heading type="sub">Section</Heading>
                <Heading type="sub">Status</Heading>
                <Heading type="sub">State</Heading>
                <div />

                <Heading type="normal">Re-using authorization</Heading>
                <Heading type="normal">
                    {StatusToStringMap(state.deployResult?.status?.applicationRegistrations?.status || Status.None)}
                </Heading>
                <Heading type="normal">
                    {StateToStringMap(state.deployResult?.status?.applicationRegistrations?.state || State.NotStarted)}
                </Heading>
                {isRunning(state.deployResult?.status?.applicationRegistrations?.state || State.NotStarted) ? (
                    <LoaderDots color="black" />
                ) : (
                    <div />
                )}

                <Heading type="normal">Cloning Container</Heading>
                <Heading type="normal">
                    {StatusToStringMap(state.deployResult?.status?.containerRegistration?.status || Status.None)}
                </Heading>
                <Heading type="normal">
                    {StateToStringMap(state.deployResult?.status?.containerRegistration?.state || State.NotStarted)}
                </Heading>

                {isRunning(state.deployResult?.status?.containerRegistration?.state || State.NotStarted) ? (
                    <LoaderDots color="black" />
                ) : (
                    <div />
                )}

                <Heading type="normal">Cloning Servicebus, Queues & Topics</Heading>
                <Heading type="normal">
                    {StatusToStringMap(
                        state.deployResult?.status?.servicebusQueueAndTopicRegistrations?.status || Status.None,
                    )}
                </Heading>
                <Heading type="normal">
                    {StateToStringMap(
                        state.deployResult?.status?.servicebusQueueAndTopicRegistrations?.state || State.NotStarted,
                    )}
                </Heading>
                {isRunning(
                    state.deployResult?.status?.servicebusQueueAndTopicRegistrations?.state || State.NotStarted,
                ) ? (
                    <LoaderDots color="black" />
                ) : (
                    <div />
                )}

                <Heading type="normal">Cloning Database</Heading>
                <Heading type="normal">
                    {StatusToStringMap(state.deployResult?.status?.databaseRegistration?.status || Status.None)}
                </Heading>
                <Heading type="normal">
                    {StateToStringMap(state.deployResult?.status?.databaseRegistration?.state || State.NotStarted)}
                </Heading>

                {isRunning(state.deployResult?.status?.databaseRegistration?.state || State.NotStarted) ? (
                    <LoaderDots color="black" />
                ) : (
                    <div />
                )}

                {flowId && <Heading type="normal">Cloning Flow Instance</Heading>}
                {flowId && (
                    <Heading type="normal">
                        {StatusToStringMap(state.deployResult?.status?.flowDeployment?.status || Status.None)}
                    </Heading>
                )}
                {flowId && (
                    <Heading type="normal">
                        {StateToStringMap(state.deployResult?.status?.flowDeployment?.state || State.NotStarted)}
                    </Heading>
                )}

                {flowId &&
                    (isRunning(state.deployResult?.status?.flowDeployment?.state || State.NotStarted) ? (
                        <LoaderDots color="black" />
                    ) : (
                        <div />
                    ))}

                <Heading type="normal">Deploying to Cluster</Heading>
                <Heading type="normal">
                    {StatusToStringMap(state.deployResult?.status?.kubernetesDeployment?.status || Status.None)}
                </Heading>
                <Heading type="normal">
                    {StateToStringMap(state.deployResult?.status?.kubernetesDeployment?.state || State.NotStarted)}
                </Heading>

                {isRunning(state.deployResult?.status?.kubernetesDeployment?.state || State.NotStarted) ? (
                    <LoaderDots color="black" />
                ) : (
                    <div />
                )}

                <Heading type="normal">Sending invite to administrator</Heading>
                <Heading type="normal">
                    {StatusToStringMap(state.deployResult?.status?.permissionsDeployment?.status || Status.None)}
                </Heading>
                <Heading type="normal">
                    {StateToStringMap(state.deployResult?.status?.permissionsDeployment?.state || State.NotStarted)}
                </Heading>

                {isRunning(state.deployResult?.status?.permissionsDeployment?.state || State.NotStarted) ? (
                    <LoaderDots color="black" />
                ) : (
                    <div />
                )}
            </Grid>
        )
    }

    return (
        <Grid rows="auto 1fr auto" gap={20}>
            <Heading type="heading1">Clone '{deploymentName}'</Heading>

            <Input
                name="cloneDeploymentName"
                type="cloneDeploymentName"
                value={state.cloneDeploymentName}
                onChange={onInputChange}
                headerText={'Set new name to the Invision Instance clone'}
                placeholder="Invision Instance Name"
                style={{ width: '516px' }}
                readOnly={state.isWorking}
            />

            {flowId && (
                <Input
                    name="cloneFlowDeploymentName"
                    type="cloneFlowDeploymentName"
                    value={state.cloneFlowDeploymentName}
                    onChange={onInputChange}
                    headerText={'Connected Flow Instance to be cloned as'}
                    style={{ width: '516px' }}
                    readOnly={state.isWorking}
                />
            )}

            <Grid rows="1fr auto auto">
                <Heading type="heading1" style={{ visibility: state.isWorking ? 'visible' : 'hidden' }}>
                    {!state.showError && 'Progress'}
                    {showDeployingDetails && ' (' + state.currentTime + ')'}
                </Heading>
                {state.showError && <div style={{ fontStyle: 'italic', color: 'red' }}>{state.errorMessage}</div>}
                {state.isValidating && renderValidating()}
                {showDeployingDetails && renderProgressDetails()}
            </Grid>

            <Grid columns="1fr auto auto" className={styles.footer}>
                <div />
                {!state.isWorking && (
                    <Button
                        className={styles.deploybtn}
                        buttonType={ButtonType.Confirm}
                        onClick={handleCloneClicked}
                        disabled={state.isWorking}
                    >
                        Clone
                    </Button>
                )}
            </Grid>
        </Grid>
    )
}

export default CloneDeployment
