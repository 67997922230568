import cx from 'classnames'
import { IconProps } from '../IconProps'

export default function OnIcon({ width = 20, height = 20, className, style }: IconProps) {
    return (
        <svg
            fill="none"
            viewBox="0 0 30 30"
            width={width}
            height={height}
            className={cx('icon', className)}
            style={style}
            data-icon
        >
            <path d="M19 17C17.3431 17 16 15.6569 16 14C16 12.3431 17.3431 11 19 11C20.6569 11 22 12.3431 22 14C22 15.6569 20.6569 17 19 17ZM20 8C23.3137 8 26 10.6863 26 14C26 17.3137 23.3137 20 20 20H8C4.68629 20 2 17.3137 2 14C2 10.6863 4.68629 8 8 8H20ZM24.5 14C24.5 11.5147 22.4853 9.5 20 9.5H8C5.51472 9.5 3.5 11.5147 3.5 14C3.5 16.4853 5.51472 18.5 8 18.5H20C22.4853 18.5 24.5 16.4853 24.5 14Z" 
            fill="currentColor"/>
        </svg>
    )
}
