import * as React from 'react'
import styles from './Button.module.scss'
import cx from 'classnames'

export enum ButtonType {
    Default = 'default',
    Primary = 'primary',
    Secondary = 'secondary',
    Confirm = 'confirm',
    Link = 'link',
    Icon = 'icon',
}

type Props = {
    buttonType?: ButtonType
    pushRight?: boolean
    hotkey?: string
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

const Button = React.forwardRef<HTMLButtonElement, Props>(
    ({ className, buttonType = ButtonType.Default, pushRight, style, hotkey, children, ...props }: Props, ref) => {
        let btnStyle = pushRight ? { ...style, marginLeft: 'auto' } : style

        const innerRef = React.useRef<HTMLButtonElement>()
        const setRefs = (element: HTMLButtonElement) => {
            innerRef.current = element
            if (typeof ref === 'function') {
                ref(element)
            } else if (ref) {
                ref.current = element
            }
        }
        return (
            <button
                className={cx(styles.button, className, styles[buttonType])}
                style={btnStyle}
                {...props}
                ref={setRefs}
            >
                {children}
            </button>
        )
    },
)

export default Button
