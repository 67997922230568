import { FunctionComponent, useContext, useState } from 'react'
import Grid from '../../../infratructure/grid/Grid'
import Heading from '../../../infratructure/heading/Heading'
import styles from './ConnectToFlow.module.scss'
import { FlowInstanceModel, Tenant } from '../../Model/AllSparkModel'
import Select, { OptionType } from '../../../infratructure/select/Select'
import LoaderDots from '../../../infratructure/dots/LoaderDots'
import Button, { ButtonType } from '../../../infratructure/button/Button'
import { useGlobalStateContext } from '../../../../GlobalState'
import { AllSparkContext } from '../../../..'
import { ArrayUtil } from '../../../utils/ArrayUtil'
import Input from '../../../infratructure/input/Input'
import cx from 'classnames'

interface ConnectToFlowProps {
    deploymentName: string
    deploymentId: string
    flows: FlowInstanceModel[]
    onClose: () => void
}

type ConnectToFlowState = {
    isWorking: boolean
    isConnectedDone: boolean
    selectedFlowInstanceId: string
    errorMessage: string | undefined
    tenants: Tenant[]
    selectedTenant: string
    flowSubscriptionId: string
}

const ConnectToFlow: FunctionComponent<ConnectToFlowProps> = ({ deploymentName, deploymentId, flows, onClose }) => {
    const api = useContext(AllSparkContext)
    const { setGlobalState } = useGlobalStateContext()

    const [state, setState] = useState<ConnectToFlowState>({
        isWorking: false,
        isConnectedDone: false,
        selectedFlowInstanceId: '',
        errorMessage: undefined,
        tenants: [],
        selectedTenant: '',
        flowSubscriptionId: 'AllSparkDummySubscriptionId',
    })

    const onFlowSubscriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }))
    }

    const onFlowInstanceChange = async (flowInstance: OptionType) => {
        const tenants = await api.getFlowTenants(flowInstance.value.toString())
        setState((prev) => ({
            ...prev,
            selectedFlowInstanceId: flowInstance.value.toString(),
            tenants: tenants,
            selectedTenant: '',
        }))
    }

    const onTenantChange = (tenant: OptionType) => {
        setState((prev) => ({ ...prev, selectedTenant: tenant.value.toString() }))
    }

    const handleCloseClicked = () => {
        onClose()
        setState((prev) => ({ ...prev, upgradeErrorMessage: undefined }))
    }

    const handleConnectClicked = async () => {
        try {
            setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: true }))
            setState((prev) => ({ ...prev, isWorking: true }))

            let connectToFlowResponse = await api.connectFlowToInvision(
                deploymentId,
                state.selectedFlowInstanceId,
                state.flowSubscriptionId,
                state.selectedTenant,
            )

            if (connectToFlowResponse.success) {
                setState((prev) => ({
                    ...prev,
                    isWorking: false,
                    isConnectedDone: true,
                }))
            } else {
                setState((prev) => ({
                    ...prev,
                    isWorking: false,
                    isConnectedDone: false,
                    errorMessage: 'Unable to connect to Flow Instance.' + connectToFlowResponse.errorMessage,
                }))
            }
        } catch (error) {
            setState((prev) => ({
                ...prev,
                isWorking: false,
                isConnectedDone: true,
                errorMessage: 'Unable to connect to Flow Instance.' + error,
            }))
        } finally {
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        }
    }

    const isButtonDisabled =
        state.isWorking ||
        !state.selectedFlowInstanceId ||
        !state.flowSubscriptionId ||
        (state.tenants.length > 0 && !state.selectedTenant)

    return (
        <Grid rows="auto 1fr auto" gap={20} style={{ height: '250px' }}>
            <Heading type="heading1" className={styles.heading}>
                Connect Flow To Invision deployment '{deploymentName}'
            </Heading>
            <Grid rows="1fr auto auto" className={styles.main}>
                {!state.isWorking && !state.isConnectedDone && (
                    <Grid gap={20}>
                        <Select
                            headerText="Flow Instance"
                            isSearchable={false}
                            isClearable={false}
                            options={flows.sort(ArrayUtil.sortByPropCompare('name', true)).map((flow) => ({
                                label: flow.name,
                                value: flow.deploymentId,
                            }))}
                            value={state.selectedFlowInstanceId}
                            onChange={onFlowInstanceChange}
                            placeholder={'Select Flow Instance'}
                            isDisabled={state.isWorking}
                        />

                        {state.tenants?.length > 0 ? (
                            <Select
                                headerText="Tenant Id"
                                isSearchable={false}
                                isClearable={false}
                                options={state.tenants
                                    .sort(ArrayUtil.sortByPropCompare('name', true))
                                    .map((tenant) => ({
                                        label: tenant.name + ' (' + tenant.id + ')',
                                        value: tenant.id,
                                    }))}
                                value={state.selectedTenant}
                                onChange={onTenantChange}
                                placeholder={'Select Tenant Id'}
                                isDisabled={state.isWorking}
                            />
                        ) : state.selectedFlowInstanceId ? (
                            <div
                                style={{
                                    marginTop: '10px',
                                    marginBottom: '0px',
                                    fontSize: 'smaller',
                                }}
                            >
                                The Flow Instance has no tenants. A new will be created for you.
                            </div>
                        ) : (
                            <div />
                        )}

                        {!state.flowSubscriptionId && (
                            <Input
                                name="flowSubscriptionId"
                                type="flowSubscriptionId"
                                value={state.flowSubscriptionId}
                                onChange={onFlowSubscriptionChange}
                                headerText={'Flow Subscription Id'}
                                placeholder="Flow Subscription Id"
                                style={{ width: 'inherit' }}
                                readOnly={state.isWorking}
                                className={cx({
                                    [styles.requiredInput]: !Boolean(state.flowSubscriptionId),
                                })}
                            />
                        )}
                    </Grid>
                )}
                {state.isWorking && (
                    <Grid columns="auto 1fr" className={styles.centerSelf} gap={16}>
                        <div className={styles.centerSelf}>Connecting</div>
                        <LoaderDots color={'black'} />
                    </Grid>
                )}

                {state.isConnectedDone && !state.errorMessage && (
                    <div className={styles.centerSelf}>
                        Connected to Flow Instance '
                        {flows?.find((f) => f.deploymentId === state.selectedFlowInstanceId)?.name}' succeeded!
                    </div>
                )}

                {state.isConnectedDone && state.errorMessage && (
                    <div className={styles.centerSelf}>
                        Connect to Flow failed. Check system log for error messages.
                    </div>
                )}
            </Grid>

            <Grid columns="1fr auto auto" className={styles.footer}>
                <div />
                <Button
                    className={styles.deploybtn}
                    buttonType={ButtonType.Confirm}
                    onClick={state.isConnectedDone ? handleCloseClicked : handleConnectClicked}
                    disabled={isButtonDisabled}
                >
                    {state.isConnectedDone ? 'Close' : 'Connect'}
                </Button>
            </Grid>
        </Grid>
    )
}

export default ConnectToFlow
